import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton
} from "@mui/material";
import {
  CalendarTodayOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp
} from "@mui/icons-material";
import React, { useState } from "react";

import { CustomTypo } from "../pages/Vendors";
import DropDown from "../../shared/formElements/DropDown";
import { inventory_table_labels } from "../../constants";
import DateInput from "../../shared/formElements/DateInput";
import TextInput from "../../shared/formElements/TextInput";
import CheckBoxSelect from "../../shared/formElements/CheckboxSelect";
import { LoadingButton } from "@mui/lab";

const Filter = ({
  filters = [],
  control,
  onApply,
  onClear,
  isFetching,
  isLoading
}) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleAccordionChange = () => {
    setExpanded(!isExpanded);
  };

const getFilters = (filter, index) => {
    switch (filter.type) {
      case "DropDown": {
        return (
          <DropDown
            key={index}
            control={control}
            name={filter.name}
            label={filter.label}
            options={filter.options}
            filterCount={filter.filterCount}
            isLoading={isFetching}
            placeholder={filter?.placeholder ?? ""}
            unSelectText={filter?.unSelectText ?? ""}
            availUnselect={true}
            allowSearch={filter?.allowSearch}
          />
        );
      }
      case "DateInput": {
        return (
          <DateInput
            key={index}
            control={control}
            name={filter.name}
            label={filter.label}
            minDate={filter?.minDate || new Date("2000-01-01")}
            endIcon={CalendarTodayOutlined}
            placeholder={filter.placeholder}
            selectsRange={filter.selectsRange}
          />
        );
      }
      case "TextInput": {
        return (
          <TextInput
            key={index}
            control={control}
            name={filter.name}
            label={filter.label}
            filterCount={filter.filterCount}
            placeholder={filter?.placeholder ?? ""}
            onlyCaps={filter?.onlyCaps ?? false}
            isOrderTypeFilter={filter?.isOrderTypeFilter ?? false}
          />
        );
      }
      case "CheckboxSelect": {
        return (
          <CheckBoxSelect
            key={index}
            control={control}
            name={filter.name}
            label={filter.label}
            options={filter.options}
            isLoading={isFetching}
          />
        );
      }
      default:
        return <>No Filter</>;
    }
  };

  const WebView = (
    <Accordion
      elevation={0}
      sx={{ border: "0.6px solid #2773FF", borderRadius: "8px" }}
    >
      <AccordionSummary expanded={isExpanded} onClick={handleAccordionChange}>
        <CustomTypo color={"primary"}>
          {inventory_table_labels.applyFilters}
          <IconButton edge="end" disableRipple>
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </CustomTypo>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display={"flex"}
          justifyContent={filters?.length === 1 && "space-between"}
          alignItems={"flex-end"}
          gap={2.5}
        >
          <Box
            display={"flex"}
            width={filters?.length === 1 ? "160px" : "100%"}
            gap={2.5}
          >
            {filters.map((filter, index) => getFilters(filter, index))}
          </Box>
          <Box display={"flex"} alignItems={"flex-end"} gap={2.5}>
            <Button
              variant="contained"
              component={LoadingButton}
              loading={isLoading}
              sx={{ minWidth: "100px", minHeight: "42px" }}
              onClick={onApply}
            >
              {"Apply"}
            </Button>
            <Button
              variant="outlined"
              sx={{ minWidth: "100px", minHeight: "42px" }}
              onClick={onClear}
            >
              {"Clear"}
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  const MobileView = (
    <Box>
      <Grid container spacing={4} padding={"20px"}>
        {filters.map((filter, index) => (
          <Grid item xs={12} key={index}>
            {getFilters(filter, index)}
          </Grid>
        ))}
      </Grid>
      <Box
        display={"flex"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        width={"100%"}
        minHeight={"72px"}
        sx={{ background: "#F0F2F5" }}
      >
        <Button
          variant="contained"
          component={LoadingButton}
          loading={isLoading}
          sx={{ width: "100px", height: "40px" }}
          onClick={onApply}
        >
          {"Apply"}
        </Button>
        <Button
          variant="outlined"
          sx={{ width: "100px", height: "40px" }}
          onClick={onClear}
        >
          {"Clear"}
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>{WebView}</Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>{MobileView}</Box>
    </>
  );
};

export default Filter;
