import React, { Fragment } from "react";
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  Divider
} from "@mui/material";
import QRCode from "react-qr-code";
import moment from "moment";
import { currencyOnlyFormatter } from "../../../shared/utils";

const EwayPrintPage = ({ data }) => {
  const topPart = [
    "Mode",
    "Vehicle / Trans Doc No. & Dt.",
    "From",
    "Entered Date",
    "Entered By",
    "CEWB No. (If any)",
    "Multi Veh. Info (If any)"
  ];

  const partAData = [
    { key: 1, label: "E-Way Bill No.", value: data?.ewbNo },
    {
      key: 2,
      label: "E-Way Bill Date",
      value: `${moment(data?.genDate).format("DD/MM/YYYY")} ${moment(
        data?.genDate
      ).format("hh:mm A")}`
    },
    {
      key: 3,
      label: "Generated By",
      value: `${data?.genGstNo || "--"} `
    },
    {
      key: 4,
      label: "Valid From",
      value: `${moment(data?.genDate).format("DD/MM/YYYY")} ${moment(
        data?.genDate
      ).format("hh:mm A")} [${data?.transDistance}Kms]`
    },
    {
      key: 5,
      label: "Valid Until",
      value: `${moment(data?.ewbValidity).format("DD/MM/YYYY")}`
    },
    { key: 6, label: "Part - A", value: "" },
    {
      key: 7,
      label: "GSTIN of Supplier",
      value: `${data?.fromGstin},${data?.fromTrdName}`
    },
    {
      key: 8,
      label: "Place of Dispatch",
      value: `${data?.fromPlace}, ${data?.fromState?.displayName} - ${data?.fromPincode}`
    },
    {
      key: 9,
      label: "GSTIN of Recipient",
      value: `${data?.toGstin},${data?.toTrdName}`
    },
    {
      key: 10,
      label: "Place of Delivery",
      value: `${data?.toPlace}, ${data?.toState?.displayName} - ${data?.toPincode}`
    },
    { key: 11, label: "Document No.", value: data?.docNo },
    {
      key: 12,
      label: "Document Date",
      value: `${moment(data?.docDate).format("DD/MM/YYYY")}`
    },
    {
      key: 13,
      label: "Transaction Type",
      value: `${data?.transactionType?.displayName}`
    },
    {
      key: 14,
      label: "Value of Goods",
      value: currencyOnlyFormatter(data?.totInvValue)
    },
    {
      key: 15,
      label: "HSN Code",
      value: data?.items?.map((item) => item?.skuData?.hsn).join(",")
    },
    {
      key: 16,
      label: "Reason for Transportation",
      value: `${data?.supplyType?.displayName} - ${data?.subSupplyType?.displayName}`
    },
    {
      key: 17,
      label: "Transporter",
      value: data?.transporterName
    },
    { key: 18, label: "Part - B", value: "" }
  ];

  const qrData = {
    eWayBillNo: data?.ewbNo,
    eWayBillDate: `${moment(data?.genDate).format("DD/MM/YYYY")} ${moment(
      data?.ewbValidity
    ).format("hh:mm A")}`,
    validTill: `${moment(data?.ewbValidity).format("DD/MM/YYYY")} ${moment(
      data?.ewbValidity
    ).format("hh:mm A")}`
  };

  const getpartBData = (data) => {
    return data?.map((item) => ({
      mode: item?.transMode?.displayName,
      vehicleNo: item?.vehicleNo || "--",
      from: `${item?.fromPlace}, ${item?.fromState?.displayName}`,
      enteredDate: `${moment(item?.updDate).format("DD/MM/YYYY")} ${moment(
        item?.updDate
      ).format("hh:mm A")}`,
      enteredBy: `${item?.createdBy?.firstName || "--"} ${
        item?.createdBy?.lastName || ""
      }`
    }));
  };

  const partBData = getpartBData(data?.partBs);

  const renderTableCell = (item, isHeading = false) => (
    <TableCell
      sx={{
        border: "2.5px solid #98A0A9",
        fontWeight: isHeading ? 700 : 400,
        ...(item.label === "Part - A" || item.label === "Part - B"
          ? { fontWeight: 700, fontSize: 16, borderRight: "none" }
          : {})
      }}
    >
      {item.label || item}
    </TableCell>
  );

  return (
    <Grid container p={"20px 4px"}>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={0.5}
        border="2.5px solid #98A0A9"
        p="0 0 10px 0"
        mb="2px"
      >
        <Typography
          sx={{ fontSize: "25px", lineHeight: "30px", fontWeight: 700 }}
        >
          e-Way Bill
        </Typography>
        <Divider sx={{ border: "0.5px solid #B7BEC7", width: 150 }} />
        <QRCode value={JSON.stringify(qrData)} size={130} level="H" />
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableBody>
            {partAData.map((item) => (
              <TableRow key={item.key}>
                {renderTableCell(item)}
                <TableCell
                  sx={{
                    border: "2.5px solid #98A0A9",
                    fontWeight: 600,
                    ...(item.label === "Part - A" || item.label === "Part - B"
                      ? { borderLeft: "none" }
                      : item.key === 1
                      ? { fontSize: 17 }
                      : {})
                  }}
                >
                  {item.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ border: "2.5px solid #98A0A9", p: "5px", borderTop: "none" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {topPart.map((item, index) => (
                <Fragment key={index}>
                  {renderTableCell({ label: item }, true)}
                </Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {partBData.map((item) => (
              <TableRow key={item.mode}>
                {Object.values(item).map((value, index) => (
                  <TableCell key={index} sx={{ border: "2.5px solid #98A0A9" }}>
                    {value}
                  </TableCell>
                ))}
                <TableCell sx={{ border: "2.5px solid #98A0A9" }}>-</TableCell>
                <TableCell sx={{ border: "2.5px solid #98A0A9" }}>-</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          border: "2.5px solid #98A0A9",
          p: "15px 5px",
          borderTop: "none"
        }}
      >
        <Typography sx={{ fontSize: 12, lineHeight: "15px" }}>
          Note*: If any discrepancy in information please try after sometime.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EwayPrintPage;
