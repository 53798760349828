// import * as yup from "yup";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import TextArea from "../../../shared/formElements/TextArea";

import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Backdrop
} from "@mui/material";
import CustomTable from "../../../shared/customTable";
import SuccessfulDialog from "../../../shared/UiElements/SuccessfulDialog";
import { useCustomDialog } from "../../../shared/customDialog";
import { useFieldArray, useForm } from "react-hook-form";
import { gatePassKeys } from "../../../constants/formKeys";
import { Link } from "react-router-dom";
import {
  TotalBreakup,
  reportLabel2
} from "../../../purchase/pages/CreateOrder";
import { purchase_labels } from "../../../constants";
// import { useFetchDCByGPId } from "../../query-hooks/ClientOrderDeliveryChallan/useFetchDCByGPId";
import { useFetchDCFormData } from "../../../query-hooks/ClientOrderDeliveryChallan/useFetchDCFormData";
import moment from "moment";
import { gstColumn } from "../../../purchase/components/Purchase/PurchaseOrderTable";
import { currencyOnlyFormatter } from "../../../shared/utils";
import { ToWords } from "to-words";
// import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateDC } from "../../../query-hooks/ClientOrderDeliveryChallan/useCreateDC";
import { useDeleteDCDraft } from "../../../query-hooks/ClientOrderDeliveryChallan/useDeleteDCDraft";
import { useSaveDC } from "../../../query-hooks/ClientOrderDeliveryChallan/useSaveDC";
import { useSubmitDCDraft } from "../../../query-hooks/ClientOrderDeliveryChallan/useSubmitDCDraft";
import { enqueueSnackbar } from "notistack";
import { useUpdateDCDraft } from "../../../query-hooks/ClientOrderDeliveryChallan/useUpdateDCDraft";
import { AppContext } from "../../../shared/context/auth-context";
import PrintableTable from "../../../purchase/components/PrintableTable";
import { useReactToPrint } from "react-to-print";
import {
  AddressSection,
  LabelTextInput,
  LabelValueText
} from "../../pages/MaterialReceivedReports";
import MobileOrdersPage, { DocInfo } from "../MobileOrdersPage";
import {
  ButtonGroup,
  MobileLabelInput
} from "../MaterialReceivedReport/MrrTable";
import MobileDCTable from "./MobileDCTable";
import TextInput from "../../../shared/formElements/TextInput";
import { VerifiedPhone } from "../../../customer/pages/Profile";
import { BlockedIcon } from "../../../purchase/pages/AddVendor";
import ConfirmEwayBillNo from "./ConfirmEwayBillNo";
import EwayBillLogin from "../EwayBills/EwayBillLogin";
import { LoadingButton } from "@mui/lab";
import { useFetchEwayBillByNo } from "../../../query-hooks/ClientEwayBill/useFetchEwayBillByNo";

const getColumns = (gstType) => {
  return [
    {
      Header: "S.No",
      accessor: "sNo",
      width: 90,
      size: 0.5
    },
    {
      Header: "Item/ SKU",
      accessor: "item",
      width: 200,
      size: 2,
      Cell: ({ row, value }) => {
        return row.original?.skuCode ? (
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {row.original?.image?.thumbUrl && (
                <img
                  src={row.original?.image?.thumbUrl}
                  alt={row.original?.brandName ?? "--"}
                  height={52}
                  width={50}
                  style={{ objectFit: "contain" }}
                />
              )}
              <Typography
                fontSize={12}
                fontWeight={600}
                color={"primary"}
                component={Link}
              >
                {row.original?.skuCode}
              </Typography>
            </Box>
            <Typography fontSize={13} color={"text.secondary"}>
              {`HSN : ${row.original?.hsn ?? "--"}`}
            </Typography>
          </Box>
        ) : (
          value
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      width: 180,
      size: 1.5,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          row.original?.productName && (
            <Box display={"flex"} flexDirection={"column"} gap={0.5}>
              <Typography fontSize={14}>{`Product: ${
                row.original?.productName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Category: ${
                row.original?.categoryName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                row.original?.brandName ?? "--"
              }`}</Typography>
            </Box>
          )
        );
      }
    },
    {
      Header: "UOM",
      accessor: "uom",
      disableSortBy: true,
      size: 0.5,
      Cell: ({ row }) => {
        return (
          row.original?.unit && (
            <Typography>{row.original?.unit?.name}</Typography>
          )
        );
      }
    },
    {
      Header: "Price",
      accessor: "rate",
      size: 1,
      disableSortBy: true
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      size: 1,
      disableSortBy: true
    },
    {
      Header: "Amount",
      accessor: "amount",
      size: 1.5,
      disableSortBy: true
    },
    ...[
      ...(gstType
        ? [
            {
              Header: "CGST",
              accessor: "cgst",
              disableSortBy: true,
              size: 1,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.cgst ?? 0,
                  row.original?.gstRegime ? row.original?.gstRegime / 2 : 0
                );
              }
            },
            {
              Header: "SGST",
              accessor: "sgst",
              disableSortBy: true,
              size: 1,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.sgst,
                  row.original?.gstRegime ? row.original?.gstRegime / 2 : 0
                );
              }
            }
          ]
        : [
            {
              Header: "IGST",
              accessor: "igst",
              disableSortBy: true,
              size: 1,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.igst ?? 0,
                  row.original?.gstRegime ? row.original?.gstRegime : 0
                );
              }
            }
          ])
    ],
    {
      Header: "Total",
      accessor: "total",
      size: 2,
      disableSortBy: true
    }
  ];
};

const getRows = (rows = [], gstType) => {
  return rows.map((row, index) => ({
    sNo: index + 1,
    ...row,
    rate: currencyOnlyFormatter(parseFloat(row?.rate).toFixed(2)),
    amount: currencyOnlyFormatter(parseFloat(row?.amount).toFixed(2)),
    cgst: currencyOnlyFormatter(parseFloat(row?.cgst).toFixed(2)),
    sgst: currencyOnlyFormatter(parseFloat(row?.sgst).toFixed(2)),
    igst: currencyOnlyFormatter(parseFloat(row?.igst).toFixed(2)),
    total: currencyOnlyFormatter(parseFloat(row?.total).toFixed(2)),
    gstType: gstType
  }));
};

const DCButtons = ({ status, onSubmit, onSaveDraft, onDeleteClick }) => {
  return status === "" ? (
    <>
      <Button
        variant="link"
        disableRipple
        sx={{ width: "79px" }}
        onClick={onSaveDraft}
      >
        {"Save Draft"}
      </Button>
      <Button
        variant="link"
        disableRipple
        sx={{ width: "79px" }}
        onClick={onSubmit}
      >
        {"Submit DC"}
      </Button>
    </>
  ) : status === "Draft" ? (
    <>
      <Button
        variant="link"
        disableRipple
        sx={{ minWidth: "90px" }}
        onClick={onSaveDraft}
      >
        {"Update Draft"}
      </Button>
      <Button
        variant="link"
        disableRipple
        sx={{ color: "error.main", minWidth: "90px" }}
        onClick={onDeleteClick}
      >
        {"Delete Draft"}
      </Button>
      <Button
        variant="link"
        disableRipple
        sx={{ minWidth: "90px" }}
        onClick={onSubmit}
      >
        {"Submit Draft"}
      </Button>
    </>
  ) : (
    <></>
  );
};

const DCOrderInfo = ({ orderData, deliveryChallanData, logo }) => {
  return (
    <Grid container display={"flex"} justifyContent={"space-between"}>
      <Grid
        item
        xs={4}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        gap={2}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
          <LabelValueText
            label={"Challan No :"}
            value={deliveryChallanData?.code ?? "--"}
          />
          <LabelValueText
            label={"Challan date :"}
            value={
              deliveryChallanData?.docDate
                ? moment(deliveryChallanData?.docDate).format("DD-MM-YYYY")
                : "--"
            }
          />
          <LabelValueText
            label={`${orderData?.orderType?.displayName} No :`}
            value={orderData?.code}
          />
          <LabelValueText
            label={"Order date :"}
            value={moment(orderData?.orderDate).format("DD-MM-YYYY")}
          />
        </Box>
        <Box textAlign={"left"}>
          <AddressSection
            title={
              orderData?.orderType?.name === "PurchaseReturn"
                ? "Buyer Address"
                : "Seller Address"
            }
            name={orderData?.clientData?.name}
            addressLine1={orderData?.clientData?.companyName ?? ""}
            addressLine2={`${orderData?.clientData?.billing?.address?.address}, ${orderData?.clientData?.billing?.address?.area}, ${orderData?.clientData?.billing?.address?.city},${orderData?.clientData?.billing?.address?.state} ${orderData?.clientData?.billing?.address?.pincode}`}
            addressLine3={
              orderData?.clientData?.billing?.gstNo
                ? `GSTIN: ${orderData?.clientData?.billing?.gstNo}`
                : ""
            }
          />
        </Box>
      </Grid>
      {logo?.thumbUrl && (
        <Grid
          item
          xs={4}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img src={logo?.thumbUrl} alt="Company Logo" />
        </Grid>
      )}
      <Grid
        item
        xs={4}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
          <AddressSection
            title={
              orderData?.orderType?.name === "PurchaseReturn"
                ? "Seller Address"
                : "Buyer Address"
            }
            name={orderData?.vendorData?.name ?? ""}
            addressLine1={`${orderData?.vendorData?.companyName ?? ""}`}
            addressLine2={`${orderData?.vendorData?.billing?.address?.address}, ${orderData?.vendorData?.billing?.address?.area}, ${orderData?.vendorData?.billing?.address?.city},${orderData?.vendorData?.billing?.address?.state} ${orderData?.vendorData?.billing?.address?.pincode}`}
            addressLine3={
              orderData?.vendorData?.billing?.gstNo
                ? `GSTIN: ${orderData?.vendorData?.billing?.gstNo}`
                : ""
            }
          />
        </Box>
        <Box textAlign={"left"}>
          <AddressSection
            title={"Ship To"}
            name={
              orderData?.orderType?.name === "PurchaseReturn"
                ? orderData?.vendorData?.companyName
                : orderData?.vendorData?.warehouse?.code
            }
            addressLine1={`${orderData?.vendorData?.name ?? ""}`}
            addressLine2={`${orderData?.vendorData?.warehouse?.address?.address}, ${orderData?.vendorData?.warehouse?.address?.area}, ${orderData?.vendorData?.warehouse?.address?.city},${orderData?.vendorData?.warehouse?.address?.state} ${orderData?.vendorData?.warehouse?.address?.pincode}`}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const StockTranferDCOrderInfo = ({ orderData, deliveryChallanData, logo }) => {
  return (
    <Grid container display={"flex"} justifyContent={"space-between"}>
      <Grid item xs={4} display={"flex"} flexDirection={"column"} gap={6}>
        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
          <LabelValueText
            label={"Challan No :"}
            value={deliveryChallanData?.code ?? "--"}
          />
          <LabelValueText
            label={"Challan date :"}
            value={
              deliveryChallanData?.docDate
                ? moment(deliveryChallanData?.docDate).format("DD-MM-YYYY")
                : "--"
            }
          />
        </Box>
        <Box textAlign={"left"}>
          <AddressSection
            title={"Ship From"}
            name={orderData?.clientData?.warehouse?.code}
            addressLine1={orderData?.clientData?.companyName ?? ""}
            addressLine2={`${orderData?.clientData?.warehouse?.address?.address}, ${orderData?.clientData?.warehouse?.address?.area}, ${orderData?.clientData?.warehouse?.address?.city},${orderData?.clientData?.warehouse?.address?.state} ${orderData?.clientData?.warehouse?.address?.pincode}`}
          />
        </Box>
      </Grid>
      {logo?.thumbUrl && (
        <Grid
          item
          xs={4}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img src={logo?.thumbUrl} alt="Company Logo" />
        </Grid>
      )}
      <Grid
        item
        xs={4}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
        gap={6}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
          <LabelValueText
            label={`${orderData?.orderType?.displayName} No :`}
            value={orderData?.code}
          />
          <LabelValueText
            label={"Order date :"}
            value={moment(orderData?.orderDate).format("DD-MM-YYYY")}
          />
        </Box>
        <Box textAlign={"left"}>
          <AddressSection
            title={"Ship To"}
            name={orderData?.vendorData?.warehouse?.code}
            addressLine1={`${orderData?.clientData?.companyName ?? ""}`}
            addressLine2={`${orderData?.vendorData?.warehouse?.address?.address}, ${orderData?.vendorData?.warehouse?.address?.area}, ${orderData?.vendorData?.warehouse?.address?.city},${orderData?.vendorData?.warehouse?.address?.state} ${orderData?.vendorData?.warehouse?.address?.pincode}`}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const getAccordianInfo = (isStockTranfer = false, orderData) => {
  return isStockTranfer
    ? [
        {
          title: "Ship From",
          name: orderData?.clientData?.warehouse?.code,
          addressLine1: orderData?.clientData?.companyName ?? "",
          addressLine2: `${orderData?.clientData?.warehouse?.address?.address}, ${orderData?.clientData?.warehouse?.address?.area}, ${orderData?.clientData?.warehouse?.address?.city},${orderData?.clientData?.warehouse?.address?.state} ${orderData?.clientData?.warehouse?.address?.pincode}`
        },
        {
          title: "Ship To",
          name: orderData?.vendorData?.warehouse?.code,
          addressLine1: `${orderData?.clientData?.companyName ?? ""}`,
          addressLine2: `${orderData?.vendorData?.warehouse?.address?.address}, ${orderData?.vendorData?.warehouse?.address?.area}, ${orderData?.vendorData?.warehouse?.address?.city},${orderData?.vendorData?.warehouse?.address?.state} ${orderData?.vendorData?.warehouse?.address?.pincode}`
        }
      ]
    : [
        {
          title:
            orderData?.orderType?.name === "PurchaseReturn"
              ? "Buyer Address"
              : "Seller Address",
          name: orderData?.clientData?.name,
          addressLine1: orderData?.clientData?.companyName ?? "",
          addressLine2: `${orderData?.clientData?.billing?.address?.address}, ${orderData?.clientData?.billing?.address?.area}, ${orderData?.clientData?.billing?.address?.city},${orderData?.clientData?.billing?.address?.state} ${orderData?.clientData?.billing?.address?.pincode}`,
          addressLine3: orderData?.clientData?.billing?.gstNo
            ? `GSTIN: ${orderData?.clientData?.billing?.gstNo}`
            : ""
        },
        {
          title:
            orderData?.orderType?.name === "PurchaseReturn"
              ? "Seller Address"
              : "Buyer Address",
          name: orderData?.vendorData?.name ?? "",
          addressLine1: `${orderData?.vendorData?.companyName ?? ""}`,
          addressLine2: `${orderData?.vendorData?.billing?.address?.address}, ${orderData?.vendorData?.billing?.address?.area}, ${orderData?.vendorData?.billing?.address?.city},${orderData?.vendorData?.billing?.address?.state} ${orderData?.vendorData?.billing?.address?.pincode}`,
          addressLine3: orderData?.vendorData?.billing?.gstNo
            ? `GSTIN: ${orderData?.vendorData?.billing?.gstNo}`
            : ""
        },
        {
          title: "Ship To",
          name:
            orderData?.orderType?.name === "PurchaseReturn"
              ? orderData?.vendorData?.companyName
              : orderData?.vendorData?.warehouse?.code,
          addressLine1: `${orderData?.vendorData?.name ?? ""}`,
          addressLine2: `${orderData?.vendorData?.warehouse?.address?.address}, ${orderData?.vendorData?.warehouse?.address?.area}, ${orderData?.vendorData?.warehouse?.address?.city},${orderData?.vendorData?.warehouse?.address?.state} ${orderData?.vendorData?.warehouse?.address?.pincode}`
        }
      ];
};

// const DCSchema = yup.object({
//   [gatePassKeys.EwayBillNo]: yup.string().required(error_msg.required),
//   [gatePassKeys.transporter]: yup.string().required(error_msg.required)
// });

const DeliveryChallanTable = ({
  gatePassId,
  dcId,
  DcData,
  orderInfo,
  DcCreateAllowed,
  refectDocs,
  ewayButton
}) => {
  const [isEwayPresent, setIsEwayPresent] = useState(false);
  const [isEwayChecked, setIsEwayChecked] = useState("none");
  const [ewayId, setEwayId] = useState(null);
  const { profileData } = useContext(AppContext);
  const { logo } = profileData?.user?.client;

  const { control, setValue, handleSubmit, reset, watch } = useForm();
  const { showDialog, hideDialog } = useCustomDialog();
  const toWords = new ToWords();

  const { fields, append } = useFieldArray({
    control,
    name: "orderItems"
  });

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: DcData?.code
  });

  const ewayWatch = watch(gatePassKeys.EwayBillNo);

  // const {
  //   data: DCDataByGp,
  //   isFetching: isFetchingDCByGPId,
  //   refetch: refetchDCByGPId
  // } = useFetchDCByGPId(gatePassId);

  const { data: DCFormData, isFetching: isFetchingDCFormData } =
    useFetchDCFormData(dcId, gatePassId);

  const {
    data: ewayData,
    isFetching: isFetchingEwayBill,
    isError: isEwayError,
    error: ewayError,
    refetch: refetchEway,
    isSuccess: isEwaySuccess
  } = useFetchEwayBillByNo(isEwayPresent, ewayWatch);

  const EwayBillLoginShow = useCallback(
    (autoSubmit) => {
      showDialog({
        component: (
          <EwayBillLogin hideDialog={hideDialog} autoSubmit={autoSubmit} />
        ),
        size: "xs",
        closeIcon: true
      });
    },
    [showDialog, hideDialog]
  );

  const {
    mutate: createDC,
    isLoading: isCreating,
    isError: isCreateError,
    error: createError
  } = useCreateDC();

  const {
    mutate: saveDraftDC,
    isLoading: isSavingDraft,
    isError: isSaveDraftError,
    error: saveDraftError
  } = useSaveDC();

  const {
    mutate: updateDraftDC,
    isLoading: isUpdatingDraft,
    isError: isUpdateDraftError,
    error: updateDraftError
  } = useUpdateDCDraft();

  const {
    mutate: submitDCDraft,
    isLoading: isSubmittingDraft,
    isError: isSubmitDraftError,
    error: submitDraftError
  } = useSubmitDCDraft();

  const {
    mutate: deleteDCDraft,
    isLoading: isDeleting,
    isError: isDeleteDraftError,
    error: deleteDraftError
  } = useDeleteDCDraft();

  useEffect(() => {
    if (DcData === null) {
      DCFormData?.gatePass?.items?.forEach((item, index) => {
        append({
          rate: item.rate,
          quantity: item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          gstRegime: item?.taxes[0]?.totalAmount
            ? item?.skuData?.gstRegime ?? 0
            : 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image,
          amount: currencyOnlyFormatter(
            parseFloat(item?.subTotalAmount).toFixed(2)
          ),
          cgst: currencyOnlyFormatter(
            parseFloat(item?.taxes[0]?.totalAmount ?? 0).toFixed(2)
          ),
          sgst: currencyOnlyFormatter(
            parseFloat(item?.taxes[1]?.totalAmount ?? 0).toFixed(2)
          ),
          igst: currencyOnlyFormatter(
            parseFloat(item?.taxes[0]?.totalAmount ?? 0).toFixed(2)
          ),
          total: currencyOnlyFormatter(parseFloat(item?.totalAmount).toFixed(2))
        });
      });
    }
    if (Boolean(DcData)) {
      setValue(gatePassKeys.EwayBillNo, DcData?.ewbNo);
      setValue(gatePassKeys.transporter, DcData?.transporter);
      setValue("tnc", DcData?.remarks);
      DcData?.items?.forEach((item, index) => {
        append({
          rate: item.rate,
          quantity: item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          gstRegime: item?.taxes[0]?.totalAmount
            ? item?.skuData?.gstRegime ?? 0
            : 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image,
          amount: currencyOnlyFormatter(
            parseFloat(item?.subTotalAmount).toFixed(2)
          ),
          cgst: currencyOnlyFormatter(
            parseFloat(item?.taxes[0]?.totalAmount ?? 0).toFixed(2)
          ),
          sgst: currencyOnlyFormatter(
            parseFloat(item?.taxes[1]?.totalAmount ?? 0).toFixed(2)
          ),
          igst: currencyOnlyFormatter(
            parseFloat(item?.taxes[0]?.totalAmount ?? 0).toFixed(2)
          ),
          total: currencyOnlyFormatter(parseFloat(item?.totalAmount).toFixed(2))
        });
      });
    }
  }, [DcData, DCFormData?.gatePass?.items, append, setValue]);

  const getEndIcon = (type) => {
    switch (type) {
      case "none":
        return false;
      case "1":
        return VerifiedPhone;
      case "2":
        return BlockedIcon;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (ewayWatch?.length !== 12) {
      setIsEwayChecked("none");
      setIsEwayPresent(false);
      setEwayId(null);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(ewayWatch)]);

  const handleCheck = () => {
    showDialog({
      component: (
        <ConfirmEwayBillNo
          data={ewayData?.eWayBill}
          hideDialog={hideDialog}
          btn1Text={"Confirm"}
          btn1Variant="contained"
          btn2Text={"Clear"}
          btn2Variant="outlined"
          btn1Callback={hideDialog}
          btn2Callback={() => {
            setValue(gatePassKeys.EwayBillNo, "");
            hideDialog();
            setIsEwayChecked("none");
            setEwayId(null);
            setIsEwayPresent(false);
          }}
        />
      ),
      backdropOff: true,
      closeIcon: true
    });
  };

  useEffect(() => {
    if (isEwaySuccess) {
      setIsEwayChecked("1");
      setIsEwayPresent(false);
      setEwayId(ewayData?.eWayBill?._id);
      handleCheck();
    } // eslint-disable-next-line
  }, [isEwaySuccess, setIsEwayChecked]);

  const onCheckClick = () => {
    if (ewayWatch?.length === 12) {
      setIsEwayPresent(true);
    } else {
      enqueueSnackbar("Enter 12 Digit Eway Bill No.", {
        variant: "error"
      });
    }
  };

  const onSaveDraft = (data) => {
    const dcData = {
      ...{
        ...(ewayId ? { ewbId: ewayId } : {})
      },
      ...{
        ...(data[gatePassKeys.transporter]
          ? { transporter: data[gatePassKeys.transporter] }
          : {})
      },
      remarks: data["tnc"] ?? ""
    };
    dcId
      ? updateDraftDC(
          {
            data: dcData,
            dcId: dcId
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("Delivery Challan Draft Updated!", {
                variant: "success"
              });
              reset();
              refectDocs();
            }
          }
        )
      : saveDraftDC(
          {
            data: dcData,
            gatePassId: gatePassId
          },
          {
            onSuccess: (res) => {
              enqueueSnackbar("Delivery Challan Draft Saved!", {
                variant: "success"
              });
              reset();
              refectDocs();
            }
          }
        );
  };

  const submitData = (data) => {
    const dcData = {
      ...{
        ...(ewayId ? { ewbId: ewayId } : {})
      },
      ...{
        ...(data[gatePassKeys.transporter]
          ? { transporter: data[gatePassKeys.transporter] }
          : {})
      },
      remarks: data["tnc"] ?? ""
    };
    dcId
      ? submitDCDraft(
          {
            data: dcData,
            dcId: dcId
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("Delivery Challan Created!", {
                variant: "success"
              });
              reset();
              refectDocs();
            }
          }
        )
      : createDC(
          {
            data: dcData,
            gatePassId: gatePassId
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("Delivery Challan Created!", {
                variant: "success"
              });
              reset();
              refectDocs();
            }
          }
        );
  };

  const onSubmitClick = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to submit the document ?"}
          subText={"Submitted documents cannot be edited. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Submit"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => submitData(data)}
        />
      )
    });
  };

  const deleteDraft = () => {
    deleteDCDraft(
      {
        dcId: dcId
      },
      {
        onSuccess: (res) => {
          hideDialog();
          enqueueSnackbar("Draft Deleted!", {
            variant: "success"
          });
          reset();
          refectDocs();
        }
      }
    );
  };

  const onDeleteClick = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Delete this draft ?"}
          subText={"Deleted documents cannot be recovered. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Delete"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => deleteDraft()}
        />
      )
    });
  };

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSubmitDraftError) {
      enqueueSnackbar(submitDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDraftError)
      enqueueSnackbar(saveDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isUpdateDraftError)
      enqueueSnackbar(updateDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isDeleteDraftError) {
      enqueueSnackbar(deleteDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    submitDraftError?.response?.data?.message,
    createError?.response?.data?.message,
    saveDraftError?.response?.data?.message,
    updateDraftError?.response?.data?.message,
    deleteDraftError?.response?.data?.message,
    isSaveDraftError,
    isUpdateDraftError,
    isSubmitDraftError,
    isCreateError,
    isDeleteDraftError
  ]);

  const DeliveryChallanDoc = ({ children }) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={3}
        border={"0.7px solid #6097FF"}
        borderRadius={"8px"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"30px 20px"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "24px"
            }}
          >
            {"Delivery Challan"}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "20px"
            }}
          >
            {(DCFormData?.order?.orderType?.name === "PurchaseReturn" ||
              orderInfo?.orderType?.name === "PurchaseReturn") &&
              "(Sales Returns)"}
            {(DCFormData?.order?.orderType?.name === "StockTransfer" ||
              orderInfo?.orderType?.name === "StockTransfer") &&
              "(For Internal Stock Transfer, Not for Sale)"}
          </Typography>
        </Box>
        {DCFormData?.order?.orderType?.name === "StockTransfer" ||
        orderInfo?.orderType?.name === "StockTransfer" ? (
          <StockTranferDCOrderInfo
            orderData={orderInfo ?? DCFormData?.order}
            deliveryChallanData={DcData ?? ""}
            logo={logo}
          />
        ) : (
          <DCOrderInfo
            orderData={orderInfo ?? DCFormData?.order}
            deliveryChallanData={DcData ?? ""}
            logo={logo}
          />
        )}
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          columnSpacing={2}
        >
          {DcCreateAllowed &&
          DcData?.status?.name !== "Placed" &&
          DcData?.status?.name !== "Delivered" ? (
            <>
              <Grid item xs={5}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "17px",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {"Eway Bill No.:"}
                  </Typography>
                  <TextInput
                    control={control}
                    name={gatePassKeys.EwayBillNo}
                    endIcon={getEndIcon(isEwayChecked)}
                    endButton={() =>
                      !getEndIcon(isEwayChecked) && (
                        <Button
                          variant="link"
                          component={LoadingButton}
                          loading={isFetchingEwayBill}
                          disabled={!DcCreateAllowed}
                          onClick={onCheckClick}
                        >
                          {"Check"}
                        </Button>
                      )
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <LabelTextInput
                  control={control}
                  name={gatePassKeys.transporter}
                  label={"Transporter.:"}
                  placeholder="ABC Logistics"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={5}>
                <LabelValueText
                  label={"Eway Bill No.:"}
                  value={DcData?.ewbNo ?? "--"}
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValueText
                  label={"Transporter.:"}
                  value={DcData?.transporter ?? "--"}
                />
              </Grid>
            </>
          )}
          <Grid item xs={3}>
            <LabelValueText
              label={"Truck Reg No.:"}
              value={
                DcData?.vehicle?.regNo
                  ? DcData?.vehicle?.regNo
                  : DCFormData?.gatePass?.vehicle?.regNo
              }
            />
          </Grid>
        </Grid>
        {children}
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Box display={"flex"} flexDirection={"column"} gap={3}>
              <Box width={"500px"}>
                <TextArea
                  control={control}
                  name={"tnc"}
                  label={purchase_labels.otherItemsAndConditionsLabel}
                  disabled={
                    Boolean(
                      DcData?.status?.name === "Placed" ||
                        DcData?.status?.name === "Delivered"
                    ) || !DcCreateAllowed
                  }
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"} gap={2.5}>
                {reportLabel2(
                  purchase_labels.amountInWordsLabel,
                  `${toWords.convert(
                    DcData?.totalAmount ??
                      DCFormData?.gatePass?.totalAmount ??
                      0,
                    {
                      currency: true
                    }
                  )}`
                )}
                {reportLabel2(
                  purchase_labels.preparedByLabel,
                  DcData?.preparedBy
                    ? `${DcData?.preparedBy?.firstName} ${DcData?.preparedBy?.lastName}`
                    : "--"
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <TotalBreakup
              gstType={
                Boolean(DcData)
                  ? DcData?.taxes?.length > 1
                  : DCFormData?.gatePass?.taxes?.length > 1
              }
              subTotal={
                DcData?.subTotalAmount ??
                DCFormData?.gatePass?.subTotalAmount ??
                0
              }
              cgst={
                DcData?.taxes[0]?.totalAmount
                  ? DcData?.taxes[0]?.totalAmount ?? 0
                  : DCFormData?.gatePass?.taxes[0]?.totalAmount ?? 0
              }
              sgst={
                DcData?.taxes[1]?.totalAmount
                  ? DcData?.taxes[1]?.totalAmount ?? 0
                  : DCFormData?.gatePass?.taxes[1]?.totalAmount ?? 0
              }
              igst={
                DcData?.taxes[0]?.totalAmount
                  ? DcData?.taxes[0]?.totalAmount
                  : DCFormData?.gatePass?.taxes[0]?.totalAmount ?? 0
              }
              total={
                DcData?.totalAmount ?? DCFormData?.gatePass?.totalAmount ?? 0
              }
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const DcTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns(
          Boolean(DcData)
            ? DcData?.taxes?.length > 1
            : DCFormData?.gatePass?.taxes?.length > 1
        )}
        data={getRows(
          fields,
          Boolean(
            Boolean(DcData)
              ? DcData?.taxes?.length > 1
              : DCFormData?.gatePass?.taxes?.length > 1
          )
        )}
        isLoading={isFetchingDCFormData}
        mobileComponent={MobileDCTable}
      />
    ), // eslint-disable-next-line
    [control, fields, isFetchingDCFormData, DcData]
  );

  const OtherInfoLabelText = [
    {
      label: "Eway Bill No.:",
      value: DcData?.ewbNo ?? "--"
    },
    {
      label: "Transporter.:",
      value: DcData?.transporter ?? "--"
    },
    {
      label: "Truck Reg No.:",
      value: DcData?.vehicle?.regNo
    }
  ];

  const OtherDetailsComponent =
    DcCreateAllowed &&
    DcData?.status?.name !== "Placed" &&
    DcData?.status?.name !== "Delivered" ? (
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Grid container columnSpacing={1}>
          <Grid
            item
            xs={4}
            display={"flex"}
            justifyContent={"right"}
            alignItems={"center"}
          >
            <Typography fontSize={14} fontWeight={400} lineHeight={"16.94px"}>
              {"Eway Bill No.:"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={gatePassKeys.EwayBillNo}
              endIcon={getEndIcon(isEwayChecked)}
              endButton={() =>
                !getEndIcon(isEwayChecked) && (
                  <Button
                    variant="link"
                    component={LoadingButton}
                    loading={isFetchingEwayBill}
                    disabled={!DcCreateAllowed}
                    onClick={onCheckClick}
                  >
                    {"Check"}
                  </Button>
                )
              }
              sx={{ maxHeight: "30px" }}
            />
          </Grid>
        </Grid>
        <MobileLabelInput
          control={control}
          name={gatePassKeys.transporter}
          label={"Transporter.:"}
          placeholder="ABC Logistics"
        />
        <DocInfo
          label={"Truck Reg No.:"}
          value={
            DcData?.vehicle?.regNo
              ? DcData?.vehicle?.regNo
              : DCFormData?.gatePass?.vehicle?.regNo
          }
        />
      </Box>
    ) : (
      <Grid container rowSpacing={2}>
        {OtherInfoLabelText?.map((item, index) => (
          <DocInfo key={index} label={item.label} value={item.value} />
        ))}
      </Grid>
    );

  useEffect(() => {
    if (isEwayError) {
      if (ewayError?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(refetchEway);
      } else {
        enqueueSnackbar(ewayError?.response?.data?.message, {
          variant: "error"
        });
        setIsEwayPresent(false);
      }
    }
    // eslint-disable-next-line
  }, [ewayError?.response?.data?.message, isEwayError]);

  const onSaveDraftCheck = () => {
    if (isEwayChecked !== "1" && Boolean(ewayWatch)) {
      onCheckClick();
    } else {
      handleSubmit(onSaveDraft)();
    }
  };

  const onSubmitCheck = () => {
    if (isEwayChecked !== "1" && Boolean(ewayWatch)) {
      onCheckClick();
    } else {
      handleSubmit(onSubmitClick)();
    }
  };

  return isFetchingDCFormData ||
    isCreating ||
    isSavingDraft ||
    isUpdatingDraft ||
    isSubmittingDraft ||
    isDeleting ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={
        isFetchingDCFormData ||
        isCreating ||
        isSavingDraft ||
        isUpdatingDraft ||
        isSubmittingDraft ||
        isDeleting
      }
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <>
      {/* Mobile view */}
      <Box sx={{ display: { xs: "block", lg: "none" }, px: "15px", mb: 9 }}>
        <MobileOrdersPage
          showOtherDetails
          Status={
            DcData?.status?.name === "Delivered"
              ? "Placed"
              : DcData?.status?.name
          }
          title={"Delivery Challan"}
          subTitle={
            DCFormData?.order?.orderType?.name === "PurchaseReturn" ||
            orderInfo?.orderType?.name === "PurchaseReturn"
              ? "(Sales Returns)"
              : DCFormData?.order?.orderType?.name === "StockTransfer" ||
                orderInfo?.orderType?.name === "StockTransfer"
              ? "(For Internal Stock Transfer, Not for Sale)"
              : ""
          }
          docInfo={[
            {
              label: "DC No :",
              value: DcData?.code ?? "--"
            },
            {
              label: "DC date :",
              value: DcData
                ? moment(DcData?.docDate).format("DD-MM-YYYY")
                : "--"
            },
            {
              label: `${orderInfo?.orderType?.displayName} No.:`,
              value: orderInfo?.code
            },
            {
              label: "Order date :",
              value: moment(orderInfo?.orderDate).format("DD-MM-YYYY")
            }
          ]}
          accordianInfo={getAccordianInfo(
            Boolean(
              DCFormData?.order?.orderType?.name === "StockTransfer" ||
                orderInfo?.orderType?.name === "StockTransfer"
            ),
            orderInfo ?? DCFormData?.order
          )}
          otherInfo={OtherDetailsComponent}
          mobileTable={DcTable}
          Total={[
            {
              label: "Subtotal Amount :",
              value: currencyOnlyFormatter(
                parseFloat(
                  DcData?.subTotalAmount ??
                    DCFormData?.gatePass?.subTotalAmount ??
                    0
                ).toFixed(2)
              )
            },
            ...[
              ...(Boolean(
                Boolean(DcData)
                  ? DcData?.taxes?.length > 1
                  : DCFormData?.gatePass?.taxes?.length > 1
              )
                ? [
                    {
                      label: "CGST :",
                      value: currencyOnlyFormatter(
                        parseFloat(
                          DcData?.taxes[0]?.totalAmount
                            ? DcData?.taxes[0]?.totalAmount ?? 0
                            : DCFormData?.gatePass?.taxes[0]?.totalAmount ?? 0
                        ).toFixed(2)
                      )
                    },
                    {
                      label: "SGST :",
                      value: currencyOnlyFormatter(
                        parseFloat(
                          DcData?.taxes[1]?.totalAmount
                            ? DcData?.taxes[1]?.totalAmount ?? 0
                            : DCFormData?.gatePass?.taxes[1]?.totalAmount ?? 0
                        ).toFixed(2)
                      )
                    }
                  ]
                : [
                    {
                      label: "IGST :",
                      value: currencyOnlyFormatter(
                        parseFloat(
                          DcData?.taxes[0]?.totalAmount
                            ? DcData?.taxes[0]?.totalAmount
                            : DCFormData?.gatePass?.taxes[0]?.totalAmount ?? 0
                        ).toFixed(2)
                      )
                    }
                  ])
            ],
            {
              label: "Total Amount :",
              value: currencyOnlyFormatter(
                parseFloat(
                  DcData?.totalAmount ?? DCFormData?.gatePass?.totalAmount ?? 0
                ).toFixed(2)
              )
            }
          ]}
          Remarks={
            <TextArea
              control={control}
              name={"tnc"}
              disabled={
                Boolean(
                  DcData?.status?.name === "Placed" ||
                    DcData?.status?.name === "Delivered"
                ) || !DcCreateAllowed
              }
            />
          }
          preparedBy={orderInfo?.clientData?.name}
          ButtonGroup={
            <ButtonGroup
              Status={
                DcData?.status?.name === "Delivered"
                  ? "Placed"
                  : DcData?.status?.name
              }
              onDeleteClick={() => onDeleteClick()}
              onSaveDraft={onSaveDraftCheck}
              onSubmitClick={onSubmitCheck}
            />
          }
        />
      </Box>
      {/* Web view */}
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          mt={-3}
          zIndex={1}
          gap={1}
        >
          {DcCreateAllowed && (
            <Box
              display={"flex"}
              justifyContent={"end"}
              alignItems={"end"}
              gap={3}
            >
              <DCButtons
                status={DcData?.status?.name ?? ""}
                onSubmit={onSubmitCheck}
                onSaveDraft={onSaveDraftCheck}
                onDeleteClick={handleSubmit(onDeleteClick)}
              />
            </Box>
          )}
          {(DcData?.status?.name === "Placed" ||
            DcData?.status?.name === "Delivered") && (
            <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
              {ewayButton()}
              {DcData?.status?.name === "Delivered" && (
                <Typography
                  fontSize={15}
                  fontWeight={600}
                  lineHeight={"40px"}
                >{`Delivered: ${moment(DcData?.deliveredDate).format(
                  "DD.MM.YYYY"
                )}`}</Typography>
              )}
              <Button variant="link" disableRipple onClick={handlePrint}>
                Print
              </Button>
            </Box>
          )}
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={3}
            border={"0.7px solid #6097FF"}
            borderRadius={"8px"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={"30px 20px"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={1}
              alignItems={"center"}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "24px"
                }}
              >
                {"Delivery Challan"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "20px"
                }}
              >
                {(DCFormData?.order?.orderType?.name === "PurchaseReturn" ||
                  orderInfo?.orderType?.name === "PurchaseReturn") &&
                  "(Sales Returns)"}
                {(DCFormData?.order?.orderType?.name === "StockTransfer" ||
                  orderInfo?.orderType?.name === "StockTransfer") &&
                  "(For Internal Stock Transfer, Not for Sale)"}
              </Typography>
            </Box>
            {DCFormData?.order?.orderType?.name === "StockTransfer" ||
            orderInfo?.orderType?.name === "StockTransfer" ? (
              <StockTranferDCOrderInfo
                orderData={orderInfo ?? DCFormData?.order}
                deliveryChallanData={DcData ?? ""}
                logo={logo}
              />
            ) : (
              <DCOrderInfo
                orderData={orderInfo ?? DCFormData?.order}
                deliveryChallanData={DcData ?? ""}
                logo={logo}
              />
            )}
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              columnSpacing={2}
            >
              {DcCreateAllowed &&
              DcData?.status?.name !== "Placed" &&
              DcData?.status?.name !== "Delivered" ? (
                <>
                  <Grid item xs={5}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "17px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {"Eway Bill No.:"}
                      </Typography>
                      <TextInput
                        control={control}
                        name={gatePassKeys.EwayBillNo}
                        endIcon={getEndIcon(isEwayChecked)}
                        endButton={() =>
                          !getEndIcon(isEwayChecked) && (
                            <Button
                              variant="link"
                              component={LoadingButton}
                              loading={isFetchingEwayBill}
                              disabled={!DcCreateAllowed}
                              onClick={onCheckClick}
                            >
                              {"Check"}
                            </Button>
                          )
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <LabelTextInput
                      control={control}
                      name={gatePassKeys.transporter}
                      label={"Transporter.:"}
                      placeholder="ABC Logistics"
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={5}>
                    <LabelValueText
                      label={"Eway Bill No.:"}
                      value={DcData?.ewbNo ?? "--"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <LabelValueText
                      label={"Transporter.:"}
                      value={DcData?.transporter ?? "--"}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={3}>
                <LabelValueText
                  label={"Truck Reg No.:"}
                  value={
                    DcData?.vehicle?.regNo
                      ? DcData?.vehicle?.regNo
                      : DCFormData?.gatePass?.vehicle?.regNo
                  }
                />
              </Grid>
            </Grid>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
            >
              {DcTable}
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Box display={"flex"} flexDirection={"column"} gap={3}>
                  <Box width={"500px"}>
                    <TextArea
                      control={control}
                      name={"tnc"}
                      label={purchase_labels.otherItemsAndConditionsLabel}
                      disabled={
                        Boolean(
                          DcData?.status?.name === "Placed" ||
                            DcData?.status?.name === "Delivered"
                        ) || !DcCreateAllowed
                      }
                    />
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} gap={2.5}>
                    {reportLabel2(
                      purchase_labels.amountInWordsLabel,
                      `${toWords.convert(
                        DcData?.totalAmount ??
                          DCFormData?.gatePass?.totalAmount ??
                          0,
                        {
                          currency: true
                        }
                      )}`
                    )}
                    {reportLabel2(
                      purchase_labels.preparedByLabel,
                      DcData?.preparedBy
                        ? `${DcData?.preparedBy?.firstName} ${DcData?.preparedBy?.lastName}`
                        : "--"
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <TotalBreakup
                  gstType={
                    Boolean(DcData)
                      ? DcData?.taxes?.length > 1
                      : DCFormData?.gatePass?.taxes?.length > 1
                  }
                  subTotal={
                    DcData?.subTotalAmount ??
                    DCFormData?.gatePass?.subTotalAmount ??
                    0
                  }
                  cgst={
                    DcData?.taxes[0]?.totalAmount
                      ? DcData?.taxes[0]?.totalAmount ?? 0
                      : DCFormData?.gatePass?.taxes[0]?.totalAmount ?? 0
                  }
                  sgst={
                    DcData?.taxes[1]?.totalAmount
                      ? DcData?.taxes[1]?.totalAmount ?? 0
                      : DCFormData?.gatePass?.taxes[1]?.totalAmount ?? 0
                  }
                  igst={
                    DcData?.taxes[0]?.totalAmount
                      ? DcData?.taxes[0]?.totalAmount
                      : DCFormData?.gatePass?.taxes[0]?.totalAmount ?? 0
                  }
                  total={
                    DcData?.totalAmount ??
                    DCFormData?.gatePass?.totalAmount ??
                    0
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <div style={{ display: "none" }}>
            <Box ref={componentRef} sx={{ padding: "10px" }}>
              <DeliveryChallanDoc>
                <Grid container>
                  <PrintableTable
                    columns={getColumns(
                      Boolean(DcData)
                        ? DcData?.taxes?.length > 1
                        : DCFormData?.gatePass?.taxes?.length > 1
                    )}
                    rows={getRows(fields)}
                    docType="DeliveryChallan"
                    gstType={
                      Boolean(DcData)
                        ? DcData?.taxes?.length > 1
                        : DCFormData?.gatePass?.taxes?.length > 1
                    }
                  />
                </Grid>
              </DeliveryChallanDoc>
            </Box>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default DeliveryChallanTable;
