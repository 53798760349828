import { client } from "../../shared/api/client";

export const getSkuFormData = async () => {
  return await client
    .get("/clients/skus/form-data")
    .then((res) => res.data.data);
};

export const getHsn = async (hsn) => {
  return await client
    .get(`/clients/e-way-bills/hsn`, {
      params: { hsn: hsn }
    })
    .then((res) => res.data.data);
};

export const getGst = async (gst) => {
  return await client
    .get(`/clients/e-way-bills/gst`, {
      params: { gstNo: gst }
    })
    .then((res) => res.data.data);
};

export const getSkuFilters = async () => {
  return await client.get("/clients/skus/filters").then((res) => res.data.data);
};

export const getSkuExcelTemplate = async () => {
  return await client
    .get("/clients/skus/upload/sample", {
      responseType: "arraybuffer" // Ensure binary response type
    })
    .then((res) => res.data);
};

export const getUpdatedSkuFormData = async (skuId) => {
  return await client
    .get(`/clients/skus/${skuId}/form-data`)
    .then((res) => res.data.data);
};

export const createNewSku = async (data) => {
  return await client.post(`/clients/skus`, data);
};

export const uploadExcel = async (data) => {
  return await client.post(`/clients/skus/upload`, data);
};

export const updateSku = async (skuId, data) => {
  return await client.put(`/clients/skus/${skuId}`, data);
};

export const getSkus = async (params, page = 1) => {
  return await client
    .get(`/clients/skus`, {
      params: { ...params, page: page }
    })
    .then((res) => res.data.data);
};

export const getSkuById = async (skuId) => {
  return await client
    .get(`/clients/skus/${skuId}`)
    .then((res) => res.data.data);
};

export const getItemLedgers = async (skuId, params, page = 1) => {
  return await client
    .get(`/clients/skus/${skuId}/ledgers`, {
      params: { ...params, page: page }
    })
    .then((res) => res.data.data);
};

export const getWarehouses = async () => {
  return await client.get(`/clients/warehouses`).then((res) => res.data.data);
};

export const getAssetWarehouseById = async (warehouseId) => {
  return await client
    .get(`/clients/warehouses/${warehouseId}`)
    .then((res) => res.data.data);
};

export const getCreateWarehouseFormData = async () => {
  return await client
    .get("/clients/warehouses/form-data")
    .then((res) => res.data.data);
};

export const getUpdateWarehouseFormData = async (warehouseId) => {
  return await client
    .get(`/clients/warehouses/${warehouseId}/form-data`)
    .then((res) => res.data.data);
};

export const createWarehouse = async (data) => {
  return await client.post(`/clients/warehouses`, data);
};

export const updateWarehouse = async (data, warehouseId) => {
  return await client.put(`/clients/warehouses/${warehouseId}`, data);
};

export const getTransport = async () => {
  return await client.get(`/clients/vehicles`).then((res) => res.data.data);
};

export const getAssetTransportById = async (transportId) => {
  return await client
    .get(`/clients/vehicles/${transportId}`)
    .then((res) => res.data.data);
};

export const getCreateTransportFormData = async () => {
  return await client
    .get("/clients/vehicles/form-data")
    .then((res) => res.data.data);
};

export const getUpdateTransportFormData = async (transportId) => {
  return await client
    .get(`/clients/vehicles/${transportId}/form-data`)
    .then((res) => res.data.data);
};

export const createTransport = async (data) => {
  return await client.post(`/clients/vehicles`, data);
};

export const updateTransport = async (data, transportId) => {
  return await client.put(`/clients/vehicles/${transportId}`, data);
};

export const getRegisterFilters = async () => {
  return await client
    .get("/clients/registers/filters")
    .then((res) => res.data.data);
};

export const getRegister = async (params, page = 1) => {
  return await client
    .get(`/clients/registers`, {
      params: { ...params, page: page }
    })
    .then((res) => res.data.data);
};

export const getMRRs = async (orderId) => {
  return await client
    .get(`/clients/orders/${orderId}/mrrs`)
    .then((res) => res.data.data);
};

export const getMrrFormData = async (orderId) => {
  return await client
    .get(`/clients/orders/${orderId}/mrrs/form-data`)
    .then((res) => res.data.data);
};

export const getMrrDocumentsById = async (orderId, MrrId) => {
  return await client
    .get(`/clients/orders/${orderId}/mrrs/${MrrId}/documents`)
    .then((res) => res.data.data);
};

export const createMrr = async (data, orderId) => {
  return await client.post(`/clients/orders/${orderId}/mrrs`, data);
};

export const saveMrr = async (data, orderId) => {
  return await client.post(`/clients/orders/${orderId}/mrrs/save`, data);
};

export const updateMRRDraft = async (data, orderId, mrrId) => {
  return await client.put(
    `/clients/orders/${orderId}/mrrs/${mrrId}/save`,
    data
  );
};

export const submitMRRDraft = async (data, orderId, mrrId) => {
  return await client.put(
    `/clients/orders/${orderId}/mrrs/${mrrId}/submit`,
    data
  );
};

export const deleteMrrDraft = async (orderId, mrrId) => {
  return await client.delete(`/clients/orders/${orderId}/mrrs/${mrrId}`);
};

export const getGPs = async (orderId) => {
  return await client
    .get(`/clients/orders/${orderId}/gate-passes`)
    .then((res) => res.data.data);
};

export const getGpFormData = async (orderId) => {
  return await client
    .get(`/clients/orders/${orderId}/gate-passes/form-data`)
    .then((res) => res.data.data);
};

export const createGp = async (data, orderId) => {
  return await client.post(`/clients/orders/${orderId}/gate-passes`, data);
};

export const saveGp = async (data, orderId) => {
  return await client.post(`/clients/orders/${orderId}/gate-passes/save`, data);
};

export const updateGPDraft = async (data, orderId, gatepassId) => {
  return await client.put(
    `/clients/orders/${orderId}/gate-passes/${gatepassId}/save`,
    data
  );
};

export const submitGPDraft = async (data, orderId, gatepassId) => {
  return await client.put(
    `/clients/orders/${orderId}/gate-passes/${gatepassId}/submit`,
    data
  );
};

export const deleteGpDraft = async (orderId, gatepassId) => {
  return await client.delete(
    `/clients/orders/${orderId}/gate-passes/${gatepassId}`
  );
};

export const getDCByOrderId = async (orderId) => {
  return await client
    .get(`/clients/orders/${orderId}/gate-passes/delivery-challans`)
    .then((res) => res.data.data);
};

export const getDCDeliveredFormData = async (orderId) => {
  return await client
    .get(
      `/clients/orders/${orderId}/gate-passes/delivery-challans/delivered/form-data`
    )
    .then((res) => res.data.data);
};

export const getDCByGPId = async (gatePassId) => {
  return await client
    .get(`/clients/orders/gate-passes/${gatePassId}/delivery-challans`)
    .then((res) => res.data.data);
};

export const getGPDocumentsById = async (orderId, gatePassId) => {
  return await client
    .get(`/clients/orders/${orderId}/gate-passes/${gatePassId}/documents`)
    .then((res) => res.data.data);
};

export const getDCFormData = async (gatePassId) => {
  return await client
    .get(
      `/clients/orders/gate-passes/${gatePassId}/delivery-challans/form-data`
    )
    .then((res) => res.data.data);
};

export const getUpdatedDCFormData = async (dcId) => {
  return await client
    .get(`/clients/orders/gate-passes/delivery-challans/${dcId}/form-data`)
    .then((res) => res.data.data);
};

export const createDC = async (data, gatePassId) => {
  return await client.post(
    `/clients/orders/gate-passes/${gatePassId}/delivery-challans`,
    data
  );
};

export const saveDC = async (data, gatePassId) => {
  return await client.post(
    `/clients/orders/gate-passes/${gatePassId}/delivery-challans/save`,
    data
  );
};

export const updateDCDraft = async (data, dcId) => {
  return await client.put(
    `/clients/orders/gate-passes/delivery-challans/${dcId}/save`,
    data
  );
};

export const submitDCDraft = async (data, dcId) => {
  return await client.put(
    `/clients/orders/gate-passes/delivery-challans/${dcId}/submit`,
    data
  );
};

export const recordDeliveryDate = async (data, dcId) => {
  return await client.put(
    `/clients/orders/gate-passes/delivery-challans/${dcId}/delivered`,
    data
  );
};

export const deleteDCDraft = async (dcId) => {
  return await client.delete(
    `/clients/orders/gate-passes/delivery-challans/${dcId}`
  );
};

export const authenticateEwayBill = async (data) => {
  return await client.post(`/clients/e-way-bills/authenticate`, data);
};

export const getEwayBillFormData = async () => {
  return await client
    .get("/clients/e-way-bills/form-data")
    .then((res) => res.data.data);
};

export const getEwayBillFormDataByInvId = async (dcInvId) => {
  return await client
    .get(`/clients/orders/invoices/${dcInvId}/e-way-bills/form-data`)
    .then((res) => res.data.data);
};

export const createEwayBill = async (data) => {
  return await client.post(`/clients/e-way-bills`, data);
};
export const createEwayBillById = async (data, dcInvId) => {
  return await client.post(
    `/clients/orders/invoices/${dcInvId}/e-way-bills`,
    data
  );
};

export const calculateEwayBillTotal = async (data) => {
  return await client.post(`/clients/e-way-bills/calculate`, data);
};

export const getAllEwayBill = async (params, page = 1) => {
  return await client
    .get(`/clients/e-way-bills`, { params: { ...params, page: page } })
    .then((res) => res.data.data);
};

export const getEwayBillById = async (ewayBillId) => {
  return await client
    .get(`/clients/e-way-bills/${ewayBillId}`)
    .then((res) => res.data.data);
};

export const getEwayBillByNo = async (ewayBillNo) => {
  return await client
    .get(`/clients/e-way-bills/ewb-no/${ewayBillNo}`)
    .then((res) => res.data.data);
};

export const syncDataById = async (ewbId) => {
  return await client
    .get(`/clients/e-way-bills/${ewbId}/sync`)
    .then((res) => res.data.data);
};

export const getUpdatePartBFormData = async (ewbId) => {
  return await client
    .get(`/clients/e-way-bills/${ewbId}/part-b/form-data`)
    .then((res) => res.data.data);
};

export const updatePartB = async (data, ewbId) => {
  return await client.put(`/clients/e-way-bills/${ewbId}/part-b`, data);
};

export const getTransporter = async (transporterId) => {
  return await client
    .get(`/clients/e-way-bills/transporter`, {
      params: { transporterId: transporterId }
    })
    .then((res) => res.data.data);
};

export const getEwayByDateSync = async (params) => {
  return await client
    .get(`/clients/e-way-bills/sync`, {
      params: { ...params }
    })
    .then((res) => res.data.data);
};

export const getOtherEwayByDateSync = async (params) => {
  return await client
    .get(`/clients/e-way-bills/others/sync`, {
      params: { ...params }
    })
    .then((res) => res.data.data);
};

export const updateTransporter = async (data, ewbId) => {
  return await client.put(`/clients/e-way-bills/${ewbId}/transporter`, data);
};

export const getExtendValFormData = async (ewbId) => {
  return await client
    .get(`/clients/e-way-bills/${ewbId}/validity/form-data`)
    .then((res) => res.data.data);
};

export const extendValidity = async (data, ewbId) => {
  return await client.put(`/clients/e-way-bills/${ewbId}/validity`, data);
};

export const getCancelEwbFormData = async (ewbId) => {
  return await client
    .get(`/clients/e-way-bills/${ewbId}/cancel/form-data`)
    .then((res) => res.data.data);
};

export const cancelEwb = async (data, ewbId) => {
  return await client.put(`/clients/e-way-bills/${ewbId}/cancel`, data);
};

export const rejectEwb = async (ewbId) => {
  return await client.put(`/clients/e-way-bills/${ewbId}/reject`, {});
};
