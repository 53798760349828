import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UpdatePartBKeys } from "../../../constants/formKeys";
import TextInput from "../../../shared/formElements/TextInput";
import { UpdatePartB_labels } from "../../../constants";
import DropDown from "../../../shared/formElements/DropDown";
import DateInput from "../../../shared/formElements/DateInput";
import VehicleHistory from "./VehicleHistory";
import { useFetchUpdatePartBFormData } from "../../../query-hooks/ClientEwayBill/UpdatePartB/useFetchUpdatePartBFormData";
import { useUpdatePartB } from "../../../query-hooks/ClientEwayBill/UpdatePartB/useUpdatePartB";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";

const UpdatePartB = ({
  setUpdateType,
  ewayId,
  refetchEwayBillById,
  reset,
  EwayBillLoginShow,
  refetch,
  ewbValidity,
  partBs
}) => {
  const { control, handleSubmit } = useForm();
  const onBackClick = () => {
    setUpdateType("0");
  };

  const { data: formData, isFetching: isFetchingFormData } =
    useFetchUpdatePartBFormData(ewayId);

  const { mutate, isLoading, error, isError } = useUpdatePartB();

  const onUpdatePartB = (data) => {
    const cleanObj = (obj) =>
      Object.fromEntries(
        Object.entries(obj).filter(
          ([, value]) => value !== undefined && value !== null && value !== ""
        )
      );

    const formatObj = cleanObj({
      fromPlace: data[UpdatePartBKeys.placeChange],
      fromState: data[UpdatePartBKeys.stateChange],
      transMode: data[UpdatePartBKeys.mode],
      transDocNo: data[UpdatePartBKeys.transDocNo],
      ...{
        ...(Boolean(data[UpdatePartBKeys.docDate])
          ? {
              transDocDate: moment(data[UpdatePartBKeys.docDate]).format(
                "YYYY-MM-DD"
              )
            }
          : {})
      },
      vehicleNo: data[UpdatePartBKeys.vehicleNo],
      reason: data[UpdatePartBKeys.reason],
      reasonRemark: "testing"
    });
    mutate(
      {
        data: formatObj,
        ewbId: ewayId
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Part B Updated!", { variant: "success" });
          reset();
          refetch();
          refetchEwayBillById();
          setUpdateType("0");
        }
      }
    );
  };

  useEffect(() => {
    if (isError) {
      if (error?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(handleSubmit(onUpdatePartB));
      } else {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: "error"
        });
      }
    } // eslint-disable-next-line
  }, [
    error?.response?.data?.message,
    isError,
    EwayBillLoginShow,
    error?.response?.data?.ewbErrorCode
  ]);

  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "20px 20px 120px 20px", md: "25px 25px 100px 25px" }
        }}
        spacing={3}
      >
        <Grid item xs={12}>
          <Accordion
            elevation={0}
            defaultExpanded={true}
            sx={{
              "& .MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "45px"
              }
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    height: "25px",
                    width: "25px",
                    color: "#2773FF"
                  }}
                />
              }
              sx={{
                padding: 0,
                border: "none",
                position: "unset",
                minHeight: 0,
                display: "flex",
                justifyContent: "left",
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                  flexGrow: 1
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                  flexGrow: 1
                }
              }}
            >
              <Grid item xs={4} display={"flex"} alignItems={"center"}>
                <Typography
                  fontWeight={500}
                  fontSize={20}
                  lineHeight={"24.2px"}
                  color={"#2773FF"}
                >
                  {"Part B"}
                </Typography>
              </Grid>
              <Grid item xs={8} display={"flex"} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: 600,
                    lineHeight: { xs: "14px", md: "16.94px" },
                    color: "#2773FF",
                    letterSpacing: "0.002em"
                  }}
                >
                  {`EWB valid till : ${moment(ewbValidity).format(
                    "DD.MM.YYYY"
                  )} ${moment(ewbValidity).format("HH:mm")}`}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                border: "none",
                p: `0px`,
                m: 0
              }}
            >
              <Grid container rowSpacing={2} columnSpacing={4}>
                <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 500,
                      lineHeight: "24.2px",
                      letterSpacing: "0.002em",
                      textAlign: "center"
                    }}
                  >
                    {"Vehicle Updation"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <DropDown
                    isLoading={isFetchingFormData}
                    control={control}
                    name={UpdatePartBKeys.mode}
                    label={UpdatePartB_labels.mode}
                    options={formData?.transportationModes}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DropDown
                    isLoading={isFetchingFormData}
                    control={control}
                    name={UpdatePartBKeys.vehicleType}
                    label={UpdatePartB_labels.vehicleType}
                    options={[]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    control={control}
                    name={UpdatePartBKeys.placeChange}
                    label={UpdatePartB_labels.placeChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DropDown
                    isLoading={isFetchingFormData}
                    control={control}
                    name={UpdatePartBKeys.stateChange}
                    label={UpdatePartB_labels.stateChange}
                    options={formData?.states}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    control={control}
                    name={UpdatePartBKeys.vehicleNo}
                    label={UpdatePartB_labels.vehicleNo}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DropDown
                    isLoading={isFetchingFormData}
                    control={control}
                    name={UpdatePartBKeys.reason}
                    label={UpdatePartB_labels.reason}
                    options={formData?.reasons}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    control={control}
                    name={UpdatePartBKeys.transDocNo}
                    label={UpdatePartB_labels.transDocNo}
                  />
                </Grid>
                <Grid item xs={5} mb={1}>
                  <DateInput
                    control={control}
                    name={UpdatePartBKeys.docDate}
                    label={UpdatePartB_labels.docDate}
                    minDate={new Date("2000-01-01")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  mb={2}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 500,
                      lineHeight: "24.2px",
                      letterSpacing: "0.002em",
                      textAlign: "center"
                    }}
                  >
                    {"Vehicle History"}
                  </Typography>
                </Grid>
                {partBs?.map((item, index) => (
                  <Grid item xs={12} key={index} mb={1}>
                    <VehicleHistory {...item} />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            pb: {
              xs: !ewayId ? "120px" : 0,
              md: !ewayId ? "100px" : 0
            }
          }}
        >
          <Divider sx={{ border: "0.4px solid #B7BEC7" }} />
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        sx={{
          padding: {
            xs: "10px 16px 10px 16px",
            md: 0
          },
          gap: { xs: 1, md: 0 },
          width: { xs: "100%", md: 520 },
          background: { xs: "white", md: "transparent" },
          position: "fixed",
          borderTop: { xs: "1px solid #B7BEC7", md: "none" },
          bottom: 0
        }}
      >
        <Button
          type="button"
          variant="soft"
          fullWidth
          sx={{
            fontWeight: 500,
            fontSize: { xs: 15, md: 24 },
            height: {
              xs: 48,
              md: 81
            },
            borderRadius: { xs: "8px", md: 0 },
            backgroundColor: "#EFF2F7",
            "&:hover": {
              backgroundColor: "#EFF2F7",
              color: "#2773FF"
            }
          }}
          onClick={onBackClick}
        >
          {"Go back"}
        </Button>
        <Button
          type="button"
          variant="contained"
          component={LoadingButton}
          loading={isLoading}
          onClick={handleSubmit(onUpdatePartB)}
          fullWidth
          sx={{
            fontWeight: 500,
            fontSize: { xs: 15, md: 24 },
            height: {
              xs: 48,
              md: 81
            },
            borderRadius: { xs: "8px", md: 0 },
            whiteSpace: "nowrap"
          }}
        >
          {"Update Part B"}
        </Button>
      </Box>
    </>
  );
};

export default UpdatePartB;
