import { queryKeys } from "../../constants/queryKeys";
import { syncDataById } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useSyncDataById = (ewayBillId, isSynced) => {
  const ewayBillSyncById = useQuery(
    [queryKeys.querySyncEwayBillById, ewayBillId, isSynced],
    () => syncDataById(ewayBillId),
    {
      enabled: isSynced && Boolean(ewayBillId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return ewayBillSyncById;
};
