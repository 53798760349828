import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { stringCapitalization } from "../../../shared/utils";
import { StyledChip } from "../../../shared/UiElements/StatusChip";
import moment from "moment";

export const MobileEwayBillTable = (row) => {
  const { CreateEwayBill } = row;
  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container rowSpacing={2}>
        <Grid item xs={6}>
          <Button
            variant="link"
            fontSize={14}
            color={"primary"}
            sx={{ textDecoration: "underline" }}
            onClick={() => CreateEwayBill(row?._id)}
          >
            {row?.EwaybillNo}
          </Button>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"right"}>
          <Typography
            sx={{ fontSize: 14, lineHeight: "20px", fontWeight: 400 }}
          >
            {moment(row.EwaybillDate).format("DD-MM-YYYY")}
            <br />
            {moment(row.EwaybillDate).format("hh:mm A")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box display={"flex"} flexDirection={"column"} gap={0.5}>
            <Typography fontSize={14} lineHeight={"16.94px"} fontWeight={400}>
              {row?.DocNo}
            </Typography>
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
            >
              {row?.MovementType}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "end"
          }}
        >
          <Typography fontSize={14} lineHeight={"16.94px"} fontWeight={400}>
            {row?.gstin}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            alignItems={"flex-start"}
          >
            <StyledChip
              label={stringCapitalization(row?.Status)}
              variant="text"
            />
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
            >
              Status
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"right"}>
          <Typography
            sx={{ fontSize: 14, lineHeight: "20px", fontWeight: 400 }}
          >
            {moment(row.Validity).format("DD-MM-YYYY")}
            <br />
            {moment(row.Validity).format("hh:mm A")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
