import { getGst } from "../../inventory/services/index";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchGst = (checkGst, Gst) => {
  const queryGst = useQuery(
    [queryKeys.queryFetchGst, checkGst, Gst],
    () => getGst(Gst),
    {
      enabled: Boolean(checkGst) && Gst?.length === 15,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return queryGst;
};
