import { getEwayByDateSync } from "../../../inventory/services/index";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useSyncDataByDate = (isSyncByMe, params) => {
  const queryEwayByDateSync = useQuery(
    [queryKeys.querySyncEwayBillByDate, isSyncByMe, params],
    () => getEwayByDateSync(params),
    {
      enabled: isSyncByMe && Boolean(params?.date),
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return queryEwayByDateSync;
};
