export const queryKeys = {
  queryFetchListings: "query-get-Listings",
  queryFetchTransporters: "query-get-transporters",
  queryFetchWarehouses: "query-get-warehouses",
  queryFetchActiveOrders: "query-get-active-orders",
  queryFetchPastOrders: "query-get-past-orders",
  queryFetchActiveInquiry: "query-get-active-inquiry",
  queryFetchPastInquiry: "query-get-past-inquiry",
  queryFetchWarehouseSearchFormData: "query-warehouse-search-form",
  queryFetchTransportSearchFormData: "query-transport-search-form",
  queryFetchProfile: "query-get-profile",
  queryGetUpdateBusinessFormData: "query-get-update-business-form-data",
  queryFetchWarehouseByPartnerId: "query-get-warehouse-by-partner-id",
  queryFetchRetrieves: "query-get-retrieves",
  queryFetchNotifications: "query-get-notifications",
  queryFetchTransportByPartnerId: "query-get-transport-by-partner-id",
  queryGetRfq: "query-get-rfq",
  queryGetRfqCancelFormData: "query-get-rfq-cancel-form-data",
  queryGetProfile: "query-get-profile",
  queryFetchCreateOrderFormData: "query-get-create-order-form-data",
  queryFetchOrderDetails: "query-get-order-details",
  queryFetchUpdatedTotal: "query-fetch-updated-total",
  queryFetchDocuments: "query-get-documents",
  queryFetchInvoice: "query-get-invoice",
  queryFetchSkuFormData: "query-get-sku-form-data",
  queryFetchSkuFilters: "query-get-sku-filters",
  queryFetchSkuExcelTemplate: "query-get-sku-excel-template",
  queryFetchUpdatedSkuFormData: "query-get-updated-sku-form-data",
  queryFetchSkuById: "query-get-sku-by-id",
  queryFetchSkus: "query-get-skus",
  queryFetchLedgers: "query-get-ledgers",
  queryFetchAssetWarehouses: "query-get-asset-warehouses",
  queryFetchAssetWarehouseById: "query-get-asset-warehouse-by-id",
  queryFetchCreateWarehouseFormData: "query-get-create-warehouse-form-data",
  queryFetchUpdateWarehouseFormData: "query-get-update-warehouse-form-data",
  queryFetchAssetTransport: "query-get-asset-transport",
  queryFetchAssetTransportById: "query-get-asset-transport-by-id",
  queryFetchCreateTransportFormData: "query-get-create-transport-form-data",
  queryFetchUpdateTransportFormData: "query-get-update-transport-form-data",
  queryFetchCustomerFilters: "query-get-customer-filters",
  queryFetchCustomers: "query-get-customers",
  queryFetchCustomersSubtotal: "query-get-customers-subtotal",
  queryFetchCustomerById: "query-get-customer-by-id",
  queryFetchCustomerFormData: "query-get-customer-form-data",
  queryFetchUpdateCustomerFormData: "query-get-update-customer-form-data",
  queryFetchBillingAddresses: "query-get-billing-address",
  queryFetchBillingById: "query-get-billing-by-id",
  queryFetchBillingFormData: "query-get-billing-form-data",
  queryFetchUpdateBillingFormData: "query-get-update-billing-form-data",
  queryFetchStores: "query-get-stores",
  queryFetchStoreById: "query-get-store-by-id",
  queryFetchStoreFormData: "query-get-store-form-data",
  queryFetchUpdateStoreFormData: "query-get-update-store-form-data",
  queryFetchVendors: "query-get-vendors",
  queryFetchVendorFilters: "query-get-vendor-filters",
  queryFetchVendorsSubtotal: "query-get-vendors-subtotal",
  queryFetchVendorById: "query-get-vendor-by-id",
  queryFetchVendorFormData: "query-get-vendor-form-data",
  queryFetchUpdateVendorFormData: "query-get-update-vendor-form-data",
  queryFetchPurchaseOrders: "query-get-purchase-orders",
  queryFetchPOFilters: "query-get-purchase-orders-filters",
  queryFetchPOSubtotal: "query-get-purchase-orders-Subtotals",
  queryFetchPurchaseOrderById: "query-get-purchase-order-by-id",
  queryFetchPurchaseOrderFormData: "query-get-purchase-order-form-data",
  queryFetchUpdatePurchaseOrderFormData:
    "query-get-update-purchase-order-form-data",
  queryFetchPOListFormData: "query-get-po-list-form-data",
  queryFetchPurchaseReturnById: "query-get-purchase-return-by-id",
  queryFetchPurchaseReturnFormData: "query-get-purchase-return-form-data",
  queryFetchUpdatePurchaseReturnFormData:
    "query-get-update-purchase-return-form-data",
  queryFetchRegisterFilters: "query-get-register-filters",
  queryFetchRegister: "query-get-register",
  queryFetchMrrFormData: "query-get-mrr-form-data",
  queryFetchMRRs: "query-get-mrrs",
  queryFetchSalesPersons: "query-get-sales-persons",
  queryFetchSOFilters: "query-get-sales-order-filters",
  queryFetchSalesOrders: "query-get-sales-orders",
  queryFetchSOSubtotal: "query-get-sales-orders-subtotal",
  queryFetchSalesOrderById: "query-get-sales-order-by-id",
  queryFetchSalesOrderFormData: "query-get-sales-order-form-data",
  queryFetchCustomerBillingByCustomerId:
    "query-get-customer-billing-by-customerId",
  queryFetchUpdateSalesOrderFormData: "query-get-update-sales-order-form-data",
  queryFetchGpFormData: "query-get-gp-form-data",
  queryFetchGPs: "query-get-gatePasses",
  queryFetchSOListFormData: "query-get-so-list-form-data",
  queryFetchSalesReturnById: "query-get-sales-return-by-id",
  queryFetchSalesReturnFormData: "query-get-sales-return-form-data",
  queryFetchUpdateSalesReturnFormData:
    "query-get-update-sales-return-form-data",
  queryFetchDCFormData: "query-get-dc-form-data",
  queryFetchUpdatedDCFormData: "query-get-updated-dc-form-data",
  queryFetchDCByOrderId: "query-get-delivery-challans-by-order-id",
  queryFetchDCDeliveredFormData: "query-get-dc-delivered-form-data",
  queryFetchDCByGPId: "query-get-delivery-challans-by-gp-id",
  queryFetchDocument: "query-get-documents-by-gp-id-order-id",
  queryFetchMrrDocument: "query-get-documents-by-mrr-id-order-id",
  queryFetchStockTransfers: "query-get-stock-transfers",
  queryFetchStockTransferById: "query-get-stock-transfer-by-id",
  queryFetchStockTransferFormData: "query-get-stock-transfer-form-data",
  queryFetchUpdateStockTransferFormData:
    "query-get-update-stock-transfer-form-data",
  queryFetchStockAdjustments: "query-get-stock-adjustments",
  queryFetchStockAdjustmentById: "query-get-stock-adjustment-by-id",
  queryFetchStockAdjustmentFormData: "query-get-stock-adjustment-form-data",
  queryFetchUpdateStockAdjustmentFormData:
    "query-get-update-stock-adjustment-form-data",
  queryFetchStockTransferSKUs: "query-get-stock-transfer-skus",
  queryFetchStockAdjustmentSKUs: "query-get-stock-adjustment-skus",
  queryFetchQuotesFilters: "query-get-sales-quotation-filters",
  queryFetchQuotesSubtotal: "query-get-sales-quotation-subtotal",
  queryFetchQuotes: "query-get-sales-quotations",
  queryFetchQuoteById: "query-get-sales-quotation-by-id",
  queryFetchQuoteFormData: "query-get-sales-quotation-form-data",
  queryFetchQuoteCustomerBillingByCustomerId:
    "query-get-quote-customer-billing-by-customerId",
  queryFetchUpdateQuoteFormData: "query-get-update-sales-quotation-form-data",
  queryFetchMrrInCreateFormData: "query-get-mrr-inv-create-form-data",
  queryFetchDCInCreateFormData: "query-get-dc-inv-create-form-data",
  queryFetchDCInUpdateFormData: "query-get-dc-inv-updated-form-data",
  queryFetchDCInvoiceById: "query-get-dc-invoice-by-id",
  queryFetchDebitNoteCreateFormData: "query-get-debit-note-create-form-data",
  queryFetchDebitNoteUpdateFormData: "query-get-debit-note-updated-form-data",
  queryFetchDebitNoteById: "query-get-debit-note-by-id",
  queryFetchCreditNoteCreateFormData: "query-get-credit-note-create-form-data",
  queryFetchCreditNoteUpdateFormData: "query-get-credit-note-updated-form-data",
  queryFetchCreditNoteById: "query-get-credit-note-by-id",
  queryFetchAllInvoices: "query-get-client-all-invoices",
  queryFetchInvoiceSubtotal: "query-get-client-invoices-subtotal",
  queryFetchPaymentSubtotal: "query-get-client-payments-subtotal",
  queryFetchInvoiceFilters: "query-get-invoices-filter",
  queryFetchFinaceOrders: "query-get-finance-orders",
  queryFetchSubscriptions: "query-get-subscriptions",
  queryFetchPOTransactionsById: "query-get-purchase-order-transactions-by-id",
  queryFetchSOTransactionsById: "query-get-sales-order-transactions-by-id",
  queryFetchSubUsers: "query-get-SubUsers",
  queryGetCreateSubUserFormData: "query-get-create-subUser-form-data",
  queryGetUpdateSubUserFormData: "query-get-update-subUser-form-data",
  queryFetchEwayBillFormData: "query-get-EwayBill-form-data",
  queryFetchEwayBillFormDataById: "query-get-EwayBill-form-data-By-Id",
  queryFetchEwayBills: "query-get-EwayBills",
  queryFetchEwayBillById: "query-get-EwayBill-by-id",
  queryFetchEwayBillByNo: "query-get-EwayBill-by-No",
  querySyncEwayBillById: "query-sync-EwayBill-by-id",
  querySyncEwayBillByDate: "query-sync-EwayBill-by-date",
  queryFetchHsn: "query-get-hsn",
  queryFetchGst: "query-get-gst",
  querySyncOtherEwayBillByDate: "query-sync-other-EwayBill-by-date",
  queryFetchUpdatePartBFormData: "query-get-PartB-Form-Data",
  queryFetchTransporter: "query-get-Transporter",
  queryFetchExtendValFormData: "query-get-Extend-Validity-Form-Data",
  queryFetchCancelEwbFormData: "query-get-cancel-ewb-Form-Data"
};
