import * as yup from "yup";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import TextArea from "../../../shared/formElements/TextArea";
import { Box, Button, Grid, Typography } from "@mui/material";
import CustomTable from "../../../shared/customTable";
import { CustomFieldRow } from "../../../purchase/pages/CreateOrder";
import SuccessfulDialog from "../../../shared/UiElements/SuccessfulDialog";
import { useCustomDialog } from "../../../shared/customDialog";
import {
  PrimaryText,
  SecondaryText
} from "../../../inventory/pages/Performance";
import { useFieldArray, useForm } from "react-hook-form";
import DropDown from "../../../shared/formElements/DropDown";
import { gatePassKeys } from "../../../constants/formKeys";
import { createMrr_labels, error_msg } from "../../../constants";
import { currencyOnlyFormatter } from "../../../shared/utils";
import moment from "moment";
import TextInput from "../../../shared/formElements/TextInput";
import { enqueueSnackbar } from "notistack";
import { useCreateGP } from "../../../query-hooks/ClientOrderGatePass/useCreateGP";
import { useDeleteGPDraft } from "../../../query-hooks/ClientOrderGatePass/useDeleteGPDraft";
import { useSubmitGPDraft } from "../../../query-hooks/ClientOrderGatePass/useSubmitGPDraft";
import { useUpdateGPDraft } from "../../../query-hooks/ClientOrderGatePass/useUpdateGPDraft";
import { useSaveGP } from "../../../query-hooks/ClientOrderGatePass/useSaveGP";
import { yupResolver } from "@hookform/resolvers/yup";
import { AppContext } from "../../../shared/context/auth-context";
import { useReactToPrint } from "react-to-print";
import PrintableTable from "../../../purchase/components/PrintableTable";
import {
  AddressSection,
  LabelValueText
} from "../../pages/MaterialReceivedReports";
import MobileOrdersPage, { DocInfo } from "../MobileOrdersPage";
import {
  ButtonGroup,
  MobileLabelInput
} from "../MaterialReceivedReport/MrrTable";
import MobileGatepassTable from "./MobileGatepassTable";

const getColumns = (PlacedStatus) => {
  return [
    {
      Header: "S.No",
      accessor: "sNo",
      width: 82,
      size: 0.5,
      Cell: ({ value }) => {
        return (
          <Typography fontSize={14} fontWeight={600} lineHeight={"17px"}>
            {value}
          </Typography>
        );
      }
    },
    {
      Header: "Item/ SKU",
      accessor: "skuItem",
      width: 170,
      size: 2.5,
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} gap={1} alignItems={"center"}>
            {value?.img && (
              <img
                src={value?.img}
                alt={value.name}
                style={{ width: "50%", maxWidth: "50%" }}
              />
            )}
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                textDecoration: "underline",
                color: "#2773FF",
                whiteSpace: "pre-wrap"
              }}
            >
              {value.name}
            </Typography>
          </Box>
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      width: 190,
      size: 3,
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} flexDirection={"column"}>
            <PrimaryText>{`Product: ${value?.product ?? "--"}`}</PrimaryText>
            <SecondaryText>{`Category: ${
              value?.category ?? "--"
            }`}</SecondaryText>
            <SecondaryText>{`Brand: ${value?.brand ?? "--"}`}</SecondaryText>
          </Box>
        );
      }
    },
    {
      Header: "UOM",
      accessor: "uom",
      width: 72,
      size: 0.5,
      disableSortBy: true
    },
    {
      Header: "Price (INR)",
      accessor: "price",
      disableSortBy: true,
      width: 122,
      size: 2,
      Cell: ({ row, value }) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            textAlign={"center"}
            gap={2}
          >
            <PrimaryText>
              {currencyOnlyFormatter(parseFloat(value).toFixed(2))}
            </PrimaryText>
            {row.original.incGst && (
              <SecondaryText>{`(Incl. GST)`}</SecondaryText>
            )}
          </Box>
        );
      }
    },
    {
      Header: PlacedStatus ? "Qty in Ordered" : "Qty (Dispatched/Ordered)",
      accessor: "qtyOrder",
      disableSortBy: true,
      width: PlacedStatus ? 130 : 220,
      size: 1.5,
      Cell: ({ value, row }) => {
        return (
          <Box
            display={"flex"}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
            width={"100%"}
          >
            <PrimaryText>
              {PlacedStatus ? value : row.original.prevDispatched ?? 0}
              {!PlacedStatus && (
                <span
                  style={{
                    color: "#607088",
                    fontSize: 13,
                    fontFamily: "Inter",
                    lineHeight: "18px",
                    fontWeight: 600
                  }}
                >{` / ${value}`}</span>
              )}
            </PrimaryText>
          </Box>
        );
      }
    },
    {
      Header: "Qty Dispatched",
      accessor: "qtyDispatched",
      width: 140,
      size: 2,
      disableSortBy: true
    }
  ];
};

const getRows = (rows = [], control, GatePassStatus) => {
  return rows.map((item, index) => {
    return {
      sNo: index + 1,
      skuId: item?.sku,
      skuItem: {
        img: item?.skuData?.image?.thumbUrl,
        name: item?.skuData?.skuCode
      },
      product: {
        product: item?.skuData?.productName,
        category: item?.skuData?.categoryName,
        brand: item?.skuData?.brandName
      },
      uom: item?.skuData?.unit?.name,
      incGst: Boolean(item?.isTaxApplicable),
      price: item?.isTaxApplicable
        ? +item.rate + (+item.skuData?.gstRegime / 100 ?? 0) * +item.rate
        : item.rate,
      qtyOrder: item?.orderQuantity,
      prevDispatched: item?.outQuantity,
      qtyDispatched: GatePassStatus ? (
        Boolean(GatePassStatus === "Placed") ? (
          item?.quantity
        ) : (
          <TextInput
            control={control}
            name={`gatePassItems.${index}.quantity`}
            type="number"
          />
        )
      ) : (
        <TextInput
          control={control}
          name={`gatePassItems.${index}.newQuantity`}
          type="number"
        />
      )
    };
  });
};

const GPSchema = (hasGatePassStatus) => {
  const baseSchema = {
    // truckRegNo: yup.string().required(error_msg.required),
    gatePassItems: yup.array().of(
      yup.object().shape({
        sku: yup.string().required(error_msg.required),
        ...(hasGatePassStatus
          ? {
              quantity: yup
                .number()
                .min(0)
                .typeError(error_msg.required)
                .required(error_msg.required)
            }
          : {
              newQuantity: yup
                .number()
                .min(0)
                .typeError(error_msg.required)
                .required(error_msg.required)
            })
      })
    )
  };
  return yup.object(baseSchema);
};

const calculateGrossSum = (arrayOfObjects, hasGatePassStatus) => {
  return arrayOfObjects.reduce((acc, obj) => {
    const taxSum = obj?.isTaxApplicable ? obj?.skuData?.gstRegime / 100 : 0;
    const acceptedQuantity = hasGatePassStatus
      ? +obj.quantity || 0
      : +obj.newQuantity || 0;
    return acc + (+obj.rate + taxSum * +obj.rate) * acceptedQuantity;
  }, 0);
};

const GatePassTable = ({
  orderId,
  truckRegNoOptions,
  GatePassStatus = "",
  tableData,
  orderInfo,
  GatePassInfo,
  isTaxApplicable,
  refetchGPs,
  refetchFormData,
  ewayButton
}) => {
  const [costBreakUp, setCostBreakUp] = useState({
    gross: ""
  });

  const { profileData } = useContext(AppContext);
  const { logo } = profileData?.user?.client;

  const { control, watch, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(GPSchema(Boolean(GatePassStatus)))
  });

  const { showDialog, hideDialog } = useCustomDialog();

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: GatePassInfo?.code
  });

  const {
    mutate: createNewGatePass,
    isError: isCreateError,
    error: createError
  } = useCreateGP();

  const {
    mutate: saveDraftGatePass,
    isError: isSaveDraftError,
    error: saveDraftError
  } = useSaveGP();

  const {
    mutate: updateGPDraft,
    isError: isUpdateDraftError,
    error: updateDraftError
  } = useUpdateGPDraft();

  const {
    mutate: submitGPDraft,
    isError: isSubmitDraftError,
    error: submitDraftError
  } = useSubmitGPDraft();

  const {
    mutate: deleteGPDraft,
    isError: isDeleteDraftError,
    error: deleteDraftError
  } = useDeleteGPDraft();

  const { fields, append } = useFieldArray({
    control,
    name: "gatePassItems"
  });

  const addItems = useCallback(
    (data) => {
      data?.length &&
        data?.forEach((item, index) => {
          append({ isTaxApplicable: isTaxApplicable, ...item });
        });
    },
    [append, isTaxApplicable]
  );

  useEffect(() => {
    if (tableData) {
      addItems(tableData);
    } // eslint-disable-next-line
  }, [addItems, JSON.stringify(tableData)]);

  useEffect(
    () => {
      if (GatePassStatus) {
        setValue(gatePassKeys.truckRegNo, GatePassInfo?.vehicle?.regNo);
        setValue(gatePassKeys.remarks, GatePassInfo?.remarks);
      }
    },
    // eslint-disable-next-line
    [GatePassStatus]
  );

  const skuWatch = watch("gatePassItems");

  const onSubmitClick = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to submit the document ?"}
          subText={"Submitted documents cannot be edited. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Submit"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => submitGatePass(data)}
        />
      )
    });
  };

  const onSaveDraft = (data) => {
    const skuItems =
      GatePassStatus === "Draft"
        ? data["gatePassItems"]?.filter((item) => +item.quantity !== 0)
        : data["gatePassItems"]?.filter((item) => +item.newQuantity !== 0);
    const formData = {
      ...{
        ...(data[gatePassKeys.truckRegNo]
          ? {
              vehicle: {
                regNo: data[gatePassKeys.truckRegNo]
              }
            }
          : {})
      },
      remarks: data[gatePassKeys.remarks] ?? "",
      items: skuItems?.map((item) => ({
        sku: item.sku,
        quantity:
          GatePassStatus === "Draft" ? +item.quantity : +item.newQuantity
      }))
    };
    GatePassStatus === "Draft"
      ? updateGPDraft(
          {
            data: formData,
            orderId: orderId,
            gatepassId: GatePassInfo?._id
          },
          {
            onSuccess: (res) => {
              enqueueSnackbar("GatePass Draft Updated!", {
                variant: "success"
              });
              reset();
              refetchGPs();
              refetchFormData();
            }
          }
        )
      : saveDraftGatePass(
          {
            data: formData,
            orderId: orderId
          },
          {
            onSuccess: (res) => {
              enqueueSnackbar("GatePass Draft Saved!", {
                variant: "success"
              });
              reset();
              refetchGPs();
              refetchFormData();
            }
          }
        );
  };

  const submitGatePass = (data) => {
    const skuItems =
      GatePassStatus === "Draft"
        ? data["gatePassItems"]?.filter((item) => +item.quantity !== 0)
        : data["gatePassItems"]?.filter((item) => +item.newQuantity !== 0);
    const formData = {
      ...{
        ...(data[gatePassKeys.truckRegNo]
          ? {
              vehicle: {
                regNo: data[gatePassKeys.truckRegNo]
              }
            }
          : {})
      },
      remarks: data[gatePassKeys.remarks] ?? "",
      items: skuItems?.map((item) => ({
        sku: item.sku,
        quantity:
          GatePassStatus === "Draft" ? +item.quantity : +item.newQuantity
      }))
    };
    GatePassStatus === "Draft"
      ? submitGPDraft(
          {
            data: formData,
            orderId: orderId,
            gatepassId: GatePassInfo?._id
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("GatePass Created!", {
                variant: "success"
              });
              reset({ gatePassItems: [] });
              refetchGPs();
              if (
                orderInfo?.itemDetails?.quantity >
                orderInfo?.itemDetails?.outQuantity
              ) {
                refetchFormData();
              }
            }
          }
        )
      : createNewGatePass(
          {
            data: formData,
            orderId: orderId
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("GatePass Created!", {
                variant: "success"
              });
              reset({ gatePassItems: [] });
              refetchGPs();
              if (
                orderInfo?.itemDetails?.quantity >
                orderInfo?.itemDetails?.outQuantity
              ) {
                refetchFormData();
              }
            }
          }
        );
  };

  const onDeleteClick = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Delete this draft ?"}
          subText={"Deleted documents cannot be recovered. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Delete"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => deleteGatePass()}
        />
      )
    });
  };

  const deleteGatePass = () => {
    GatePassStatus === "Draft" &&
      deleteGPDraft(
        {
          orderId: orderId,
          gatepassId: GatePassInfo?._id
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("GatePass Draft Deleted!", {
              variant: "success"
            });
            reset();
            refetchGPs();
          }
        }
      );
  };

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDraftError)
      enqueueSnackbar(saveDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isUpdateDraftError)
      enqueueSnackbar(updateDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isSubmitDraftError) {
      enqueueSnackbar(submitDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isDeleteDraftError) {
      enqueueSnackbar(deleteDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    createError?.response?.data?.message,
    saveDraftError?.response?.data?.message,
    updateDraftError?.response?.data?.message,
    submitDraftError?.response?.data?.message,
    deleteDraftError?.response?.data?.message,
    isSaveDraftError,
    isCreateError,
    isUpdateDraftError,
    isSubmitDraftError,
    isDeleteDraftError
  ]);

  useEffect(() => {
    if (skuWatch && fields.length) {
      setCostBreakUp({
        gross: +calculateGrossSum(skuWatch, Boolean(GatePassStatus))
      });
    } // eslint-disable-next-line
  }, [JSON.stringify(skuWatch)]);

  const GatePassDoc = ({ children }) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={6}
        border={"0.7px solid #6097FF"}
        borderRadius={"8px"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"30px 20px"}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "24px",
            textAlign: "center"
          }}
        >
          {"Gatepass"}
        </Typography>
        <Grid container display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={4} display={"flex"} flexDirection={"column"} gap={6}>
            <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
              <LabelValueText
                label={`${orderInfo?.orderType?.displayName} No :`}
                value={orderInfo?.code ?? "--"}
              />
              <LabelValueText
                label={"Order date :"}
                value={moment(orderInfo?.orderDate).format("DD-MM-YYYY")}
              />
            </Box>
            <Box textAlign={"left"}>
              <AddressSection
                title={"Seller Address"}
                name={orderInfo?.clientData?.name}
                addressLine1={orderInfo?.clientData?.companyName ?? ""}
                addressLine2={`${orderInfo?.clientData?.warehouse?.address?.address}, ${orderInfo?.clientData?.warehouse?.address?.area}, ${orderInfo?.clientData?.warehouse?.address?.city},${orderInfo?.clientData?.warehouse?.address?.state} ${orderInfo?.clientData?.warehouse?.address?.pincode}`}
              />
            </Box>
          </Grid>
          {logo?.thumbUrl && (
            <Grid
              item
              xs={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img src={logo?.thumbUrl} alt="Company Logo" />
            </Grid>
          )}
          <Grid
            item
            xs={4}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            gap={6}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"10px"}
              alignItems={"flex-end"}
            >
              <LabelValueText
                label={"Gatepass No :"}
                value={GatePassInfo?.code ?? "--"}
              />
              <LabelValueText
                label={"Gate pass date :"}
                value={
                  GatePassInfo?.docDate
                    ? moment(GatePassInfo?.docDate).format("DD-MM-YYYY")
                    : "--"
                }
              />
            </Box>
            <Box textAlign={"left"}>
              <AddressSection
                title={"ShipTo Address"}
                name={
                  orderInfo?.orderType?.name === "PurchaseReturn"
                    ? orderInfo?.vendorData?.companyName
                    : orderInfo?.vendorData?.warehouse?.code
                }
                addressLine1={`${orderInfo?.vendorData?.name ?? ""}`}
                addressLine2={`${orderInfo?.vendorData?.warehouse?.address?.address}, ${orderInfo?.vendorData?.warehouse?.address?.area}, ${orderInfo?.vendorData?.warehouse?.address?.city},${orderInfo?.vendorData?.warehouse?.address?.state} ${orderInfo?.vendorData?.warehouse?.address?.pincode}`}
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          display={"flex"}
          justifyContent={"right"}
          alignItems={"center"}
          width={"100%"}
        >
          {GatePassStatus === "Placed" ? (
            <LabelValueText
              label={"Truck Reg No.:"}
              value={GatePassInfo?.vehicle?.regNo}
            />
          ) : (
            <CustomFieldRow label={"Truck Reg No.:"} notMandatory>
              <DropDown
                control={control}
                name={gatePassKeys.truckRegNo}
                placeholder="Select Vehicle"
                options={truckRegNoOptions}
              />
            </CustomFieldRow>
          )}
        </Box>
        {children}
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          alignItems={"center"}
          width={"100%"}
        >
          <Box width={"500px"}>
            <TextArea
              control={control}
              name={gatePassKeys.remarks}
              label={createMrr_labels.remarks}
              disabled={Boolean(GatePassStatus === "Placed")}
            />
          </Box>
          <LabelValueText
            label={"Gross Amount (INR) :"}
            value={currencyOnlyFormatter(
              parseFloat(costBreakUp.gross).toFixed(2)
            )}
          />
        </Box>
        <Box display={"flex"} width={"100%"}>
          <LabelValueText
            label={"Prepared By:"}
            value={orderInfo?.clientData?.name}
          />
        </Box>
      </Box>
    );
  };

  const gpTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns(Boolean(GatePassStatus === "Placed"))}
        data={getRows(fields, control, GatePassStatus)}
        mobileComponent={MobileGatepassTable}
      />
    ),
    [GatePassStatus, control, fields]
  );

  const OtherDetailsComponent = Boolean(GatePassStatus === "Placed") ? (
    <Grid container>
      <DocInfo label={"Truck Reg No.:"} value={GatePassInfo?.vehicle?.regNo} />
    </Grid>
  ) : (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <MobileLabelInput
        isDropDown
        control={control}
        label={"Truck Reg No.:"}
        name={gatePassKeys.truckRegNo}
        placeholder="Select Vehicle"
        options={truckRegNoOptions}
      />
    </Box>
  );

  return (
    <>
      {/* Mobile view */}
      <Box sx={{ display: { xs: "block", lg: "none" }, px: "15px", mb: 9 }}>
        <MobileOrdersPage
          showOtherDetails
          Status={GatePassStatus}
          title={"Gate Pass"}
          docInfo={[
            {
              label: "Gatepass No :",
              value: GatePassInfo?.code ?? "--"
            },
            {
              label: "GP date :",
              value: GatePassInfo
                ? moment(GatePassInfo?.docDate).format("DD-MM-YYYY")
                : "--"
            },
            {
              label: `${orderInfo?.orderType?.displayName} No.:`,
              value: orderInfo?.code
            },
            {
              label: "Order date :",
              value: moment(orderInfo?.orderDate).format("DD-MM-YYYY")
            }
          ]}
          accordianInfo={[
            {
              title: "Seller Address",
              name: orderInfo?.clientData?.name,
              addressLine1: orderInfo?.clientData?.companyName ?? "",
              addressLine2: `${orderInfo?.clientData?.warehouse?.address?.address}, ${orderInfo?.clientData?.warehouse?.address?.area}, ${orderInfo?.clientData?.warehouse?.address?.city},${orderInfo?.clientData?.warehouse?.address?.state} ${orderInfo?.clientData?.warehouse?.address?.pincode}`
            },
            {
              title: "Shipto Address",
              name:
                orderInfo?.orderType?.name === "PurchaseReturn"
                  ? orderInfo?.vendorData?.companyName
                  : orderInfo?.vendorData?.warehouse?.code,
              addressLine1: `${orderInfo?.vendorData?.name ?? ""}`,
              addressLine2: `${orderInfo?.vendorData?.warehouse?.address?.address}, ${orderInfo?.vendorData?.warehouse?.address?.area}, ${orderInfo?.vendorData?.warehouse?.address?.city},${orderInfo?.vendorData?.warehouse?.address?.state} ${orderInfo?.vendorData?.warehouse?.address?.pincode}`
            }
          ]}
          otherInfo={OtherDetailsComponent}
          mobileTable={gpTable}
          Total={[
            {
              label: "Gross Amount :",
              value: currencyOnlyFormatter(
                parseFloat(costBreakUp.gross).toFixed(2)
              )
            }
          ]}
          Remarks={
            <TextArea
              control={control}
              name={gatePassKeys.remarks}
              label={createMrr_labels.remarks}
              disabled={Boolean(GatePassStatus === "Placed")}
            />
          }
          preparedBy={orderInfo?.clientData?.name}
          ButtonGroup={
            <ButtonGroup
              Status={GatePassStatus}
              onDeleteClick={() => onDeleteClick()}
              onSaveDraft={handleSubmit(onSaveDraft)}
              onSubmitClick={handleSubmit(onSubmitClick)}
            />
          }
        />
      </Box>
      {/* Web view */}
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          mt={!Boolean(GatePassStatus) ? 0 : -5}
          zIndex={1}
          gap={2}
        >
          {GatePassStatus === "Placed" ? (
            <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
              {ewayButton()}
              <Button variant="link" disableRipple onClick={handlePrint}>
                Print
              </Button>
            </Box>
          ) : (
            <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
              {GatePassStatus === "Draft" && (
                <Button
                  variant="link"
                  disableRipple
                  onClick={() => onDeleteClick()}
                  sx={{ color: "error.main" }}
                >
                  Delete Draft
                </Button>
              )}
              <Button
                variant="link"
                disableRipple
                onClick={handleSubmit(onSaveDraft)}
              >
                Save Draft
              </Button>
              <Button
                variant="link"
                disableRipple
                onClick={handleSubmit(onSubmitClick)}
              >
                Submit Gatepass
              </Button>
            </Box>
          )}
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={6}
            border={"0.7px solid #6097FF"}
            borderRadius={"8px"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={"30px 20px"}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24px",
                textAlign: "center"
              }}
            >
              {"Gatepass"}
            </Typography>
            <Grid container display={"flex"} justifyContent={"space-between"}>
              <Grid
                item
                xs={4}
                display={"flex"}
                flexDirection={"column"}
                gap={6}
              >
                <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                  <LabelValueText
                    label={`${orderInfo?.orderType?.displayName} No :`}
                    value={orderInfo?.code ?? "--"}
                  />
                  <LabelValueText
                    label={"Order date :"}
                    value={moment(orderInfo?.orderDate).format("DD-MM-YYYY")}
                  />
                </Box>
                <Box textAlign={"left"}>
                  <AddressSection
                    title={"Seller Address"}
                    name={orderInfo?.clientData?.name}
                    addressLine1={orderInfo?.clientData?.companyName ?? ""}
                    addressLine2={`${orderInfo?.clientData?.warehouse?.address?.address}, ${orderInfo?.clientData?.warehouse?.address?.area}, ${orderInfo?.clientData?.warehouse?.address?.city},${orderInfo?.clientData?.warehouse?.address?.state} ${orderInfo?.clientData?.warehouse?.address?.pincode}`}
                  />
                </Box>
              </Grid>
              {logo?.thumbUrl && (
                <Grid
                  item
                  xs={4}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <img src={logo?.thumbUrl} alt="Company Logo" />
                </Grid>
              )}
              <Grid
                item
                xs={4}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"flex-end"}
                alignItems={"flex-end"}
                gap={6}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"10px"}
                  alignItems={"flex-end"}
                >
                  <LabelValueText
                    label={"Gatepass No :"}
                    value={GatePassInfo?.code ?? "--"}
                  />
                  <LabelValueText
                    label={"Gate pass date :"}
                    value={
                      GatePassInfo?.docDate
                        ? moment(GatePassInfo?.docDate).format("DD-MM-YYYY")
                        : "--"
                    }
                  />
                </Box>
                <Box textAlign={"left"}>
                  <AddressSection
                    title={"ShipTo Address"}
                    name={
                      orderInfo?.orderType?.name === "PurchaseReturn"
                        ? orderInfo?.vendorData?.companyName
                        : orderInfo?.vendorData?.warehouse?.code
                    }
                    addressLine1={`${orderInfo?.vendorData?.name ?? ""}`}
                    addressLine2={`${orderInfo?.vendorData?.warehouse?.address?.address}, ${orderInfo?.vendorData?.warehouse?.address?.area}, ${orderInfo?.vendorData?.warehouse?.address?.city},${orderInfo?.vendorData?.warehouse?.address?.state} ${orderInfo?.vendorData?.warehouse?.address?.pincode}`}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box
              display={"flex"}
              justifyContent={"right"}
              alignItems={"center"}
              width={"100%"}
            >
              {GatePassStatus === "Placed" ? (
                <LabelValueText
                  label={"Truck Reg No.:"}
                  value={GatePassInfo?.vehicle?.regNo}
                />
              ) : (
                <CustomFieldRow label={"Truck Reg No.:"} notMandatory>
                  <DropDown
                    control={control}
                    name={gatePassKeys.truckRegNo}
                    placeholder="Select Vehicle"
                    options={truckRegNoOptions}
                  />
                </CustomFieldRow>
              )}
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
            >
              {gpTable}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-around"}
              alignItems={"center"}
              width={"100%"}
            >
              <Box width={"500px"}>
                <TextArea
                  control={control}
                  name={gatePassKeys.remarks}
                  label={createMrr_labels.remarks}
                  disabled={Boolean(GatePassStatus === "Placed")}
                />
              </Box>
              <LabelValueText
                label={"Gross Amount (INR) :"}
                value={currencyOnlyFormatter(
                  parseFloat(costBreakUp.gross).toFixed(2)
                )}
              />
            </Box>
            <Box display={"flex"} width={"100%"}>
              <LabelValueText
                label={"Prepared By:"}
                value={orderInfo?.clientData?.name}
              />
            </Box>
          </Box>
          {GatePassStatus === "Placed" && (
            <div style={{ display: "none" }}>
              <Box ref={componentRef} sx={{ padding: "10px" }}>
                <GatePassDoc>
                  <Grid container>
                    <PrintableTable
                      columns={getColumns(Boolean(GatePassStatus === "Placed"))}
                      rows={getRows(fields, control, GatePassStatus)}
                      docType="GatePass"
                    />
                  </Grid>
                </GatePassDoc>
              </Box>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default GatePassTable;
