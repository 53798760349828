import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UpdateTransporterKeys } from "../../../constants/formKeys";
import TextInput from "../../../shared/formElements/TextInput";
import { CustomFieldRow } from "./EwayBillForm";
import { useFetchTransporter } from "../../../query-hooks/ClientEwayBill/UpdateTransporter/useFetchTransporter";
import { useUpdateTransporter } from "../../../query-hooks/ClientEwayBill/UpdateTransporter/useUpdateTransporter";
import { enqueueSnackbar } from "notistack";

const UpdateTransporter = ({
  ewayId,
  refetchEwayBillById,
  reset,
  setUpdateType,
  EwayBillLoginShow,
  refetch
}) => {
  const [showTransporter, setShowTransporter] = useState(false);
  const { control, watch, handleSubmit, setValue } = useForm();
  const onBackClick = () => {
    setUpdateType("0");
  };

  const TransporterIdWatch = watch(UpdateTransporterKeys.TransporterId);

  const {
    data,
    error: transporterFetchError,
    isError: isTranporterFetchError,
    isFetched,
    isSuccess
  } = useFetchTransporter(showTransporter, TransporterIdWatch);

  const { mutate, isLoading, error, isError } = useUpdateTransporter();

  useEffect(() => {
    if (Boolean(TransporterIdWatch) && TransporterIdWatch.length === 15) {
      setShowTransporter(true);
    }
  }, [TransporterIdWatch, setShowTransporter]);

  useEffect(() => {
    if (isSuccess && isFetched) {
      setValue(
        UpdateTransporterKeys.TransporterName,
        data?.transporter?.tradeName
      );
    } //
  }, [
    setValue,
    data?.transporterName,
    isSuccess,
    isFetched,
    data?.transporter?.tradeName
  ]);

  const onUpdateTransporter = (data) => {
    mutate(
      {
        data: {
          transporterId: data[UpdateTransporterKeys.TransporterId],
          transporterName: data[UpdateTransporterKeys.TransporterName]
        },
        ewbId: ewayId
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Transporter Updated!", { variant: "success" });
          reset();
          refetch();
          refetchEwayBillById();
          setUpdateType("0");
        }
      }
    );
  };

  useEffect(() => {
    if (isError) {
      if (error?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(handleSubmit(onUpdateTransporter));
      } else {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: "error"
        });
      }
    }
    if (isTranporterFetchError) {
      enqueueSnackbar(transporterFetchError?.response?.data?.message, {
        variant: "error"
      });
    }
    // eslint-disable-next-line
  }, [
    error?.response?.data?.message,
    isError,
    EwayBillLoginShow,
    error?.response?.data?.ewbErrorCode,
    isTranporterFetchError,
    transporterFetchError?.response?.data?.message
  ]);

  return (
    <>
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        sx={{
          padding: { xs: "20px 20px 120px 20px", md: "25px 25px 100px 25px" }
        }}
        gap={2}
      >
        <Grid item xs={9} mb={2}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "24.2px",
              letterSpacing: "0.002em",
              textAlign: "center"
            }}
          >
            {"Are you sure you want to update the Transporter ?"}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <CustomFieldRow
            label={"Transporter ID : "}
            labelSize={5.5}
            childSize={6.5}
          >
            <TextInput
              control={control}
              name={UpdateTransporterKeys.TransporterId}
              placeholder={"12xxxxxx"}
            />
          </CustomFieldRow>
        </Grid>
        <Grid item xs={9}>
          <CustomFieldRow
            label={"Transporter Name : "}
            labelSize={5.5}
            childSize={6.5}
            notMandotary
          >
            <TextInput
              name={UpdateTransporterKeys.TransporterName}
              control={control}
              placeholder={"xxxxx"}
              disabled
            />
          </CustomFieldRow>
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        sx={{
          padding: {
            xs: "10px 16px 10px 16px",
            md: 0
          },
          gap: { xs: 1, md: 0 },
          width: { xs: "100%", md: 520 },
          background: { xs: "white", md: "transparent" },
          position: "fixed",
          borderTop: { xs: "1px solid #B7BEC7", md: "none" },
          bottom: 0
        }}
      >
        <Button
          type="button"
          variant="soft"
          fullWidth
          sx={{
            fontWeight: 500,
            fontSize: { xs: 15, md: 24 },
            height: {
              xs: 48,
              md: 81
            },
            borderRadius: { xs: "8px", md: 0 },
            backgroundColor: "#EFF2F7",
            "&:hover": {
              backgroundColor: "#EFF2F7",
              color: "#2773FF"
            }
          }}
          onClick={onBackClick}
        >
          {"Go back"}
        </Button>
        <Button
          type="button"
          variant="contained"
          component={LoadingButton}
          loading={isLoading}
          onClick={handleSubmit(onUpdateTransporter)}
          fullWidth
          sx={{
            fontWeight: 500,
            fontSize: { xs: 15, md: 24 },
            height: {
              xs: 48,
              md: 81
            },
            background: "#6097FF",
            "&:hover": {
              backgroundColor: "#6097FF"
            },
            borderRadius: { xs: "8px", md: 0 },
            whiteSpace: "nowrap"
          }}
        >
          {"Update Transporter"}
        </Button>
      </Box>
    </>
  );
};

export default UpdateTransporter;
