import { appConstants } from "../../constants";
import moment from "moment";

function currencyFormatter(x) {
  return "₹ " + x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "";
}

function currencyOnlyFormatter(x) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "";
}

function generatePassword() {
  const length = 20;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let password = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
}

function stringCapitalization(inputString) {
  return (
    inputString?.charAt(0).toUpperCase() + inputString?.slice(1) || inputString
  );
}

function dateTimeFormatter(date, format = appConstants.dateFormat) {
  return (date && moment(date).format(format)) || "";
}

function truncateStringWithEllipsis(str, maxLength) {
  if (typeof str !== "string") {
    return "";
  }
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + "...";
  } else {
    return str;
  }
}

function optionsFormatter(arr, type) {
  const transformedData = arr?.map((item, index) => {
    if (type === "address") {
      return {
        name: item._id,
        displayName: `Address ${index + 1}`
      };
    }
    return {};
  });
  return transformedData;
}

function dropdownOptions(array = [], nameKey, displayNameKey) {
  return array.map(function (item) {
    return {
      name: typeof item === "object" ? item[nameKey] || "" : item,
      displayName: typeof item === "object" ? item[displayNameKey] || "" : item
    };
  });
}

function capitalizeFirstCharacter(str) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export {
  currencyFormatter,
  generatePassword,
  dateTimeFormatter,
  stringCapitalization,
  truncateStringWithEllipsis,
  currencyOnlyFormatter,
  optionsFormatter,
  dropdownOptions,
  capitalizeFirstCharacter
};
