import * as yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import TextArea from "../../../shared/formElements/TextArea";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import CustomTable from "../../../shared/customTable";
import { useFieldArray, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  MobilePaymentTermsField,
  OtherDetailsComp,
  TotalBreakup,
  reportLabel1,
  reportLabel2,
  shipToAddressData,
  vendorAddressData
} from "../../../purchase/pages/CreateOrder";
import { error_msg, purchase_labels } from "../../../constants";
import OrderReport from "../../../purchase/components/OrderReport";
import { gstColumn } from "../../../purchase/components/Purchase/PurchaseOrderTable";
import { useCreateDCInvoice } from "../../../query-hooks/ClientOrderInvoice/useCreateDCInvoice";
import { useSubmitDCInvoiceDraft } from "../../../query-hooks/ClientOrderInvoice/useSubmitDCInvoiceDraft";
import { useUpdateDCInvoiceDraft } from "../../../query-hooks/ClientOrderInvoice/useUpdateDCInvoiceDraft";
import { useSaveDCInvoice } from "../../../query-hooks/ClientOrderInvoice/useSaveDCInvoice";
import { useDeleteDCInvoiceDraft } from "../../../query-hooks/ClientOrderInvoice/useDeleteDCInvoiceDraft";
import { useUploadDCInvoice } from "../../../query-hooks/ClientOrderInvoice/useUploadDCInvoice";
import { enqueueSnackbar } from "notistack";
import SuccessfulDialog from "../../../shared/UiElements/SuccessfulDialog";
import { useCustomDialog } from "../../../shared/customDialog";
import { currencyOnlyFormatter } from "../../../shared/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { ToWords } from "to-words";
import { useCreateDebitNote } from "../../../query-hooks/ClientOrderDebitNote/useCreateDebitNote";
import { useUploadDebitNote } from "../../../query-hooks/ClientOrderDebitNote/useUploadDebitNote";
import { useSaveDebitNote } from "../../../query-hooks/ClientOrderDebitNote/useSaveDebitNote";
import { useUpdateDebitNoteDraft } from "../../../query-hooks/ClientOrderDebitNote/useUpdateDebitNoteDraft";
import { useSubmitDebitNoteDraft } from "../../../query-hooks/ClientOrderDebitNote/useSubmitDebitNoteDraft";
import { useDeleteDebitNoteDraft } from "../../../query-hooks/ClientOrderDebitNote/useDeleteDebitNoteDraft";
import PrintableTable from "../../../purchase/components/PrintableTable";
import { useReactToPrint } from "react-to-print";
import { isMobileDevice } from "../../../App";
import MobileOrderReport from "../../../purchase/components/MobileOrderReport";
import MobileDCTable from "./MobileDCTable";
import ManualInvoiceUpload from "../../../finance/components/ManualInvoiceUpload";
import SuggestionInput from "../../../shared/formElements/SuggestionInput";
import { BorderColorOutlined, Cancel } from "@mui/icons-material";
import { CustomTooltip } from "../../../shared/theme/globalTheme";
import TextInput from "../../../shared/formElements/TextInput";
import MobileDCInvTable from "./MobileDCInvTable";

export const InvoiceSchema = (isManualInvoice) => {
  const baseSchema = {
    advance: yup.string().required(error_msg.required),
    due: yup.string().required(error_msg.required)
  };

  if (isManualInvoice) {
    baseSchema["invoiceId"] = yup.string().required(error_msg.required);
    baseSchema["invoiceDate"] = yup.string().required(error_msg.required);
    baseSchema["invoiceAmount"] = yup.string().required(error_msg.required);
    baseSchema["manualInvoice"] = yup
      .mixed()
      .required("file is required")
      .test(
        "fileType",
        "Kindly Upload images (jpg, jpeg, png) or PDF ",
        (value) => {
          const supportedFileTypes = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf"
          ];
          return supportedFileTypes.includes(value.type);
        }
      );
  }

  return yup.object(baseSchema);
};

const getColumns = (gstType, isSaleOrder) => {
  return [
    {
      Header: "S.No",
      accessor: "sNo",
      width: 90,
      size: 0.5
    },
    {
      Header: "Item/ SKU",
      accessor: "item",
      width: 200,
      size: gstType ? 1 : 2,
      Cell: ({ row, value }) => {
        return row.original?.skuCode ? (
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {row.original?.image?.thumbUrl && (
                <img
                  src={row.original?.image?.thumbUrl}
                  alt={row.original?.brandName ?? "--"}
                  height={52}
                  width={50}
                  style={{ objectFit: "contain" }}
                />
              )}
              <Typography
                fontSize={12}
                fontWeight={600}
                color={"primary"}
                component={Link}
              >
                {row.original?.skuCode}
              </Typography>
            </Box>
            <Typography fontSize={13} color={"text.secondary"}>
              {`HSN : ${row.original?.hsn ?? "--"}`}
            </Typography>
          </Box>
        ) : (
          value
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      width: 150,
      size: 1.5,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          row.original?.productName && (
            <Box display={"flex"} flexDirection={"column"} gap={0.5}>
              <Typography fontSize={14}>{`Product: ${
                row.original?.productName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Category: ${
                row.original?.categoryName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                row.original?.brandName ?? "--"
              }`}</Typography>
            </Box>
          )
        );
      }
    },
    {
      Header: "UOM",
      accessor: "uom",
      disableSortBy: true,
      size: 0.5,
      width: 60,
      Cell: ({ row }) => {
        return (
          row.original?.unit && (
            <Typography>{row.original?.unit?.name}</Typography>
          )
        );
      }
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      size: 1,
      width: 80,
      disableSortBy: true
    },
    {
      Header: "Price",
      accessor: "rate",
      size: 1,
      width: 80,
      disableSortBy: true
    },
    {
      Header: "Amount",
      accessor: "amount",
      size: 1.5,
      width: 100,
      disableSortBy: true
    },
    ...[
      ...(gstType
        ? [
            {
              Header: "CGST",
              accessor: "cgst",
              disableSortBy: true,
              size: 1,
              width: 80,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.cgst ?? 0,
                  row.original?.gstRegime ? row.original?.gstRegime / 2 : 0
                );
              }
            },
            {
              Header: "SGST",
              accessor: "sgst",
              disableSortBy: true,
              size: 1,
              width: 80,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.sgst,
                  row.original?.gstRegime ? row.original?.gstRegime / 2 : 0
                );
              }
            }
          ]
        : [
            {
              Header: "IGST",
              accessor: "igst",
              disableSortBy: true,
              size: 1,
              width: 80,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.igst ?? 0,
                  row.original?.gstRegime ? row.original?.gstRegime : 0
                );
              }
            }
          ])
    ],
    ...[
      ...(isSaleOrder
        ? [
            {
              Header: "Cess Adv",
              accessor: "cess",
              disableSortBy: true,
              size: 1,
              width: 110
            },
            {
              Header: "Cess Non Adv",
              accessor: "nonAdvol",
              disableSortBy: true,
              size: 1,
              width: 140
            }
          ]
        : [])
    ],
    {
      Header: "Total",
      accessor: "total",
      size: 1,
      width: 100,
      disableSortBy: true
    }
  ];
};

const getRows = (
  rows = [],
  gstType,
  isSaleOrder,
  isEditEnabled,
  control,
  watch,
  dcInvId
) => {
  return rows.map((row, index) => ({
    sNo: index + 1,
    ...row,
    rate: parseFloat(row?.rate).toFixed(2),
    amount: parseFloat(row?.amount).toFixed(2),
    ...{
      ...(isSaleOrder
        ? {
            isEditEnabled: isEditEnabled,
            mobileCessRate: !isEditEnabled ? (
              row?.cess || 0
            ) : (
              <Box display={"flex"} alignItems={"center"}>
                <TextInput
                  control={control}
                  name={`invoices.${[index]}.cess`}
                  placeholder={"Cess Advol %"}
                  sx={{
                    "& input::placeholder": {
                      fontSize: "8px"
                    },
                    maxWidth: "60px",
                    maxHeight: "30px"
                  }}
                  type="number"
                />
                <Box>%</Box>
              </Box>
            ),
            mobileCessValue:
              watch(`invoices.${[index]}.cess`) &&
              currencyOnlyFormatter(
                parseFloat(
                  (+row?.amount * +watch(`invoices.${[index]}.cess`)) / 100
                ).toFixed(2)
              ),
            cess: !isEditEnabled ? (
              gstColumn(
                watch(`invoices.${[index]}.cess`) &&
                  currencyOnlyFormatter(
                    parseFloat(
                      (+row?.amount * +watch(`invoices.${[index]}.cess`)) / 100
                    ).toFixed(2)
                  ),
                row?.cess || 0
              )
            ) : (
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <>
                  {(watch(`invoices.${[index]}.cess`) &&
                    currencyOnlyFormatter(
                      parseFloat(
                        (+row?.amount * +watch(`invoices.${[index]}.cess`)) /
                          100
                      ).toFixed(2)
                    )) ||
                    0}
                </>
                <Box display={"flex"} alignItems={"center"}>
                  <TextInput
                    control={control}
                    name={`invoices.${[index]}.cess`}
                    placeholder={"Cess Advol %"}
                    sx={{
                      "& input::placeholder": {
                        fontSize: "8px"
                      },
                      maxWidth: "60px",
                      maxHeight: "30px"
                    }}
                    type="number"
                  />
                  <Box>%</Box>
                </Box>
              </Box>
            ),
            nonAdvol: !isEditEnabled ? (
              row?.nonAdvol || 0
            ) : (
              <TextInput
                control={control}
                name={`invoices.${[index]}.nonAdvol`}
                placeholder={"Cess nonAdvol rate"}
                sx={{
                  "& input::placeholder": {
                    fontSize: "8px"
                  },
                  maxWidth: { xs: "60px", md: "100px" },
                  maxHeight: { xs: "30px", md: "50px" }
                }}
                type="number"
              />
            ),
            total:
              watch(`invoices.${[index]}.cess`) !== "" &&
              watch(`invoices.${[index]}.cess`) !== undefined &&
              watch(`invoices.${[index]}.nonAdvol`) !== "" &&
              watch(`invoices.${[index]}.nonAdvol`) !== undefined &&
              currencyOnlyFormatter(
                parseFloat(
                  (+row?.amount * +watch(`invoices.${[index]}.cess`)) / 100 +
                    +watch(`invoices.${[index]}.nonAdvol`) +
                    +row?.amount +
                    (gstType ? +row?.cgst + +row?.sgst : +row?.igst)
                ).toFixed(2)
              )
          }
        : { total: currencyOnlyFormatter(parseFloat(row?.total).toFixed(2)) })
    },
    gstType: gstType
  }));
};

const calculateCessSubtotal = (fields, watch) =>
  fields.reduce((total, field, i) => {
    const cess = watch(`invoices.${i}.cess`);
    return cess ? total + (field.amount * +cess) / 100 : total;
  }, 0);

const calculateNonAdvol = (fields, watch) =>
  fields.reduce((total, field, i) => {
    const cess = watch(`invoices.${i}.nonAdvol`);
    return cess ? total + +cess : total;
  }, 0);

const getTotalTaxAmounts = (arr) =>
  arr?.reduce((sum, obj) => sum + (obj?.totalAmount || 0), 0);

export const PaymentTermsFieldRow = ({
  label,
  control,
  disabled = false,
  advanceOptions = [],
  dueOptions = []
}) => {
  return (
    <Box display={"flex"} gap={4} width={"100%"}>
      <Box display={"flex"} gap={1} alignItems={"center"}>
        <Typography
          fontSize={16}
          fontWeight={500}
          whiteSpace={"nowrap"}
          color={disabled && "#607088"}
        >
          {label}
          <span style={{ color: disabled ? "#607088" : "#F05454" }}> *</span>
        </Typography>
        <Typography color={"primary"} fontWeight={700} fontSize={13}>
          Advance
        </Typography>
        <Box minWidth={89}>
          <SuggestionInput
            control={control}
            name={"advance"}
            type="number"
            menuItems={advanceOptions}
            disabled={disabled}
            sx={{ maxWidth: "89px" }}
          />
        </Box>
        %
      </Box>
      <Box display={"flex"} gap={1} alignItems={"center"}>
        <Typography color={"error"} fontWeight={700} fontSize={13}>
          Due
        </Typography>
        <Box minWidth={89}>
          <SuggestionInput
            control={control}
            name={"due"}
            menuItems={dueOptions}
            disabled={disabled}
            sx={{ maxWidth: "89px" }}
          />
        </Box>
        <Typography fontSize={13} whiteSpace={"nowrap"} marginLeft={2}>
          Days from receipt
        </Typography>
      </Box>
    </Box>
  );
};

export const InvoiceButtonGroup = ({
  InvoiceStatus,
  onDeleteClick,
  onSaveDraft,
  onSubmitClick,
  onEditClick,
  editText,
  submitButtonText
}) => {
  return (
    <>
      <Grid item xs={12} container rowSpacing={1}>
        {InvoiceStatus === "Draft" && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              sx={{ color: "error.main", minHeight: "48px" }}
              onClick={onDeleteClick}
            >
              Delete Draft
            </Button>
          </Grid>
        )}
        {submitButtonText === "Submit Invoice" && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                minHeight: "48px",
                color: editText === "Edit Cess" ? "warning" : "#46BD7D"
              }}
              onClick={onEditClick}
            >
              {editText}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="soft"
            fullWidth
            sx={{ minHeight: "48px" }}
            onClick={onSaveDraft}
          >
            Save Draft
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            sx={{ minHeight: "48px" }}
            onClick={onSubmitClick}
          >
            {submitButtonText}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const GPInvoiceTable = ({
  DcId,
  dcInvId,
  orderId,
  orderType,
  InvoiceStatus,
  deliveredDate,
  orderData,
  tableData,
  advOptions,
  dueOptions,
  DcCreateAllowed,
  InvCreateAllowed,
  refectDocs,
  CreateEwayBill,
  ewayButton
}) => {
  const [showManual, setShowManual] = useState(!Boolean(dcInvId));
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [costBreakUp, setCostBreakUp] = useState({
    cess: 0,
    nonAdvol: 0,
    total: 0
  });
  const toWords = new ToWords();

  const {
    control,
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(InvoiceSchema(showManual))
  });

  const handleEdit = () => {
    setIsEditEnabled((prev) => !prev);
  };

  const { showDialog, hideDialog } = useCustomDialog();

  const { fields, append } = useFieldArray({
    control,
    name: "invoices"
  });

  const InvWatch = watch("invoices");

  const onResetClick = () => {
    setValue("invoices", []);
    if (Boolean(tableData)) {
      tableData?.items?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item.rate,
          quantity: item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          gstRegime: item?.taxes[0]?.totalAmount
            ? item?.skuData?.gstRegime ?? 0
            : 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image,
          amount: item?.subTotalAmount,
          cgst: currencyOnlyFormatter(
            parseFloat(item?.taxes[0]?.totalAmount ?? 0).toFixed(2)
          ),
          sgst: currencyOnlyFormatter(
            parseFloat(item?.taxes[1]?.totalAmount ?? 0).toFixed(2)
          ),
          igst: currencyOnlyFormatter(
            parseFloat(item?.taxes[0]?.totalAmount ?? 0).toFixed(2)
          ),
          cess: dcInvId ? item?.cessRate || 0 : item?.skuData?.cessRate || 0,
          nonAdvol: dcInvId
            ? item?.cessNonAdvolAmount || 0
            : item?.skuData?.cessNonAdvolAmount || 0,
          total: item?.totalAmount
        });
        setValue(
          `invoices.${[index]}.cess`,
          dcInvId ? item?.cessRate || 0 : item?.skuData?.cessRate || 0
        );
        setValue(
          `invoices.${[index]}.nonAdvol`,
          dcInvId
            ? item?.cessNonAdvolAmount || 0
            : item?.skuData?.cessNonAdvolAmount || 0
        );
      });
    }
    handleEdit();
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: tableData?.code
  });

  const paymentTerms = watch(["advance", "due"]);

  const otherWatch = watch("others");

  useEffect(() => {
    if (Boolean(tableData)) {
      setValue("advance", tableData?.advancePercent);
      setValue("due", tableData?.dueDays);
      setValue("others", tableData?.otherAmount || 0);
      tableData?.items?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item.rate,
          quantity: item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          gstRegime: item?.taxes[0]?.totalAmount
            ? item?.skuData?.gstRegime ?? 0
            : 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image,
          amount: item?.subTotalAmount,
          cgst: currencyOnlyFormatter(
            parseFloat(item?.taxes[0]?.totalAmount ?? 0).toFixed(2)
          ),
          sgst: currencyOnlyFormatter(
            parseFloat(item?.taxes[1]?.totalAmount ?? 0).toFixed(2)
          ),
          igst: currencyOnlyFormatter(
            parseFloat(item?.taxes[0]?.totalAmount ?? 0).toFixed(2)
          ),
          cess: dcInvId ? item?.cessRate || 0 : item?.skuData?.cessRate || 0,
          nonAdvol: dcInvId
            ? item?.cessNonAdvolAmount || 0
            : item?.skuData?.cessNonAdvolAmount || 0,
          total: item?.totalAmount
        });
        setValue(
          `invoices.${[index]}.cess`,
          dcInvId ? item?.cessRate || 0 : item?.skuData?.cessRate || 0
        );
        setValue(
          `invoices.${[index]}.nonAdvol`,
          dcInvId
            ? item?.cessNonAdvolAmount || 0
            : item?.skuData?.cessNonAdvolAmount || 0
        );
      });
    } // eslint-disable-next-line
  }, [JSON.stringify(tableData)]);

  useEffect(() => {
    if (InvWatch) {
      setCostBreakUp({
        cess: tableData?.cessValue || calculateCessSubtotal(fields, watch),
        nonAdvol:
          tableData?.cessNonAdvolValue || calculateNonAdvol(fields, watch),
        total:
          +tableData?.subTotalAmount +
          +getTotalTaxAmounts(tableData?.taxes) +
          +calculateCessSubtotal(fields, watch) +
          +calculateNonAdvol(fields, watch) +
          (+otherWatch || 0)
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(InvWatch), JSON.stringify(otherWatch), dcInvId]);

  const {
    mutate: createNewInvoice,
    isError: isCreateError,
    error: createError
  } = useCreateDCInvoice();

  const {
    mutate: uploadNewInvoice,
    isError: isUploadError,
    error: uploadError
  } = useUploadDCInvoice();

  const {
    mutate: saveDraftInvoice,
    isError: isSaveDraftError,
    error: saveDraftError
  } = useSaveDCInvoice();

  const {
    mutate: updateDCInvoiceDraft,
    isError: isUpdateDraftError,
    error: updateDraftError
  } = useUpdateDCInvoiceDraft();

  const {
    mutate: submitDCInvoiceDraft,
    isError: isSubmitDraftError,
    error: submitDraftError
  } = useSubmitDCInvoiceDraft();

  const {
    mutate: deleteDCInvoiceDraft,
    isError: isDeleteDraftError,
    error: deleteDraftError
  } = useDeleteDCInvoiceDraft();

  const {
    mutate: createDebitNote,
    isError: isCreateDNError,
    error: createDNError
  } = useCreateDebitNote();

  const {
    mutate: uploadDebitNote,
    isError: isUploadDNError,
    error: uploadDNError
  } = useUploadDebitNote();

  const {
    mutate: saveDebitNoteDraft,
    isError: isSaveDNDraftError,
    error: saveDNDraftError
  } = useSaveDebitNote();

  const {
    mutate: updateDebitNoteDraft,
    isError: isUpdateDNDraftError,
    error: updateDNDraftError
  } = useUpdateDebitNoteDraft();

  const {
    mutate: submitDebitNoteDraft,
    isError: isSubmitDNDraftError,
    error: submitDNDraftError
  } = useSubmitDebitNoteDraft();

  const {
    mutate: deleteDebitNoteDraft,
    isError: isDeleteDNDraftError,
    error: deleteDNDraftError
  } = useDeleteDebitNoteDraft();

  const onSaveDraft = (data) => {
    const dcData = {
      advancePercent: data["advance"],
      dueDays: data["due"],
      ...{
        ...(orderType === "SaleOrder"
          ? {
              ...{
                ...(data["others"] !== undefined || data["others"] !== ""
                  ? { otherAmount: +data["others"] || 0 }
                  : {})
              },
              items: data["invoices"]?.map((item) => ({
                sku: item._id,
                cessRate: +item?.cess || 0,
                cessNonAdvolAmount: +item?.nonAdvol || 0
              }))
            }
          : {})
      }
    };
    orderType === "SaleOrder"
      ? dcInvId
        ? updateDCInvoiceDraft(
            {
              data: dcData,
              invoiceId: dcInvId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Invoice Draft Updated!", {
                  variant: "success"
                });
                reset();
                refectDocs();
              }
            }
          )
        : saveDraftInvoice(
            {
              data: dcData,
              dcId: DcId
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Invoice Draft Saved!", {
                  variant: "success"
                });
                reset();
                refectDocs();
              }
            }
          )
      : dcInvId
      ? updateDebitNoteDraft(
          {
            data: dcData,
            dnId: dcInvId
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("Debit Note Draft Updated!", {
                variant: "success"
              });
              reset();
              refectDocs();
            }
          }
        )
      : saveDebitNoteDraft(
          {
            data: dcData,
            dcId: DcId
          },
          {
            onSuccess: (res) => {
              enqueueSnackbar("Debit Note Draft Saved!", {
                variant: "success"
              });
              reset();
              refectDocs();
            }
          }
        );
  };

  const submitData = (data) => {
    if (showManual) {
      const InvData = new FormData();
      InvData.append("advancePercent", data["advance"]);
      InvData.append("dueDays", data["due"]);
      InvData.append("customCode", data["invoiceId"]);
      InvData.append(
        "docDate",
        moment(data["invoiceDate"]).format("YYYY-MM-DD")
      );
      InvData.append("totalAmount", data["invoiceAmount"]);
      InvData.append("file", data["manualInvoice"]);
      orderType === "SaleOrder"
        ? uploadNewInvoice(
            {
              data: InvData,
              dcId: DcId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Invoice Created!", {
                  variant: "success"
                });
                reset();
                refectDocs();
              }
            }
          )
        : uploadDebitNote(
            {
              data: InvData,
              dcId: DcId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Debit Note Created!", {
                  variant: "success"
                });
                reset();
                refectDocs();
              }
            }
          );
    } else {
      const dcData = {
        advancePercent: data["advance"],
        dueDays: data["due"],
        ...{
          ...(orderType === "SaleOrder"
            ? {
                ...{
                  ...(data["others"] !== undefined || data["others"] !== ""
                    ? { otherAmount: +data["others"] || 0 }
                    : {})
                },
                items: data["invoices"]?.map((item) => ({
                  sku: item._id,
                  cessRate: +item?.cess || 0,
                  cessNonAdvolAmount: +item?.nonAdvol || 0
                }))
              }
            : {})
        }
      };
      orderType === "SaleOrder"
        ? dcInvId
          ? submitDCInvoiceDraft(
              {
                data: { ...dcData },
                invoiceId: dcInvId
              },
              {
                onSuccess: (res) => {
                  hideDialog();
                  enqueueSnackbar("Invoice Created!", {
                    variant: "success"
                  });
                  reset();
                  refectDocs();
                }
              }
            )
          : createNewInvoice(
              {
                data: { ...dcData },
                dcId: DcId
              },
              {
                onSuccess: (res) => {
                  hideDialog();
                  enqueueSnackbar("Invoice Created!", {
                    variant: "success"
                  });
                  reset();
                  refectDocs();
                }
              }
            )
        : dcInvId
        ? submitDebitNoteDraft(
            {
              data: { ...dcData },
              dnId: dcInvId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Debit Note Created!", {
                  variant: "success"
                });
                reset();
                refectDocs();
              }
            }
          )
        : createDebitNote(
            {
              data: { ...dcData },
              dcId: DcId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Debit Note Created!", {
                  variant: "success"
                });
                reset();
                refectDocs();
              }
            }
          );
    }
  };

  const onSubmitClick = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to submit the document ?"}
          subText={"Submitted documents cannot be edited. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Submit"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => submitData(data)}
        />
      )
    });
  };

  const deleteDraft = () => {
    orderType === "SaleOrder"
      ? deleteDCInvoiceDraft(
          {
            invoiceId: dcInvId
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("Draft Deleted!", {
                variant: "success"
              });
              reset();
              refectDocs();
            }
          }
        )
      : deleteDebitNoteDraft(
          {
            dnId: dcInvId
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("Draft Deleted!", {
                variant: "success"
              });
              reset();
              refectDocs();
            }
          }
        );
  };

  const onDeleteClick = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Delete this draft ?"}
          subText={"Deleted documents cannot be recovered. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Delete"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => deleteDraft()}
        />
      )
    });
  };

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUploadError) {
      enqueueSnackbar(uploadError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDraftError) {
      enqueueSnackbar(saveDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateDraftError) {
      enqueueSnackbar(updateDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSubmitDraftError) {
      enqueueSnackbar(submitDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isDeleteDraftError) {
      enqueueSnackbar(deleteDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isCreateDNError) {
      enqueueSnackbar(createDNError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUploadDNError) {
      enqueueSnackbar(uploadDNError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDNDraftError) {
      enqueueSnackbar(saveDNDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateDNDraftError) {
      enqueueSnackbar(updateDNDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSubmitDNDraftError) {
      enqueueSnackbar(submitDNDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isDeleteDNDraftError) {
      enqueueSnackbar(deleteDNDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    createError?.response?.data?.message,
    uploadError?.response?.data?.message,
    saveDraftError?.response?.data?.message,
    updateDraftError?.response?.data?.message,
    submitDraftError?.response?.data?.message,
    deleteDraftError?.response?.data?.message,
    createDNError?.response?.data?.message,
    uploadDNError?.response?.data?.message,
    saveDNDraftError?.response?.data?.message,
    updateDNDraftError?.response?.data?.message,
    submitDNDraftError?.response?.data?.message,
    deleteDNDraftError?.response?.data?.message,
    isSaveDraftError,
    isCreateError,
    isUploadError,
    isUpdateDraftError,
    isSubmitDraftError,
    isDeleteDraftError,
    isSaveDNDraftError,
    isCreateDNError,
    isUploadDNError,
    isUpdateDNDraftError,
    isSubmitDNDraftError,
    isDeleteDNDraftError
  ]);

  const otherDetails = [
    {
      label: `Payment Terms :`,
      value:
        paymentTerms[0] !== "" &&
        paymentTerms[0] !== undefined &&
        paymentTerms[1]
          ? `${paymentTerms[0]}% Advance. Balance Due ${paymentTerms[1]} days from receipt`
          : "--"
    }
  ];

  const otherDetailsComp = (
    <Grid container rowSpacing={2}>
      {otherDetails?.map((item, index) => (
        <OtherDetailsComp key={index} label={item.label} value={item.value} />
      ))}
    </Grid>
  );

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      {InvCreateAllowed && (
        <Box display={"flex"} justifyContent={"space-between"}>
          {(!Boolean(InvoiceStatus) || InvoiceStatus === "Draft") && (
            <>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <PaymentTermsFieldRow
                  label={"Payment Terms"}
                  control={control}
                  disabled={false}
                  advanceOptions={advOptions}
                  dueOptions={dueOptions}
                />
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }} pl={2}>
                <MobilePaymentTermsField
                  label={"Payment Terms"}
                  control={control}
                  disabled={false}
                  advanceOptions={advOptions}
                  dueOptions={dueOptions}
                />
              </Box>
            </>
          )}
          {!showManual &&
            (!Boolean(InvoiceStatus) || InvoiceStatus === "Draft") && (
              <Box
                sx={{ display: { xs: "none", md: "flex" } }}
                justifyContent={"flex-end"}
                gap={1}
              >
                {(!Boolean(InvoiceStatus) || InvoiceStatus === "Draft") &&
                  (isEditEnabled ? (
                    <CustomTooltip title={"Reset Changes"} placement="top">
                      <IconButton onClick={onResetClick}>
                        <Cancel color="error" />
                      </IconButton>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip title={"Edit"} placement="top">
                      <IconButton onClick={handleEdit}>
                        <BorderColorOutlined color="warning" />
                      </IconButton>
                    </CustomTooltip>
                  ))}
                {InvoiceStatus === "Draft" && (
                  <Button
                    variant="link"
                    disableRipple
                    onClick={() => onDeleteClick()}
                    sx={{ color: "error.main", width: "110px" }}
                  >
                    Delete Draft
                  </Button>
                )}
                <Button
                  variant="link"
                  disableRipple
                  onClick={handleSubmit(onSaveDraft)}
                  sx={{ width: "90px" }}
                >
                  Save Draft
                </Button>
                <Button
                  variant="link"
                  disableRipple
                  onClick={handleSubmit(onSubmitClick)}
                  sx={{ width: "110px" }}
                >
                  {orderType === "SaleOrder" ? "Submit Invoice" : "Submit DN"}
                </Button>
              </Box>
            )}
        </Box>
      )}
      {!showManual && InvoiceStatus === "Placed" && !isMobileDevice && (
        <Box display={"flex"} justifyContent={"flex-end"} mt={-3} gap={4}>
          {Boolean(deliveredDate) && (
            <Typography
              fontSize={15}
              fontWeight={600}
              lineHeight={"40px"}
            >{`Delivered: ${moment(deliveredDate).format(
              "DD.MM.YYYY"
            )}`}</Typography>
          )}
          {DcCreateAllowed && ewayButton() === "" ? (
            <Button
              variant="link"
              disableRipple
              onClick={() => CreateEwayBill(null)}
            >
              Generate E-waybill
            </Button>
          ) : (
            ewayButton()
          )}
          <Button variant="link" disableRipple onClick={handlePrint}>
            Print
          </Button>
        </Box>
      )}
      {showManual ? (
        <Box
          boxShadow={"0px 8px 24px 0px #1515161A"}
          sx={{
            borderRadius: { xs: "8px", md: "unset" }
          }}
        >
          <ManualInvoiceUpload
            ewayButton={ewayButton}
            control={control}
            setValue={setValue}
            setShowManual={setShowManual}
            onSubmit={handleSubmit(onSubmitClick)}
            errors={errors}
            Title={orderType === "SaleOrder" ? "Invoice" : "Debit Note"}
            ewayData={tableData?.ewb}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            flexDirection={"column"}
            gap={1}
          >
            <OrderReport
              title={orderType === "SaleOrder" ? "Tax Invoice" : "Debit Note"}
              buyerTitle={"Buyer"}
              shippingTitle={"Shipto"}
              sellerTitle={"Seller"}
              orderInfo={
                orderType === "SaleOrder"
                  ? {
                      invoiceNo: InvoiceStatus
                        ? InvoiceStatus === "Draft"
                          ? "Draft"
                          : tableData?.code
                        : "--",
                      invoiceDate: InvoiceStatus
                        ? moment(tableData?.docDate).format("DD-MM-YYYY")
                        : "--"
                    }
                  : {
                      debitNoteNo: InvoiceStatus ? tableData?.code : "--",
                      dnDate: InvoiceStatus
                        ? moment(tableData?.docDate).format("DD-MM-YYYY")
                        : "--"
                    }
              }
              sellerInfo={
                orderType === "SaleOrder"
                  ? vendorAddressData(orderData?.clientData)
                  : vendorAddressData(orderData?.vendorData)
              }
              buyerInfo={
                orderType === "SaleOrder"
                  ? vendorAddressData(orderData?.vendorData)
                  : vendorAddressData(orderData?.clientData)
              }
              shipInfo={shipToAddressData(orderData?.vendorData?.warehouse)}
            >
              {
                <CustomTable
                  columns={getColumns(
                    Boolean(tableData?.taxes?.length)
                      ? tableData?.taxes?.length > 1
                      : false,
                    orderType === "SaleOrder",
                    isEditEnabled
                  )}
                  data={getRows(
                    fields,
                    Boolean(
                      Boolean(tableData?.taxes?.length)
                        ? tableData?.taxes?.length > 1
                        : false
                    ),
                    orderType === "SaleOrder",
                    isEditEnabled,
                    control,
                    watch,
                    dcInvId
                  )}
                  mobileComponent={
                    orderType === "SaleOrder" ? MobileDCInvTable : MobileDCTable
                  }
                />
              }
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box display={"flex"} flexDirection={"column"} gap={3}>
                    <Box display={"flex"} gap={5}>
                      {reportLabel1(
                        purchase_labels.paymentTermsText,
                        paymentTerms[0] !== "" &&
                          paymentTerms[0] !== undefined &&
                          paymentTerms[1]
                          ? `${paymentTerms[0]}% Advance. Balance Due ${paymentTerms[1]} days from receipt`
                          : "--"
                      )}
                    </Box>
                    <Box width={"500px"}>
                      <TextArea
                        control={control}
                        name={"otherItemsAndConditionsLabel"}
                        label={purchase_labels.otherItemsAndConditionsLabel}
                        disabled
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={2.5}>
                      {reportLabel2(
                        purchase_labels.amountInWordsLabel,
                        `${toWords.convert(costBreakUp.total || 0, {
                          currency: true
                        })}`
                      )}
                      {reportLabel2(
                        purchase_labels.preparedByLabel,
                        InvoiceStatus
                          ? `${tableData?.preparedBy?.firstName} ${tableData?.preparedBy?.lastName}`
                          : "--"
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <TotalBreakup
                    gstType={
                      Boolean(tableData?.taxes?.length)
                        ? tableData?.taxes?.length > 1
                        : false
                    }
                    subTotal={tableData?.subTotalAmount ?? 0}
                    cgst={
                      tableData?.taxes ? tableData?.taxes[0]?.totalAmount : 0
                    }
                    sgst={
                      tableData?.taxes ? tableData?.taxes[1]?.totalAmount : 0
                    }
                    igst={
                      tableData?.taxes ? tableData?.taxes[0]?.totalAmount : 0
                    }
                    control={control}
                    cess={costBreakUp.cess}
                    nonAdvol={costBreakUp.nonAdvol}
                    showCess
                    disableInput={!isEditEnabled}
                    total={costBreakUp.total || 0}
                  />
                </Grid>
              </Grid>
            </OrderReport>
          </Box>
          <Box
            sx={{ display: { xs: "flex", md: "none" } }}
            flexDirection={"column"}
            gap={1}
          >
            <MobileOrderReport
              otherInfo={otherDetailsComp}
              mobileTable={
                <CustomTable
                  columns={getColumns(
                    Boolean(tableData?.taxes?.length)
                      ? tableData?.taxes?.length > 1
                      : false,
                    orderType === "SaleOrder",
                    isEditEnabled
                  )}
                  data={getRows(
                    fields,
                    Boolean(
                      Boolean(tableData?.taxes?.length)
                        ? tableData?.taxes?.length > 1
                        : false
                    ),
                    orderType === "SaleOrder",
                    isEditEnabled,
                    control,
                    watch,
                    dcInvId
                  )}
                  mobileComponent={
                    orderType === "SaleOrder" ? MobileDCInvTable : MobileDCTable
                  }
                />
              }
              remarksComp={
                <Box
                  display={"flex"}
                  minWidth={"289px"}
                  minHeight={"90px"}
                  border={"1px solid #B7BEC7"}
                  borderRadius={"8px"}
                  padding={"10px"}
                ></Box>
              }
              Total={[
                {
                  label: "Subtotal Amount :",
                  value: tableData?.subTotalAmount
                    ? currencyOnlyFormatter(
                        parseFloat(tableData?.subTotalAmount).toFixed(2)
                      )
                    : "--"
                },
                ...[
                  ...(Boolean(
                    Boolean(tableData?.taxes?.length)
                      ? tableData?.taxes?.length > 1
                      : false
                  )
                    ? [
                        {
                          label: "CGST :",
                          value: tableData?.taxes[0]?.totalAmount
                            ? currencyOnlyFormatter(
                                parseFloat(
                                  tableData?.taxes[0]?.totalAmount
                                ).toFixed(2)
                              )
                            : "--"
                        },
                        {
                          label: "SGST :",
                          value: tableData?.taxes[1]?.totalAmount
                            ? currencyOnlyFormatter(
                                parseFloat(
                                  tableData?.taxes[1]?.totalAmount
                                ).toFixed(2)
                              )
                            : "--"
                        }
                      ]
                    : [
                        {
                          label: "IGST :",
                          value: tableData?.taxes[0]?.totalAmount
                            ? currencyOnlyFormatter(
                                parseFloat(
                                  tableData?.taxes[0]?.totalAmount
                                ).toFixed(2)
                              )
                            : "--"
                        }
                      ])
                ],
                {
                  label: "Cess Advol :",
                  value: costBreakUp.cess
                    ? currencyOnlyFormatter(
                        parseFloat(costBreakUp.cess).toFixed(2)
                      )
                    : "--"
                },
                {
                  label: "Cess NonAdvol :",
                  value: costBreakUp.nonAdvol
                    ? currencyOnlyFormatter(
                        parseFloat(costBreakUp.nonAdvol).toFixed(2)
                      )
                    : "--"
                },
                {
                  label: "Others :",
                  value: (
                    <TextInput
                      control={control}
                      name={"others"}
                      type="number"
                      sx={{ maxHeight: "30px", maxWidth: "90px" }}
                      disabled={!isEditEnabled}
                    />
                  )
                },
                {
                  label: "Total Amount :",
                  value: costBreakUp.total
                    ? currencyOnlyFormatter(
                        parseFloat(costBreakUp.total || 0).toFixed(2)
                      )
                    : "--"
                }
              ]}
              ButtonGroup={
                !showManual &&
                (!Boolean(InvoiceStatus) || InvoiceStatus === "Draft") && (
                  <InvoiceButtonGroup
                    InvoiceStatus={InvoiceStatus}
                    onDeleteClick={onDeleteClick}
                    onSaveDraft={handleSubmit(onSaveDraft)}
                    onSubmitClick={handleSubmit(onSubmitClick)}
                    submitButtonText={
                      orderType === "SaleOrder" ? "Submit Invoice" : "Submit DN"
                    }
                    editText={isEditEnabled ? "Reset" : "Edit Cess"}
                    onEditClick={handleEdit}
                  />
                )
              }
              preparedBy={
                InvoiceStatus
                  ? `${tableData?.preparedBy?.firstName} ${tableData?.preparedBy?.lastName}`
                  : "--"
              }
              title={orderType === "SaleOrder" ? "Tax Invoice" : "Debit Note"}
              buyerTitle={"Buyer"}
              shippingTitle={"Shipto"}
              sellerTitle={"Seller"}
              orderInfo={
                orderType === "SaleOrder"
                  ? {
                      invoiceNo: InvoiceStatus
                        ? InvoiceStatus === "Draft"
                          ? "Draft"
                          : tableData?.code
                        : "--",
                      invoiceDate: InvoiceStatus
                        ? moment(tableData?.docDate).format("DD-MM-YYYY")
                        : "--"
                    }
                  : {
                      debitNoteNo: InvoiceStatus ? tableData?.code : "--",
                      dnDate: InvoiceStatus
                        ? moment(tableData?.docDate).format("DD-MM-YYYY")
                        : "--"
                    }
              }
              sellerInfo={
                orderType === "SaleOrder"
                  ? vendorAddressData(orderData?.clientData)
                  : vendorAddressData(orderData?.vendorData)
              }
              buyerInfo={
                orderType === "SaleOrder"
                  ? vendorAddressData(orderData?.vendorData)
                  : vendorAddressData(orderData?.clientData)
              }
              shipInfo={shipToAddressData(orderData?.vendorData?.warehouse)}
            />
          </Box>
          <div style={{ display: "none" }}>
            <Box ref={componentRef} sx={{ padding: "10px" }}>
              <OrderReport
                title={orderType === "SaleOrder" ? "Tax Invoice" : "Debit Note"}
                buyerTitle={"Buyer"}
                shippingTitle={"Shipto"}
                sellerTitle={"Seller"}
                orderInfo={
                  orderType === "SaleOrder"
                    ? {
                        invoiceNo: InvoiceStatus
                          ? InvoiceStatus === "Draft"
                            ? "Draft"
                            : tableData?.code
                          : "--",
                        invoiceDate: InvoiceStatus
                          ? moment(tableData?.docDate).format("DD-MM-YYYY")
                          : "--"
                      }
                    : {
                        debitNoteNo: InvoiceStatus ? tableData?.code : "--",
                        dnDate: InvoiceStatus
                          ? moment(tableData?.docDate).format("DD-MM-YYYY")
                          : "--"
                      }
                }
                sellerInfo={
                  orderType === "SaleOrder"
                    ? vendorAddressData(orderData?.clientData)
                    : vendorAddressData(orderData?.vendorData)
                }
                buyerInfo={
                  orderType === "SaleOrder"
                    ? vendorAddressData(orderData?.vendorData)
                    : vendorAddressData(orderData?.clientData)
                }
                shipInfo={shipToAddressData(orderData?.vendorData?.warehouse)}
              >
                <Grid container>
                  <PrintableTable
                    columns={getColumns(
                      Boolean(tableData?.taxes?.length)
                        ? tableData?.taxes?.length > 1
                        : false,
                      orderType === "SaleOrder",
                      isEditEnabled
                    )}
                    rows={getRows(
                      fields,
                      Boolean(
                        Boolean(tableData?.taxes?.length)
                          ? tableData?.taxes?.length > 1
                          : false
                      ),
                      orderType === "SaleOrder",
                      isEditEnabled,
                      control,
                      watch,
                      dcInvId
                    )}
                    docType={
                      orderType === "SaleOrder"
                        ? "TaxInvoice"
                        : "DeliveryChallan"
                    }
                    gstType={
                      Boolean(tableData?.taxes?.length)
                        ? tableData?.taxes?.length > 1
                        : false
                    }
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Box display={"flex"} flexDirection={"column"} gap={3}>
                      <Box display={"flex"} gap={5}>
                        {reportLabel1(
                          purchase_labels.paymentTermsText,
                          paymentTerms[0] !== "" &&
                            paymentTerms[0] !== undefined &&
                            paymentTerms[1]
                            ? `${paymentTerms[0]}% Advance. Balance Due ${paymentTerms[1]} days from receipt`
                            : "--"
                        )}
                      </Box>
                      <Box width={"500px"}>
                        <TextArea
                          control={control}
                          name={"otherItemsAndConditionsLabel"}
                          label={purchase_labels.otherItemsAndConditionsLabel}
                          disabled
                        />
                      </Box>
                      <Box display={"flex"} flexDirection={"column"} gap={2.5}>
                        {reportLabel2(
                          purchase_labels.amountInWordsLabel,
                          `${toWords.convert(costBreakUp.total || 0, {
                            currency: true
                          })}`
                        )}
                        {reportLabel2(
                          purchase_labels.preparedByLabel,
                          InvoiceStatus
                            ? `${tableData?.preparedBy?.firstName} ${tableData?.preparedBy?.lastName}`
                            : "--"
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <TotalBreakup
                      gstType={
                        Boolean(tableData?.taxes?.length)
                          ? tableData?.taxes?.length > 1
                          : false
                      }
                      subTotal={tableData?.subTotalAmount ?? 0}
                      cgst={
                        tableData?.taxes ? tableData?.taxes[0]?.totalAmount : 0
                      }
                      sgst={
                        tableData?.taxes ? tableData?.taxes[1]?.totalAmount : 0
                      }
                      igst={
                        tableData?.taxes ? tableData?.taxes[0]?.totalAmount : 0
                      }
                      control={control}
                      cess={costBreakUp.cess}
                      nonAdvol={costBreakUp.nonAdvol}
                      showCess
                      disableInput={!isEditEnabled}
                      total={costBreakUp.total || 0}
                    />
                  </Grid>
                </Grid>
              </OrderReport>
            </Box>
          </div>
        </>
      )}
    </Box>
  );
};

export default GPInvoiceTable;
