import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ItemDetailsCard } from "./ItemDetailsCard";
import { AddCircleOutline } from "@mui/icons-material";
import { CustomFieldRow } from "./EwayBillForm";
import TextInput from "../../../shared/formElements/TextInput";
import DropDown from "../../../shared/formElements/DropDown";
import { currencyOnlyFormatter, dropdownOptions } from "../../../shared/utils";
import { SkusById } from "../../../purchase/components/Purchase/PurchaseOrderTable";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { EwayBillFormKeys } from "../../../constants/formKeys";
import { useCalculateEwayBill } from "../../../query-hooks/ClientEwayBill/CreateEwayBill/useCalculateTotalValue";

const getRows = (rows, control, options, remove, toggleEdit) => {
  return rows.map((row, index) => ({
    ...row,
    index: index,
    isEditing: row.isEditing,
    rate: row.isEditing ? (
      <TextInput
        control={control}
        name={`itemDetails.${index}.rate`}
        type="number"
        sx={{
          maxHeight: { xs: "30px", md: "44px" },
          border: "1px solid #2773FF",
          "& input::placeholder": {
            fontSize: "8px"
          }
        }}
        placeholder={"rate"}
      />
    ) : (
      parseFloat(row?.rate).toFixed(2)
    ),
    quantity: row.isEditing ? (
      <TextInput
        control={control}
        name={`itemDetails.${index}.quantity`}
        type="number"
        sx={{
          maxHeight: { xs: "30px", md: "44px" },
          border: "1px solid #2773FF",
          "& input::placeholder": {
            fontSize: "8px"
          }
        }}
        placeholder={"Qty"}
      />
    ) : (
      row?.quantity
    ),
    igst: parseFloat(row?.igstRate).toFixed(1),
    cgst: parseFloat(row?.cgstRate).toFixed(1),
    sgst: parseFloat(row?.sgstRate).toFixed(1),
    taxable: parseFloat(row?.taxableAmount).toFixed(2),
    advol: row.isEditing ? (
      <TextInput
        control={control}
        name={`itemDetails.${index}.advol`}
        type="number"
        sx={{
          maxHeight: { xs: "30px", md: "44px" },
          border: "1px solid #2773FF",
          "& input::placeholder": {
            fontSize: "8px"
          }
        }}
        placeholder={"Cess Advol %"}
      />
    ) : (
      parseFloat(row?.advol).toFixed(2)
    ),
    Nonadval: row.isEditing ? (
      <TextInput
        control={control}
        name={`itemDetails.${index}.Nonadval`}
        type="number"
        sx={{
          maxHeight: { xs: "30px", md: "44px" },
          border: "1px solid #2773FF",
          "& input::placeholder": {
            fontSize: "8px"
          }
        }}
        placeholder={"Cess nonAdvol rate"}
      />
    ) : (
      parseFloat(row?.Nonadval || 0).toFixed(2)
    ),
    item: !row?.item && (
      <DropDown
        control={control}
        name={`itemDetails.${index}.item`}
        options={dropdownOptions(options, "_id", "skuCode")}
        placeholder={"Select SKU"}
        allowSearch
      />
    ),
    remove: remove,
    toggleEdit: () => toggleEdit(index)
  }));
};

const ItemDetailsForm = ({
  fields = [],
  control,
  append,
  remove,
  options,
  EwayId,
  watch,
  setValue,
  replace,
  handleSubmit,
  calculationValues
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [costBreakUp, setCostBreakUp] = useState(calculationValues);

  const {
    mutate: calculateTotalValue,
    isError: isCalulateError,
    error: calculateError,
    isLoading: isCalculating
  } = useCalculateEwayBill();

  const updateFieldArray = (response) => {
    // Transform the response array into the desired format for the items array
    const transformedItems = response?.map((resp) => ({
      _id: resp.sku,
      skuCode: resp.skuData.skuCode,
      image: options?.find((item) => item._id === resp.sku)?.image,
      productName: resp.skuData.productName,
      brandName: resp.skuData.brandName,
      categoryName: resp.skuData.categoryName,
      unit: resp.skuData.unit,
      gstRegime: resp.skuData.gstRegime,
      hsn: resp.skuData.hsn,
      rate: resp.rate,
      quantity: resp.quantity,
      advol: resp.cessRate,
      Nonadval: resp.cessNonAdvolValue,
      taxableAmount: resp.taxableAmount,
      sgstRate: resp.sgstRate,
      cgstRate: resp.cgstRate,
      igstRate: resp.igstRate,
      isEditing: false
    }));

    // Replace the current field array with the transformed items
    replace(transformedItems);
  };

  const toggleEdit = (index) => {
    const updatedFields = fields.map((field, idx) =>
      idx === index ? { ...field, isEditing: !field.isEditing } : field
    );
    replace(updatedFields);
  };

  const onCalculateClick = (data) => {
    calculateTotalValue(
      {
        data: {
          fromGstin: data[EwayBillFormKeys.supplierGstin],
          fromState: data[EwayBillFormKeys.supplierState1],
          toGstin: data[EwayBillFormKeys.recipientGstin],
          toState: data[EwayBillFormKeys.recipientState1],
          ...{
            ...(!Boolean(data["others"])
              ? {}
              : {
                  otherValue: data["others"]
                })
          },
          items: data["itemDetails"]?.map((item) => ({
            sku: item._id,
            rate: +item.rate,
            quantity: +item.quantity,
            cessRate: +item.advol,
            cessNonAdvolValue: +item.Nonadval
          }))
        }
      },
      {
        onSuccess: (res) => {
          updateFieldArray(res?.data?.data?.calculatedData?.items);
          setCostBreakUp({
            taxableAmount: res?.data?.data?.calculatedData?.totalTaxableValue,
            cgst: res?.data?.data?.calculatedData?.sgstValue,
            sgst: res?.data?.data?.calculatedData?.cgstValue,
            igst: res?.data?.data?.calculatedData?.igstValue,
            cessAdv: res?.data?.data?.calculatedData?.cessValue,
            cessNonAdv: res?.data?.data?.calculatedData?.cessNonAdvolValue,
            totalInv: res?.data?.data?.calculatedData?.totInvValue
          });
          enqueueSnackbar("Values Calculated", { variant: "success" });
        }
      }
    );
  };

  useEffect(() => {
    if (isCalulateError)
      enqueueSnackbar(calculateError?.response?.data?.message, {
        variant: "error"
      });
  }, [calculateError?.response?.data?.message, isCalulateError]);

  useEffect(() => {
    const selectedSKUs = fields.map((item) => item._id);
    const newOptions = options.filter(
      (option) => !selectedSKUs.includes(option._id)
    );
    setFilteredOptions(newOptions);
  }, [fields, options]);

  const skuWatch = watch(
    `itemDetails.${fields.length ? fields.length - 1 : 0}.item`
  );
  const rateWatch = watch(
    `itemDetails.${fields.length ? fields.length - 1 : 0}.rate`
  );

  const isSameState =
    watch(EwayBillFormKeys.supplierState1) ===
    watch(EwayBillFormKeys.recipientState1);

  const quantityWatch = watch(
    `itemDetails.${fields.length ? fields.length - 1 : 0}.quantity`
  );

  useEffect(() => {
    if (skuWatch && fields.length > 0) {
      const skuData = SkusById(options, skuWatch);
      remove(fields.length - 1);
      append({ ...skuData[0], isEditing: true });
    }
  }, [skuWatch, fields.length, remove, append, options]);

  const onAddClick = () => {
    if ((rateWatch && quantityWatch) || fields.length === 0) {
      append({ item: "", isEditing: true });
    } else {
      enqueueSnackbar("kindly first fill rate and quantity", {
        variant: "error"
      });
    }
  };

  useEffect(() => {
    if (fields.length === 0 && !EwayId) onAddClick(); // Add one row by default
    // eslint-disable-next-line
  }, [fields.length, EwayId]);

  const skuItems = getRows(
    fields,
    control,
    filteredOptions,
    remove,
    toggleEdit
  );

  return (
    <Grid
      container
      spacing={1}
      sx={{ padding: { xs: "10px", md: "20px 20px 0px 30px" } }}
    >
      {fields.length < 1 ? (
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          No Items
        </Grid>
      ) : (
        <Box
          className="custom-mobile-table"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px"
          }}
        >
          {skuItems?.map((item, index) => (
            <Box
              key={index}
              sx={{
                border: "0.4px solid #B7BEC7",
                boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
                borderRadius: "8px",
                padding: "5px 16px",
                position: "relative"
              }}
            >
              <ItemDetailsCard
                row={item}
                index={index}
                isSameState={isSameState}
                EwayId={EwayId}
              />
            </Box>
          ))}
        </Box>
      )}
      <Grid container my={1}>
        <Grid item xs={12}>
          {!EwayId && (
            <IconButton onClick={onAddClick}>
              <AddCircleOutline sx={{ color: "#2773FF", fontSize: 28 }} />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={3}
        columnSpacing={1}
        sx={{ ml: { xs: 0, md: 0.5 } }}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontWeight={600}
            fontSize={14}
            lineHeight={"16.94px"}
            color={"#1B51B3"}
          >
            {"Totals"}
          </Typography>
          {!EwayId && (
            <Button
              variant="link"
              component={LoadingButton}
              loading={isCalculating}
              onClick={handleSubmit(onCalculateClick)}
            >
              {"Calculate Amount"}
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <CustomFieldRow
            textAlign={"left"}
            label={"Taxable Amount"}
            labelSize={4}
            childSize={8}
            showColon
            notMandotary
          >
            <Typography
              sx={{
                fontSize: { xs: 11, md: 14 },
                lineHeight: { xs: "12px", md: "16.94px" },
                color: "text.disabled"
              }}
            >
              {currencyOnlyFormatter(
                parseFloat(costBreakUp?.taxableAmount || 0).toFixed(2)
              )}
            </Typography>
          </CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            textAlign={"left"}
            label={isSameState ? "CGST Amount" : "IGST Amount"}
            labelSize={7}
            childSize={5}
            showColon
            notMandotary
          >
            <Typography
              sx={{
                fontSize: { xs: 11, md: 14 },
                lineHeight: { xs: "12px", md: "16.94px" },
                color: "text.disabled"
              }}
            >
              {isSameState
                ? currencyOnlyFormatter(
                    parseFloat(costBreakUp?.cgst || 0).toFixed(2)
                  )
                : currencyOnlyFormatter(
                    parseFloat(costBreakUp?.igst || 0).toFixed(2)
                  )}
            </Typography>
          </CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          {isSameState && (
            <CustomFieldRow
              textAlign={"left"}
              label={"SGST Amount"}
              labelSize={7}
              childSize={5}
              showColon
              notMandotary
            >
              <Typography
                sx={{
                  fontSize: { xs: 11, md: 14 },
                  lineHeight: { xs: "12px", md: "16.94px" },
                  color: "text.disabled"
                }}
              >
                {currencyOnlyFormatter(
                  parseFloat(costBreakUp?.sgst || 0).toFixed(2)
                )}
              </Typography>
            </CustomFieldRow>
          )}
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            textAlign={"left"}
            label={"Cess|Adv Amount"}
            labelSize={6.5}
            childSize={5.5}
            showColon
            wrap
            notMandotary
          >
            <Typography
              sx={{
                fontSize: { xs: 11, md: 14 },
                lineHeight: { xs: "12px", md: "16.94px" },
                color: "text.disabled"
              }}
            >
              {currencyOnlyFormatter(
                parseFloat(costBreakUp?.cessAdv || 0).toFixed(2)
              )}
            </Typography>
          </CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            textAlign={"left"}
            label={"Cess NonAdv|Amount"}
            labelSize={7}
            childSize={5}
            showColon
            wrap
            notMandotary
          >
            <Typography
              sx={{
                fontSize: { xs: 11, md: 14 },
                lineHeight: { xs: "12px", md: "16.94px" },
                color: "text.disabled"
              }}
            >
              {currencyOnlyFormatter(
                parseFloat(costBreakUp?.cessNonAdv || 0).toFixed(2)
              )}
            </Typography>
          </CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            textAlign={"left"}
            label={"Others"}
            labelSize={4}
            childSize={7}
            showColon
            notMandotary
          >
            <TextInput
              control={control}
              name={"others"}
              placeholder={"others"}
              sx={{ border: "1px solid #2773FF" }}
              disabled={Boolean(EwayId)}
            />
          </CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            textAlign={"left"}
            label={"Total Invoice|Amount"}
            labelSize={6}
            childSize={6}
            showColon
            wrap
          >
            <Typography
              sx={{
                fontSize: { xs: 11, md: 14 },
                lineHeight: { xs: "12px", md: "16.94px" },
                color: "text.disabled"
              }}
            >
              {currencyOnlyFormatter(
                parseFloat(costBreakUp?.totalInv || 0).toFixed(2)
              )}
            </Typography>
          </CustomFieldRow>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ItemDetailsForm;
