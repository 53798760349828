import { Box, Grid, IconButton, Typography } from "@mui/material";
import CustomTable from "../../../shared/customTable";
import DropDown from "../../../shared/formElements/DropDown";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import TextInput from "../../../shared/formElements/TextInput";
import TextArea from "../../../shared/formElements/TextArea";
import { purchase_labels } from "../../../constants";
import {
  TotalBreakup,
  reportLabel1,
  reportLabel2
} from "../../../purchase/pages/CreateOrder";
import { ToWords } from "to-words";
import {
  SkusById,
  calculateGstAmount,
  calculateTotalAmount,
  gstColumn
} from "../../../purchase/components/Purchase/PurchaseOrderTable";
import { currencyOnlyFormatter, dropdownOptions } from "../../../shared/utils";
import {
  calculateGstSubtotal,
  calculateSubTotal
} from "../Sales/SalesOrderTable";
import PrintableTable from "../../../purchase/components/PrintableTable";
import { AddCircleOutline } from "@mui/icons-material";
import { MobileSOTable } from "../Sales/MobileSOTable";

const getColumns = (gstType, orderStatus, isEditable) => {
  return [
    ...[
      ...(orderStatus === "" || (isEditable && orderStatus !== "Placed")
        ? [
            {
              Header: "",
              id: "selection",
              width: 50
            }
          ]
        : [])
    ],
    {
      Header: "S.No",
      accessor: "sNo",
      width: 90,
      size: 0.5
    },
    {
      Header: "Item/ SKU",
      accessor: "item",
      width: 200,
      size: 1.5,
      Cell: ({ row, value }) => {
        return row.original?.skuCode ? (
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {row.original?.image?.thumbUrl && (
                <img
                  src={row.original?.image?.thumbUrl}
                  alt={row.original?.brandName ?? "--"}
                  height={52}
                  width={50}
                  style={{ objectFit: "contain" }}
                />
              )}
              <Typography
                fontSize={12}
                fontWeight={600}
                color={"primary"}
                component={Link}
              >
                {row.original?.skuCode}
              </Typography>
            </Box>
            <Typography fontSize={13} color={"text.secondary"}>
              {`HSN : ${row.original?.hsn ?? "--"}`}
            </Typography>
          </Box>
        ) : (
          value
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      width: 150,
      size: 1.5,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          row.original?.productName && (
            <Box display={"flex"} flexDirection={"column"} gap={0.5}>
              <Typography fontSize={14}>{`Product: ${
                row.original?.productName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Category: ${
                row.original?.categoryName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                row.original?.brandName ?? "--"
              }`}</Typography>
            </Box>
          )
        );
      }
    },
    {
      Header: "UOM",
      accessor: "uom",
      disableSortBy: true,
      size: 0.5,
      width: 80,
      Cell: ({ row }) => {
        return (
          row.original?.unit && (
            <Typography>{row.original?.unit?.name}</Typography>
          )
        );
      }
    },
    {
      Header: "List Price",
      accessor: "rate",
      width: 96,
      size: 1,
      disableSortBy: true
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      width: 120,
      size: 1,
      disableSortBy: true
    },
    {
      Header: "Disc.",
      accessor: "disc",
      width: 120,
      size: 1,
      disableSortBy: true
    },
    {
      Header: "Off. Price",
      accessor: "offPrice",
      width: 96,
      size: 1,
      disableSortBy: true
    },
    {
      Header: "Amount",
      accessor: "amount",
      size: 1,
      disableSortBy: true
    },
    ...[
      ...(gstType
        ? [
            {
              Header: "CGST",
              accessor: "cgst",
              size: 1,
              disableSortBy: true,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.cgst ?? 0,
                  row.original?.gstRegime / 2 ?? 0
                );
              }
            },
            {
              Header: "SGST",
              accessor: "sgst",
              disableSortBy: true,
              size: 1,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.sgst,
                  row.original?.gstRegime / 2 ?? 0
                );
              }
            }
          ]
        : [
            {
              Header: "IGST",
              accessor: "igst",
              size: 1,
              disableSortBy: true,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.igst ?? 0,
                  row.original?.gstRegime ?? 0
                );
              }
            }
          ])
    ],
    {
      Header: "Total",
      accessor: "total",
      size: 1,
      disableSortBy: true
    }
  ];
};

const getRows = (
  rows,
  control,
  options,
  watch,
  isEditable,
  orderStatus,
  applyGst,
  gstType,
  remove
) => {
  return rows.map((row, index) => ({
    index: index,
    sNo: index + 1,
    ...row,
    rate:
      (orderStatus === "Draft" && !isEditable) ||
      (orderStatus !== "" && orderStatus !== "Draft") ? (
        `${parseFloat(row.rate).toFixed(2)}`
      ) : (
        <TextInput
          control={control}
          name={`newOrders.${index}.rate`}
          type="number"
          placeholder={"rate"}
        />
      ),
    item: !row.item && (
      <DropDown
        control={control}
        name={`newOrders.${index}.item`}
        options={dropdownOptions(options, "_id", "skuCode")}
        placeholder={"Select SKU"}
        allowSearch
      />
    ),
    quantity:
      !isEditable && Boolean(orderStatus) ? (
        row.quantity
      ) : (
        <TextInput
          control={control}
          name={`newOrders.${index}.quantity`}
          type="number"
          placeholder={"Qty"}
        />
      ),
    disc:
      !isEditable && Boolean(orderStatus) ? (
        `${parseFloat(row.disc).toFixed(2)} %`
      ) : (
        <>
          <TextInput
            control={control}
            name={`newOrders.${index}.disc`}
            type="number"
            placeholder={"Discount"}
          />{" "}
          %
        </>
      ),
    offPrice:
      watch(`newOrders.${index}.disc`) !== "" &&
      watch(`newOrders.${index}.disc`) !== undefined &&
      watch(`newOrders.${index}.quantity`)
        ? currencyOnlyFormatter(
            parseFloat(
              +watch(`newOrders.${index}.rate`) -
                (+watch(`newOrders.${index}.disc`) / 100) *
                  +watch(`newOrders.${index}.rate`)
            ).toFixed(2)
          )
        : 0,
    amount:
      watch(`newOrders.${index}.disc`) !== "" &&
      watch(`newOrders.${index}.disc`) !== undefined &&
      watch(`newOrders.${index}.quantity`)
        ? currencyOnlyFormatter(
            parseFloat(
              (+watch(`newOrders.${index}.rate`) -
                (+watch(`newOrders.${index}.disc`) / 100) *
                  +watch(`newOrders.${index}.rate`)) *
                +watch(`newOrders.${index}.quantity`)
            ).toFixed(2)
          )
        : 0,
    cgst:
      watch(`newOrders.${index}.disc`) !== "" &&
      watch(`newOrders.${index}.disc`) !== undefined &&
      watch(`newOrders.${index}.quantity`) &&
      row.gstRegime &&
      applyGst
        ? calculateGstAmount(
            +watch(`newOrders.${index}.rate`) -
              (+watch(`newOrders.${index}.disc`) / 100) *
                +watch(`newOrders.${index}.rate`),
            +watch(`newOrders.${index}.quantity`),
            row.gstRegime / 200
          )
        : 0,
    sgst:
      watch(`newOrders.${index}.disc`) !== "" &&
      watch(`newOrders.${index}.disc`) !== undefined &&
      watch(`newOrders.${index}.quantity`) &&
      row.gstRegime &&
      applyGst
        ? calculateGstAmount(
            +watch(`newOrders.${index}.rate`) -
              (+watch(`newOrders.${index}.disc`) / 100) *
                +watch(`newOrders.${index}.rate`),
            +watch(`newOrders.${index}.quantity`),
            row.gstRegime / 200
          )
        : 0,
    igst:
      watch(`newOrders.${index}.disc`) !== "" &&
      watch(`newOrders.${index}.disc`) !== undefined &&
      watch(`newOrders.${index}.quantity`) &&
      row.gstRegime &&
      applyGst
        ? calculateGstAmount(
            +watch(`newOrders.${index}.rate`) -
              (+watch(`newOrders.${index}.disc`) / 100) *
                +watch(`newOrders.${index}.rate`),
            +watch(`newOrders.${index}.quantity`),
            row.gstRegime / 100
          )
        : 0,
    total:
      watch(`newOrders.${index}.disc`) !== "" &&
      watch(`newOrders.${index}.disc`) !== undefined &&
      watch(`newOrders.${index}.quantity`)
        ? currencyOnlyFormatter(
            applyGst
              ? calculateTotalAmount(
                  +watch(`newOrders.${index}.rate`) -
                    (+watch(`newOrders.${index}.disc`) / 100) *
                      +watch(`newOrders.${index}.rate`),
                  +watch(`newOrders.${index}.quantity`),
                  row.gstRegime / 100 ?? 1
                )
              : +watch(`newOrders.${index}.rate`) -
                  (+watch(`newOrders.${index}.disc`) / 100) *
                    +watch(`newOrders.${index}.rate`),
            +watch(`newOrders.${index}.quantity`)
          )
        : 0,
    isEditable: isEditable,
    orderStatus: orderStatus,
    gstType: gstType,
    remove: remove
  }));
};

const SalesQuotationTable = ({
  isPrint = false,
  costBreakUp,
  setCostBreakUp,
  fields,
  control,
  append,
  remove,
  onAdd,
  watch,
  setValue,
  onRowSelectionChange,
  gstType = false,
  options,
  paymentTerms,
  shipping,
  preparedBy,
  isEditable,
  orderStatus,
  applyGst
}) => {
  const toWords = new ToWords();

  const skuWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.item`
  );

  const ordersWatch = watch("newOrders");

  useEffect(() => {
    if (skuWatch && fields.length > 0) {
      const skuData = SkusById(options?.skus, skuWatch);
      remove(fields.length - 1);
      append({ ...skuData[0] });
      setValue(`newOrders.${fields.length - 1}.rate`, skuData[0]?.rate);
    }
  }, [skuWatch, fields.length, remove, append, options?.skus]);

  useEffect(() => {
    if (ordersWatch && fields.length > 0) {
      setCostBreakUp({
        subtotal: calculateSubTotal(fields, watch),
        cgst: applyGst ? calculateGstSubtotal(fields, watch) / 2 : 0,
        sgst: applyGst ? calculateGstSubtotal(fields, watch) / 2 : 0,
        igst: applyGst ? calculateGstSubtotal(fields, watch) : 0,
        total: applyGst
          ? +calculateSubTotal(fields, watch) +
            +calculateGstSubtotal(fields, watch)
          : +calculateSubTotal(fields, watch)
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(ordersWatch), applyGst]);

  return (
    <>
      {isPrint ? (
        <Grid container>
          <PrintableTable
            columns={getColumns(gstType, orderStatus, isEditable).filter(
              (item) => item
            )}
            rows={getRows(
              fields,
              control,
              options?.skus,
              watch,
              isEditable,
              orderStatus,
              applyGst
            )}
            docType="SalesOrder"
            gstType={gstType}
          />
        </Grid>
      ) : (
        <>
          {(orderStatus === "" || (orderStatus === "Draft" && isEditable)) && (
            <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton onClick={onAdd}>
                <AddCircleOutline color="primary" />
              </IconButton>
            </Grid>
          )}
          <CustomTable
            columns={getColumns(gstType, orderStatus, isEditable).filter(
              (item) => item
            )}
            data={getRows(
              fields,
              control,
              options?.skus,
              watch,
              isEditable,
              orderStatus,
              applyGst,
              gstType,
              remove
            )}
            onRowSelectionChange={onRowSelectionChange}
            mobileComponent={MobileSOTable}
          />
        </>
      )}
      <Grid
        container
        spacing={2}
        sx={{ display: { xs: isPrint ? "flex" : "none", md: "flex" } }}
      >
        <Grid item xs={9}>
          <Box display={"flex"} flexDirection={"column"} gap={3}>
            <Box display={"flex"} gap={5}>
              {reportLabel1(
                purchase_labels.paymentTermsText,
                paymentTerms[0] !== "" &&
                  paymentTerms[0] !== undefined &&
                  paymentTerms[1]
                  ? `${paymentTerms[0]}% Advance. Balance Due ${paymentTerms[1]} days from receipt`
                  : "--"
              )}
              {reportLabel1(purchase_labels.shipping, shipping ?? "--")}
            </Box>
            <Box width={"500px"}>
              <TextArea
                control={control}
                name={"otherItemsAndConditionsLabel"}
                label={purchase_labels.otherItemsAndConditionsLabel}
                disabled={
                  Boolean(orderStatus)
                    ? orderStatus === "Draft"
                      ? !isEditable
                      : true
                    : false
                }
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2.5}>
              {reportLabel2(
                purchase_labels.amountInWordsLabel,
                `${toWords.convert(
                  applyGst
                    ? +calculateSubTotal(fields, watch) +
                        +calculateGstSubtotal(fields, watch) ?? 0
                    : +calculateSubTotal(fields, watch) ?? 0,
                  { currency: true }
                )}`
              )}
              {reportLabel2(
                purchase_labels.preparedByLabel,
                preparedBy ?? "--"
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <TotalBreakup
            gstType={gstType}
            subTotal={costBreakUp?.subtotal}
            cgst={costBreakUp?.cgst}
            sgst={costBreakUp?.sgst}
            igst={costBreakUp?.igst}
            total={costBreakUp?.total}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SalesQuotationTable;
