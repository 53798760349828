import * as yup from "yup";
import { Button, FormHelperText, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { error_msg, ewayBillLogin_label, labels } from "../../../constants";
import TextInput from "../../../shared/formElements/TextInput";
import { EwayBillLoginKeys } from "../../../constants/formKeys";
import { useForm } from "react-hook-form";
import CheckBoxGroup from "../../../shared/formElements/CheckBoxGroup";
import { useAuthenticateUser } from "../../../query-hooks/ClientEwayBill/useAuthenticateUser";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";

const schema = yup.object({
  [EwayBillLoginKeys.username]: yup.string().required(error_msg.required),
  [EwayBillLoginKeys.password]: yup.string().required(error_msg.required)
});

const EwayBillLogin = ({ hideDialog, autoSubmit = () => {} }) => {
  const { control, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema)
  });
  const [showError, setShowError] = useState("");
  const togglePassword = watch("showPassword");

  const { mutate, isLoading, error, isError } = useAuthenticateUser();

  const onSubmit = (data) => {
    mutate(
      {
        data: {
          username: data[EwayBillLoginKeys.username],
          password: data[EwayBillLoginKeys.password]
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Authentication successful", { variant: "success" });
          hideDialog();
          autoSubmit();
        }
      }
    );
  };

  useEffect(() => {
    if (isError) setShowError(error?.response?.data?.message);
  }, [error?.response?.data?.message, isError]);

  return (
    <Grid
      container
      rowSpacing={2}
      sx={{ p: { xs: 0, md: 2 } }}
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12} display={"flex"} mb={1}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "24.2px",
            letterSpacing: "0.002em",
            textAlign: "left"
          }}
        >
          {labels.ewaybillLogin}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          control={control}
          name={EwayBillLoginKeys.username}
          placeholder={ewayBillLogin_label.username}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          control={control}
          name={EwayBillLoginKeys.password}
          placeholder={ewayBillLogin_label.password}
          type={!togglePassword?.includes("showPassword") ? "password" : "text"}
        />
      </Grid>
      {showError && (
        <Grid item xs={12}>
          <FormHelperText error>{showError}</FormHelperText>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{
          "& .MuiTypography-root": {
            fontSize: 13
          }
        }}
      >
        <CheckBoxGroup
          control={control}
          name={"showPassword"}
          options={[{ name: "showPassword", displayName: "Show password" }]}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        <Button
          component={LoadingButton}
          loading={isLoading}
          variant="contained"
          fullWidth
          size="large"
          sx={{
            height: {
              xs: 48,
              md: 64
            }
          }}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          {labels.login}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EwayBillLogin;
