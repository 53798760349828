import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { currencyOnlyFormatter } from "../../../shared/utils";

const MobileGatepassTable = (row) => {
  return (
    <Grid container rowSpacing={2} padding={"10px 10px 5px 10px"}>
      <Grid item xs={8}>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          {row?.skuItem?.img && (
            <img
              src={row?.skuItem?.img}
              alt={row?.product?.brand}
              style={{
                maxWidth: "50px",
                maxHeight: "50px"
              }}
            />
          )}
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              textDecoration: "underline",
              color: "#2773FF",
              whiteSpace: "pre-wrap"
            }}
          >
            {row?.skuItem?.name}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        <Typography fontSize={14} fontWeight={400} lineHeight={"16.94px"}>
          {`${row.prevDispatched}/`}
          <span style={{ color: "#2773FF" }}>
            <b>{row.qtyOrder}</b>
          </span>
        </Typography>
      </Grid>
      <Grid item xs={4} display={"flex"} alignItems={"center"}>
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight={"14.52px"}
          color={"text.secondary"}
        >
          {row.uom}
        </Typography>
      </Grid>
      <Grid item xs={4} display={"flex"} alignItems={"center"}>
        <Typography
          sx={{ fontSize: 14, lineHeight: "16.94px", fontWeight: 400 }}
        >
          {currencyOnlyFormatter(parseFloat(row.price).toFixed(2))}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        {row.qtyDispatched}
      </Grid>
    </Grid>
  );
};

export default MobileGatepassTable;
