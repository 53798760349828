import { queryKeys } from "../../constants/queryKeys";
import { getEwayBillById } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchEwayBillById = (ewayBillId) => {
  const ewayBillById = useQuery(
    [queryKeys.queryFetchEwayBillById, ewayBillId],
    () => getEwayBillById(ewayBillId),
    {
      enabled: Boolean(ewayBillId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return ewayBillById;
};
