import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { ValueLabelText } from "../../../purchase/components/Purchase/MobilePOTable";
import { BorderColorOutlined, RemoveCircleOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { currencyOnlyFormatter } from "../../../shared/utils";

export const ItemDetailsCard = ({ row, index, isSameState, EwayId }) => {
  const { remove, toggleEdit } = row;
  const onRemoveClick = () => {
    remove(index);
  };

  return !row?.isEditing ? (
    <Grid container spacing={1}>
      <Grid
        item
        xs={6}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
      >
        <Box display={"flex"} gap={1} alignItems={"center"}>
          {row?.image?.thumbUrl && (
            <img
              src={row?.image?.thumbUrl}
              alt={row?.brandName || "--"}
              style={{
                objectFit: "contain",
                maxWidth: "50px",
                maxHeight: "50px"
              }}
            />
          )}
          <Typography
            fontSize={12}
            fontWeight={600}
            color={"primary"}
            sx={{ textDecoration: "underline" }}
          >
            {row?.skuCode}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        {!EwayId && (
          <>
            <IconButton onClick={onRemoveClick}>
              <RemoveCircleOutline color="error" />
            </IconButton>
            <IconButton onClick={toggleEdit}>
              <BorderColorOutlined sx={{ color: "#F9A90E" }} />
            </IconButton>
          </>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        <ValueLabelText label={"Qty"} value={row.quantity} />
      </Grid>
      <Grid
        item
        xs={6}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        <ValueLabelText label={"Name"} value={row?.brandName || ""} onLeft />
      </Grid>
      <Grid
        item
        xs={2}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight={"14.52px"}
          color={"text.disabled"}
          pl={1}
        >
          {row?.unit?.name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"flex-start"}
      >
        <ValueLabelText label={"Rate"} value={row.rate} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        <ValueLabelText
          label={"HSN"}
          value={row?.hsn || "--"}
          onLeft
          isMandotary
        />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {isSameState && (
          <ValueLabelText
            label={`CGST(${row.cgst})%`}
            value={currencyOnlyFormatter(
              parseFloat((row.taxable * row.cgst) / 100).toFixed(2)
            )}
          />
        )}
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        {isSameState ? (
          <ValueLabelText
            label={`SGST(${row.sgst})%`}
            value={currencyOnlyFormatter(
              parseFloat((row.taxable * row.sgst) / 100).toFixed(2)
            )}
          />
        ) : (
          <ValueLabelText
            label={`IGST(${row.igst})%`}
            value={currencyOnlyFormatter(
              parseFloat((row.taxable * row.igst) / 100).toFixed(2)
            )}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        <ValueLabelText
          label={"Taxable Value"}
          value={currencyOnlyFormatter(parseFloat(row.taxable).toFixed(2))}
          onLeft
          isMandotary
        />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ValueLabelText
          label={"Cess Advol"}
          value={currencyOnlyFormatter(
            parseFloat((row.taxable * row.advol) / 100).toFixed(2)
          )}
        />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        <ValueLabelText
          label={"Cess Nonadvol"}
          value={currencyOnlyFormatter(row.Nonadval)}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2} py={1}>
      <Grid
        item
        xs={6}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
      >
        {row?._id ? (
          <Box display={"flex"} gap={1} alignItems={"center"}>
            {row?.image?.thumbUrl && (
              <img
                src={row?.image?.thumbUrl}
                alt={row?.brandName || "--"}
                style={{
                  objectFit: "contain",
                  maxWidth: "50px",
                  maxHeight: "50px"
                }}
              />
            )}
            <Typography
              fontSize={12}
              fontWeight={600}
              color={"primary"}
              component={Link}
            >
              {row?.skuCode}
            </Typography>
          </Box>
        ) : (
          row?.item
        )}
      </Grid>
      <Grid
        item
        xs={6}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"right"}
      >
        <IconButton onClick={onRemoveClick}>
          <RemoveCircleOutline color="error" />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
      >
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight={"14.52px"}
          color={"text.disabled"}
        >
          {row?.unit?.name || "Unit"}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"right"}
      >
        {row?.rate}
      </Grid>
      <Grid item xs={4}>
        {row?.quantity}
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
      >
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight={"14.52px"}
          color={"text.disabled"}
        >
          {`HSN `}
          <sup style={{ color: "#F05454" }}>*</sup>
          {`: ${row?.hsn || "--"}`}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {row.advol}
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"right"}
      >
        {row.Nonadval}
      </Grid>
    </Grid>
  );
};
