import { getTransporter } from "../../../inventory/services/index";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchTransporter = (showTransporter, transporterId) => {
  const queryTransporter = useQuery(
    [queryKeys.queryFetchTransporter, showTransporter, transporterId],
    () => getTransporter(transporterId),
    { enabled: showTransporter , refetchOnWindowFocus: false }
  );

  return queryTransporter;
};
