import { queryKeys } from "../../../constants/queryKeys";
import { getAllEwayBill } from "../../../inventory/services";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchAllEwayBills = (params) => {
  const bills = useInfiniteQuery(
    [queryKeys.queryFetchEwayBills, Boolean(params)],
    ({ pageParam = 1 }) => getAllEwayBill(params, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.eWayBills?.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return bills;
};
