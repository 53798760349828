import { getEwayBillFormDataByInvId } from "../../../inventory/services/index";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchCreateFormDataByInvId = (dcInvId) => {
  const queryEwayBillFormData = useQuery(
    [queryKeys.queryFetchEwayBillFormDataById, dcInvId],
    () => getEwayBillFormDataByInvId(dcInvId),
    {
      enabled: !!dcInvId,
      refetchOnWindowFocus: false
    }
  );

  return queryEwayBillFormData;
};
