import { getExtendValFormData } from "../../../inventory/services/index";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchExtendValFormData = (ewbId) => {
  const queryExtendValFormData = useQuery(
    [queryKeys.queryFetchExtendValFormData, ewbId],
    () => getExtendValFormData(ewbId),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryExtendValFormData;
};
