import { Box, Button, Grid, Typography } from "@mui/material";

import { CheckCircle } from "@mui/icons-material";
import moment from "moment";

const getLabelRow = (label, value) => {
  return (
    <Grid item xs={8} container display={"flex"} alignItems={"left"}>
      <Grid
        item
        xs={5}
        fontSize={14}
        fontWeight={600}
        color={"#607088"}
        display={"flex"}
        justifyContent={"left"}
      >
        {label}
      </Grid>
      <Grid item xs={1} fontSize={13} fontWeight={400} color={"#000000"}>
        :
      </Grid>
      <Grid item xs={6} fontSize={13}>
        {value}
      </Grid>
    </Grid>
  );
};

const ConfirmEwayBillNo = ({
  data,
  btn1Text,
  btn2Text,
  btn1Callback,
  btn2Callback,
  btn1Variant = "",
  btn2Variant = ""
}) => {
  
  const ewayData = [
    { label: "Doc/ Inv No.", value: data?.docNo },
    { label: "Doc/ Inv date", value: moment(data?.docDate).format("DD-MM-YYYY") },
    { label: "Generated By", value: data?.genGstNo },
    { label: "Validity", value:  moment(data?.ewbValidity).format("DD-MM-YYYY")  },
    { label: "Status", value: data?.status?.displayName }
  ];

  return (
    <Box
      padding={1}
      display={"flex"}
      flexDirection={"column"}
      gap={"8px"}
      textAlign={"center"}
      alignItems={"center"}
    >
      <CheckCircle
        color="success"
        sx={{
          display: { xs: "none", md: "flex" },
          height: 60,
          width: 60
        }}
      />
      <Typography
        sx={{
          fontSize: { xs: 16, md: 20 },
          lineHeight: { xs: "19.36px", md: "24px" },
          fontWeight: 500,
          letterSpacing: "0.002em"
        }}
      >
        {
          "Please confirm if you are going to use this ewaybill. Once confirmed, you will only be using the invoice document as below."
        }
      </Typography>
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"center"}
        mt={0.5}
      >
        {ewayData?.map(({ label, value }) => getLabelRow(label, value))}
      </Grid>
      <Box display={{ xs: "block", lg: "flex" }} gap={"10px"} width={"100%"}>
        {btn1Text && (
          <Button
            fullWidth
            variant={btn1Variant ? btn1Variant : "soft"}
            sx={{
              height: { xs: 48, lg: 64 },
              fontWeight: 500,
              fontSize: 15,
              marginTop: "20px",
              whiteSpace: "nowrap"
            }}
            onClick={btn1Callback}
          >
            {btn1Text}
          </Button>
        )}
        {btn2Text && (
          <Button
            fullWidth
            variant={btn2Variant ? btn2Variant : "contained"}
            sx={{
              height: { xs: 48, lg: 64 },
              fontWeight: 500,
              fontSize: 15,
              marginTop: "20px",
              whiteSpace: "nowrap"
            }}
            onClick={btn2Callback}
          >
            {btn2Text}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ConfirmEwayBillNo;
