import * as yup from "yup";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DateInput from "../../shared/formElements/DateInput";
import TextInput from "../../shared/formElements/TextInput";
import { useParams } from "react-router-dom";
import { useFetchMRRs } from "../../query-hooks/ClientOrderMRR/useFetchMRRs";
import MrrTable from "../components/MaterialReceivedReport/MrrTable";
import { error_msg, statusKeys } from "../../constants";
import { useFetchMRRFormData } from "../../query-hooks/ClientOrderMRR/useFetchMRRFormData";
import { useCustomDialog } from "../../shared/customDialog";
import MiniDialogBoxForms from "../../shared/UiElements/MiniDialogBoxForms";
import { useFetchMrrInCreateFormData } from "../../query-hooks/ClientOrderInvoice/useFetchMrrInCreateFormData";
import DropDown from "../../shared/formElements/DropDown";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { dropdownOptions } from "../../shared/utils";
import { useCreateMRRInvoice } from "../../query-hooks/ClientOrderInvoice/useCreateMRRInvoice";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { AppContext } from "../../shared/context/auth-context";
import { LoadingButton } from "@mui/lab";
import { CustomTabs } from "../../shared/customTab/CustomTabs";
import MrrTabs from "../components/MaterialReceivedReport/MrrTabs";
import { isMobileDevice } from "../../App";

export const LabelValueText = ({ label, value }) => {
  return (
    <Box display={"flex"} gap={"4px"}>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: { xs: 400, md: 600 },
          lineHeight: "17px"
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "18px",
          color: "text.secondary"
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export const AddressSection = ({
  title,
  name,
  addressLine1,
  addressLine2,
  addressLine3
}) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"12px"} maxWidth={174}>
      <Typography
        color={"primary.dark"}
        fontSize={16}
        fontWeight={600}
        lineHeight={"19.36px"}
        letterSpacing={"0.5%"}
        sx={{ display: { xs: "none", lg: "block" } }}
      >
        {title}
      </Typography>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
          {name}
        </Typography>
        {addressLine1 && (
          <Typography
            fontSize={13}
            fontWeight={400}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {addressLine1}
          </Typography>
        )}
        {addressLine2 && (
          <Typography
            fontSize={13}
            fontWeight={400}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {addressLine2}
          </Typography>
        )}
        {addressLine3 && (
          <Typography
            fontSize={13}
            fontWeight={400}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {addressLine3}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const LabelTextInput = ({
  control,
  name,
  label,
  placeholder = "",
  isDateInput = false
}) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={1}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "17px",
          whiteSpace: "nowrap"
        }}
      >
        {label}
      </Typography>
      {isDateInput ? (
        <DateInput
          control={control}
          name={name}
          placeholder={placeholder}
          minDate={new Date("1900-01-01")}
        />
      ) : (
        <TextInput control={control} name={name} />
      )}
    </Box>
  );
};

const EditInvoiceForm = (control, options) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      gap={1}
    >
      <Box width={"183px"}>
        <DropDown
          control={control}
          name={"mrrId"}
          label={"Select MRR"}
          options={options}
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <DateInput
          control={control}
          name={"date"}
          minDate={new Date("1900-01-01")}
          endIcon={CalendarTodayOutlined}
          placeholder={"Pick Date"}
          popperPlacement={"right-end"}
        />
        <TextInput
          control={control}
          name={"invoiceNo"}
          placeholder={"Inv No."}
        />
        <TextInput
          control={control}
          name={"invoiceAmount"}
          placeholder={"Inv Amt"}
        />
      </Box>
    </Box>
  );
};

const editInvoiceSchema = yup.object({
  mrrId: yup.string().required(error_msg.required),
  date: yup.string().required(error_msg.required),
  invoiceNo: yup.string().required(error_msg.required),
  invoiceAmount: yup.string().required(error_msg.required)
});

const MaterialReceivedReports = () => {
  const { profileData } = useContext(AppContext);
  const { orderId, Document, Page } = useParams();
  const { showDialog, hideDialog } = useCustomDialog();
  const { role, allowedScModules } = profileData?.user;
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const MrrCreateAllowed =
    !Boolean(Document) &&
    (Boolean(role === "CustomerAdmin") ||
      allowedScModules?.some(({ name }) => name === "Warehouse"));

  const InvEditAllowed =
    Boolean(Document) &&
    (Boolean(role === "CustomerAdmin") ||
      allowedScModules?.some(({ name }) => name === "Purchase"));

  const CNCreateAllowed =
    Boolean(Document) &&
    Boolean(Page === "Invoice") &&
    (Boolean(role === "CustomerAdmin") ||
      allowedScModules?.some(({ name }) => name === "Finance"));

  const {
    data: MrrsData,
    isFetching: isFetchingMrrs,
    refetch: refetchMrrs
  } = useFetchMRRs(orderId);

  const onOrderTypeConditionCheck =
    MrrsData?.order?.orderType?.name === "StockTransfer"
      ? MrrsData?.order?.itemDetails?.outQuantity >
        MrrsData?.order?.itemDetails?.inQuantity
      : MrrsData?.order?.itemDetails?.quantity >
        MrrsData?.order?.itemDetails?.inQuantity;

  const AllowCreate =
    MrrCreateAllowed && !isFetchingMrrs && onOrderTypeConditionCheck;

  const AllowPurchaseRole =
    InvEditAllowed &&
    Boolean(Page === "Purchase") &&
    !isFetchingMrrs &&
    MrrsData?.order?.orderType?.name === "PurchaseOrder";

  const {
    data: FormData,
    isFetching: isFetchingFormData,
    refetch: refetchFormData
  } = useFetchMRRFormData(orderId, AllowCreate);

  const {
    data: MrrInvoiceFormData,
    isFetching: isFetchingMrrInvoiceFormData,
    refetch: refetchMrrInvFormData
  } = useFetchMrrInCreateFormData(orderId, AllowPurchaseRole);

  const {
    mutate: createMrrInvoice,
    isError: isCreateInvoiceError,
    error: createInvoiceError
  } = useCreateMRRInvoice();

  const onCreateInvoice = (data) => {
    const formData = {
      customCode: data["invoiceNo"],
      docDate: moment(data["date"]).format("YYYY-MM-DD"),
      totalAmount: +data["invoiceAmount"]
    };
    createMrrInvoice(
      {
        data: formData,
        mrrId: data["mrrId"]
      },
      {
        onSuccess: (res) => {
          hideDialog();
          enqueueSnackbar("MRR Invoice Updated!", {
            variant: "success"
          });
          refetchMrrInvFormData();
        }
      }
    );
  };

  const onEditInvoice = () => {
    showDialog({
      component: (
        <MiniDialogBoxForms
          text={""}
          btnText={"Submit"}
          btnCallback={onCreateInvoice}
          childComponent={EditInvoiceForm}
          schema={editInvoiceSchema}
          options={
            dropdownOptions(MrrInvoiceFormData?.mrrs, "_id", "code") ?? []
          }
        />
      )
    });
  };

  const viewOnlyMrrs =
    !AllowCreate && MrrsData?.mrrs?.length
      ? MrrsData?.mrrs?.filter((item) => item.status.name !== statusKeys.Draft)
      : MrrsData?.mrrs;

  const NewMrr = (
    <MrrTable
      orderId={orderId}
      rejectOptions={FormData?.rejectReasons ?? []}
      MRRStatus={""}
      tableData={FormData?.order?.items}
      orderInfo={FormData?.order}
      orderStatus={MrrsData?.order?.status?.name}
      MRRInfo={""}
      isTaxApplicable={FormData?.taxRule?.isTaxApplicable}
      refetchMrrs={refetchMrrs}
      refetchFormData={refetchFormData}
    />
  );

  useEffect(() => {
    if (isCreateInvoiceError) {
      enqueueSnackbar(createInvoiceError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [createInvoiceError?.response?.data?.message, isCreateInvoiceError]);

  const tabs = [].concat(
    viewOnlyMrrs?.map((item, index) => ({
      label: `${item?.code ?? "Draft"}`,
      Component: (
        <MrrTabs
          orderId={orderId}
          MrrId={item?._id}
          expanded={activeTab === index}
          isSaleReturnType={MrrsData?.order?.orderType?.name === "SaleReturn"}
          MrrData={item}
          FormData={FormData}
          rejectOptions={FormData?.rejectReasons ?? []}
          orderInfo={MrrsData?.order}
          orderStatus={MrrsData?.order?.status?.name}
          isTaxApplicable={MrrsData?.taxRule?.isTaxApplicable}
          refetchMrrs={refetchMrrs}
          refetchFormData={refetchFormData}
          CNCreateAllowed={CNCreateAllowed}
        />
      )
    })),
    ...(AllowCreate &&
    MrrsData?.mrrs?.length &&
    !Boolean(MrrsData?.mrrs?.some((obj) => obj.status.name === "Draft")) &&
    !isFetchingFormData
      ? [
          {
            label: `New MRR`,
            Component: NewMrr
          }
        ]
      : [])
  );

  return isFetchingMrrs || isFetchingFormData ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetchingMrrs || isFetchingFormData}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box
      display={"flex"}
      flexDirection={"column"}
      paddingBottom={"20px"}
      sx={{ gap: { xs: 2, md: 2 } }}
    >
      {InvEditAllowed &&
        MrrsData?.order?.orderType?.name === "PurchaseOrder" && (
          <>
            <Grid
              item
              xs={12}
              textAlign={"end"}
              sx={{
                p: { xs: "0 16px 0 0", md: 0 },
                mt: -4.5,
                ml: 5
              }}
              zIndex={1}
            >
              <Button
                variant="link"
                disableRipple
                component={LoadingButton}
                loading={isFetchingMrrInvoiceFormData}
                onClick={onEditInvoice}
                disabled={
                  isFetchingMrrInvoiceFormData
                    ? true
                    : !Boolean(MrrInvoiceFormData?.mrrs?.length)
                }
              >
                {"Edit Invoice Details"}
              </Button>
            </Grid>
            <Divider sx={{ display: { xs: "none", md: "block" } }} />
          </>
        )}
      <Box>
        {AllowCreate &&
        MrrsData?.mrrs?.length === 0 &&
        !Boolean(MrrsData?.mrrs?.some((obj) => obj.status.name === "Draft")) &&
        !isFetchingFormData ? (
          NewMrr
        ) : (
          <CustomTabs
            tabs={tabs}
            activeTab={activeTab}
            onChange={handleTabChange}
            backgroundColor={"#F0F2F5"}
            fixTab={isMobileDevice}
          />
        )}
      </Box>
    </Box>
  );
};

export default MaterialReceivedReports;
