import { getUpdatePartBFormData } from "../../../inventory/services/index";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchUpdatePartBFormData = (ewbId) => {
  const queryUpdatePartBFormData = useQuery(
    [queryKeys.queryFetchUpdatePartBFormData, ewbId],
    () => getUpdatePartBFormData(ewbId),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryUpdatePartBFormData;
};
