import { Box, Typography } from "@mui/material";

import { CustomTabs } from "../../shared/customTab/CustomTabs";
import { NAV_LINK } from "../../shared/layouts/SideDrawer";
import appRoutes from "../../constants/appRoutes";
import { useContext } from "react";
import Register from "./Register";
import AssetsMobile from "./AssetsMobile";
import { AppContext } from "../../shared/context/auth-context";
import EWaybills from "./EWaybills";

const Warehouse = () => {
  const { activeMobileTab, handleMobileTabChange } = useContext(AppContext);

  const componentPath = {
    [appRoutes.warehouse.assets]: <AssetsMobile />,
    [appRoutes.warehouse.register]: <Register />,
    [appRoutes.warehouse.ewaybills]: <EWaybills />,
  };
  const MenuItems = NAV_LINK.find(
    (item) => item.name === "Warehouse"
  )?.children?.map((menu) => {
    return {
      label: menu.name,
      Component: componentPath[menu.to]
    };
  });
  return (
    <Box
      sx={{
        mb: { xs: 9 }
      }}
    >
      <Typography
        sx={{
          padding: "20px 21px 18px 21px",
          background: "#EEF1F4",
          fontSize: "16px",
          lineHeight: "19.36px",
          fontWeight: 700
        }}
      >
        {"Warehouse"}
      </Typography>
      <CustomTabs
        tabs={MenuItems}
        activeTab={activeMobileTab.Warehouse}
        onChange={handleMobileTabChange}
        secondaryTab={true}
      />
    </Box>
  );
};

export default Warehouse;
