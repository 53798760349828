import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import DropDown from "../../../shared/formElements/DropDown";
import { useForm } from "react-hook-form";
import { CancelEwayBillKeys } from "../../../constants/formKeys";
import TextInput from "../../../shared/formElements/TextInput";
import { CustomFieldRow } from "./EwayBillForm";
import { useFetchCancelEwbFormData } from "../../../query-hooks/ClientEwayBill/CancelEwayBill/useFetchCancelEwbFormData";
import { useCancelEwb } from "../../../query-hooks/ClientEwayBill/CancelEwayBill/useCancelEwb";
import { enqueueSnackbar } from "notistack";

const CancelEwayBill = ({
  setUpdateType,
  ewayId,
  refetchEwayBillById,
  reset,
  EwayBillLoginShow,
  refetch
}) => {
  const { control, handleSubmit } = useForm();
  const onBackClick = () => {
    setUpdateType("0");
  };

  const { data: formData, isFetching: isFetchingFormData } =
    useFetchCancelEwbFormData(ewayId);

  const { mutate, isLoading, error, isError } = useCancelEwb();

  const onCancelClick = (data) => {
    mutate(
      {
        data: {
          cancelReason: data[CancelEwayBillKeys.reason],
          cancelRemark: data[CancelEwayBillKeys.remarks]
        },
        ewbId: ewayId
      },
      {
        onSuccess: () => {
          enqueueSnackbar("E-WayBill Cancelled", { variant: "success" });
          reset();
          refetch();
          refetchEwayBillById();
          setUpdateType("0");
        }
      }
    );
  };

  useEffect(() => {
    if (isError) {
      if (error?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(handleSubmit(onCancelClick));
      } else {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: "error"
        });
      }
    } // eslint-disable-next-line
  }, [
    error?.response?.data?.message,
    isError,
    EwayBillLoginShow,
    error?.response?.data?.ewbErrorCode
  ]);

  return (
    <>
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        sx={{
          padding: { xs: "20px 20px 120px 20px", md: "25px 25px 100px 25px" }
        }}
        gap={2}
      >
        <Grid item xs={9} mb={2}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "24.2px",
              letterSpacing: "0.002em",
              textAlign: "center"
            }}
          >
            {"Are you sure you want to cancel the E-Waybill ?"}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <CustomFieldRow
            label={"Select Reason : "}
            labelSize={5}
            childSize={7}
            notMandotary
          >
            <DropDown
              control={control}
              name={CancelEwayBillKeys.reason}
              options={formData?.cancelReasons}
              isLoading={isFetchingFormData}
              placeholder={"Select Reason"}
            />
          </CustomFieldRow>
        </Grid>
        <Grid item xs={9}>
          <CustomFieldRow
            label={"Remarks : "}
            labelSize={5}
            childSize={7}
            notMandotary
          >
            <TextInput
              name={CancelEwayBillKeys.remarks}
              control={control}
              placeholder={"xxx"}
            />
          </CustomFieldRow>
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        sx={{
          padding: {
            xs: "10px 16px 10px 16px",
            md: 0
          },
          gap: { xs: 1, md: 0 },
          width: { xs: "100%", md: 520 },
          background: { xs: "white", md: "transparent" },
          position: "fixed",
          borderTop: { xs: "1px solid #B7BEC7", md: "none" },
          bottom: 0
        }}
      >
        <Button
          type="button"
          variant="soft"
          fullWidth
          sx={{
            fontWeight: 500,
            fontSize: { xs: 15, md: 24 },
            height: {
              xs: 48,
              md: 81
            },
            borderRadius: { xs: "8px", md: 0 },
            backgroundColor: "#EFF2F7",
            "&:hover": {
              backgroundColor: "#EFF2F7",
              color: "#2773FF"
            }
          }}
          onClick={onBackClick}
        >
          {"Go back"}
        </Button>
        <Button
          type="button"
          variant="contained"
          component={LoadingButton}
          loading={isLoading}
          onClick={handleSubmit(onCancelClick)}
          fullWidth
          sx={{
            fontWeight: 500,
            fontSize: { xs: 15, md: 24 },
            height: {
              xs: 48,
              md: 81
            },
            background: "#F05454",
            "&:hover": {
              backgroundColor: "#F05454"
            },
            borderRadius: { xs: "8px", md: 0 }
          }}
        >
          {"Cancel E-Waybill"}
        </Button>
      </Box>
    </>
  );
};

export default CancelEwayBill;
