import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { currencyOnlyFormatter } from "../../shared/utils";
import styled from "@emotion/styled";

const PrintPrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "10px",
  fontFamily: "Inter",
  lineHeight: "12px",
  fontWeight: 400
}));

const PrintSecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "9px",
  fontFamily: "Inter",
  lineHeight: "11px",
  fontWeight: 400
}));

const RowGridContainer = styled((props) => <Grid {...props} />)(
  ({ theme }) => ({
    border: "0.6px solid #b7bec7",
    borderRadius: "8px",
    padding: "10px",
    margin: "5px"
  })
);

const RowGrid = styled((props) => <Grid {...props} />)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

const gstColumn = (value, percent) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <PrintPrimaryText>{value ?? 0}</PrintPrimaryText>
      <PrintSecondaryText>{`(${percent ?? 0}%)`}</PrintSecondaryText>
    </Box>
  );
};

const getRowFormatByDocType = (
  docType = "",
  columns = [],
  data = [],
  gstType
) => {
  switch (docType) {
    case "Mrr":
      return data.map((item, index) => {
        return (
          <Fragment key={index}>
            <RowGridContainer container>
              <RowGrid item xs={columns[0].size}>
                <PrintPrimaryText>{item.sNo}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[1].size}>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  {item?.skuItem?.img && (
                    <img
                      src={item?.skuItem?.img}
                      alt={item?.product?.brand}
                      style={{ width: "50%", maxWidth: "50%" }}
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: "10px",
                      lineHeight: "12px",
                      fontWeight: 600,
                      textDecoration: "underline",
                      color: "#2773FF",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    {item?.skuItem?.name}
                  </Typography>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[2].size}>
                <Box display={"flex"} flexDirection={"column"}>
                  <PrintPrimaryText>{`Product: ${
                    item?.product?.product ?? "--"
                  }`}</PrintPrimaryText>
                  <PrintSecondaryText>{`Category: ${
                    item?.product?.category ?? "--"
                  }`}</PrintSecondaryText>
                  <PrintSecondaryText>{`Brand: ${
                    item?.product?.brand ?? "--"
                  }`}</PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[3].size}>
                <PrintPrimaryText>{item.uom}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[4].size}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  textAlign={"center"}
                  gap={2}
                >
                  <PrintPrimaryText>
                    {currencyOnlyFormatter(parseFloat(item.rate).toFixed(2))}
                  </PrintPrimaryText>
                  {item.incGst && (
                    <PrintSecondaryText>{`(Incl. GST)`}</PrintSecondaryText>
                  )}
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[5].size}>
                <PrintPrimaryText>{item.qtyOrdered}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[6].size}>
                <PrintPrimaryText>{item.qtyReceived}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[7].size}>
                <PrintPrimaryText>{item.qtyRejected}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[8].size}>
                <PrintPrimaryText>{item.rejectReason}</PrintPrimaryText>
              </RowGrid>
            </RowGridContainer>
          </Fragment>
        );
      });
    case "GatePass":
      return data.map((item, index) => {
        return (
          <Fragment key={index}>
            <RowGridContainer container>
              <RowGrid item xs={columns[0].size}>
                <PrintPrimaryText>{item.sNo}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[1].size}>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  {item?.skuItem?.img && (
                    <img
                      src={item?.skuItem?.img}
                      alt={item?.product?.brand}
                      style={{ width: "50%", maxWidth: "50%" }}
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: "10px",
                      lineHeight: "12px",
                      fontWeight: 600,
                      textDecoration: "underline",
                      color: "#2773FF",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    {item?.skuItem?.name}
                  </Typography>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[2].size}>
                <Box display={"flex"} flexDirection={"column"}>
                  <PrintPrimaryText>{`Product: ${
                    item?.product?.product ?? "--"
                  }`}</PrintPrimaryText>
                  <PrintSecondaryText>{`Category: ${
                    item?.product?.category ?? "--"
                  }`}</PrintSecondaryText>
                  <PrintSecondaryText>{`Brand: ${
                    item?.product?.brand ?? "--"
                  }`}</PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[3].size}>
                <PrintPrimaryText>{item.uom}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[4].size}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  textAlign={"center"}
                  gap={2}
                >
                  <PrintPrimaryText>
                    {currencyOnlyFormatter(parseFloat(item.price).toFixed(2))}
                  </PrintPrimaryText>
                  {item.incGst && (
                    <PrintSecondaryText>{`(Incl. GST)`}</PrintSecondaryText>
                  )}
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[5].size}>
                <PrintPrimaryText>{item.qtyOrder}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[6].size}>
                <PrintPrimaryText>{item.qtyDispatched}</PrintPrimaryText>
              </RowGrid>
            </RowGridContainer>
          </Fragment>
        );
      });
    case "DeliveryChallan":
      return data.map((item, index) => {
        return (
          <Fragment key={index}>
            <RowGridContainer container>
              <RowGrid item xs={columns[0].size}>
                <PrintPrimaryText>{item.sNo}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[1].size}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    {item?.image?.thumbUrl && (
                      <img
                        src={item?.image?.thumbUrl}
                        alt={item?.brandName}
                        style={{ width: "50%", maxWidth: "50%" }}
                      />
                    )}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        lineHeight: "12px",
                        fontWeight: 600,
                        textDecoration: "underline",
                        color: "#2773FF",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {item?.skuCode}
                    </Typography>
                  </Box>
                  <PrintSecondaryText>
                    {`HSN : ${item?.hsn ?? "--"}`}
                  </PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[2].size}>
                <Box display={"flex"} flexDirection={"column"}>
                  <PrintPrimaryText>{`Product: ${
                    item?.productName ?? "--"
                  }`}</PrintPrimaryText>
                  <PrintSecondaryText>{`Category: ${
                    item?.categoryName ?? "--"
                  }`}</PrintSecondaryText>
                  <PrintSecondaryText>{`Brand: ${
                    item?.brandName ?? "--"
                  }`}</PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[3].size}>
                <PrintPrimaryText>{item.unit.name}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[4].size}>
                <PrintPrimaryText>{item.rate}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[5].size}>
                <PrintPrimaryText>{item.quantity}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[6].size}>
                <PrintPrimaryText>{item.amount}</PrintPrimaryText>
              </RowGrid>
              {gstType ? (
                <>
                  <RowGrid item xs={columns[7].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.cgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                  <RowGrid item xs={columns[8].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.sgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                </>
              ) : (
                <RowGrid item xs={columns[7].size}>
                  {gstColumn(
                    item.igst ?? 0,
                    item?.gstRegime ? item?.gstRegime : 0
                  )}
                </RowGrid>
              )}
              <RowGrid item xs={columns[gstType ? 9 : 8].size}>
                <PrintPrimaryText>{item.total}</PrintPrimaryText>
              </RowGrid>
            </RowGridContainer>
          </Fragment>
        );
      });
    case "TaxInvoice":
      return data.map((item, index) => {
        return (
          <Fragment key={index}>
            <RowGridContainer container>
              <RowGrid item xs={columns[0].size}>
                <PrintPrimaryText>{item.sNo}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[1].size}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    {item?.image?.thumbUrl && (
                      <img
                        src={item?.image?.thumbUrl}
                        alt={item?.brandName}
                        style={{ width: "50%", maxWidth: "50%" }}
                      />
                    )}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        lineHeight: "12px",
                        fontWeight: 600,
                        textDecoration: "underline",
                        color: "#2773FF",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {item?.skuCode}
                    </Typography>
                  </Box>
                  <PrintSecondaryText>
                    {`HSN : ${item?.hsn ?? "--"}`}
                  </PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[2].size}>
                <Box display={"flex"} flexDirection={"column"}>
                  <PrintPrimaryText>{`Product: ${
                    item?.productName ?? "--"
                  }`}</PrintPrimaryText>
                  <PrintSecondaryText>{`Category: ${
                    item?.categoryName ?? "--"
                  }`}</PrintSecondaryText>
                  <PrintSecondaryText>{`Brand: ${
                    item?.brandName ?? "--"
                  }`}</PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[3].size}>
                <PrintPrimaryText>{item.unit.name}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[4].size}>
                <PrintPrimaryText>{item.rate}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[5].size}>
                <PrintPrimaryText>{item.quantity}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[6].size}>
                <PrintPrimaryText>{item.amount}</PrintPrimaryText>
              </RowGrid>
              {gstType ? (
                <>
                  <RowGrid item xs={columns[7].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.cgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                  <RowGrid item xs={columns[8].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.sgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                </>
              ) : (
                <RowGrid item xs={columns[7].size}>
                  {gstColumn(
                    item.igst ?? 0,
                    item?.gstRegime ? item?.gstRegime : 0
                  )}
                </RowGrid>
              )}
              <RowGrid item xs={columns[gstType ? 9 : 8]?.size}>
                <PrintPrimaryText>{item.cess}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[gstType ? 10 : 9]?.size}>
                <PrintPrimaryText>{item.nonAdvol}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[gstType ? 11 : 10]?.size}>
                <PrintPrimaryText>{item.total}</PrintPrimaryText>
              </RowGrid>
            </RowGridContainer>
          </Fragment>
        );
      });
    case "StockTransfer":
      return data.map((item, index) => {
        return (
          <Fragment key={index}>
            <RowGridContainer container>
              <RowGrid item xs={columns[0].size}>
                <PrintPrimaryText>{item.sNo}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[1].size}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    {item?.image?.thumbUrl && (
                      <img
                        src={item?.image?.thumbUrl}
                        alt={item?.brandName}
                        style={{ width: "50%", maxWidth: "50%" }}
                      />
                    )}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        lineHeight: "12px",
                        fontWeight: 600,
                        textDecoration: "underline",
                        color: "#2773FF",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {item?.skuCode}
                    </Typography>
                  </Box>
                  <PrintSecondaryText>
                    {`HSN : ${item?.hsn ?? "--"}`}
                  </PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[2].size}>
                <Box display={"flex"} flexDirection={"column"}>
                  <PrintPrimaryText>{`Product: ${
                    item?.productName ?? "--"
                  }`}</PrintPrimaryText>
                  <PrintSecondaryText>{`Category: ${
                    item?.categoryName ?? "--"
                  }`}</PrintSecondaryText>
                  <PrintSecondaryText>{`Brand: ${
                    item?.brandName ?? "--"
                  }`}</PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[3].size}>
                <PrintPrimaryText>{item.unit.name}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[4].size}>
                <PrintPrimaryText>
                  {item?.stock?.origin?.closingQuantity}
                </PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[5].size}>
                <PrintPrimaryText>
                  {item?.stock?.receiving?.closingQuantity}
                </PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[6].size}>
                <PrintPrimaryText>{item.toBeTransferred}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[7].size}>
                <PrintPrimaryText>{item.reason}</PrintPrimaryText>
              </RowGrid>
            </RowGridContainer>
          </Fragment>
        );
      });
    case "StockAdjustment":
      return data.map((item, index) => {
        return (
          <Fragment key={index}>
            <RowGridContainer container>
              <RowGrid item xs={columns[0].size}>
                <PrintPrimaryText>{item.sNo}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[1].size}>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  {item?.image?.thumbUrl && (
                    <img
                      src={item?.image?.thumbUrl}
                      alt={item?.brandName}
                      style={{ width: "50%", maxWidth: "50%" }}
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: "10px",
                      lineHeight: "12px",
                      fontWeight: 600,
                      textDecoration: "underline",
                      color: "#2773FF",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    {item?.skuCode}
                  </Typography>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[2].size}>
                <Box display={"flex"} flexDirection={"column"}>
                  <PrintPrimaryText>{`Product: ${
                    item?.productName ?? "--"
                  }`}</PrintPrimaryText>
                  <PrintSecondaryText>{`Category: ${
                    item?.categoryName ?? "--"
                  }`}</PrintSecondaryText>
                  <PrintSecondaryText>{`Brand: ${
                    item?.brandName ?? "--"
                  }`}</PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[3].size}>
                <PrintPrimaryText>{item.unit.name}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[4].size}>
                <PrintPrimaryText>{item?.warehouse}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[5].size}>
                <PrintPrimaryText>
                  {item?.stock?.closingQuantity}
                </PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[6].size}>
                <PrintPrimaryText>{item.actualStockQuantity}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[7].size}>
                <PrintPrimaryText>{item?.change}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[8].size}>
                <PrintPrimaryText>{item.reasonDisplay}</PrintPrimaryText>
              </RowGrid>
            </RowGridContainer>
          </Fragment>
        );
      });
    case "PurchaseOrder":
      return data.map((item, index) => {
        return (
          <Fragment key={index}>
            <RowGridContainer container>
              <RowGrid item xs={columns[0].size}>
                <PrintPrimaryText>{item.sNo}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[1].size}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    {item?.image?.thumbUrl && (
                      <img
                        src={item?.image?.thumbUrl}
                        alt={item?.brandName}
                        style={{ width: "50%", maxWidth: "50%" }}
                      />
                    )}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        lineHeight: "12px",
                        fontWeight: 600,
                        textDecoration: "underline",
                        color: "#2773FF",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {item?.skuCode}
                    </Typography>
                  </Box>
                  <PrintSecondaryText>
                    {`HSN : ${item?.hsn ?? "--"}`}
                  </PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[2].size}>
                <Box display={"flex"} flexDirection={"column"}>
                  <PrintPrimaryText>{`Product: ${
                    item?.productName ?? "--"
                  }`}</PrintPrimaryText>
                  <PrintSecondaryText>{`Category: ${
                    item?.categoryName ?? "--"
                  }`}</PrintSecondaryText>
                  <PrintSecondaryText>{`Brand: ${
                    item?.brandName ?? "--"
                  }`}</PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[3].size}>
                <PrintPrimaryText>{item.unit.name}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[4].size}>
                <PrintPrimaryText>{item.rate}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[5].size}>
                <PrintPrimaryText>{item.quantity}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[6].size}>
                <PrintPrimaryText>{item.amount}</PrintPrimaryText>
              </RowGrid>
              {gstType ? (
                <>
                  <RowGrid item xs={columns[7].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.cgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                  <RowGrid item xs={columns[8].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.sgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                </>
              ) : (
                <RowGrid item xs={columns[7].size}>
                  {gstColumn(
                    item.igst ?? 0,
                    item?.gstRegime ? item?.gstRegime : 0
                  )}
                </RowGrid>
              )}
              <RowGrid item xs={columns[gstType ? 9 : 8].size}>
                <PrintPrimaryText>{item.total}</PrintPrimaryText>
              </RowGrid>
            </RowGridContainer>
          </Fragment>
        );
      });
    case "PurchaseReturn":
      return data.map((item, index) => {
        return (
          <Fragment key={index}>
            <RowGridContainer container>
              <RowGrid item xs={columns[0].size}>
                <PrintPrimaryText>{item.sNo}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[1].size}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    {item?.image?.thumbUrl && (
                      <img
                        src={item?.image?.thumbUrl}
                        alt={item?.brandName}
                        style={{ width: "50%", maxWidth: "50%" }}
                      />
                    )}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        lineHeight: "12px",
                        fontWeight: 600,
                        textDecoration: "underline",
                        color: "#2773FF",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {item?.skuCode}
                    </Typography>
                  </Box>
                  <PrintSecondaryText>
                    {`HSN : ${item?.hsn ?? "--"}`}
                  </PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[2].size}>
                <Box display={"flex"} flexDirection={"column"}>
                  <PrintPrimaryText>{`Product: ${
                    item?.productName ?? "--"
                  }`}</PrintPrimaryText>
                  <PrintSecondaryText>{`Category: ${
                    item?.categoryName ?? "--"
                  }`}</PrintSecondaryText>
                  <PrintSecondaryText>{`Brand: ${
                    item?.brandName ?? "--"
                  }`}</PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[3].size}>
                <PrintPrimaryText>{item.unit.name}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[4].size}>
                <PrintPrimaryText>{item.rate}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[5].size}>
                <PrintPrimaryText>{item.quantity}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[6].size}>
                <PrintPrimaryText>{item.returnedQuantity}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[7].size}>
                <PrintPrimaryText>{item.amount}</PrintPrimaryText>
              </RowGrid>
              {gstType ? (
                <>
                  <RowGrid item xs={columns[8].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.cgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                  <RowGrid item xs={columns[9].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.sgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                </>
              ) : (
                <RowGrid item xs={columns[8].size}>
                  {gstColumn(
                    item.igst ?? 0,
                    item?.gstRegime ? item?.gstRegime : 0
                  )}
                </RowGrid>
              )}
              <RowGrid item xs={columns[gstType ? 10 : 9].size}>
                <PrintPrimaryText>{item.total}</PrintPrimaryText>
              </RowGrid>
            </RowGridContainer>
          </Fragment>
        );
      });
    case "SalesOrder":
      return data.map((item, index) => {
        return (
          <Fragment key={index}>
            <RowGridContainer container>
              <RowGrid item xs={columns[0].size}>
                <PrintPrimaryText>{item.sNo}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[1].size}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    {item?.image?.thumbUrl && (
                      <img
                        src={item?.image?.thumbUrl}
                        alt={item?.brandName}
                        style={{ width: "50%", maxWidth: "50%" }}
                      />
                    )}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        lineHeight: "12px",
                        fontWeight: 600,
                        textDecoration: "underline",
                        color: "#2773FF",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {item?.skuCode}
                    </Typography>
                  </Box>
                  <PrintSecondaryText>
                    {`HSN : ${item?.hsn ?? "--"}`}
                  </PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[2].size}>
                <Box display={"flex"} flexDirection={"column"}>
                  <PrintPrimaryText>{`Product: ${
                    item?.productName ?? "--"
                  }`}</PrintPrimaryText>
                  <PrintSecondaryText>{`Category: ${
                    item?.categoryName ?? "--"
                  }`}</PrintSecondaryText>
                  <PrintSecondaryText>{`Brand: ${
                    item?.brandName ?? "--"
                  }`}</PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[3].size}>
                <PrintPrimaryText>{item.unit.name}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[4].size}>
                <PrintPrimaryText>{item.rate}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[5].size}>
                <PrintPrimaryText>{item.quantity}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[6].size}>
                <PrintPrimaryText>{item.disc}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[7].size}>
                <PrintPrimaryText>{item.offPrice}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[8].size}>
                <PrintPrimaryText>{item.amount}</PrintPrimaryText>
              </RowGrid>
              {gstType ? (
                <>
                  <RowGrid item xs={columns[9].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.cgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                  <RowGrid item xs={columns[10].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.sgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                </>
              ) : (
                <RowGrid item xs={columns[9].size}>
                  {gstColumn(
                    item.igst ?? 0,
                    item?.gstRegime ? item?.gstRegime : 0
                  )}
                </RowGrid>
              )}
              <RowGrid item xs={columns[gstType ? 11 : 10].size}>
                <PrintPrimaryText>{item.total}</PrintPrimaryText>
              </RowGrid>
            </RowGridContainer>
          </Fragment>
        );
      });
    case "SalesReturns":
      return data.map((item, index) => {
        return (
          <Fragment key={index}>
            <RowGridContainer container>
              <RowGrid item xs={columns[0].size}>
                <PrintPrimaryText>{item.sNo}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[1].size}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    {item?.image?.thumbUrl && (
                      <img
                        src={item?.image?.thumbUrl}
                        alt={item?.brandName}
                        style={{ width: "50%", maxWidth: "50%" }}
                      />
                    )}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        lineHeight: "12px",
                        fontWeight: 600,
                        textDecoration: "underline",
                        color: "#2773FF",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {item?.skuCode}
                    </Typography>
                  </Box>
                  <PrintSecondaryText>
                    {`HSN : ${item?.hsn ?? "--"}`}
                  </PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[2].size}>
                <Box display={"flex"} flexDirection={"column"}>
                  <PrintPrimaryText>{`Product: ${
                    item?.productName ?? "--"
                  }`}</PrintPrimaryText>
                  <PrintSecondaryText>{`Category: ${
                    item?.categoryName ?? "--"
                  }`}</PrintSecondaryText>
                  <PrintSecondaryText>{`Brand: ${
                    item?.brandName ?? "--"
                  }`}</PrintSecondaryText>
                </Box>
              </RowGrid>
              <RowGrid item xs={columns[3].size}>
                <PrintPrimaryText>{item.unit.name}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[4].size}>
                <PrintPrimaryText>{item.rate}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[5].size}>
                <PrintPrimaryText>{item.quantity}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[6].size}>
                <PrintPrimaryText>{item.returnedQuantity}</PrintPrimaryText>
              </RowGrid>
              <RowGrid item xs={columns[7].size}>
                <PrintPrimaryText>{item.amount}</PrintPrimaryText>
              </RowGrid>
              {gstType ? (
                <>
                  <RowGrid item xs={columns[8].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.cgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                  <RowGrid item xs={columns[9].size}>
                    <PrintPrimaryText>
                      {gstColumn(
                        item.sgst ?? 0,
                        item?.gstRegime ? item?.gstRegime / 2 : 0
                      )}
                    </PrintPrimaryText>
                  </RowGrid>
                </>
              ) : (
                <RowGrid item xs={columns[8].size}>
                  {gstColumn(
                    item.igst ?? 0,
                    item?.gstRegime ? item?.gstRegime : 0
                  )}
                </RowGrid>
              )}
              <RowGrid item xs={columns[gstType ? 10 : 9].size}>
                <PrintPrimaryText>{item.total}</PrintPrimaryText>
              </RowGrid>
            </RowGridContainer>
          </Fragment>
        );
      });
    default:
      return [];
  }
};

const PrintableTable = ({ columns = [], rows = [], docType = "", gstType }) => {
  return (
    <Box width={"100%"}>
      <Grid
        container
        sx={{
          background: "#bbcadf",
          marginBottom: "8px",
          padding: "10px"
        }}
      >
        {columns?.map((item, index) => {
          return (
            <Fragment key={index}>
              <RowGrid item xs={item.size}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#0f2744",
                    fontWeight: 600
                  }}
                >
                  {item.Header}
                </Typography>
              </RowGrid>
            </Fragment>
          );
        })}
      </Grid>
      {getRowFormatByDocType(docType, columns, rows, gstType)}
    </Box>
  );
};

export default PrintableTable;
