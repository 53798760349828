import * as yup from "yup";

import { Box, Button, Grid, Typography, styled } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import AutoCompleteMapField from "../../shared/formElements/AutoCompleteMapField";
import CheckBoxGroup from "../../shared/formElements/CheckBoxGroup";
import CheckBoxSelect from "../../shared/formElements/CheckboxSelect";
import DropDown from "../../shared/formElements/DropDown";
import { ImageUpload } from "../../shared/formElements/ImageUpload";
import { StyledButton } from "../../shared/formElements/StyledButton";
import TextInput from "../../shared/formElements/TextInput";
import { error_msg, purchase_labels } from "../../constants";
import { useForm } from "react-hook-form";
import { vendorFormKeys } from "../../constants/formKeys";
import { useFetchVendorFormData } from "../../query-hooks/Vendors/useFetchVendorFormData";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchUpdateVendorFormData } from "../../query-hooks/Vendors/useFetchUpdateVendorFormData";
import { useCreateVendor } from "../../query-hooks/Vendors/useCreateVendor";
import { useUpdateVendor } from "../../query-hooks/Vendors/useUpdateVendor";
import { enqueueSnackbar } from "notistack";
import { useFetchVendorById } from "../../query-hooks/Vendors/useFetchVendorById";
import { capitalizeFirstCharacter, dropdownOptions } from "../../shared/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import appRoutes from "../../constants/appRoutes";
import { AccordianInfo } from "../../warehouse/components/MobileOrdersPage";
import { DrawerButton } from "../../inventory/components/SKU/SkuDetailsForm";
import { useFetchGst } from "../../query-hooks/ClientEwayBill/useFetchGst";
import EwayBillLogin from "../../warehouse/components/EwayBills/EwayBillLogin";
import { useCustomDialog } from "../../shared/customDialog";
import { VerifiedPhone } from "../../customer/pages/Profile";
import { HighlightOff } from "@mui/icons-material";
import ErrorPopup from "../../shared/UiElements/ErrorPopup";

const schema = (includeGSTIN) => {
  const baseSchema = {
    [vendorFormKeys.vendorCompanyName]: yup
      .string()
      .required(error_msg.required),
    [vendorFormKeys.contactName]: yup.string().required(error_msg.required),
    [vendorFormKeys.contact]: yup.string().required(error_msg.required),
    [vendorFormKeys.warehouseAddress]: yup
      .string()
      .required(error_msg.required),
    [vendorFormKeys.address]: yup.string().required(error_msg.required),
    [vendorFormKeys.area]: yup.string().required(error_msg.required),
    [vendorFormKeys.city]: yup.string().required(error_msg.required),
    [vendorFormKeys.state]: yup.string().required(error_msg.required),
    [vendorFormKeys.zipCode]: yup.string().required(error_msg.required),
    [vendorFormKeys.billingAddress]: yup.string().required(error_msg.required),
    [vendorFormKeys.billingArea]: yup.string().required(error_msg.required),
    [vendorFormKeys.billingCity]: yup.string().required(error_msg.required),
    [vendorFormKeys.billingState]: yup.string().required(error_msg.required),
    [vendorFormKeys.billingZipCode]: yup.string().required(error_msg.required),
    [vendorFormKeys.skuDealing]: yup
      .array()
      .of(yup.string())
      .min(1, "Please select at least 1 sku")
      .required(error_msg.required),
    [vendorFormKeys.accountType]: yup.string().required(error_msg.required),
    [vendorFormKeys.bankName]: yup.string().required(error_msg.required),
    [vendorFormKeys.accountName]: yup.string().required(error_msg.required),
    [vendorFormKeys.accountNumber]: yup.string().required(error_msg.required),
    [vendorFormKeys.ifsc]: yup.string().required(error_msg.required),
    [vendorFormKeys.pan]: yup
      .string()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Enter a correct PAN number")
      .required(error_msg.required),
    [vendorFormKeys.bankName]: yup.string().required(error_msg.required)
  };

  if (includeGSTIN) {
    baseSchema[vendorFormKeys.gst] = yup
      .string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Please Enter Correct GSTIN"
      )
      .required(error_msg.required);
  }

  return yup.object(baseSchema);
};

export const StyledBox = styled(Box)(({ theme }) => ({
  border: "0.6px solid #6097FF",
  borderRadius: 8,
  padding: 20,
  height: "100%"
}));

export const StyledCheckBoxContainer = styled((props) => (
  <Box
    {...props}
    sx={{
      "& label > span > span, & label > span > span::before": {
        height: {
          md: 16
        },
        width: { md: 16 }
      },
      "& label > span:last-child": {
        fontSize: { md: 12 },
        lineHeight: "16px"
      }
    }}
  />
))(({ theme }) => ({}));

export const BlockedIcon = () => {
  return (
    <Box display={"flex"} alignItems={"center"} px={2}>
      <HighlightOff
        fontSize="16"
        sx={{ mr: "4px", cursor: "pointer", color: "#A85B5F" }}
      />
      <Typography
        color={"#A85B5F"}
        fontWeight={400}
        fontSize={12}
        lineHeight={"16px"}
        whiteSpace={"nowrap"}
      >
        {"Blocked"}
      </Typography>
    </Box>
  );
};

const VendorDetails = ({ hideLabel = false, control }) => {
  return (
    <>
      <Grid item xs={12} md={3}>
        <TextInput
          mandatoryField={true}
          control={control}
          label={hideLabel ? "" : purchase_labels.vendorCompanyName}
          placeholder={purchase_labels.vendorCompanyNamePlaceholder}
          name={vendorFormKeys.vendorCompanyName}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextInput
          mandatoryField={true}
          control={control}
          label={hideLabel ? "" : purchase_labels.contactName}
          placeholder={purchase_labels.contactNamePlaceholder}
          name={vendorFormKeys.contactName}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextInput
          mandatoryField={true}
          control={control}
          label={hideLabel ? "" : purchase_labels.contact}
          placeholder={purchase_labels.contactPlaceholder}
          name={vendorFormKeys.contact}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextInput
          control={control}
          label={hideLabel ? "" : purchase_labels.email}
          placeholder={purchase_labels.emailPlaceholder}
          name={vendorFormKeys.email}
        />
      </Grid>
    </>
  );
};

const WarehouseAddress = ({
  hideLabel = false,
  control,
  setValue,
  isFormDataFetching,
  isFetchingUpdateFormData,
  formData,
  updateFormData
}) => {
  return (
    <>
      <Grid item xs={12}>
        <AutoCompleteMapField
          mandatoryField={true}
          control={control}
          name={vendorFormKeys.warehouseAddress}
          label={hideLabel ? "" : purchase_labels.warehouseAddress}
          placeholder={purchase_labels.warehouseAddressPlaceholder}
          reset={(address, location) => {
            setValue(vendorFormKeys.warehouseAddress, address);
            setValue(vendorFormKeys.lat, location.lat);
            setValue(vendorFormKeys.lng, location.lng);
          }}
        />
      </Grid>
      <Grid item xs={12} ml={0.5} mb={3}>
        <StyledCheckBoxContainer>
          <CheckBoxGroup
            control={control}
            name={vendorFormKeys.useSameBillingAddress}
            options={[
              {
                name: "yes",
                displayName: purchase_labels.sameBillingAddressLabelText
              }
            ]}
            setValue={setValue}
          />
        </StyledCheckBoxContainer>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          control={control}
          placeholder={purchase_labels.address}
          name={vendorFormKeys.address}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          placeholder={purchase_labels.area}
          name={vendorFormKeys.area}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          placeholder={purchase_labels.city}
          name={vendorFormKeys.city}
        />
      </Grid>
      <Grid item xs={6}>
        <DropDown
          control={control}
          placeholder={purchase_labels.state}
          name={vendorFormKeys.state}
          isLoading={isFormDataFetching || isFetchingUpdateFormData}
          options={dropdownOptions(
            formData?.states ?? updateFormData?.states,
            "displayName",
            "displayName"
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          placeholder={purchase_labels.zipCode}
          name={vendorFormKeys.zipCode}
        />
      </Grid>
    </>
  );
};

const BillingAddress = ({
  hideLabel = false,
  control,
  setValue,
  isFormDataFetching,
  isFetchingUpdateFormData,
  formData,
  updateFormData,
  unRegistered,
  isFetchingGst,
  allowCheck,
  handleCheckGst,
  endIcon
}) => {
  return (
    <>
      <Grid item xs={12}>
        <TextInput
          control={control}
          label={hideLabel ? "" : purchase_labels.gst}
          placeholder={purchase_labels.gstPlaceholder}
          name={vendorFormKeys.gst}
          disabled={unRegistered}
          tooltipTitle={"Enter 15 Characters to Verify GSTIN"}
          endIcon={endIcon}
          endButton={() =>
            !endIcon && (
              <Button
                variant="link"
                component={LoadingButton}
                loading={isFetchingGst}
                disabled={!allowCheck}
                onClick={handleCheckGst}
              >
                {"Check"}
              </Button>
            )
          }
        />
      </Grid>
      <Grid item xs={12} ml={0.5}>
        <StyledCheckBoxContainer>
          <CheckBoxGroup
            control={control}
            name={vendorFormKeys.selectIfUnregistered}
            options={[
              {
                name: "yes",
                displayName: purchase_labels.selectIfUnregisteredText
              }
            ]}
            setValue={setValue}
          />
        </StyledCheckBoxContainer>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          control={control}
          label={hideLabel ? "" : purchase_labels.billingAddress}
          placeholder={purchase_labels.address}
          name={vendorFormKeys.billingAddress}
          mandatoryField={true}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          placeholder={purchase_labels.area}
          name={vendorFormKeys.billingArea}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          placeholder={purchase_labels.city}
          name={vendorFormKeys.billingCity}
        />
      </Grid>
      <Grid item xs={6}>
        <DropDown
          control={control}
          placeholder={purchase_labels.state}
          name={vendorFormKeys.billingState}
          isLoading={isFormDataFetching || isFetchingUpdateFormData}
          options={dropdownOptions(
            formData?.states ?? updateFormData?.states,
            "displayName",
            "displayName"
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          placeholder={purchase_labels.zipCode}
          name={vendorFormKeys.billingZipCode}
        />
      </Grid>
    </>
  );
};

const SKUComponent = ({
  hideLabel = false,
  control,
  isFormDataFetching,
  isFetchingUpdateFormData,
  formData,
  updateFormData
}) => {
  return (
    <CheckBoxSelect
      mandatoryField={true}
      control={control}
      isLoading={isFormDataFetching || isFetchingUpdateFormData}
      options={dropdownOptions(
        formData?.skus ?? updateFormData?.skus,
        "_id",
        "skuCode"
      )}
      label={hideLabel ? "" : purchase_labels.skuDealingLabel}
      name={vendorFormKeys.skuDealing}
      allowSearch
    />
  );
};

const KYC = ({
  control,
  setValue,
  isFormDataFetching,
  isFetchingUpdateFormData,
  formData,
  updateFormData
}) => {
  return (
    <>
      <Grid item xs={6}>
        <DropDown
          control={control}
          placeholder={purchase_labels.accountType}
          name={vendorFormKeys.accountType}
          isLoading={isFormDataFetching || isFetchingUpdateFormData}
          options={dropdownOptions(
            formData?.bankAccountTypes ?? updateFormData?.bankAccountTypes
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          placeholder={purchase_labels.bankName}
          name={vendorFormKeys.bankName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          placeholder={purchase_labels.accountName}
          name={vendorFormKeys.accountName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          placeholder={purchase_labels.accountNumber}
          name={vendorFormKeys.accountNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          placeholder={purchase_labels.ifsc}
          name={vendorFormKeys.ifsc}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          placeholder={purchase_labels.pan}
          name={vendorFormKeys.pan}
        />
      </Grid>
      <Grid item xs={12}>
        <ImageUpload
          name={vendorFormKeys.cancelledCheque}
          control={control}
          compact={true}
          uploadButtonText={purchase_labels.cancelledCheque}
          setValue={setValue}
        />
      </Grid>
    </>
  );
};

const AddVendor = () => {
  const [includeGSTIN, setIncludeGSTIN] = useState(true);
  const [checkGst, setCheckGst] = useState(false);
  const [isGstChecked, setIsGstChecked] = useState("none");
  const { control, setValue, watch, handleSubmit } = useForm({
    resolver: yupResolver(schema(includeGSTIN))
  });
  const { vendorId } = useParams();
  const navigate = useNavigate();

  const { showDialog, hideDialog } = useCustomDialog();

  const handleCheckGst = () => {
    setCheckGst(true);
  };

  const { data: formData, isFetching: isFormDataFetching } =
    useFetchVendorFormData(vendorId);

  const { data: updateFormData, isFetching: isFetchingUpdateFormData } =
    useFetchUpdateVendorFormData(vendorId);

  const {
    data: vendorData,
    isFetching: isFetchingVendorData,
    refetch
  } = useFetchVendorById(vendorId);

  const {
    mutate: createVendor,
    isLoading: isCreating,
    isError: isCreateError,
    error: createError
  } = useCreateVendor();

  const {
    mutate: updateVendor,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError
  } = useUpdateVendor();

  useEffect(() => {
    if (!isFetchingVendorData) {
      setValue(
        vendorFormKeys.vendorCompanyName,
        vendorData?.vendor?.companyName
      );
      setValue(vendorFormKeys.contactName, vendorData?.vendor?.contact?.name);
      setValue(vendorFormKeys.contact, vendorData?.vendor?.contact?.mobile);
      setValue(vendorFormKeys.email, vendorData?.vendor?.contact?.email);
    }
    // eslint-disable-next-line
  }, [isFetchingVendorData, setValue]);

  const unRegistered = watch(vendorFormKeys.selectIfUnregistered)?.[0];

  const sameBillingAddress = watch(vendorFormKeys.useSameBillingAddress)?.[0];

  const billingAddress = watch([
    vendorFormKeys.billingAddress,
    vendorFormKeys.billingArea,
    vendorFormKeys.billingCity,
    vendorFormKeys.billingState,
    vendorFormKeys.billingZipCode
  ]);

  const gstWatch = watch(vendorFormKeys.gst);

  const allowCheck = gstWatch?.length === 15;

  const {
    data: gstData,
    isFetching: isFetchingGst,
    refetch: refetchGst,
    isSuccess: isGstSuccess,
    isError: isGstError,
    error: gstError
  } = useFetchGst(checkGst, gstWatch);

  useEffect(() => {
    if (unRegistered === "yes") {
      setIncludeGSTIN(false);
      setValue(vendorFormKeys.gst, "");
    }
  }, [unRegistered, setValue]);

  useEffect(() => {
    if (sameBillingAddress === "yes") {
      setValue(vendorFormKeys.address, billingAddress[0]);
      setValue(vendorFormKeys.area, billingAddress[1]);
      setValue(vendorFormKeys.city, billingAddress[2]);
      setValue(vendorFormKeys.state, billingAddress[3]);
      setValue(vendorFormKeys.zipCode, billingAddress[4]);
    }
  }, [sameBillingAddress, billingAddress, setValue]);

  const onSaveClick = (data) => {
    const vendorData = new FormData();
    vendorData.append("companyName", data[vendorFormKeys.vendorCompanyName]);
    vendorData.append("contact[name]", data[vendorFormKeys.contactName]);
    vendorData.append("contact[mobile]", data[vendorFormKeys.contact]);
    data[vendorFormKeys.email] &&
      vendorData.append("contact[email]", data[vendorFormKeys.email]);
    vendorData.append(
      "warehouse[address][address]",
      data[vendorFormKeys.address]
    );
    vendorData.append("warehouse[address][area]", data[vendorFormKeys.area]);
    vendorData.append("warehouse[address][city]", data[vendorFormKeys.city]);
    vendorData.append("warehouse[address][state]", data[vendorFormKeys.state]);
    vendorData.append(
      "warehouse[address][pincode]",
      data[vendorFormKeys.zipCode]
    );
    vendorData.append(
      "warehouse[address][location][coordinates][0]",
      data[vendorFormKeys.lat]
    );
    vendorData.append(
      "warehouse[address][location][coordinates][1]",
      data[vendorFormKeys.lng]
    );

    vendorData.append(
      "warehouse[billingAddress][sameAsAddress]",
      Boolean(data[vendorFormKeys.useSameBillingAddress]?.[0] === "yes")
    );
    if (data[vendorFormKeys.useSameBillingAddress]?.[0] !== "yes") {
      vendorData.append(
        "warehouse[billingAddress][area]",
        data[vendorFormKeys.billingArea]
      );
      vendorData.append(
        "warehouse[billingAddress][city]",
        data[vendorFormKeys.billingCity]
      );
      vendorData.append(
        "warehouse[billingAddress][address]",
        data[vendorFormKeys.billingAddress]
      );
      vendorData.append(
        "warehouse[billingAddress][state]",
        data[vendorFormKeys.billingState]
      );
      vendorData.append(
        "warehouse[billingAddress][pincode]",
        data[vendorFormKeys.billingZipCode]
      );
    }
    data[vendorFormKeys.gst] &&
      vendorData.append("warehouse[gstNo]", data[vendorFormKeys.gst]);
    vendorData.append("pan", data[vendorFormKeys.pan]);
    data[vendorFormKeys.skuDealing]?.forEach((item, index) => {
      vendorData.append(`skuIds[${index}]`, item);
    });
    vendorData.append(
      "bankDetails[accountType]",
      data[vendorFormKeys.accountType]
    );
    vendorData.append("bankDetails[bankName]", data[vendorFormKeys.bankName]);
    vendorData.append(
      "bankDetails[beneficiaryName]",
      data[vendorFormKeys.accountName]
    );
    vendorData.append(
      "bankDetails[accountNumber]",
      data[vendorFormKeys.accountNumber]
    );
    vendorData.append("bankDetails[ifsc]", data[vendorFormKeys.ifsc]);
    data[vendorFormKeys.cancelledCheque] &&
      vendorData.append("chequeImage", data[vendorFormKeys.cancelledCheque]);

    vendorId
      ? updateVendor(
          {
            data: vendorData,
            vendorId: vendorId
          },
          {
            onSuccess: (res) => {
              refetch();
              enqueueSnackbar("Vendor Updated!", { variant: "success" });
            }
          }
        )
      : createVendor(
          {
            data: vendorData
          },
          {
            onSuccess: (res) => {
              navigate(
                `${appRoutes.purchase.main}/${appRoutes.purchase.vendors}`
              );
              enqueueSnackbar("Vendor created!", { variant: "success" });
            }
          }
        );
  };

  useEffect(() => {
    if (isCreateError)
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    if (isUpdateError)
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
  }, [
    updateError?.response?.data?.message,
    createError?.response?.data?.message,
    isUpdateError,
    isCreateError
  ]);

  const EwayBillLoginShow = useCallback(
    (autoSubmit) => {
      showDialog({
        component: (
          <EwayBillLogin hideDialog={hideDialog} autoSubmit={autoSubmit} />
        ),
        size: "xs",
        closeIcon: true
      });
    },
    [showDialog, hideDialog]
  );

  const getEndIcon = (type) => {
    switch (type) {
      case "none":
        return false;
      case "1":
        return VerifiedPhone;
      case "2":
        return BlockedIcon;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (!allowCheck) {
      setIsGstChecked("none");
      setCheckGst(false);
      setValue(vendorFormKeys.billingAddress, "");
      setValue(vendorFormKeys.billingArea, "");
      setValue(vendorFormKeys.billingCity, "");
      setValue(vendorFormKeys.billingState, "");
      setValue(vendorFormKeys.billingZipCode, "");
    }
  }, [allowCheck, setValue]);

  useEffect(() => {
    if (isGstSuccess) {
      if (gstData?.gst?.blockStatus?.name === "U") {
        setIsGstChecked("1");
        setCheckGst(false);
        setValue(vendorFormKeys.billingAddress, gstData?.gst?.address1);
        setValue(vendorFormKeys.billingArea, gstData?.gst?.address2);
        setValue(vendorFormKeys.billingCity, gstData?.gst?.address3 || "");
        setValue(
          vendorFormKeys.billingState,
          capitalizeFirstCharacter(gstData?.gst?.state?.displayName)
        );
        setValue(vendorFormKeys.billingZipCode, gstData?.gst?.pincode);
      } else {
        setIsGstChecked("2");
        setCheckGst(false);
        showDialog({
          component: (
            <ErrorPopup
              text={`This GSTIN is Blocked`}
              subText={`Legal Name : ${gstData?.gst?.legalName || "--"}`}
              btn2Text={"Try Another GSTIN"}
              btn2Callback={() => {
                hideDialog();
                setIsGstChecked("none");
              }}
            />
          ),
          backdropOff: true
        });
      }
    } // eslint-disable-next-line
  }, [
    isGstSuccess,
    setIsGstChecked,
    gstData?.gst?.blockStatus?.name,
    setValue
  ]);

  useEffect(() => {
    if (isGstError) {
      if (gstError?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(refetchGst);
      } else {
        enqueueSnackbar(gstError?.response?.data?.message, {
          variant: "error"
        });
      }
      setCheckGst(false);
    }
    // eslint-disable-next-line
  }, [gstError?.response?.data?.message, isGstError]);

  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "block" }
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={500} fontSize={20} lineHeight={"24.2px"}>
              {purchase_labels.vendorDetailsLabel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledBox>
              <Grid container spacing={4}>
                <VendorDetails control={control} />
                <Grid item xs={6}>
                  <StyledBox display={"flex"} flexDirection={"column"} gap={2}>
                    <Grid container spacing={2}>
                      <BillingAddress
                        control={control}
                        setValue={setValue}
                        isFormDataFetching={isFormDataFetching}
                        isFetchingUpdateFormData={isFetchingUpdateFormData}
                        formData={formData}
                        updateFormData={updateFormData}
                        unRegistered={unRegistered}
                        allowCheck={allowCheck}
                        handleCheckGst={handleCheckGst}
                        isFetchingGst={isFetchingGst}
                        endIcon={getEndIcon(isGstChecked)}
                      />
                    </Grid>
                  </StyledBox>
                </Grid>
                <Grid item xs={6}>
                  <StyledBox>
                    <Grid container spacing={2}>
                      <WarehouseAddress
                        control={control}
                        setValue={setValue}
                        isFormDataFetching={isFormDataFetching}
                        isFetchingUpdateFormData={isFetchingUpdateFormData}
                        formData={formData}
                        updateFormData={updateFormData}
                      />
                    </Grid>
                  </StyledBox>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    height={"100%"}
                    justifyContent={"space-between"}
                  >
                    <SKUComponent
                      control={control}
                      isFormDataFetching={isFormDataFetching}
                      isFetchingUpdateFormData={isFetchingUpdateFormData}
                      formData={formData}
                      updateFormData={updateFormData}
                    />
                    <StyledButton
                      variant="contained"
                      component={LoadingButton}
                      loading={isCreating || isUpdating}
                      sx={{ height: "auto" }}
                      onClick={handleSubmit(onSaveClick)}
                    >
                      {purchase_labels.saveButton}
                    </StyledButton>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <StyledBox display={"flex"} flexDirection={"column"} gap={2}>
                    <Typography fontWeight={500} lineHeight={"19.36px"}>
                      {purchase_labels.kycLabel}
                    </Typography>
                    <Grid container rowSpacing={2} columnSpacing={5}>
                      <KYC
                        control={control}
                        setValue={setValue}
                        isFormDataFetching={isFormDataFetching}
                        isFetchingUpdateFormData={isFetchingUpdateFormData}
                        formData={formData}
                        updateFormData={updateFormData}
                      />
                    </Grid>
                  </StyledBox>
                </Grid>
              </Grid>
            </StyledBox>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}>
        <Grid item xs={12} sx={{ mt: -6, ml: "50px" }} zIndex={1}>
          <Typography
            fontWeight={700}
            fontSize={16}
            lineHeight={"19.36px"}
            color={"#2773FF"}
          >
            {vendorId
              ? purchase_labels.vendorDetailsLabel
              : purchase_labels.addVendorLabel}
          </Typography>
        </Grid>
      </Box>
      <Grid
        container
        sx={{ display: { xs: "block", md: "none" } }}
        rowSpacing={1}
        px={"15px"}
        mb={20}
      >
        <Grid item xs={12}>
          <AccordianInfo title={"Vendor Details"} hideDivider defaultExpanded>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <VendorDetails control={control} hideLabel />
            </Grid>
          </AccordianInfo>
        </Grid>
        <Grid item xs={12}>
          <AccordianInfo title={"Billing Address"} defaultExpanded>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <BillingAddress
                control={control}
                setValue={setValue}
                isFormDataFetching={isFormDataFetching}
                isFetchingUpdateFormData={isFetchingUpdateFormData}
                formData={formData}
                updateFormData={updateFormData}
                unRegistered={unRegistered}
                hideLabel
                allowCheck={allowCheck}
                handleCheckGst={handleCheckGst}
                isFetchingGst={isFetchingGst}
                endIcon={getEndIcon(isGstChecked)}
              />
            </Grid>
          </AccordianInfo>
        </Grid>
        <Grid item xs={12}>
          <AccordianInfo title={"Warehouse Address"}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <WarehouseAddress
                control={control}
                setValue={setValue}
                isFormDataFetching={isFormDataFetching}
                isFetchingUpdateFormData={isFetchingUpdateFormData}
                formData={formData}
                updateFormData={updateFormData}
                hideLabel
              />
            </Grid>
          </AccordianInfo>
        </Grid>
        <Grid item xs={12}>
          <AccordianInfo title={"KYC Details"}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <KYC
                control={control}
                setValue={setValue}
                isFormDataFetching={isFormDataFetching}
                isFetchingUpdateFormData={isFetchingUpdateFormData}
                formData={formData}
                updateFormData={updateFormData}
              />
            </Grid>
          </AccordianInfo>
        </Grid>
        <Grid item xs={12}>
          <AccordianInfo title={"SKUs Dealing with"}>
            <Grid container>
              <SKUComponent
                hideLabel
                control={control}
                isFormDataFetching={isFormDataFetching}
                isFetchingUpdateFormData={isFetchingUpdateFormData}
                formData={formData}
                updateFormData={updateFormData}
              />
            </Grid>
          </AccordianInfo>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: { xs: "flex", md: "none" },
          position: "fixed",
          bottom: "70px",
          width: "100%",
          borderBottom: "1px solid #fff",
          zIndex: 1
        }}
      >
        <DrawerButton
          component={LoadingButton}
          loading={isCreating || isUpdating}
          onClick={handleSubmit(onSaveClick)}
        >
          {"Save"}
        </DrawerButton>
      </Grid>
    </>
  );
};

export default AddVendor;
