import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ExtendValidityKeys } from "../../../constants/formKeys";
import TextInput from "../../../shared/formElements/TextInput";
import { ExtendValidity_labels } from "../../../constants";
import DropDown from "../../../shared/formElements/DropDown";
import DateInput from "../../../shared/formElements/DateInput";
import VehicleHistory from "./VehicleHistory";
import moment from "moment";
import RadioButtonGroup from "../../../shared/formElements/RadioButtonGroup";
import { CustomFieldRow } from "./EwayBillForm";
import { useExtendValidity } from "../../../query-hooks/ClientEwayBill/ExtendValidity/useExtendValidity";
import { useFetchExtendValFormData } from "../../../query-hooks/ClientEwayBill/ExtendValidity/useFetchExtendValFormData";
import { enqueueSnackbar } from "notistack";
import { ExpandMore } from "@mui/icons-material";

const ConsignmentStatuses = [
  {
    label: "In Transit",
    value: "T",
    xsSize: 5.5,
    mdSize: 5.5
  },
  {
    label: "In Movement",
    value: "M",
    xsSize: 6.5,
    mdSize: 6.5
  }
];

const TransitTypes = [
  {
    label: "On Road",
    value: "R",
    xsSize: 4.5,
    mdSize: 4.5
  },
  {
    label: "Warehouse",
    value: "W",
    xsSize: 5,
    mdSize: 5
  },
  {
    label: "Others",
    value: "O",
    xsSize: 2.5,
    mdSize: 2.5
  }
];

const ExtendValidity = ({
  ewayId,
  setUpdateType,
  ewbValidity,
  refetchEwayBillById,
  reset,
  EwayBillLoginShow,
  partBs,
  refetch
}) => {
  const { control, handleSubmit, watch } = useForm();
  const onBackClick = () => {
    setUpdateType("0");
  };

  const { data: formData, isFetching: isFetchingFormData } =
    useFetchExtendValFormData(ewayId);

  const { mutate, isLoading, error, isError } = useExtendValidity();

  const ConsignmentStatusWatch = watch(ExtendValidityKeys.ConsignmentStatus);

  const onExtendValidity = (data) => {
    mutate(
      {
        data: {
          fromPlace: data[ExtendValidityKeys.placeChange],
          fromState: data[ExtendValidityKeys.stateChange],
          fromPincode: data[ExtendValidityKeys.Pincode],
          remainingDistance: data[ExtendValidityKeys.distance],
          reason: data[ExtendValidityKeys.Reason],
          reasonRemark: data[ExtendValidityKeys.Remarks],
          consignmentStatus: data[ExtendValidityKeys.ConsignmentStatus],
          ...{
            ...(data[ExtendValidityKeys.ConsignmentStatus] === "T"
              ? {
                  addressLine1: data[ExtendValidityKeys.addressLine1],
                  addressLine2: data[ExtendValidityKeys.addressLine2],
                  addressLine3: data[ExtendValidityKeys.addressLine3]
                }
              : {
                  transMode: data[ExtendValidityKeys.mode],
                  transDocNo: data[ExtendValidityKeys.transDocNo],
                  transDocDate: moment(data[ExtendValidityKeys.docDate]).format(
                    "YYYY-MM-DD"
                  ),
                  vehicleNo: data[ExtendValidityKeys.vehicleNo]
                  // vehicleType: data[ExtendValidityKeys.vehicleType]
                })
          }
        },
        ewbId: ewayId
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Validity Extended!", { variant: "success" });
          reset();
          refetch();
          refetchEwayBillById();
          setUpdateType("0");
        }
      }
    );
  };

  useEffect(() => {
    if (isError) {
      if (error?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(handleSubmit(onExtendValidity));
      } else {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: "error"
        });
      }
    } // eslint-disable-next-line
  }, [
    error?.response?.data?.message,
    isError,
    EwayBillLoginShow,
    error?.response?.data?.ewbErrorCode
  ]);

  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "20px 20px 120px 20px", md: "25px 25px 100px 25px" }
        }}
        spacing={3}
      >
        <Grid item xs={12}>
          <Accordion
            elevation={0}
            defaultExpanded={true}
            sx={{
              "& .MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "45px"
              }
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    height: "25px",
                    width: "25px",
                    color: "#2773FF"
                  }}
                />
              }
              sx={{
                padding: 0,
                border: "none",
                position: "unset",
                minHeight: 0,
                display: "flex",
                justifyContent: "left",
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                  flexGrow: 1
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                  flexGrow: 1
                }
              }}
            >
              <Grid item xs={4} display={"flex"} alignItems={"center"}>
                <Typography
                  fontWeight={500}
                  fontSize={20}
                  lineHeight={"24.2px"}
                  color={"#2773FF"}
                >
                  {"Part B"}
                </Typography>
              </Grid>
              <Grid item xs={8} display={"flex"} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: 600,
                    lineHeight: { xs: "14px", md: "16.94px" },
                    color: "#2773FF",
                    letterSpacing: "0.002em"
                  }}
                >
                  {`EWB valid till : ${moment(ewbValidity).format(
                    "DD.MM.YYYY"
                  )} ${moment(ewbValidity).format("HH:mm")}`}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                border: "none",
                p: `0px`,
                m: 0
              }}
            >
              <Grid container spacing={3} pt={"10px"}>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "20px" },
                      fontWeight: 500,
                      lineHeight: { xs: "16px", md: "24.2px" },
                      letterSpacing: "0.002em"
                    }}
                  >
                    {"Extend Validity"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <DropDown
                    control={control}
                    isLoading={isFetchingFormData}
                    name={ExtendValidityKeys.Reason}
                    label={ExtendValidity_labels.Reason}
                    options={formData?.reasons}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    control={control}
                    name={ExtendValidityKeys.Remarks}
                    label={ExtendValidity_labels.Remarks}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    control={control}
                    name={ExtendValidityKeys.placeChange}
                    label={ExtendValidity_labels.placeChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    control={control}
                    name={ExtendValidityKeys.Pincode}
                    label={ExtendValidity_labels.Pincode}
                    type="number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <DropDown
                    control={control}
                    isLoading={isFetchingFormData}
                    name={ExtendValidityKeys.stateChange}
                    label={ExtendValidity_labels.stateChange}
                    options={formData?.states}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    control={control}
                    name={ExtendValidityKeys.distance}
                    label={ExtendValidity_labels.distance}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <RadioButtonGroup
                    label={"Consignment Status"}
                    defaultValue={"T"}
                    control={control}
                    name={ExtendValidityKeys.ConsignmentStatus}
                    RadioGroupSx={{ width: "60%" }}
                    options={ConsignmentStatuses.map((item) => ({
                      label: item.label,
                      value: item.value,
                      xsSize: item.xsSize,
                      mdSize: item.mdSize
                    }))}
                    sx={{
                      fontWeight: 600,
                      display: "flex"
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ border: "0.4px solid #B7BEC7" }} />
                </Grid>
                {ConsignmentStatusWatch === "T" ||
                ConsignmentStatusWatch === undefined ? (
                  <>
                    <Grid item xs={12}>
                      <RadioButtonGroup
                        label={ExtendValidity_labels.TransitType}
                        defaultValue={"R"}
                        control={control}
                        name={ExtendValidityKeys.TransitType}
                        RadioGroupSx={{
                          width: "72%",
                          display: "flex",
                          alignItems: "right"
                        }}
                        options={TransitTypes.map((item) => ({
                          label: item.label,
                          value: item.value,
                          xsSize: item.xsSize,
                          mdSize: item.mdSize
                        }))}
                        sx={{
                          fontWeight: 600,
                          display: "flex"
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} container rowSpacing={2}>
                      <Grid item xs={12}>
                        <CustomFieldRow
                          label={ExtendValidity_labels.addressLine1}
                          labelSize={3}
                          childSize={9}
                          notMandotary
                          showColon
                        >
                          <TextInput
                            control={control}
                            name={ExtendValidityKeys.addressLine1}
                          />
                        </CustomFieldRow>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomFieldRow
                          label={""}
                          labelSize={3}
                          childSize={9}
                          notMandotary
                        >
                          <TextInput
                            control={control}
                            name={ExtendValidityKeys.addressLine2}
                          />
                        </CustomFieldRow>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomFieldRow
                          label={""}
                          labelSize={3}
                          childSize={9}
                          notMandotary
                        >
                          <TextInput
                            control={control}
                            name={ExtendValidityKeys.addressLine3}
                          />
                        </CustomFieldRow>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <DropDown
                        control={control}
                        isLoading={isFetchingFormData}
                        name={ExtendValidityKeys.mode}
                        label={ExtendValidity_labels.mode}
                        options={formData?.transportationModes}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DropDown
                        control={control}
                        isLoading={isFetchingFormData}
                        name={ExtendValidityKeys.vehicleType}
                        label={ExtendValidity_labels.vehicleType}
                        options={formData?.vehicleTypes}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        control={control}
                        name={ExtendValidityKeys.vehicleNo}
                        label={ExtendValidity_labels.vehicleNo}
                      />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                      <TextInput
                        control={control}
                        name={ExtendValidityKeys.transDocNo}
                        label={ExtendValidity_labels.transDocNo}
                      />
                    </Grid>
                    <Grid item xs={5} mb={1}>
                      <DateInput
                        control={control}
                        name={ExtendValidityKeys.docDate}
                        label={ExtendValidity_labels.docDate}
                        minDate={new Date("2000-01-01")}
                      />
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  mb={2}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 500,
                      lineHeight: "24.2px",
                      letterSpacing: "0.002em",
                      textAlign: "center"
                    }}
                  >
                    {"Vehicle History"}
                  </Typography>
                </Grid>
                {partBs?.map((item, index) => (
                  <Grid item xs={12} key={index} mb={1}>
                    <VehicleHistory {...item} />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            pb: {
              xs: !ewayId ? "120px" : 0,
              md: !ewayId ? "100px" : 0
            }
          }}
        >
          <Divider sx={{ border: "0.4px solid #B7BEC7" }} />
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        sx={{
          padding: {
            xs: "10px 16px 10px 16px",
            md: 0
          },
          gap: { xs: 1, md: 0 },
          width: { xs: "100%", md: 520 },
          background: { xs: "white", md: "transparent" },
          position: "fixed",
          borderTop: { xs: "1px solid #B7BEC7", md: "none" },
          bottom: 0
        }}
      >
        <Button
          type="button"
          variant="soft"
          fullWidth
          sx={{
            fontWeight: 500,
            fontSize: { xs: 15, md: 24 },
            height: {
              xs: 48,
              md: 81
            },
            borderRadius: { xs: "8px", md: 0 },
            backgroundColor: "#EFF2F7",
            "&:hover": {
              backgroundColor: "#EFF2F7",
              color: "#2773FF"
            }
          }}
          onClick={onBackClick}
        >
          {"Go back"}
        </Button>
        <Button
          type="button"
          variant="contained"
          component={LoadingButton}
          loading={isLoading}
          onClick={handleSubmit(onExtendValidity)}
          fullWidth
          sx={{
            fontWeight: 500,
            fontSize: { xs: 15, md: 24 },
            height: {
              xs: 48,
              md: 81
            },
            background: "#F9A90E",
            "&:hover": {
              backgroundColor: "#F9A90E"
            },
            borderRadius: { xs: "8px", md: 0 },
            whiteSpace: "nowrap"
          }}
        >
          {"Extend Validity"}
        </Button>
      </Box>
    </>
  );
};

export default ExtendValidity;
