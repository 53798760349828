import { getHsn } from "../../inventory/services/index";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchHsn = (checkHsn, hsn) => {
  const queryHsn = useQuery(
    [queryKeys.queryFetchHsn, checkHsn, hsn],
    () => getHsn(hsn),
    {
      enabled: Boolean(checkHsn),
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return queryHsn;
};
