import { Box, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import React from "react";

const ValueByLabel = ({ value, label }) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Typography
        sx={{
          fontSize: { xs: "10px", md: "14px" },
          fontWeight: 600,
          lineHeight: { xs: "12px", md: "16.94px" }
        }}
      >
        {value}
      </Typography>

      <Typography
        sx={{
          fontSize: {xs:"8px",md:"10px"},
          fontWeight: 400,
          lineHeight: {xs:"10px",md:"12.1px"},
          color: "text.disabled"
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

const VehicleHistory = (data) => {
  return (
    <Grid
      container
      columnSpacing={1}
      rowSpacing={3}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        border: "0.4px solid #B7BEC7",
        boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
        borderRadius: "8px",
        padding: "5px 10px 20px 15px",
        position: "relative"
      }}
    >
      <Grid item xs={3}>
        <ValueByLabel
          value={data?.transMode?.displayName}
          label={"Trans Mode"}
        />
      </Grid>
      <Grid item xs={5}>
        <ValueByLabel
          value={`${data?.transDoc || "--"} / ${
            data?.transDocDate
              ? moment(data?.transDocDate).format("DD-MM-YYYY")
              : "--"
          }`}
          label={"Trans Doc. No & Date"}
        />
      </Grid>
      <Grid item xs={4}>
        <ValueByLabel value={data?.fromPlace} label={"From Place"} />
      </Grid>
      <Grid item xs={3}>
        <ValueByLabel value={data?.vehicleNo} label={"Vehicle Number"} />
      </Grid>
      <Grid item xs={5}>
        <ValueByLabel
          value={`${moment(data?.updDate).format("DD-MM-YYYY")} ${moment(
            data?.updDate
          ).format("HH:mm")}`}
          label={"Updated Date"}
        />
      </Grid>
      <Grid item xs={4}>
        <ValueByLabel
          value={`${data?.createdBy?.firstName || "--"} ${
            data?.createdBy?.lastName || ""
          } `}
          label={"Entered By"}
        />
      </Grid>
    </Grid>
  );
};

export default VehicleHistory;
