import * as yup from "yup";

import { Box, Button, CircularProgress, Grid, IconButton } from "@mui/material";
import { error_msg, eWayBills_labels } from "../../constants";

import CustomTable from "../../shared/customTable";
import { eWayBills_filterKeys } from "../../constants/formKeys";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  currencyOnlyFormatter,
  stringCapitalization
} from "../../shared/utils";
import moment from "moment";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { getCustomCell } from "../../purchase/pages/PurchaseOrders";
import EwayBillForm from "../components/EwayBills/EwayBillForm";
import { useDrawer } from "../../shared/customSideDrawer";
import { useCustomDialog } from "../../shared/customDialog";
import EwayBillLogin from "../components/EwayBills/EwayBillLogin";
import EwayBillFilter from "../components/EwayBills/EwayBillFilter";
import { CalendarTodayOutlined, TuneOutlined } from "@mui/icons-material";
import DateInput from "../../shared/formElements/DateInput";
import { MobileEwayBillTable } from "../components/EwayBills/MobileEwayBillTable";
import { useInView } from "react-intersection-observer";
import { useFetchAllEwayBills } from "../../query-hooks/ClientEwayBill/EwayBillDashboard/useFetchAllEwayBills";
import { enqueueSnackbar } from "notistack";
import { useSyncDataByDate } from "../../query-hooks/ClientEwayBill/EwayBillDashboard/useSyncDataByDate";
import { useSyncOtherDataByDate } from "../../query-hooks/ClientEwayBill/EwayBillDashboard/useSyncOtherDataByDate";
import DropDown from "../../shared/formElements/DropDown";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";

const syncSchema = yup.object({
  [eWayBills_filterKeys.ewayBillData]: yup
    .string()
    .required(error_msg.required),
  [eWayBills_filterKeys.date]: yup.string().required(error_msg.required)
});

const getColumns = (CreateEwayBill) => {
  return [
    {
      Header: "Ewaybill No.",
      accessor: "EwaybillNo",
      width: 135,
      Cell: ({ cell, value }) => {
        return (
          <Button
            variant="link"
            fontSize={14}
            color={"primary"}
            sx={{ textDecoration: "underline" }}
            onClick={() => CreateEwayBill(cell.row.original?._id)}
          >
            {value}
          </Button>
        );
      }
    },
    {
      Header: "Ewaybill Date",
      accessor: "EwaybillDate",
      width: 145,
      Cell: ({ value }) => {
        return value ? (
          <Box display={"flex"} justifyContent={"center"} textAlign={"center"}>
            {moment(value).format("DD MMM YYYY")}
            <br />
            {moment(value).format("hh:mm A")}
          </Box>
        ) : (
          "--"
        );
      }
    },
    {
      Header: "Validity",
      accessor: "Validity",
      width: 120,
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} justifyContent={"center"} textAlign={"center"}>
            {moment(value).format("DD MMM YYYY")}
            <br />
            {moment(value).format("hh:mm A")}
          </Box>
        );
      }
    },
    {
      Header: "Status",
      accessor: "Status",
      width: 135,
      Cell: ({ value }) => {
        return <StyledChip label={stringCapitalization(value)} />;
      }
    },
    {
      Header: "Movement Type",
      accessor: "MovementType",
      width: 160
    },
    {
      Header: "Doc. No.",
      accessor: "DocNo",
      width: 120,
      Cell: ({ row, value }) => {
        return getCustomCell(value, row.original.orderDate, ``, null, {
          alignItems: "center"
        });
      }
    },
    {
      Header: "GSTIN",
      accessor: "gstin"
    },
    {
      Header: "Value of Goods",
      accessor: "ValueofGoods",
      width: 155
    },
    {
      Header: "Vehicle No.",
      accessor: "VehicleNo"
    },
    {
      Header: "Transporter",
      accessor: "Transporter"
    }
  ];
};

const getRows = (rows = [], CreateEwayBill) => {
  return rows.map((row) => ({
    _id: row?._id,
    EwaybillNo: row?.ewbNo,
    EwaybillDate: row?.genDate,
    Validity: row?.ewbValidity,
    Status: row?.status?.displayName,
    MovementType: row?.movementType?.displayName || "--",
    SubSupplyType: row?.subSupplyType?.displayName,
    DocNo: row?.docNo,
    orderDate: row?.docDate ? moment(row?.docDate).format("DD MMM YYYY") : "--",
    gstin:
      row?.movementType?.displayName === "Outward"
        ? row?.toGstin
        : row?.fromGstin,
    ValueofGoods: `Rs. ${currencyOnlyFormatter(
      parseFloat(row?.totInvValue).toFixed(2)
    )}`,
    VehicleNo: row?.vehicleNo,
    Transporter: row?.transporterName || "--",
    CreateEwayBill: CreateEwayBill
  }));
};

const EWaybills = () => {
  // const [params, setParams] = useState();
  const [dateParams, setDateParams] = useState({
    myDate: null,
    otherDate: null
  });
  const { control, watch, reset, handleSubmit, setValue } = useForm({
    resolver: yupResolver(syncSchema)
  });
  const { showDrawer, hideDrawer } = useDrawer();
  const { showDialog, hideDialog } = useCustomDialog();

  const { ref, inView } = useInView();

  const ewayBillDataWatch = watch(eWayBills_filterKeys.ewayBillData);
  const ewayBillDateWatch = watch(eWayBills_filterKeys.date);

  const isSyncByMe = Boolean(ewayBillDataWatch)
    ? ewayBillDataWatch === "mySelf"
    : true;

  const {
    isFetching: isSyncing,
    error: syncError,
    isError: isSyncError,
    isSuccess: isSyncSuccess,
    refetch: refetchSync
  } = useSyncDataByDate(isSyncByMe, dateParams?.myDate);

  const {
    isFetching: isOtherSyncing,
    error: otherSyncError,
    isError: isOtherSyncError,
    isSuccess: isOtherSyncSuccess,
    refetch: refetchOtherSync
  } = useSyncOtherDataByDate(!isSyncByMe, dateParams?.otherDate);

  const {
    data,
    isFetched,
    hasNextPage,
    fetchNextPage,
    refetch,
    isError,
    error
  } = useFetchAllEwayBills();

  const EwayBillLoginShow = useCallback(
    (autoSubmit) => {
      showDialog({
        component: (
          <EwayBillLogin hideDialog={hideDialog} autoSubmit={autoSubmit} />
        ),
        size: "xs",
        closeIcon: true
      });
    },
    [showDialog, hideDialog]
  );

  const CreateEwayBill = useCallback(
    (EwayId) => {
      showDrawer({
        component: (
          <EwayBillForm
            hideDrawer={hideDrawer}
            EwayBillLoginShow={EwayBillLoginShow}
            EwayId={EwayId || null}
            refetch={refetch}
          />
        ),
        closeIcon: false
      });
    },
    [hideDrawer, showDrawer, EwayBillLoginShow, refetch]
  );

  const EWaybillsTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns(CreateEwayBill)}
        data={
          isFetched
            ? data?.pages?.flatMap((page) =>
                getRows(page?.eWayBills, CreateEwayBill)
              ) ?? []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={MobileEwayBillTable}
      />
    ),
    // eslint-disable-next-line
    [data?.pages, isFetched]
  );

  useEffect(() => {
    if (
      ewayBillDataWatch === "others" &&
      ewayBillDateWatch !== "" &&
      ewayBillDateWatch !== undefined
    ) {
      const dateValue = moment(ewayBillDateWatch).startOf("day"); // Normalize to date only
      const today = moment().startOf("day");
      const pastThreeDays = moment().subtract(3, "days").startOf("day");

      if (dateValue.isSameOrAfter(pastThreeDays) && dateValue.isBefore(today)) {
        return;
      } else {
        setValue(eWayBills_filterKeys.date, "");
      }
    }
  }, [ewayBillDataWatch, ewayBillDateWatch, setValue]);

  const onGetDataClick = (data) => {
    const filterData = {
      ...{
        ...(isSyncByMe
          ? {
              myDate: { [`date`]: moment(data?.date).format("YYYY-MM-DD") },
              otherDate: null
            }
          : {
              myDate: null,
              otherDate: { [`date`]: moment(data?.date).format("YYYY-MM-DD") }
            })
      }
    };
    setDateParams(filterData);
    hideDrawer();
  };

  const onClear = () => {
    reset({
      date: "",
      ewayBillData: ""
    });
    setDateParams({
      myDate: null,
      otherDate: null
    });
  };

  const FilterComponent = (
    <Box>
      <EwayBillFilter
        control={control}
        isSyncByMe={isSyncByMe}
        onGetData={handleSubmit(onGetDataClick)}
        onApply={() => console.log("applied")}
        onClear={onClear}
        isFetching={false}
        isLoading={false}
        isSyncing={isSyncing || isOtherSyncing}
        filters={[
          {
            type: "DropDown",
            name: eWayBills_filterKeys.supplyType,
            label: eWayBills_labels.supplyType,
            placeholder: "Outward - Invoice",
            allowSearch: false,
            options: [],
            filterCount: 45
          },
          {
            type: "DropDown",
            name: eWayBills_filterKeys.status,
            label: eWayBills_labels.status,
            placeholder: "Active",
            allowSearch: false,
            options: [],
            filterCount: 3
          },
          {
            type: "TextInput",
            name: eWayBills_filterKeys.gstin,
            label: eWayBills_labels.gstin,
            placeholder: "36xxxxxxxxx"
          }
        ]}
      />
    </Box>
  );

  useEffect(() => {
    if (isSyncError) {
      if (syncError?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(refetchSync);
      } else {
        enqueueSnackbar(syncError?.response?.data?.message, {
          variant: "error"
        });
        setDateParams({ myDate: null, otherDate: null });
      }
    }
    if (isOtherSyncError) {
      if (otherSyncError?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(refetchOtherSync);
      } else {
        enqueueSnackbar(otherSyncError?.response?.data?.message, {
          variant: "error"
        });
        setDateParams({ myDate: null, otherDate: null });
      }
    }
    // eslint-disable-next-line
  }, [
    syncError?.response?.data?.message,
    otherSyncError?.response?.data?.message,
    isSyncError,
    isOtherSyncError
  ]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  useEffect(() => {
    if (isSyncSuccess) {
      onClear();
      refetch();
      enqueueSnackbar("e-Way-Bills Updated", {
        variant: "success"
      });
    } else if (isOtherSyncSuccess) {
      onClear();
      refetch();
      enqueueSnackbar("e-Way-Bills Updated", {
        variant: "success"
      });
    }
  }, [isSyncSuccess, isOtherSyncSuccess, refetch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [error?.response?.data?.message, isError]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        mb: { xs: 9, md: 0 }
      }}
    >
      <Box
        flexDirection={"column"}
        gap={2}
        sx={{
          display: { xs: "none", md: "flex" },
          position: "sticky",
          top: "64px",
          zIndex: 1000,
          background: "#fff",
          padding: "16px 0"
        }}
      >
        <Grid item xs={12} textAlign={"end"}>
          <Button
            variant="link"
            disableRipple
            onClick={() => CreateEwayBill(null)}
          >
            {eWayBills_labels.createEWaybillButton}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {FilterComponent}
        </Grid>
      </Box>
      <Grid
        container
        rowSpacing={2}
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          position: "sticky",
          top: "60px",
          zIndex: 1,
          background: "#fff",
          padding: "10px 20px"
        }}
      >
        <Grid item xs={8}>
          <DropDown
            control={control}
            name={eWayBills_filterKeys.ewayBillData}
            label={eWayBills_labels.ewayBillData}
            options={[
              { name: "mySelf", displayName: "Generated By Me" },
              { name: "others", displayName: "Generated By Others" }
            ]}
            placeholder={"Generated By"}
            sx={{ borderRadius: 54 }}
            placeholderStyle={{
              fontSize: "12px"
            }}
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"end"}>
          <Button
            variant="link"
            disableRipple
            sx={{ fontWeight: 700 }}
            onClick={() => CreateEwayBill(null)}
          >
            {eWayBills_labels.createEWaybillMobileButton}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <DateInput
            control={control}
            name={eWayBills_filterKeys.date}
            minDate={
              isSyncByMe
                ? new Date("2000-01-01")
                : new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
            }
            maxDate={
              isSyncByMe
                ? new Date()
                : new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
            }
            endIcon={CalendarTodayOutlined}
            placeholder={"date"}
          />
        </Grid>
        <Grid item xs={2} ml={1}>
          <Button
            variant="contained"
            sx={{ minHeight: "40px" }}
            onClick={handleSubmit(onGetDataClick)}
            component={LoadingButton}
            loading={isSyncing || isOtherSyncing}
          >
            {"Get"}
          </Button>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} display={"flex"} justifyContent={"right"}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            onClick={() => {
              showDrawer({
                title: "Search and Filters",
                height: "fit-content",
                component: FilterComponent
              });
            }}
          >
            <TuneOutlined color="primary" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>
        {EWaybillsTable}
        {hasNextPage && (
          <Grid
            item
            xs={12}
            ref={ref}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default EWaybills;
