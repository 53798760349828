import { getCancelEwbFormData } from "../../../inventory/services/index";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchCancelEwbFormData = (ewbId) => {
  const queryCancelEwbFormData = useQuery(
    [queryKeys.queryFetchCancelEwbFormData, ewbId],
    () => getCancelEwbFormData(ewbId),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryCancelEwbFormData;
};
