import { queryKeys } from "../../constants/queryKeys";
import { getEwayBillByNo } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchEwayBillByNo = (allowFetch, ewayBillNo) => {
  const ewayBillByNo = useQuery(
    [queryKeys.queryFetchEwayBillByNo, allowFetch, ewayBillNo],
    () => getEwayBillByNo(ewayBillNo),
    {
      enabled: allowFetch && Boolean(ewayBillNo),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return ewayBillByNo;
};
