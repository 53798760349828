import * as yup from "yup";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { CustomTabs } from "../../shared/customTab/CustomTabs";
import DateInput from "../../shared/formElements/DateInput";
import DropDown from "../../shared/formElements/DropDown";
import OrderReport from "../components/OrderReport";
import PurchaseOrderTable from "../components/Purchase/PurchaseOrderTable";
import PurchaseReturnsTable from "../components/Purchase/PurchaseReturnsTable";
import { currencyOnlyFormatter, dropdownOptions } from "../../shared/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchPurchseOrderFormData } from "../../query-hooks/ClientPurchase/PurchaseOrder/useFetchPurchseOrderFormData";
import moment from "moment";
import { error_msg, statusKeys } from "../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreatePurchaseOrder } from "../../query-hooks/ClientPurchase/PurchaseOrder/useCreatePurchaseOrder";
import { enqueueSnackbar } from "notistack";
import appRoutes from "../../constants/appRoutes";
import { useSaveDraftPurchaseOrder } from "../../query-hooks/ClientPurchase/PurchaseOrder/useSaveDraftPurchaseOrder";
import { useFetchPurchaseOrderById } from "../../query-hooks/ClientPurchase/PurchaseOrder/useFetchPurchaseOrderById";
import { useUpdatePurchaseOrderDraft } from "../../query-hooks/ClientPurchase/PurchaseOrder/useUpdatePurchaseOrderDraft";
import { useSubmitPurchaseOrderDraft } from "../../query-hooks/ClientPurchase/PurchaseOrder/useSubmitPurchaseOrderDraft";
import { useFetchPurchaseReturnFormData } from "../../query-hooks/ClientPurchase/PurchaseReturn/useFetchPurchaseReturnFormData";
import { useFetchPOListFormData } from "../../query-hooks/ClientPurchase/PurchaseReturn/useFetchPOListFormData";
import { useCreatePurchaseReturn } from "../../query-hooks/ClientPurchase/PurchaseReturn/useCreatePurchaseReturn";
import { useSaveDraftPurchaseReturn } from "../../query-hooks/ClientPurchase/PurchaseReturn/useSaveDraftPurchaseReturn";
import { useUpdatePurchaseReturnDraft } from "../../query-hooks/ClientPurchase/PurchaseReturn/useUpdatePurchaseReturnDraft";
import { useSubmitPurchaseReturnDraft } from "../../query-hooks/ClientPurchase/PurchaseReturn/useSubmitPurchaseReturnDraft";
import { useCustomDialog } from "../../shared/customDialog";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import { useFetchPurchaseReturnOrderById } from "../../query-hooks/ClientPurchase/PurchaseReturn/useFetchPurchaseReturnOrderById";
import { useDeletePRDraft } from "../../query-hooks/ClientPurchase/PurchaseReturn/useDeletePRDraft";
import { useDeletePODraft } from "../../query-hooks/ClientPurchase/PurchaseOrder/useDeletePODraft";
import { useUpdatePurchaseOrder } from "../../query-hooks/ClientPurchase/PurchaseOrder/useUpdatePurchaseOrder";
import { useCancelPurchaseOrder } from "../../query-hooks/ClientPurchase/PurchaseOrder/useCancelPurchaseOrder";
import { useUpdatePurchaseReturn } from "../../query-hooks/ClientPurchase/PurchaseReturn/useUpdatePurchaseReturn";
import { useCancelPurchaseReturn } from "../../query-hooks/ClientPurchase/PurchaseReturn/useCancelPurchaseReturn";
import HistoryTable from "../components/HistoryTable";
import { useReactToPrint } from "react-to-print";
import { useDrawer } from "../../shared/customSideDrawer";
import { CalendarTodayOutlined, TuneOutlined } from "@mui/icons-material";
import { isMobileDevice } from "../../App";
import TextArea from "../../shared/formElements/TextArea";
import MobileOrderReport from "../components/MobileOrderReport";
import SuggestionInput from "../../shared/formElements/SuggestionInput";
import TextInput from "../../shared/formElements/TextInput";

const billingAddressData = (data) => {
  if (!Boolean(data)) return {};
  const addressObject = {
    customerName: data?.contact?.name,
    companyName: data?.companyName,
    address: `${data?.warehouse?.billingAddress?.address}, ${data?.warehouse?.billingAddress?.area}, ${data?.warehouse?.billingAddress?.city}, ${data?.warehouse?.billingAddress?.state}, ${data?.warehouse?.billingAddress?.pincode}`,
    gst: data?.warehouse?.gstNo ? `GSTIN : ${data?.warehouse?.gstNo}` : ""
  };
  return addressObject;
};

export const vendorAddressData = (data) => {
  if (!Boolean(data)) return {};
  const addressObject = {
    customerName: data?.name,
    companyName: data?.companyName,
    address: `${data?.billing?.address?.address}, ${data?.billing?.address?.area}, ${data?.billing?.address?.city}, ${data?.billing?.address?.state}, ${data?.billing?.address?.pincode}`,
    gst: data?.billing?.gstNo ? `GSTIN : ${data?.billing?.gstNo}` : ""
  };
  return addressObject;
};

export const shipToAddressData = (data) => {
  if (!Boolean(data)) return {};
  const addressObject = {
    customerName: data?.code,
    companyName: data?.companyName ? data?.companyName : data?.name ?? "",
    address: `${data?.address?.address}, ${data?.address?.area}, ${data?.address?.city}, ${data?.address?.state}, ${data?.address?.pincode}`
  };
  return addressObject;
};

export const TotalBreakup = ({
  subTotal,
  cgst,
  sgst,
  igst,
  cess,
  nonAdvol,
  total,
  control,
  gstType = false,
  showCess = false,
  disableInput = false
}) => {
  const getAmountRow = (label, value, showOthers = false, children) => {
    return (
      <Grid item xs={12} container display={"flex"} alignItems={"center"}>
        <Grid item xs={5} fontSize={14} fontWeight={600} color={"#607088"}>
          {label}
        </Grid>
        <Grid item xs={1} fontSize={14} fontWeight={600} color={"#607088"}>
          :
        </Grid>
        {showOthers ? (
          <Grid item xs={6}>
            {children}
          </Grid>
        ) : (
          <Grid item xs={6} fontSize={13}>
            {value ? currencyOnlyFormatter(parseFloat(value).toFixed(2)) : "--"}
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      {getAmountRow(showCess ? "Taxable Amount" : "Sub Total", subTotal)}
      {gstType ? (
        <>
          {getAmountRow("CGST", cgst)}
          {getAmountRow("SGST", sgst)}
        </>
      ) : (
        getAmountRow("IGST", igst)
      )}
      {showCess && (
        <>
          {getAmountRow("Cess Advol", cess)}
          {getAmountRow("Cess NonAdvol", nonAdvol)}
          {getAmountRow(
            "Others",
            null,
            true,
            <TextInput
              control={control}
              name={"others"}
              type="number"
              sx={{ maxHeight: "30px", maxWidth: "90px" }}
              disabled={disableInput}
            />
          )}
        </>
      )}
      {getAmountRow("Total Amount", total)}
    </Grid>
  );
};

export const reportLabel1 = (label, value) => {
  return (
    <Box display={"flex"} gap={0.5} alignItems={"center"}>
      <Typography
        letterSpacing={"0.2%"}
        color={"text.secondary"}
        fontWeight={500}
      >
        {label}
      </Typography>
      <Typography fontSize={14} fontWeight={600}>
        {value}
      </Typography>
    </Box>
  );
};

export const reportLabel2 = (label, value) => {
  return (
    <Box display={"flex"} gap={0.5} alignItems={"center"}>
      <Typography
        fontSize={14}
        lineHeight={"16.94px"}
        color={"text.primary"}
        fontWeight={600}
      >
        {label}
      </Typography>
      <Typography fontSize={13} lineHeight={"18px"} color={"text.secondary"}>
        {value}
      </Typography>
    </Box>
  );
};

export const OtherDetailsComp = ({ label, value }) => {
  return (
    <Grid item xs={12} container rowSpacing={2}>
      <Grid item xs={5} display={"flex"} justifyContent={"left"}>
        <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography fontSize={14} fontWeight={400} lineHeight={"16.94px"}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const PurchaseButtonGroup = ({
  updateOrderButton,
  isDraft,
  inPlacedState,
  inDraftState,
  onDeleteClick,
  onCancel,
  onUpdate,
  onModify,
  onSaveDraft,
  onSubmitClick
}) => {
  return (
    <>
      <Grid item xs={12} container rowSpacing={1}>
        {!updateOrderButton && isDraft && (
          <>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="soft"
                onClick={onModify}
                sx={{ minHeight: "48px" }}
              >
                Modify
              </Button>
            </Grid>
            {inPlacedState && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ minHeight: "48px", color: "error.main" }}
                  onClick={onCancel}
                >
                  Cancel Order
                </Button>
              </Grid>
            )}
            {inDraftState && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ minHeight: "48px", color: "error.main" }}
                  onClick={onDeleteClick}
                >
                  Delete Draft
                </Button>
              </Grid>
            )}
          </>
        )}
        {updateOrderButton && (
          <>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="soft"
                sx={{ minHeight: "48px" }}
                onClick={onUpdate}
              >
                Update Order
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ minHeight: "48px", color: "#46BD7D" }}
                onClick={onModify}
              >
                Reset
              </Button>
            </Grid>
          </>
        )}
        {!isDraft && (
          <>
            {inDraftState && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ minHeight: "48px", color: "#46BD7D" }}
                  onClick={onModify}
                >
                  Reset
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="soft"
                sx={{ minHeight: "48px" }}
                onClick={onSaveDraft}
              >
                Save Draft
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{ minHeight: "48px" }}
                onClick={onSubmitClick}
              >
                Submit
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export const CustomFieldRow = ({
  label,
  isDisabled = false,
  children,
  fullRow = false,
  notMandatory = false
}) => {
  return (
    <Grid item xs={12} lg={fullRow ? 12 : 6} container spacing={2}>
      <Grid
        item
        xs={fullRow ? 3 : 6}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Typography
          fontSize={16}
          fontWeight={500}
          whiteSpace={"nowrap"}
          color={isDisabled && "#607088"}
        >
          {label}
          {!notMandatory && (
            <span style={{ color: isDisabled ? "#607088" : "#F05454" }}>
              {" "}
              *
            </span>
          )}
        </Typography>
      </Grid>
      <Grid item xs={fullRow ? 9 : 6} display={"flex"} alignItems={"center"}>
        {children}
      </Grid>
    </Grid>
  );
};

const PaymentTermsFieldRow = ({
  label,
  control,
  disabled = false,
  advanceOptions = [],
  dueOptions = []
}) => {
  return (
    <CustomFieldRow label={label} fullRow={true} isDisabled={disabled}>
      <Box display={"flex"} gap={4} width={"100%"}>
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <Typography color={"primary"} fontWeight={700} fontSize={13}>
            Advance
          </Typography>
          <Box minWidth={89}>
            <SuggestionInput
              control={control}
              name={"advance"}
              type="number"
              menuItems={advanceOptions}
              disabled={disabled}
              sx={{ maxWidth: "89px" }}
            />
          </Box>
          %
        </Box>
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <Typography color={"error"} fontWeight={700} fontSize={13}>
            Due
          </Typography>
          <Box minWidth={89}>
            <SuggestionInput
              control={control}
              name={"due"}
              menuItems={dueOptions}
              disabled={disabled}
              sx={{ maxWidth: "89px" }}
            />
          </Box>
          <Typography fontSize={13} whiteSpace={"nowrap"} marginLeft={2}>
            Days from receipt
          </Typography>
        </Box>
      </Box>
    </CustomFieldRow>
  );
};

export const MobilePaymentTermsField = ({
  label,
  control,
  disabled = false,
  advanceOptions = [],
  dueOptions = []
}) => {
  return (
    <Grid container>
      <Grid item xs={12} mb={0.5}>
        <Typography
          fontSize={14}
          fontWeight={400}
          whiteSpace={"nowrap"}
          color={disabled && "#607088"}
        >
          {label}
          <span style={{ color: disabled ? "#607088" : "#F05454" }}> *</span>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={0.5}
          alignItems={"left"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <SuggestionInput
              control={control}
              name={"advance"}
              type="number"
              menuItems={advanceOptions}
              disabled={disabled}
              sx={{ minWidth: 89 }}
            />
            %
          </Box>
          <Typography color={"primary"} fontWeight={700} fontSize={13}>
            Advance
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} pl={1}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={0.5}
          alignItems={"left"}
        >
          <SuggestionInput
            control={control}
            name={"due"}
            menuItems={dueOptions}
            disabled={disabled}
            sx={{ minWidth: 89 }}
          />
          <Typography color={"error"} fontWeight={700} fontSize={13}>
            Due
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} pt={2}>
        <Typography fontSize={13} whiteSpace={"nowrap"}>
          Days from receipt
        </Typography>
      </Grid>
    </Grid>
  );
};

export const generateFields = (field, control, inSameRow = false, props) => {
  if (typeof field === "object") {
    if (field.type === "dropdown") {
      return (
        <Fragment key={field.id}>
          <CustomFieldRow label={field.name} isDisabled={field.isDisabled}>
            <DropDown
              control={control}
              name={field.id}
              options={field.options}
              placeholder={field.placeholder}
              disabled={field.isDisabled}
              isLoading={field?.isLoading ?? false}
              unSelectText={field?.unSelectText ?? ""}
              availUnselect={field?.availUnselect ?? false}
              allowSearch={field?.allowSearch ?? false}
            />
          </CustomFieldRow>
          {!inSameRow && <Grid item xs={6} />}
        </Fragment>
      );
    }
    if (field.type === "date") {
      return (
        <Fragment key={field.id}>
          <CustomFieldRow label={field.name} isDisabled={field.isDisabled}>
            <DateInput
              control={control}
              name={field.id}
              placeholder={field.placeholder}
              disabled={field.isDisabled}
            />
          </CustomFieldRow>
          {!inSameRow && <Grid item xs={6} />}
        </Fragment>
      );
    }
  }
  return field.id === "paymentTerms" ? (
    <PaymentTermsFieldRow
      label={field.name}
      control={control}
      key={field.id}
      disabled={field.isDisabled}
      advanceOptions={props?.advancePercents}
      dueOptions={props?.dueDays}
    />
  ) : (
    field?.map((item) => generateFields(item, control, true))
  );
};

export const generateMobileFields = (field, control, props) => {
  if (typeof field === "object") {
    if (field.type === "dropdown") {
      return (
        <Grid item xs={8} key={field.id}>
          <DropDown
            control={control}
            name={field.id}
            label={field.name}
            options={field.options}
            placeholder={field.placeholder}
            disabled={field.isDisabled}
            isLoading={field?.isLoading ?? false}
            unSelectText={field?.unSelectText ?? ""}
            availUnselect={field?.availUnselect ?? false}
            allowSearch={field?.allowSearch ?? false}
            mandatoryField
          />
        </Grid>
      );
    }
    if (field.type === "date") {
      return (
        <Grid item xs={5} key={field.id}>
          <DateInput
            control={control}
            label={field.name}
            name={field.id}
            placeholder={field.placeholder}
            disabled={field.isDisabled}
            endIcon={CalendarTodayOutlined}
            mandatoryField
          />
        </Grid>
      );
    }
  }
  return field.id === "paymentTerms" ? (
    <Grid item xs={12} key={field.id}>
      <MobilePaymentTermsField
        label={field.name}
        control={control}
        disabled={field.isDisabled}
        advanceOptions={props?.advancePercents}
        dueOptions={props?.dueDays}
      />
    </Grid>
  ) : (
    field?.map((item) => generateMobileFields(item, control))
  );
};

export const getDraftItems = (prItems, formDataItems, isDraft) => {
  const skuSet = new Set(prItems?.map((item) => item.sku));
  if (isDraft) {
    const filterFormDataItems = formDataItems?.filter(
      (item) => !skuSet.has(item.sku)
    );
    return [...prItems, ...filterFormDataItems];
  }
  return [...prItems];
};

const POSchema = (createByDraft) => {
  const baseSchema = {
    orderType: yup.string().required(error_msg.required),
    shipping: yup.string().required(error_msg.required),
    advance: yup.string().required(error_msg.required),
    due: yup.string().required(error_msg.required),
    dateOfDelivery: yup.string().required(error_msg.required),
    newOrders: yup.array().of(
      yup.object().shape({
        _id: yup.string().required(error_msg.required),
        rate: yup.string().required(error_msg.required),
        quantity: yup.string().required(error_msg.required)
      })
    )
  };

  if (!createByDraft) {
    baseSchema["vendorName"] = yup.string().required(error_msg.required);
    baseSchema["deliveryWarehouse"] = yup.string().required(error_msg.required);
  }

  return yup.object(baseSchema);
};

const PRSchema = yup.object({
  reasonForReturn: yup.string().required(error_msg.required),
  newOrders: yup.array().of(
    yup.object().shape({
      _id: yup.string().required(error_msg.required),
      returnedQuantity: yup.string().required(error_msg.required)
    })
  )
});

const CreateOrder = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [updateOrderButton, setUpdateOrderButton] = useState(false);
  const selectedRowsRef = useRef(null);
  const { orderId, manageOrderType, returnId, createReturnId } = useParams();
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);
  const [isDraft, setIsDraft] = useState(Boolean(orderId) || Boolean(returnId));
  const [checkOrderType, setCheckOrderType] = useState(manageOrderType ?? "");
  const [costBreakUp, setCostBreakUp] = useState({
    gstType: false,
    subtotal: "",
    cgst: "",
    sgst: "",
    igst: "",
    total: ""
  });

  const { control, watch, reset, handleSubmit, setValue } = useForm({
    defaultValues: {
      orderType: manageOrderType ?? "",
      newOrders: []
    },
    resolver: yupResolver(
      Boolean(checkOrderType === "purchaseReturns") || Boolean(createReturnId)
        ? PRSchema
        : POSchema(Boolean(orderId))
    )
  });

  const { showDialog, hideDialog } = useCustomDialog();
  const { showDrawer, hideDrawer } = useDrawer();

  const orderTypeWatch = watch("orderType");

  const componentRef = useRef();

  const { data: CreateOrderForm, isFetching: isCreateOrderFormFetching } =
    useFetchPurchseOrderFormData(orderTypeWatch);

  const {
    data: POForm,
    isFetching: isPOFormFetching,
    refetch: refetchPOForm
  } = useFetchPurchaseOrderById(
    orderId,
    returnId,
    Boolean(manageOrderType === "purchaseOrder")
  );

  const { data: POListFormData, isFetching: isPOListFetching } =
    useFetchPOListFormData(orderTypeWatch);

  const orderRefWatch = watch("orderRef");

  const {
    data: purchaseReturnData,
    isFetching: isPurchaseReturnDataFetching,
    refetch: refetchPRForm
  } = useFetchPurchaseReturnOrderById(
    orderId,
    returnId,
    Boolean(manageOrderType === "purchaseReturns")
  );

  const {
    data: CreateReturnFormData,
    isFetching: isReturnFormDataFetching,
    refetch: refetchPRCreateFrom
  } = useFetchPurchaseReturnFormData(
    orderId ?? orderRefWatch,
    // returnId
    //   ? !isPurchaseReturnDataFetching
    //     ? purchaseReturnData?.purchaseReturn?.status?.name
    //     : "Dispatched"
    //   : "",
    orderTypeWatch
  );

  const {
    mutate: createPurchaseOrder,
    isLoading: isCreating,
    isError: isCreateError,
    error: createError
  } = useCreatePurchaseOrder();

  const {
    mutate: updatePurchaseOrder,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError
  } = useUpdatePurchaseOrder();

  const {
    mutate: saveDraftPurchaseOrder,
    isLoading: isSavingDraft,
    isError: isSaveDraftError,
    error: saveDraftError
  } = useSaveDraftPurchaseOrder();

  const {
    mutate: updatePurchaseOrderDraft,
    isLoading: isUpdatingDraft,
    isError: isUpdateDraftError,
    error: updateDraftError
  } = useUpdatePurchaseOrderDraft();

  const {
    mutate: submitPurchaseOrderDraft,
    isLoading: isSubmittingDraft,
    isError: isSubmitDraftError,
    error: submitDraftError
  } = useSubmitPurchaseOrderDraft();

  const {
    mutate: cancelPurchaseOrder,
    isError: isCancelError,
    error: cancelError
  } = useCancelPurchaseOrder();

  const {
    mutate: deletePODraft,
    isError: isDeletePODraftError,
    error: deletePODraftError
  } = useDeletePODraft();

  const {
    mutate: createPurchaseReturn,
    isLoading: isCreatingPR,
    isError: isCreatePRError,
    error: createPRError
  } = useCreatePurchaseReturn();

  const {
    mutate: updatePurchaseReturn,
    isLoading: isUpdatingPR,
    isError: isUpdatePRError,
    error: updatePRError
  } = useUpdatePurchaseReturn();

  const {
    mutate: saveDraftPurchaseReturn,
    isLoading: isSavingPRDraft,
    isError: isSaveDraftPRError,
    error: saveDraftPRError
  } = useSaveDraftPurchaseReturn();

  const {
    mutate: updatePurchaseReturnDraft,
    isLoading: isUpdatingPRDraft,
    isError: isUpdateDraftPRError,
    error: updateDraftPRError
  } = useUpdatePurchaseReturnDraft();

  const {
    mutate: submitPurchaseReturnDraft,
    isLoading: isSubmittingPRDraft,
    isError: isSubmitDraftPRError,
    error: submitDraftPRError
  } = useSubmitPurchaseReturnDraft();

  const {
    mutate: deletePRDraft,
    isError: isDeletePRDraftError,
    error: deletePRDraftError
  } = useDeletePRDraft();

  const {
    mutate: cancelPurchaseReturn,
    isError: isCancelPRError,
    error: cancelPRError
  } = useCancelPurchaseReturn();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "newOrders"
  });

  useEffect(() => {
    if (!isPOFormFetching) {
      Boolean(POForm?.orderHistories?.length) &&
        setActiveTab(POForm?.orderHistories?.length);
      setValue("advance", POForm?.purchaseOrder?.advancePercent);
      setValue("due", POForm?.purchaseOrder?.dueDays);
      setValue("shipping", POForm?.purchaseOrder?.shipping?.name);
      setValue("dateOfDelivery", new Date(POForm?.purchaseOrder?.deliveryDate));
      POForm?.purchaseOrder?.tnc &&
        setValue("otherTNC", POForm?.purchaseOrder?.tnc);
      POForm?.purchaseOrder?.items?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item.rate,
          quantity: item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName ?? "",
          brandName: item?.skuData?.brandName ?? "",
          categoryName: item?.skuData?.categoryName ?? "",
          unit: item?.skuData.unit,
          gstRegime: item?.skuData?.gstRegime ?? 0,
          hsn: item?.skuData?.hsn ?? "",
          image: item?.skuData?.image
        });
      });
    } // eslint-disable-next-line
  }, [isPOFormFetching]);

  useEffect(() => {
    if (!isPurchaseReturnDataFetching) {
      Boolean(purchaseReturnData?.orderHistories?.length) &&
        setActiveTab(purchaseReturnData?.orderHistories?.length);
      setValue("orderRef", orderId);
      setValue(
        "reasonForReturn",
        purchaseReturnData?.purchaseReturn?.returnReason?.name
      );
      purchaseReturnData?.purchaseReturn?.tnc &&
        setValue("otherTNC", purchaseReturnData?.purchaseReturn?.tnc ?? "");
      const purchaseReturnItems = getDraftItems(
        purchaseReturnData?.purchaseReturn?.items ?? [],
        CreateReturnFormData?.purchaseOrder?.items ?? [],
        Boolean(
          purchaseReturnData?.purchaseReturn?.status?.name === statusKeys.Draft
        )
      );
      purchaseReturnItems?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item.rate,
          quantity: item.orderQuantity ?? item.quantity,
          returnedQuantity:
            index + 1 > purchaseReturnData?.purchaseReturn?.items?.length
              ? 0
              : item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          gstRegime: item?.skuData?.gstRegime ?? 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image
        });
      });
    } // eslint-disable-next-line
  }, [isPurchaseReturnDataFetching]);

  useEffect(() => {
    if (!Boolean(returnId) && !isReturnFormDataFetching && orderRefWatch) {
      setValue("newOrders", []);
      CreateReturnFormData?.purchaseOrder?.items?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item.rate,
          quantity: item.quantity,
          returnedQuantity: 0,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          gstRegime: item?.skuData?.gstRegime ?? 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image
        });
      });
    } // eslint-disable-next-line
  }, [isReturnFormDataFetching, JSON.stringify(orderRefWatch), returnId]);

  const deliveryDateWatch = watch("dateOfDelivery");

  const vendorWatch = watch("vendorName");

  const deliveryWarehouseWatch = watch("deliveryWarehouse");

  const paymentTermsWatch = watch(["advance", "due"]);

  const shipping = watch(["shipping"]);

  const reasonOfReturn = watch("reasonForReturn");

  const rateWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.rate`
  );

  const quantityWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.quantity`
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: returnId
      ? purchaseReturnData?.purchaseReturn?.code ?? "PR-Draft"
      : POForm?.purchaseOrder?.code ?? "PO-Draft"
  });

  const orderTypeFilters = {
    orderType: {
      purchaseOrder: {
        name: "Purchase Order",
        fields: [
          {
            name: "Vendor Name",
            id: "vendorName",
            isDisabled: Boolean(orderId),
            type: "dropdown",
            placeholder: orderId
              ? POForm?.purchaseOrder?.vendorData?.companyName
              : "Select Vendor",
            allowSearch: true,
            options: dropdownOptions(
              CreateOrderForm?.vendors,
              "_id",
              "companyName"
            )
          },
          {
            name: "Delivery Warehouse",
            id: "deliveryWarehouse",
            isDisabled: Boolean(orderId),
            type: "dropdown",
            placeholder: orderId
              ? POForm?.purchaseOrder?.clientData?.warehouse?.code
              : "Select WH - Code",
            options: dropdownOptions(CreateOrderForm?.warehouses, "_id", "code")
          },
          [
            {
              name: "Date of Delivery",
              id: "dateOfDelivery",
              isDisabled: Boolean(POForm?.purchaseOrder?.status?.name)
                ? POForm?.purchaseOrder?.status?.name === "Draft"
                  ? !isEditable
                  : true
                : false,
              type: "date",
              placeholder: "Select date"
            },
            {
              name: "Shipping",
              id: "shipping",
              isDisabled: Boolean(POForm?.purchaseOrder?.status?.name)
                ? POForm?.purchaseOrder?.status?.name === "Draft"
                  ? !isEditable
                  : true
                : false,
              type: "dropdown",
              options: CreateOrderForm?.shippings,
              placeholder: "Select shipping"
            }
          ],
          {
            name: "Payment Terms",
            id: "paymentTerms",
            isDisabled: Boolean(POForm?.purchaseOrder?.status?.name)
              ? POForm?.purchaseOrder?.status?.name === "Draft"
                ? !isEditable
                : true
              : false
          }
        ],
        actions:
          !isPOFormFetching &&
          POForm?.purchaseOrder?.status?.name === statusKeys.Draft
            ? ["Add", "Remove", "SaveDraft", "Submit", "Reset"]
            : ["Add", "Remove", "SaveDraft", "Submit"],
        selection: true,
        buyerTitle: "Seller",
        shippingTitle: "Shipto",
        sellerTitle: "Buyer",
        orderInfo: {
          purchaseOrderNo: orderId ? POForm?.purchaseOrder?.code ?? "--" : "--",
          orderDate: orderId
            ? moment(POForm?.purchaseOrder?.orderDate).format("DD-MM-YYYY")
            : "--",
          deliveryBy: orderId
            ? moment(POForm?.purchaseOrder?.deliveryDate).format("DD-MM-YYYY")
            : deliveryDateWatch
            ? moment(deliveryDateWatch).format("DD-MM-YYYY")
            : "--"
        },
        sellerInfo: {
          customerName: `${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`,
          companyName: `${CreateOrderForm?.client?.companyName}`,
          address: `${CreateOrderForm?.client?.billing?.address?.address}, ${CreateOrderForm?.client?.billing?.address?.area}, ${CreateOrderForm?.client?.billing?.address?.city}, ${CreateOrderForm?.client?.billing?.address?.state}, ${CreateOrderForm?.client?.billing?.address?.pincode}`,
          gst: CreateOrderForm?.client?.billing?.gstNo
            ? `GSTIN : ${CreateOrderForm?.client?.billing?.gstNo}`
            : ""
        },
        buyerInfo: orderId
          ? vendorAddressData(POForm?.purchaseOrder?.vendorData)
          : billingAddressData(
              CreateOrderForm?.vendors?.find(
                (obj) => obj["_id"] === vendorWatch
              )
            ),
        shipInfo: orderId
          ? shipToAddressData(POForm?.purchaseOrder?.clientData?.warehouse)
          : shipToAddressData(
              CreateOrderForm?.warehouses?.find(
                (obj) => obj["_id"] === deliveryWarehouseWatch
              )
            )
      },
      purchaseReturns: {
        name: "Purchase Returns",
        fields: [
          {
            name: "Purchase Order Ref",
            id: "orderRef",
            isDisabled: orderId ? true : false,
            type: "dropdown",
            placeholder: "Select order ref",
            allowSearch: true,
            options: isPOListFetching
              ? []
              : dropdownOptions(POListFormData?.purchaseOrders, "_id", "code")
          },
          {
            name: "Warehouse",
            id: "warehouse",
            isDisabled: true,
            type: "dropdown",
            placeholder: orderRefWatch
              ? CreateReturnFormData?.purchaseOrder?.clientData?.warehouse?.code
              : "Select Warehouse",
            options: []
          },
          {
            name: "Vendor Name",
            id: "vendorName",
            isDisabled: true,
            type: "dropdown",
            allowSearch: true,
            placeholder: orderRefWatch
              ? CreateReturnFormData?.purchaseOrder?.vendorData?.companyName
              : "Select Vendor Name",
            options: []
          },
          {
            name: "Reason for Return",
            id: "reasonForReturn",
            isDisabled: Boolean(
              purchaseReturnData?.purchaseReturn?.status?.name
            )
              ? purchaseReturnData?.purchaseReturn?.status?.name === "Draft"
                ? !isEditable
                : true
              : false,
            type: "dropdown",
            placeholder: "Select Reason for Return",
            options: isCreateOrderFormFetching
              ? []
              : CreateReturnFormData?.returnReasons
          }
        ],
        actions:
          !isPurchaseReturnDataFetching &&
          purchaseReturnData?.purchaseReturn?.status?.name === statusKeys.Draft
            ? ["SaveDraft", "Submit", "Reset"]
            : ["Edit", "SaveDraft", "Submit"],
        selection: false,
        buyerTitle: "Seller",
        shippingTitle: "Shipping from",
        sellerTitle: "Buyer",
        orderInfo:
          orderRefWatch !== "" &&
          orderRefWatch !== undefined &&
          !isPurchaseReturnDataFetching
            ? {
                purchaseReturnNo:
                  purchaseReturnData?.purchaseReturn?.code ?? "--",
                purchaseReturnDate: purchaseReturnData?.purchaseReturn
                  ?.orderDate
                  ? moment(
                      purchaseReturnData?.purchaseReturn?.orderDate
                    ).format("DD-MM-YYYY")
                  : "--",
                purchaseOrderNo:
                  CreateReturnFormData?.purchaseOrder?.code ?? "--",
                orderDate: CreateReturnFormData?.purchaseOrder?.orderDate
                  ? moment(
                      CreateReturnFormData?.purchaseOrder?.orderDate
                    ).format("DD-MM-YYYY")
                  : "--"
              }
            : {},
        sellerInfo: returnId
          ? vendorAddressData(purchaseReturnData?.purchaseReturn?.clientData)
          : vendorAddressData(CreateReturnFormData?.purchaseOrder?.clientData),
        buyerInfo: returnId
          ? vendorAddressData(purchaseReturnData?.purchaseReturn?.vendorData)
          : vendorAddressData(CreateReturnFormData?.purchaseOrder?.vendorData),
        shipInfo: returnId
          ? shipToAddressData(
              purchaseReturnData?.purchaseReturn?.clientData?.warehouse
            )
          : shipToAddressData(
              CreateReturnFormData?.purchaseOrder?.clientData?.warehouse
            )
      }
    }
  };

  const getOrderHistory = (item) => {
    switch (orderTypeWatch) {
      case "purchaseOrder":
        return {
          name: "Purchase Order",
          actions: [],
          selection: false,
          buyerTitle: "Seller",
          shippingTitle: "Shipto",
          sellerTitle: "Buyer",
          orderInfo: {
            purchaseOrderNo: item?.code,
            orderDate: moment(item?.orderDate).format("DD-MM-YYYY"),
            deliveryBy: moment(item?.deliveryDate).format("DD-MM-YYYY")
          },
          sellerInfo: vendorAddressData(item?.clientData),
          buyerInfo: vendorAddressData(item?.vendorData),
          shipInfo: shipToAddressData(item?.clientData?.warehouse)
        };
      case "purchaseReturns":
        return {
          name: "Purchase Returns",
          actions: [],
          selection: false,
          buyerTitle: "Seller",
          shippingTitle: "Shipping from",
          sellerTitle: "Buyer",
          orderInfo: {
            purchaseReturnNo: item?.code,
            purchaseReturnDate: moment(item?.orderDate).format("DD-MM-YYYY"),
            purchaseOrderNo: CreateReturnFormData?.purchaseOrder?.code,
            orderDate: moment(
              CreateReturnFormData?.purchaseOrder?.orderDate
            ).format("DD-MM-YYYY")
          },
          sellerInfo: vendorAddressData(item?.clientData),
          buyerInfo: vendorAddressData(item?.vendorData),
          shipInfo: shipToAddressData(item?.clientData?.warehouse)
        };
      default:
        return {};
    }
  };

  const getOrderHistoryTable = (item, isPrint = false) => {
    switch (orderTypeWatch) {
      case "purchaseOrder":
        return (
          <HistoryTable
            isPrint={isPrint}
            data={item?.items ?? []}
            orderType={"PurchaseOrder"}
            costBreakUp={{
              subtotal: item?.subTotalAmount,
              cgst: item?.taxes?.length > 1 ? item?.taxes[0]?.totalAmount : 0,
              sgst: item?.taxes?.length > 1 ? item?.taxes[1]?.totalAmount : 0,
              igst: item?.taxes?.length === 1 ? item?.taxes[0]?.totalAmount : 0,
              total: item?.totalAmount
            }}
            orderId={orderId}
            gstType={item?.taxes ? item?.taxes?.length > 1 : false}
            tnc={item?.tnc ?? ""}
            paymentTerms={[item?.advancePercent, item?.dueDays]}
            shipping={item?.shipping?.displayName}
            reasonOfReturn={""}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
          />
        );
      case "purchaseReturns":
        return (
          <HistoryTable
            isPrint={isPrint}
            data={item?.items}
            orderType={"PurchaseReturn"}
            costBreakUp={{
              subtotal: item?.subTotalAmount,
              cgst: item?.taxes?.length > 1 ? item?.taxes[0]?.totalAmount : 0,
              sgst: item?.taxes?.length > 1 ? item?.taxes[1]?.totalAmount : 0,
              igst: item?.taxes?.length === 1 ? item?.taxes[0]?.totalAmount : 0,
              total: item?.totalAmount
            }}
            orderId={orderId}
            gstType={item?.taxes ? item?.taxes?.length > 1 : false}
            tnc={item?.tnc ?? ""}
            paymentTerms={[]}
            shipping={""}
            reasonOfReturn={item?.returnReason?.displayName}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
          />
        );
      default:
        return <>Not Found</>;
    }
  };

  const orderHistoriesAllReport = (item) => {
    const orderData = getOrderHistory(item);

    const otherOldPODetails = [
      { label: `Shipping :`, value: item?.shipping?.displayName },
      {
        label: `Payment Terms :`,
        value: `${item?.advancePercent}% Advance. Balance Due ${item?.dueDays} days from receipt`
      }
    ];

    const otherOldPODetailsComp = (
      <Grid container rowSpacing={2}>
        {otherOldPODetails?.map((item, index) => (
          <OtherDetailsComp key={index} label={item.label} value={item.value} />
        ))}
      </Grid>
    );

    const otherOldDetailsPR = (
      <Grid container rowSpacing={2}>
        <OtherDetailsComp
          label={"Return Reason :"}
          value={item?.returnReason?.displayName}
        />
      </Grid>
    );

    return (
      <>
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          flexDirection={"column"}
          gap={1}
        >
          <OrderReport
            isDraft={false}
            title={orderData?.name}
            sellerTitle={orderData?.sellerTitle}
            buyerTitle={orderData?.buyerTitle}
            shippingTitle={orderData?.shippingTitle}
            orderInfo={orderData?.orderInfo}
            sellerInfo={orderData?.sellerInfo}
            buyerInfo={orderData?.buyerInfo}
            shipInfo={orderData?.shipInfo}
            actions={orderData?.actions}
          >
            {getOrderHistoryTable(item)}
          </OrderReport>
        </Box>
        <Box
          sx={{ display: { xs: "flex", md: "none" } }}
          flexDirection={"column"}
          gap={1}
        >
          <MobileOrderReport
            status={item?.status?.name}
            otherInfo={
              orderTypeWatch === "purchaseOrder"
                ? otherOldPODetailsComp
                : otherOldDetailsPR
            }
            mobileTable={getOrderHistoryTable(item)}
            remarksComp={
              <Box
                display={"flex"}
                minWidth={"289px"}
                minHeight={"90px"}
                border={"1px solid #B7BEC7"}
                borderRadius={"8px"}
                padding={"10px"}
              >
                <Typography color={"text.disabled"}>
                  {item?.tnc || ""}
                </Typography>
              </Box>
            }
            Total={[
              {
                label: "Subtotal Amount :",
                value: item?.subTotalAmount
                  ? currencyOnlyFormatter(
                      parseFloat(item?.subTotalAmount).toFixed(2)
                    )
                  : "--"
              },
              ...[
                ...(item?.taxes?.length > 1
                  ? [
                      {
                        label: "CGST :",
                        value: item?.taxes[0]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
                            )
                          : "--"
                      },
                      {
                        label: "SGST :",
                        value: item?.taxes[1]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(item?.taxes[1]?.totalAmount).toFixed(2)
                            )
                          : "--"
                      }
                    ]
                  : [
                      {
                        label: "IGST :",
                        value: item?.taxes[0]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
                            )
                          : "--"
                      }
                    ])
              ],
              {
                label: "Total Amount :",
                value: item?.totalAmount
                  ? currencyOnlyFormatter(
                      parseFloat(item?.totalAmount).toFixed(2)
                    )
                  : "--"
              }
            ]}
            ButtonGroup={""}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
            title={orderData?.name}
            sellerTitle={orderData?.sellerTitle}
            buyerTitle={orderData?.buyerTitle}
            shippingTitle={orderData?.shippingTitle}
            orderInfo={orderData?.orderInfo}
            sellerInfo={orderData?.sellerInfo}
            buyerInfo={orderData?.buyerInfo}
            shipInfo={orderData?.shipInfo}
          />
        </Box>
        {orderId && (
          <div style={{ display: "none" }}>
            <Box ref={componentRef} sx={{ padding: "10px" }}>
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <OrderReport
                  isDraft={false}
                  title={orderData?.name}
                  sellerTitle={orderData?.sellerTitle}
                  buyerTitle={orderData?.buyerTitle}
                  shippingTitle={orderData?.shippingTitle}
                  orderInfo={orderData?.orderInfo}
                  sellerInfo={orderData?.sellerInfo}
                  buyerInfo={orderData?.buyerInfo}
                  shipInfo={orderData?.shipInfo}
                  actions={orderData?.actions}
                >
                  {getOrderHistoryTable(item, true)}
                </OrderReport>
              </Box>
            </Box>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (Boolean(createReturnId)) {
      setIsEditable(false);
      setValue("orderType", "purchaseReturns");
      setValue("orderRef", createReturnId);
      setIsDraft(false);
    }
  }, [setValue, createReturnId]);

  useEffect(() => {
    if (!Boolean(createReturnId)) {
      setIsEditable(false);
      reset((prevValue) => ({
        orderType: orderId ? manageOrderType : prevValue.orderType,
        newOrders: []
      }));
      setActiveTab(0);
      setIsDraft(Boolean(orderId) || Boolean(returnId));
      setCheckOrderType(orderTypeWatch);
    }
  }, [
    reset,
    orderTypeWatch,
    manageOrderType,
    createReturnId,
    orderId,
    returnId
  ]);

  const getTable = (type, isPrint = false) => {
    switch (type) {
      case "Purchase Order":
        return (
          <PurchaseOrderTable
            costBreakUp={costBreakUp}
            setCostBreakUp={setCostBreakUp}
            isPrint={isPrint}
            fields={fields}
            control={control}
            watch={watch}
            append={append}
            remove={remove}
            onAdd={() => {
              if (fields.length < 1 || (rateWatch && quantityWatch)) {
                append({ item: "" });
              } else {
                enqueueSnackbar("kindly first fill rate and quantity", {
                  variant: "error"
                });
              }
            }}
            onRowSelectionChange={(rows) => {
              selectedRowsRef.current = rows;
            }}
            orderId={orderId}
            gstType={
              orderId
                ? Boolean(
                    POForm?.purchaseOrder?.vendorData?.billing?.address
                      ?.state ===
                      POForm?.purchaseOrder?.clientData?.billing?.address?.state
                  )
                : Boolean(
                    CreateOrderForm?.vendors?.find(
                      (obj) => obj["_id"] === vendorWatch
                    )?.warehouse?.billingAddress?.state ===
                      CreateOrderForm?.client?.billing?.address?.state
                  )
            }
            options={isCreateOrderFormFetching ? [] : CreateOrderForm}
            orderStatus={POForm?.purchaseOrder?.status?.name ?? ""}
            isEditable={
              Boolean(POForm?.purchaseOrder?.status?.name) ? isEditable : true
            }
            applyGst={
              orderId
                ? Boolean(POForm?.purchaseOrder?.vendorData?.billing?.gstNo)
                : Boolean(
                    CreateOrderForm?.vendors?.find(
                      (obj) => obj["_id"] === vendorWatch
                    )?.warehouse?.gstNo
                  )
            }
            paymentTerms={paymentTermsWatch}
            shipping={shipping}
            preparedBy={`${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`}
          />
        );
      case "Purchase Returns":
        return (
          <PurchaseReturnsTable
            costBreakUp={costBreakUp}
            setCostBreakUp={setCostBreakUp}
            isPrint={isPrint}
            fields={fields}
            control={control}
            watch={watch}
            isEditable={isEditable}
            gstType={
              returnId
                ? Boolean(
                    purchaseReturnData?.purchaseReturn?.vendorData?.billing
                      ?.address?.state ===
                      purchaseReturnData?.purchaseReturn?.clientData?.billing
                        ?.address?.state
                  )
                : Boolean(
                    CreateReturnFormData?.purchaseOrder?.vendorData?.billing
                      ?.address?.state ===
                      CreateReturnFormData?.purchaseOrder?.clientData?.billing
                        ?.address?.state
                  )
            }
            applyGst={
              orderId
                ? Boolean(
                    purchaseReturnData?.purchaseReturn?.vendorData?.billing
                      ?.gstNo
                  )
                : Boolean(
                    CreateReturnFormData?.purchaseOrder?.vendorData?.billing
                      ?.gstNo
                  )
            }
            reasonOfReturn={reasonOfReturn}
            orderStatus={purchaseReturnData?.purchaseReturn?.status?.name ?? ""}
            onEdit={() => {
              setIsEditable((prevIsEditable) => !prevIsEditable);
            }}
            preparedBy={`${CreateReturnFormData?.purchaseOrder?.clientData?.name}`}
          />
        );
      default:
        return <>Not Found</>;
    }
  };

  const getTableFunctions = (type) => {
    switch (type) {
      case "Purchase Order": {
        return {
          onAdd: () => {
            if (fields.length < 1 || (rateWatch && quantityWatch)) {
              append({ item: "" });
            } else {
              enqueueSnackbar("kindly first fill rate and quantity", {
                variant: "error"
              });
            }
          },
          onRemove: () => {
            const rowsToRemove = Object.keys(selectedRowsRef.current).filter(
              (key) => selectedRowsRef.current[key]
            );
            remove(rowsToRemove);
          }
        };
      }
      case "Purchase Returns": {
        return {
          onEdit: () => {
            setIsEditable((prevIsEditable) => !prevIsEditable);
          }
        };
      }
      default:
        return <>Not Found</>;
    }
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const onReset = () => {
    reset();
    if (orderTypeWatch === "purchaseOrder") {
      setValue("advance", POForm?.purchaseOrder?.advancePercent);
      setValue("due", POForm?.purchaseOrder?.dueDays);
      setValue("shipping", POForm?.purchaseOrder?.shipping?.name);
      setValue("dateOfDelivery", new Date(POForm?.purchaseOrder?.deliveryDate));
      POForm?.purchaseOrder?.tnc &&
        setValue("otherTNC", POForm?.purchaseOrder?.tnc);
      POForm?.purchaseOrder?.items?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item.rate,
          quantity: item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName ?? "",
          brandName: item?.skuData?.brandName ?? "",
          categoryName: item?.skuData?.categoryName ?? "",
          unit: item?.skuData.unit,
          gstRegime: item?.skuData?.gstRegime ?? 0,
          hsn: item?.skuData?.hsn ?? "",
          image: item?.skuData?.image
        });
      });
    }
    if (orderTypeWatch === "purchaseReturns") {
      setValue("orderRef", orderId);
      setValue(
        "reasonForReturn",
        purchaseReturnData?.purchaseReturn?.returnReason?.name
      );
      purchaseReturnData?.purchaseReturn?.tnc &&
        setValue("otherTNC", purchaseReturnData?.purchaseReturn?.tnc ?? "");
      const purchaseReturnItems = getDraftItems(
        purchaseReturnData?.purchaseReturn?.items ?? [],
        CreateReturnFormData?.purchaseOrder?.items ?? [],
        Boolean(
          purchaseReturnData?.purchaseReturn?.status?.name === statusKeys.Draft
        )
      );
      purchaseReturnItems?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item.rate,
          quantity: item.orderQuantity ?? item.quantity,
          returnedQuantity:
            index + 1 > purchaseReturnData?.purchaseReturn?.items?.length
              ? 0
              : item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          gstRegime: item?.skuData?.gstRegime ?? 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image
        });
      });
    }
  };

  const onModify = () => {
    if (
      (orderTypeWatch === "purchaseOrder" &&
        POForm?.purchaseOrder?.status?.name === statusKeys.Draft) ||
      (orderTypeWatch === "purchaseReturns" &&
        purchaseReturnData?.purchaseReturn?.status?.name === statusKeys.Draft)
    ) {
      setIsDraft((prev) => !prev);
      setIsEditable((prevIsEditable) => !prevIsEditable);
    }
    if (
      (orderTypeWatch === "purchaseOrder" &&
        POForm?.purchaseOrder?.status?.name !== statusKeys.Draft) ||
      (orderTypeWatch === "purchaseReturns" &&
        purchaseReturnData?.purchaseReturn?.status?.name !== statusKeys.Draft)
    ) {
      setIsEditable((prevIsEditable) => !prevIsEditable);
      setUpdateOrderButton((prev) => !prev);
    }
    onReset();
  };

  const onUpdate = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Update the Order ?"}
          btn1Text={"Go Back"}
          btn2Text={"Update Order"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => updateData(data)}
        />
      )
    });
  };

  const updateData = (data) => {
    if (orderTypeWatch === "purchaseOrder") {
      const formData = {
        items: data["newOrders"]?.map((item) => ({
          sku: item._id,
          quantity: +item.quantity
        }))
      };
      updatePurchaseOrder(
        {
          data: { ...formData },
          purchaseOrderId: orderId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Purchase Order Updated!", {
              variant: "success"
            });
            setIsEditable((prevIsEditable) => !prevIsEditable);
            setUpdateOrderButton((prev) => !prev);
            reset();
            refetchPOForm();
          }
        }
      );
    }
    if (orderTypeWatch === "purchaseReturns") {
      const prFormData = {
        items: data["newOrders"]?.map((item) => ({
          sku: item._id,
          quantity: item.returnedQuantity
        }))
      };
      updatePurchaseReturn(
        {
          data: { ...prFormData },
          orderId: orderId,
          returnId: returnId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Purchase Return Updated!", {
              variant: "success"
            });
            setIsEditable((prevIsEditable) => !prevIsEditable);
            setUpdateOrderButton((prev) => !prev);
            reset();
            refetchPRForm();
          }
        }
      );
    }
  };

  const onCancel = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Cancel the Order ?"}
          btn1Text={"Go Back"}
          btn2Text={"Cancel Order"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => cancelOrder()}
        />
      )
    });
  };

  const cancelOrder = () => {
    if (orderTypeWatch === "purchaseOrder") {
      cancelPurchaseOrder(
        {
          purchaseOrderId: orderId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Purchase Order Cancelled!", {
              variant: "success"
            });
            navigate(
              `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}`
            );
          }
        }
      );
    }
    if (orderTypeWatch === "purchaseReturns") {
      cancelPurchaseReturn(
        {
          orderId: orderId,
          returnId: returnId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Purchase Return Cancelled!", {
              variant: "success"
            });
            navigate(
              `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}`
            );
          }
        }
      );
    }
  };

  const onSaveDraft = (data) => {
    if (orderTypeWatch === "purchaseOrder") {
      const formData = {
        ...{
          ...(orderId
            ? {}
            : {
                vendor: data["vendorName"],
                warehouse: data["deliveryWarehouse"]
              })
        },
        tnc: data["otherTNC"] ? data["otherTNC"] : null,
        deliveryDate: moment(data["dateOfDelivery"]).format("YYYY-MM-DD"),
        shipping: data["shipping"],
        advancePercent: +data["advance"],
        dueDays: +data["due"],
        items: data["newOrders"]?.map((item) => ({
          sku: item._id,
          rate: +item.rate,
          quantity: +item.quantity
        }))
      };
      orderId
        ? updatePurchaseOrderDraft(
            {
              data: { ...formData },
              purchaseOrderId: orderId
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Draft Updated!", {
                  variant: "success"
                });
                setIsDraft((prev) => !prev);
                setIsEditable((prevIsEditable) => !prevIsEditable);
                reset();
                refetchPOForm();
              }
            }
          )
        : saveDraftPurchaseOrder(
            {
              data: { ...formData }
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Purchase Draft Saved!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}`
                );
              }
            }
          );
    }
    if (orderTypeWatch === "purchaseReturns") {
      const items = data["newOrders"]?.filter(
        (item) => +item.returnedQuantity !== 0
      );
      const prFormData = {
        returnReason: data["reasonForReturn"],
        tnc: data["otherTNC"],
        items: items?.map((item) => ({
          sku: item._id,
          quantity: item.returnedQuantity
        }))
      };
      orderId && returnId
        ? updatePurchaseReturnDraft(
            {
              data: { ...prFormData },
              purchaseOrderId: orderId,
              purchaseReturnId: returnId
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Purchase Return Draft Updated!", {
                  variant: "success"
                });
                setIsDraft((prev) => !prev);
                setIsEditable((prevIsEditable) => !prevIsEditable);
                reset();
                refetchPRForm();
              }
            }
          )
        : saveDraftPurchaseReturn(
            {
              data: { ...prFormData },
              orderId: orderRefWatch
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Purchase Return Draft Saved!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}`
                );
              }
            }
          );
    }
  };

  const onSubmit = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to submit the document ?"}
          subText={"Submitted documents cannot be edited. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Submit"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => submitData(data)}
        />
      )
    });
  };

  const submitData = (data) => {
    if (orderTypeWatch === "purchaseOrder") {
      const formData = {
        ...{
          ...(orderId
            ? {}
            : {
                vendor: data["vendorName"],
                warehouse: data["deliveryWarehouse"]
              })
        },
        tnc: data["otherTNC"] ? data["otherTNC"] : null,
        deliveryDate: moment(data["dateOfDelivery"]).format("YYYY-MM-DD"),
        shipping: data["shipping"],
        advancePercent: +data["advance"],
        dueDays: +data["due"],
        items: data["newOrders"]?.map((item) => ({
          sku: item._id,
          rate: +item.rate,
          quantity: +item.quantity
        }))
      };
      orderId
        ? submitPurchaseOrderDraft(
            {
              data: { ...formData },
              purchaseOrderId: orderId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                refetchPOForm();
                enqueueSnackbar("Purchase Order created!", {
                  variant: "success"
                });
                setIsDraft((prev) => !prev);
                setIsEditable((prevIsEditable) => !prevIsEditable);
                reset();
                refetchPOForm();
              }
            }
          )
        : createPurchaseOrder(
            {
              data: { ...formData }
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Purchase Order created!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}`
                );
              }
            }
          );
    }
    if (orderTypeWatch === "purchaseReturns") {
      const items = data["newOrders"]?.filter(
        (item) => +item.returnedQuantity !== 0
      );
      const prFormData = {
        returnReason: data["reasonForReturn"],
        tnc: data["otherTNC"],
        items: items?.map((item) => ({
          sku: item._id,
          quantity: item.returnedQuantity
        }))
      };
      orderId && returnId
        ? submitPurchaseReturnDraft(
            {
              data: { ...prFormData },
              purchaseOrderId: orderId,
              purchaseReturnId: returnId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                refetchPRForm();
                enqueueSnackbar("Purchase Return Submitted!", {
                  variant: "success"
                });
                setIsDraft((prev) => !prev);
                setIsEditable((prevIsEditable) => !prevIsEditable);
                reset();
                refetchPRCreateFrom();
                refetchPRForm();
              }
            }
          )
        : createPurchaseReturn(
            {
              data: { ...prFormData },
              orderId: orderRefWatch
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Purchase Return Draft Saved!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}`
                );
              }
            }
          );
    }
  };

  const onDeleteClick = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Delete this draft ?"}
          subText={"Deleted documents cannot be recovered. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Delete"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => deleteDraft()}
        />
      )
    });
  };

  const deleteDraft = () => {
    if (orderTypeWatch === "purchaseReturns") {
      deletePRDraft(
        {
          purchaseOrderId: orderId,
          purchaseReturnId: returnId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("PR Draft Deleted!", {
              variant: "success"
            });
            navigate(
              `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}`
            );
          }
        }
      );
    }
    if (orderTypeWatch === "purchaseOrder") {
      deletePODraft(
        {
          purchaseOrderId: orderId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("PO Draft Deleted!", {
              variant: "success"
            });
            navigate(
              `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}`
            );
          }
        }
      );
    }
  };

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateError) {
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateDraftError)
      enqueueSnackbar(updateDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isSubmitDraftError) {
      enqueueSnackbar(submitDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDraftError)
      enqueueSnackbar(saveDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isCreatePRError) {
      enqueueSnackbar(createPRError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdatePRError) {
      enqueueSnackbar(updatePRError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateDraftPRError)
      enqueueSnackbar(updateDraftPRError?.response?.data?.message, {
        variant: "error"
      });
    if (isSubmitDraftPRError) {
      enqueueSnackbar(submitDraftPRError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDraftPRError)
      enqueueSnackbar(saveDraftPRError?.response?.data?.message, {
        variant: "error"
      });
    if (isDeletePODraftError) {
      enqueueSnackbar(deletePODraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isCancelError) {
      enqueueSnackbar(cancelError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isDeletePRDraftError) {
      enqueueSnackbar(deletePRDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isCancelPRError) {
      enqueueSnackbar(cancelPRError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    updateDraftError?.response?.data?.message,
    submitDraftError?.response?.data?.message,
    createError?.response?.data?.message,
    updateError?.response?.data?.message,
    saveDraftError?.response?.data?.message,
    updateDraftPRError?.response?.data?.message,
    submitDraftPRError?.response?.data?.message,
    createPRError?.response?.data?.message,
    updatePRError?.response?.data?.message,
    saveDraftPRError?.response?.data?.message,
    deletePODraftError?.response?.data?.message,
    cancelError?.response?.data?.message,
    deletePRDraftError?.response?.data?.message,
    cancelPRError?.response?.data?.message,
    isSaveDraftError,
    isUpdateDraftError,
    isSubmitDraftError,
    isCreateError,
    isUpdateError,
    isSaveDraftPRError,
    isUpdateDraftPRError,
    isSubmitDraftPRError,
    isCreatePRError,
    isUpdatePRError,
    isDeletePODraftError,
    isCancelError,
    isDeletePRDraftError,
    isCancelPRError
  ]);

  const otherPODetails = [
    { label: `Shipping :`, value: shipping },
    {
      label: `Payment Terms :`,
      value:
        paymentTermsWatch[0] !== "" &&
        paymentTermsWatch[0] !== undefined &&
        paymentTermsWatch[1]
          ? `${paymentTermsWatch[0]}% Advance. Balance Due ${paymentTermsWatch[1]} days from receipt`
          : "--"
    }
  ];

  const otherPODetailsComp = (
    <Grid container rowSpacing={2}>
      {otherPODetails?.map((item, index) => (
        <OtherDetailsComp key={index} label={item.label} value={item.value} />
      ))}
    </Grid>
  );

  const otherDetailsPR = (
    <Grid container rowSpacing={2}>
      <OtherDetailsComp label={"Return Reason :"} value={reasonOfReturn} />
    </Grid>
  );

  const Total = [
    {
      label: "Subtotal Amount :",
      value: costBreakUp?.subtotal
        ? currencyOnlyFormatter(parseFloat(costBreakUp?.subtotal).toFixed(2))
        : "--"
    },
    ...[
      ...(Boolean(costBreakUp?.gstType)
        ? [
            {
              label: "CGST :",
              value: costBreakUp?.cgst
                ? currencyOnlyFormatter(
                    parseFloat(costBreakUp?.cgst).toFixed(2)
                  )
                : "--"
            },
            {
              label: "SGST :",
              value: costBreakUp?.sgst
                ? currencyOnlyFormatter(
                    parseFloat(costBreakUp?.sgst).toFixed(2)
                  )
                : "--"
            }
          ]
        : [
            {
              label: "IGST :",
              value: costBreakUp?.igst
                ? currencyOnlyFormatter(
                    parseFloat(costBreakUp?.igst).toFixed(2)
                  )
                : "--"
            }
          ])
    ],
    {
      label: "Total Amount :",
      value: costBreakUp?.total
        ? currencyOnlyFormatter(parseFloat(costBreakUp?.total).toFixed(2))
        : "--"
    }
  ];

  const orderStatus =
    POForm?.purchaseOrder?.status?.name ||
    purchaseReturnData?.purchaseReturn?.status?.name ||
    "";

  const RemarksComp = (
    <TextArea
      control={control}
      name={"otherTNC"}
      disabled={
        Boolean(orderStatus)
          ? orderStatus === "Draft"
            ? !isEditable
            : true
          : false
      }
    />
  );

  const DrawerComponent = (
    <Grid container rowSpacing={2}>
      {orderTypeFilters.orderType[orderTypeWatch]?.fields?.map(
        (item, index) => (
          <Grid
            container
            rowSpacing={2}
            padding={"20px 15px 2px 10px"}
            key={index}
          >
            {generateMobileFields(
              item,
              control,
              !isCreateOrderFormFetching && { ...CreateOrderForm }
            )}
          </Grid>
        )
      )}
      <Grid
        xs={12}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"72px"}
        sx={{ background: "#F0F2F5" }}
        mt={2}
      >
        <Button
          variant="contained"
          sx={{ width: "100px", height: "40px" }}
          onClick={hideDrawer}
        >
          {"Next"}
        </Button>
      </Grid>
    </Grid>
  );
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      isMobileDevice &&
      Boolean(orderTypeWatch) &&
      !Boolean(orderId) &&
      ((!isCreateOrderFormFetching && orderTypeWatch === "purchaseOrder") ||
        (!isPOListFetching && orderTypeWatch === "purchaseReturns"))
    ) {
      showDrawer({
        title: Boolean(orderId) ? "Manage Order" : "Create Order",
        height: "fit-content",
        sx: { color: "#2773FF", fontWeight: 600 },
        component: DrawerComponent
      });
      if (Boolean(orderRefWatch) && !isReturnFormDataFetching) {
        showDrawer({
          title: Boolean(orderId) ? "Manage Order" : "Create Order",
          height: "fit-content",
          sx: { color: "#2773FF", fontWeight: 600 },
          component: DrawerComponent
        });
      }
    }
  }, [
    JSON.stringify(orderTypeWatch),
    orderId,
    isPOListFetching,
    isCreateOrderFormFetching,
    isReturnFormDataFetching
  ]);
  /* eslint-disable react-hooks/exhaustive-deps */
  const inDraftState =
    POForm?.purchaseOrder?.status?.name === "Draft" ||
    purchaseReturnData?.purchaseReturn?.status?.name === "Draft";

  const showModifyOnOrder =
    Boolean(POForm?.orderHistories?.length) ||
    Boolean(purchaseReturnData?.orderHistories?.length)
      ? POForm?.orderHistories?.length === activeTab ||
        purchaseReturnData?.orderHistories?.length === activeTab
      : true;

  const inPlacedState =
    POForm?.purchaseOrder?.status?.name === "Placed" ||
    purchaseReturnData?.purchaseReturn?.status?.name === "Placed";

  const inCompletedState =
    POForm?.purchaseOrder?.status?.name === "Received" ||
    purchaseReturnData?.purchaseReturn?.status?.name === "Dispatched";

  const ButtonGroup = (inDraftState || !inCompletedState) &&
    showModifyOnOrder && (
      <PurchaseButtonGroup
        inDraftState={inDraftState}
        inPlacedState={inPlacedState}
        isDraft={isDraft}
        onCancel={onCancel}
        onDeleteClick={onDeleteClick}
        onModify={onModify}
        onSaveDraft={handleSubmit(onSaveDraft)}
        onSubmitClick={handleSubmit(onSubmit)}
        onUpdate={handleSubmit(onUpdate)}
        updateOrderButton={updateOrderButton}
        onEdit={
          getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
            .onEdit
        }
      />
    );

  const OrderReportComp = (
    <>
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        flexDirection={"column"}
        gap={1}
      >
        <OrderReport
          status={
            POForm?.purchaseOrder?.status?.name ||
            purchaseReturnData?.purchaseReturn?.status?.name
          }
          otherInfo={
            orderTypeWatch === "purchaseOrder"
              ? otherPODetailsComp
              : otherDetailsPR
          }
          mobileTable={getTable(
            orderTypeFilters.orderType[orderTypeWatch]?.name
          )}
          remarksComp={RemarksComp}
          Total={Total}
          ButtonGroup={ButtonGroup}
          preparedBy={
            orderTypeWatch === "purchaseOrder"
              ? `${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`
              : `${CreateReturnFormData?.purchaseOrder?.clientData?.name}`
          }
          isDraft={!isDraft}
          title={orderTypeFilters.orderType[orderTypeWatch]?.name}
          sellerTitle={orderTypeFilters.orderType[orderTypeWatch]?.sellerTitle}
          buyerTitle={orderTypeFilters.orderType[orderTypeWatch]?.buyerTitle}
          shippingTitle={
            orderTypeFilters.orderType[orderTypeWatch]?.shippingTitle
          }
          orderInfo={orderTypeFilters.orderType[orderTypeWatch]?.orderInfo}
          sellerInfo={orderTypeFilters.orderType[orderTypeWatch]?.sellerInfo}
          buyerInfo={orderTypeFilters.orderType[orderTypeWatch]?.buyerInfo}
          shipInfo={orderTypeFilters.orderType[orderTypeWatch]?.shipInfo}
          actions={orderTypeFilters.orderType[orderTypeWatch]?.actions}
          onAdd={
            getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
              .onAdd
          }
          onRemove={
            getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
              .onRemove
          }
          onEdit={
            getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
              .onEdit
          }
          onSaveDraft={handleSubmit(onSaveDraft)}
          onSubmit={handleSubmit(onSubmit)}
          onModify={onModify}
        >
          {getTable(orderTypeFilters.orderType[orderTypeWatch]?.name)}
        </OrderReport>
      </Box>
      <Box
        sx={{ display: { xs: "flex", md: "none" } }}
        flexDirection={"column"}
        gap={1}
      >
        <MobileOrderReport
          status={
            POForm?.purchaseOrder?.status?.name ||
            purchaseReturnData?.purchaseReturn?.status?.name
          }
          otherInfo={
            orderTypeWatch === "purchaseOrder"
              ? otherPODetailsComp
              : otherDetailsPR
          }
          mobileTable={getTable(
            orderTypeFilters.orderType[orderTypeWatch]?.name
          )}
          remarksComp={RemarksComp}
          Total={Total}
          ButtonGroup={ButtonGroup}
          preparedBy={
            orderTypeWatch === "purchaseOrder"
              ? `${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`
              : `${CreateReturnFormData?.purchaseOrder?.clientData?.name}`
          }
          title={orderTypeFilters.orderType[orderTypeWatch]?.name}
          sellerTitle={orderTypeFilters.orderType[orderTypeWatch]?.sellerTitle}
          buyerTitle={orderTypeFilters.orderType[orderTypeWatch]?.buyerTitle}
          shippingTitle={
            orderTypeFilters.orderType[orderTypeWatch]?.shippingTitle
          }
          orderInfo={orderTypeFilters.orderType[orderTypeWatch]?.orderInfo}
          sellerInfo={orderTypeFilters.orderType[orderTypeWatch]?.sellerInfo}
          buyerInfo={orderTypeFilters.orderType[orderTypeWatch]?.buyerInfo}
          shipInfo={orderTypeFilters.orderType[orderTypeWatch]?.shipInfo}
        />
      </Box>
      {orderId && isDraft && (
        <div style={{ display: "none" }}>
          <Box ref={componentRef} sx={{ padding: "10px" }}>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <OrderReport
                isDraft={!isDraft}
                title={orderTypeFilters.orderType[orderTypeWatch]?.name}
                sellerTitle={
                  orderTypeFilters.orderType[orderTypeWatch]?.sellerTitle
                }
                buyerTitle={
                  orderTypeFilters.orderType[orderTypeWatch]?.buyerTitle
                }
                shippingTitle={
                  orderTypeFilters.orderType[orderTypeWatch]?.shippingTitle
                }
                orderInfo={
                  orderTypeFilters.orderType[orderTypeWatch]?.orderInfo
                }
                sellerInfo={
                  orderTypeFilters.orderType[orderTypeWatch]?.sellerInfo
                }
                buyerInfo={
                  orderTypeFilters.orderType[orderTypeWatch]?.buyerInfo
                }
                shipInfo={orderTypeFilters.orderType[orderTypeWatch]?.shipInfo}
                actions={orderTypeFilters.orderType[orderTypeWatch]?.actions}
                onAdd={
                  getTableFunctions(
                    orderTypeFilters.orderType[orderTypeWatch]?.name
                  ).onAdd
                }
                onRemove={
                  getTableFunctions(
                    orderTypeFilters.orderType[orderTypeWatch]?.name
                  ).onRemove
                }
                onEdit={
                  getTableFunctions(
                    orderTypeFilters.orderType[orderTypeWatch]?.name
                  ).onEdit
                }
                onSaveDraft={handleSubmit(onSaveDraft)}
                onSubmit={handleSubmit(onSubmit)}
                onModify={onModify}
              >
                {getTable(
                  orderTypeFilters.orderType[orderTypeWatch]?.name,
                  true
                )}
              </OrderReport>
            </Box>
          </Box>
        </div>
      )}
    </>
  );

  const tabs = [].concat(
    ...(!returnId && orderId && Boolean(POForm?.orderHistories)
      ? POForm.orderHistories.map((item, index) => ({
          label: `${POForm.purchaseOrder.code}/${index + 1}`,
          Component: orderHistoriesAllReport(item)
        }))
      : []),
    ...(returnId && purchaseReturnData?.orderHistories
      ? purchaseReturnData.orderHistories.map((item, index) => ({
          label: `${purchaseReturnData.purchaseReturn.code}/${index + 1}`,
          Component: orderHistoriesAllReport(item)
        }))
      : []),
    {
      label: returnId
        ? Boolean(purchaseReturnData?.orderHistories?.length)
          ? `${purchaseReturnData?.purchaseReturn?.code}/${
              purchaseReturnData?.orderHistories?.length + 1
            }`
          : purchaseReturnData?.purchaseReturn?.code ?? "Draft"
        : Boolean(POForm?.orderHistories?.length)
        ? `${POForm?.purchaseOrder?.code}/${POForm?.orderHistories?.length + 1}`
        : POForm?.purchaseOrder?.code ?? "Draft",
      Component: OrderReportComp
    }
  );

  return isCreateOrderFormFetching ||
    isPOFormFetching ||
    isPOListFetching ||
    isReturnFormDataFetching ||
    isPurchaseReturnDataFetching ||
    isCreating ||
    isUpdating ||
    isSavingDraft ||
    isUpdatingDraft ||
    isSubmittingDraft ||
    isCreatingPR ||
    isUpdatingPR ||
    isSavingPRDraft ||
    isUpdatingPRDraft ||
    isSubmittingPRDraft ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={
        isCreateOrderFormFetching ||
        isPOFormFetching ||
        isPOListFetching ||
        isReturnFormDataFetching ||
        isPurchaseReturnDataFetching ||
        isCreating ||
        isUpdating ||
        isSavingDraft ||
        isUpdatingDraft ||
        isSubmittingDraft ||
        isCreatingPR ||
        isUpdatingPR ||
        isSavingPRDraft ||
        isUpdatingPRDraft ||
        isSubmittingPRDraft
      }
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box display={"flex"} flexDirection={"column"}>
      <Grid
        container
        spacing={2}
        sx={{
          display: { xs: "none", md: "block" }
        }}
      >
        <Grid item xs={12}>
          <Typography
            fontSize={20}
            fontWeight={500}
            lineHeight={"24.2px"}
            letterSpacing={"0.2%"}
          >
            {orderId ? "Manage Order" : "Create Order"}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6} lg={9} container spacing={3}>
            <Grid item xs={12} container spacing={3}>
              <CustomFieldRow
                label={"Order Type"}
                isDisabled={Boolean(orderId)}
              >
                <DropDown
                  control={control}
                  name={"orderType"}
                  options={[
                    {
                      name: "purchaseOrder",
                      displayName: "Purchase Order"
                    },
                    {
                      name: "purchaseReturns",
                      displayName: "Purchase Returns"
                    }
                  ]}
                  placeholder={"Select order type"}
                  disabled={Boolean(orderId)}
                />
              </CustomFieldRow>
              {orderId &&
                checkOrderType === "purchaseOrder" &&
                POForm?.purchaseOrder?.status?.name !== "Placed" &&
                POForm?.purchaseOrder?.status?.name !== "Draft" && (
                  <Box display={"flex"} gap={1} alignItems={"end"} pl={3}>
                    <Button
                      variant="link"
                      disableRipple
                      onClick={() =>
                        navigate(
                          `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}/${appRoutes.purchase.createReturn}/${orderId}`
                        )
                      }
                    >
                      {"Create Return Order"}
                    </Button>
                  </Box>
                )}
            </Grid>
            {orderTypeFilters.orderType[orderTypeWatch]?.fields?.map((item) =>
              generateFields(
                item,
                control,
                false,
                !isCreateOrderFormFetching && { ...CreateOrderForm }
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      {orderId &&
        checkOrderType === "purchaseOrder" &&
        POForm?.purchaseOrder?.status?.name !== "Placed" &&
        POForm?.purchaseOrder?.status?.name !== "Draft" && (
          <Grid
            item
            xs={12}
            textAlign={"end"}
            sx={{
              display: { xs: "block", md: "none" },
              flexDirection: "column",
              p: { xs: "0 16px 0 0", md: 0 },
              mt: { xs: -4.5, md: 0 },
              mb: { xs: 2, md: 0 },
              ml: { xs: 5, md: 0 }
            }}
            zIndex={1}
          >
            <Button
              variant="link"
              disableRipple
              onClick={() =>
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}/${appRoutes.purchase.createReturn}/${orderId}`
                )
              }
            >
              {"Create Return Order"}
            </Button>
          </Grid>
        )}
      <Grid
        container
        rowSpacing={2}
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          padding: "10px 20px"
        }}
      >
        <Grid item xs={8}>
          <DropDown
            control={control}
            name={"orderType"}
            options={[
              {
                name: "purchaseOrder",
                displayName: "Purchase Order"
              },
              {
                name: "purchaseReturns",
                displayName: "Purchase Returns"
              }
            ]}
            placeholder={"Select order type"}
            disabled={Boolean(orderId)}
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            disabled={!Boolean(orderTypeWatch)}
            color={"primary"}
            onClick={() => {
              showDrawer({
                title: Boolean(orderId) ? "Manage Order" : "Create Order",
                height: "fit-content",
                sx: { color: "#2773FF", fontWeight: 600 },
                component: DrawerComponent
              });
            }}
          >
            <TuneOutlined color="inherit" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      {orderTypeWatch && !orderId && (
        <Grid item xs={12}>
          {OrderReportComp}
        </Grid>
      )}
      {orderTypeWatch && orderId && (
        <Grid item xs={12}>
          <CustomTabs
            tabs={tabs}
            activeTab={activeTab}
            onChange={handleTabChange}
            headerControls={
              !isMobileDevice &&
              ((inDraftState || !inCompletedState) && showModifyOnOrder ? (
                <Box display={"flex"} gap={4}>
                  {!updateOrderButton && isDraft && (
                    <>
                      <Button variant="link" disableRipple onClick={onModify}>
                        Modify
                      </Button>
                      {inPlacedState && (
                        <Button
                          variant="link"
                          disableRipple
                          sx={{ color: "error.main", minWidth: "100px" }}
                          onClick={onCancel}
                        >
                          Cancel Order
                        </Button>
                      )}
                      {inDraftState && (
                        <Button
                          variant="link"
                          disableRipple
                          sx={{ color: "error.main", minWidth: "100px" }}
                          onClick={() => onDeleteClick()}
                        >
                          Delete Draft
                        </Button>
                      )}
                      <Button
                        variant="link"
                        disableRipple
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </>
                  )}
                  {updateOrderButton && (
                    <>
                      <Button
                        variant="link"
                        disableRipple
                        onClick={handleSubmit(onUpdate)}
                        sx={{ minWidth: "100px" }}
                      >
                        Update Order
                      </Button>
                      <Button
                        variant="link"
                        disableRipple
                        onClick={onModify}
                        sx={{ minWidth: "100px", color: "green" }}
                      >
                        Reset
                      </Button>
                    </>
                  )}
                </Box>
              ) : (
                <>
                  <Button variant="link" disableRipple onClick={handlePrint}>
                    Print
                  </Button>
                </>
              ))
            }
          />
        </Grid>
      )}
    </Box>
  );
};

export default CreateOrder;
