import React, { useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import { contactUsFormKeys } from "../../constants/formKeys";
import { home_labels, labels } from "../../constants";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import PricingCard from "../components/PricingCard";

const princingPlans = [
  {
    tag: "SINGLE USER",
    tagBgColor: "#607088",
    usageText: "Free Usage",
    usageTextColor: "#027AFF",
    cardBgColor: "#B7BEC7",
    features: [
      "Single Warehouse",
      "Inventory Management",
      "Purchases, Returns",
      "Sales, Invoices",
      "Dues, Receivables"
    ]
  },
  {
    tag: "Multi USER",
    tagBgColor: "#2773FF",
    usageText: "₹200/user/month",
    usageTextColor: "#0F2744",
    cardBgColor: "#6097FF",
    features: [
      "Everything in Free +",
      "Multi Warehouses",
      "25 E-Waybills",
      "Performance Analytics",
      "Sales and Credit Allocation"
    ]
  }
];

const footNotes = [
  "*GST is extra as applicable. ",
  "*Subscription amount shall be paid annually.",
  "*Limited Time Offer Only."
];

const FootNoteText = ({ note }) => {
  return (
    <Typography
      sx={{
        fontSize: { xs: "12px", md: "20px" },
        lineHeight: { xs: "18px", md: "30px" },
        fontWeight: 400,
        color: "#607088"
      }}
    >
      {note}
    </Typography>
  );
};

const Pricing = () => {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const targetElement = containerRef.current; // Store current value of containerRef

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.4) {
            // When 40% or more of the swiper is visible, start autoplay
            if (swiperRef.current) swiperRef.current.autoplay.start();
          } else {
            // When less than 40% is visible, stop autoplay
            if (swiperRef.current) swiperRef.current.autoplay.stop();
          }
        });
      },
      {
        threshold: 0.4 // Trigger when 40% of the Swiper is in view
      }
    );

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement); // Clean up the observer with the stored targetElement
      }
    };
  }, []);

  const { control } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: ""
    }
  });

  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          padding: { xs: "30px 25px 0px 25px", md: "30px 70px 0px 80px" },
          gap: 2
        }}
      >
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "14px", md: "22px" },
            lineHeight: { xs: "21px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={400}
        >
          {labels.pricingInfo}
        </Typography>
      </Box>
      {isMobile ? (
        <Box mt={2} ref={containerRef}>
          <Swiper
            slidesPerView={1}
            speed={1000}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            loop={true}
            pagination={{
              clickable: true
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper-5"
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {princingPlans.map((item, index) => (
              <SwiperSlide key={index}>
                <Box display={"flex"} justifyContent={"center"} pl={3}>
                  <PricingCard {...item} />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      ) : (
        <Box
          display={"flex"}
          mt={5}
          justifyContent={"space-evenly"}
          sx={{
            width: "100%",
            position: "relative",
            overflow: "hidden"
          }}
        >
          {princingPlans?.map((item, index) => (
            <PricingCard key={index} {...item} />
          ))}
        </Box>
      )}
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          padding: { xs: "0px 25px 0px 25px", md: "0px 70px 0px 80px" },
          gap: 2
        }}
      >
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: { xs: "center", md: "space-evenly" }
          }}
        >
          <Box>
            {footNotes?.map((item, index) => (
              <FootNoteText note={item} key={index} />
            ))}
          </Box>
          <Box
            sx={{ display: { xs: "none", md: "flex" }, width: "355px" }}
          ></Box>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "14px", md: "22px" },
            lineHeight: { xs: "21px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify",
            paddingTop: { xs: "10px", md: "20px" },
            mb: { xs: 0, md: -4 }
          }}
          fontWeight={700}
        >
          {labels.contactUsforDemoText}
        </Typography>
      </Box>
      <ContactUsSection
        control={control}
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </>
  );
};

export default Pricing;
