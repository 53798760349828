import * as yup from "yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Close, ExpandMore, Print, Sync } from "@mui/icons-material";
import DropDown from "../../../shared/formElements/DropDown";
import { EwayBillForm_labels } from "../../../constants";
import { EwayBillFormKeys } from "../../../constants/formKeys";
import TextInput from "../../../shared/formElements/TextInput";
import DateInput from "../../../shared/formElements/DateInput";
import { useFieldArray, useForm } from "react-hook-form";
import ItemDetailsForm from "./ItemDetailsForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchCreateFormData } from "../../../query-hooks/ClientEwayBill/CreateEwayBill/useFetchCreateFormData";
import { useCreateEwayBill } from "../../../query-hooks/ClientEwayBill/CreateEwayBill/useCreateEwayBill";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useFetchEwayBillById } from "../../../query-hooks/ClientEwayBill/useFetchEwayBillById";
import { useDrawer } from "../../../shared/customSideDrawer";
import UpdatePartB from "./UpdatePartB";
import UpdateTransporter from "./UpdateTransporter";
import ExtendValidity from "./ExtendValidity";
import CancelEwayBill from "./CancelEwayBill";
import RejectEwayBill from "./RejectEwayBill";
import UpdateOptions from "./UpdateOptions";
import { CustomTooltip } from "../../../shared/theme/globalTheme";
import { useSyncDataById } from "../../../query-hooks/ClientEwayBill/useSyncDataById";
import EwayPrintPage from "./EwayPrintPage";
import { useReactToPrint } from "react-to-print";
import VehicleHistory from "./VehicleHistory";
import { useFetchCreateFormDataByInvId } from "../../../query-hooks/ClientEwayBill/CreateInvEwayBill/useFetchCreateFormDataByInvId";
import { useCreateEwayBillByInvId } from "../../../query-hooks/ClientEwayBill/CreateInvEwayBill/useCreateEwayBillByInvId";

const AccordianInfo = ({
  title,
  children,
  expanded,
  defaultExpanded,
  hideDivider = false,
  setManuallyExpandedIndex
}) => {
  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        {!hideDivider && <Divider sx={{ border: "0.4px solid #B7BEC7" }} />}
      </Grid>
      <Grid item xs={12}>
        <Accordion
          elevation={0}
          expanded={expanded}
          onChange={setManuallyExpandedIndex}
          defaultExpanded={defaultExpanded}
          sx={{
            "& .MuiAccordionSummary-root.Mui-expanded": {
              minHeight: "45px"
            }
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMore
                sx={{
                  height: "25px",
                  width: "25px",
                  color: "#2773FF"
                }}
              />
            }
            sx={{
              padding: 0,
              border: "none",
              position: "unset",
              minHeight: 0,
              display: "flex",
              justifyContent: "left",
              "& .MuiAccordionSummary-content": {
                margin: 0,
                flexGrow: 1
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: 0,
                flexGrow: 1
              }
            }}
          >
            <Typography
              fontWeight={600}
              fontSize={14}
              lineHeight={"16.94px"}
              color={"#1B51B3"}
            >
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              border: "none",
              p: `0px`,
              m: 0
            }}
          >
            {children}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export const CustomFieldRow = ({
  label,
  isDisabled = false,
  children,
  notMandotary = false,
  wrap = false,
  showColon = false,
  labelSize = 6,
  childSize = 6,
  textAlign = "center"
}) => {
  return (
    <Grid item xs={12} container spacing={2}>
      <Grid
        item
        xs={labelSize}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        width="100%"
        textAlign={textAlign}
      >
        <Typography
          fontWeight={600}
          whiteSpace={wrap ? "normal" : "nowrap"}
          color={isDisabled && "#607088"}
          sx={{
            wordBreak: "break-word",
            fontSize: { xs: 11, md: 14 },
            lineHeight: { xs: "12px", md: "16.94px" }
          }}
        >
          {label?.split("|")?.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {wrap && index < 1 && <br />}
            </React.Fragment>
          ))}
          {!notMandotary && (
            <sup style={{ color: isDisabled ? "#607088" : "#F05454" }}>*</sup>
          )}
          {showColon && <span>{" :"}</span>}
        </Typography>
      </Grid>
      <Grid
        item
        xs={childSize}
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={"left"}
      >
        {children}
      </Grid>
    </Grid>
  );
};

const validationSchemas = [
  yup.object().shape({
    supplyType: yup.string().required("Supply Type is required"),
    subType: yup.string().required("Sub Type is required"),
    docType: yup.string().required("Document Type is required"),
    TransactionType: yup.string().required("Transaction Type is required"),
    docNo: yup.string().required("Document Number is required"),
    docDate: yup.string().required("Document Date is required")
  }),
  yup.object().shape({
    supplierName: yup.string().required("Name is required"),
    shipFrom1: yup.string().required("Field is required"),
    shipFrom2: yup.string().required("Field is required"),
    supplierGstin: yup.string().required("Supplier GSTIN is required"),
    supplierState1: yup.string().required("Supplier State is required"),
    supplierPlace: yup.string().required("Place is required"),
    supplierPIN: yup.string().required("Supplier PIN is required"),
    supplierState2: yup.string().required("Supplier State is required")
  }),
  yup.object().shape({
    recipientName: yup.string().required("Name is required"),
    shipTo1: yup.string().required("Field is required"),
    shipTo2: yup.string().required("Field is required"),
    recipientGstin: yup.string().required("Recipient GSTIN is required"),
    recipientState1: yup.string().required("Recipient State is required"),
    recipientPlace: yup.string().required("Place is required"),
    recipientPIN: yup.string().required("Recipient PIN is required"),
    recipientState2: yup.string().required("Recipient State is required")
  })
];

const TransactionDetails = ({
  ewayId,
  control,
  watch,
  isFetching = false,
  supplyType = [],
  transactionType = []
}) => {
  const supplyTypeWatch = watch(EwayBillFormKeys.supplyType);
  const subTypeWatch = watch(EwayBillFormKeys.subType);
  return (
    <Grid container columnSpacing={1} rowSpacing={2}>
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.supplyType}
          labelSize={5}
          childSize={7}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.supplyType}
            options={supplyType}
            isLoading={isFetching}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.subType}
          labelSize={4}
          childSize={8}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.subType}
            availUnselect={!Boolean(supplyTypeWatch)}
            unSelectText="select Supply Type"
            options={
              supplyTypeWatch
                ? Object.values(
                    supplyType?.find((item) => item?.name === supplyTypeWatch)
                      ?.subSupplyTypes
                  )
                : []
            }
            isLoading={isFetching}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      {Boolean(subTypeWatch) && subTypeWatch === "8" && (
        <>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <CustomFieldRow label={""} labelSize={4} childSize={8} notMandotary>
              <TextInput
                control={control}
                name={"subSupplyDesc"}
                placeholder={"Enter others"}
                disabled={Boolean(ewayId)}
              />
            </CustomFieldRow>
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.docType}
          labelSize={5}
          childSize={7}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.docType}
            isLoading={isFetching}
            availUnselect={!Boolean(subTypeWatch)}
            unSelectText="select Sub Type"
            options={
              subTypeWatch
                ? Object.values(
                    supplyType
                      ?.find((item) => item?.name === supplyTypeWatch)
                      ?.subSupplyTypes?.find(
                        (item) => item?.name === subTypeWatch
                      )?.docTypes
                  )
                : []
            }
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.TransactionType}
          labelSize={4}
          childSize={8}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.TransactionType}
            options={transactionType}
            isLoading={isFetching}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.docNo}
          labelSize={5}
          childSize={7}
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.docNo}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.docDate}
          labelSize={4}
          childSize={8}
        >
          <DateInput
            control={control}
            name={EwayBillFormKeys.docDate}
            minDate={new Date("2000-01-01")}
            maxDate={new Date()}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
    </Grid>
  );
};

const SupplierForm = ({ ewayId, control, states = [], isFetching = false }) => {
  return (
    <Grid container columnSpacing={1} rowSpacing={2}>
      <Grid item xs={12}>
        <CustomFieldRow
          label={EwayBillForm_labels.Name}
          labelSize={2}
          childSize={10}
          showColon
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.supplierName}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12} container columnSpacing={2}>
        <Grid item xs={6}>
          <CustomFieldRow
            label={EwayBillForm_labels.Gstin}
            labelSize={3.9}
            childSize={8.1}
            showColon
          >
            <TextInput
              control={control}
              name={EwayBillFormKeys.supplierGstin}
              disabled={Boolean(ewayId)}
            />
          </CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            label={EwayBillForm_labels.State}
            labelSize={3.5}
            childSize={8.5}
            showColon
          >
            <DropDown
              control={control}
              name={EwayBillFormKeys.supplierState1}
              options={states}
              isLoading={isFetching}
              disabled={Boolean(ewayId)}
            />
          </CustomFieldRow>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={3}>
        <CustomFieldRow
          label={EwayBillForm_labels.shipFrom}
          labelSize={2.4}
          childSize={9.6}
          showColon
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.shipFrom1}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow label={""} labelSize={2.4} childSize={9.6} notMandotary>
          <TextInput
            control={control}
            name={EwayBillFormKeys.shipFrom2}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow
          label={EwayBillForm_labels.Place}
          labelSize={2.4}
          childSize={6}
          showColon
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.supplierPlace}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12} container columnSpacing={2}>
        <Grid item xs={6}>
          <CustomFieldRow
            label={EwayBillForm_labels.PIN}
            labelSize={4.8}
            childSize={7.2}
            showColon
          >
            <TextInput
              control={control}
              name={EwayBillFormKeys.supplierPIN}
              type="number"
              disabled={Boolean(ewayId)}
            />
          </CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            label={EwayBillForm_labels.State}
            labelSize={3.5}
            childSize={8.5}
            showColon
          >
            <DropDown
              control={control}
              name={EwayBillFormKeys.supplierState2}
              options={states}
              isLoading={isFetching}
              disabled={Boolean(ewayId)}
            />
          </CustomFieldRow>
        </Grid>
      </Grid>
    </Grid>
  );
};

const RecipientForm = ({
  ewayId,
  control,
  states = [],
  isFetching = false
}) => {
  return (
    <Grid container columnSpacing={1} rowSpacing={2}>
      <Grid item xs={12}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.Name}
          labelSize={2}
          childSize={10}
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.recipientName}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.Gstin}
          labelSize={3.9}
          childSize={8.1}
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.recipientGstin}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.State}
          labelSize={3.5}
          childSize={8.5}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.recipientState1}
            options={states}
            isLoading={isFetching}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12} mt={3}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.shipTo}
          labelSize={2}
          childSize={10}
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.shipTo1}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow label={""} labelSize={2} childSize={10} notMandotary>
          <TextInput
            control={control}
            name={EwayBillFormKeys.shipTo2}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.Place}
          labelSize={2}
          childSize={7}
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.recipientPlace}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.PIN}
          labelSize={3.9}
          childSize={8.1}
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.recipientPIN}
            type="number"
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.State}
          labelSize={3.5}
          childSize={8.5}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.recipientState2}
            options={states}
            isLoading={isFetching}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
    </Grid>
  );
};

const TransportationDetails = ({ ewayId, control }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomFieldRow
          label={EwayBillForm_labels.transporterId}
          labelSize={4}
          childSize={7}
          notMandotary
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.transporterId}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow
          label={EwayBillForm_labels.transporterName}
          labelSize={4}
          childSize={7}
          notMandotary
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.transporterName}
            disabled={Boolean(ewayId)}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow
          label={EwayBillForm_labels.distance}
          labelSize={4}
          childSize={7}
          notMandotary
          wrap
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.distance}
            disabled={Boolean(ewayId)}
            type="number"
          />
        </CustomFieldRow>
      </Grid>
    </Grid>
  );
};

const PartB = ({
  ewayId,
  control,
  isFetching = false,
  transportationMode = [],
  vehicleType = []
}) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={4}>
      <Grid item xs={6} md={5}>
        <DropDown
          name={EwayBillFormKeys.mode}
          control={control}
          label={EwayBillForm_labels.mode}
          options={transportationMode}
          isLoading={isFetching}
          disabled={Boolean(ewayId)}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DropDown
          name={EwayBillFormKeys.vehicleType}
          control={control}
          label={EwayBillForm_labels.vehicleType}
          options={vehicleType}
          isLoading={isFetching}
          disabled={Boolean(ewayId)}
        />
      </Grid>
      <Grid item xs={6} md={5}>
        <TextInput
          name={EwayBillFormKeys.vehicleNo}
          control={control}
          label={EwayBillForm_labels.vehicleNo}
          disabled={Boolean(ewayId)}
        />
      </Grid>
      <Grid item xs={6} md={6}></Grid>
      <Grid item xs={6} md={5}>
        <TextInput
          name={EwayBillFormKeys.transportDocNo}
          control={control}
          label={EwayBillForm_labels.transportDocNo}
          disabled={Boolean(ewayId)}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DateInput
          name={EwayBillFormKeys.transportDocDate}
          control={control}
          minDate={new Date("2000-01-01")}
          label={EwayBillForm_labels.transportDocDate}
          disabled={Boolean(ewayId)}
        />
      </Grid>
    </Grid>
  );
};

const EwayBillForm = ({
  hideDrawer,
  EwayBillLoginShow,
  EwayId,
  refetch,
  dcInvId = null
}) => {
  const componentRef = useRef();
  const [isSynced, setIsSynced] = useState(false);
  const [manuallyExpandedIndex, setManuallyExpandedIndex] = useState(null);
  const [updateType, setUpdateType] = useState("0");
  const { showDrawer } = useDrawer();
  const [activeStep, setActiveStep] = useState(0);
  const schema = validationSchemas[activeStep];

  const { data, isFetching } = useFetchCreateFormData();

  const {
    isFetching: isSyncingEway,
    isSuccess: isSyncSuccess,
    refetch: isRefetchingSync,
    error: syncError,
    isError: isSyncError
  } = useSyncDataById(EwayId, isSynced);

  const {
    data: eWayData,
    isFetching: isFetchingEway,
    refetch: refetchEwayBillById
  } = useFetchEwayBillById(EwayId);

  const {
    mutate: createEwayBill,
    isLoading: isCreatingEwayBill,
    isError: isCreateError,
    error: createError
  } = useCreateEwayBill();

  const { data: invEwayData, isFetching: isFetchingInvEway } =
    useFetchCreateFormDataByInvId(dcInvId);

  const {
    mutate: createInvEwayBill,
    isLoading: isCreatingInvEwayBill,
    isError: isCreateInvError,
    error: createInvError
  } = useCreateEwayBillByInvId(dcInvId);

  const { control, handleSubmit, trigger, watch, setValue, reset } = useForm(
    activeStep < 3 &&
      !dcInvId && {
        resolver: yupResolver(schema),
        mode: "onBlur"
      }
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `e-Way bill (${eWayData?.eWayBill?.ewbNo})`
  });

  const updateTypeWatch = watch(EwayBillFormKeys.updateType);
  const distanceWatch = watch(EwayBillFormKeys.distance);

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "itemDetails"
  });

  const getUpdateType = (type) => {
    switch (type) {
      case "0":
        return (
          <UpdateOptions
            control={control}
            updateTypeWatch={updateTypeWatch}
            setUpdateType={setUpdateType}
            allowedActions={eWayData?.eWayBill?._pm}
          />
        );
      case "1":
        return (
          <UpdatePartB
            ewayId={EwayId}
            setUpdateType={setUpdateType}
            refetchEwayBillById={refetchEwayBillById}
            ewbValidity={eWayData?.eWayBill?.ewbValidity}
            reset={reset}
            EwayBillLoginShow={EwayBillLoginShow}
            refetch={refetch}
            partBs={eWayData?.eWayBill?.partBs || []}
          />
        );
      case "2":
        return (
          <UpdateTransporter
            ewayId={EwayId}
            setUpdateType={setUpdateType}
            refetchEwayBillById={refetchEwayBillById}
            reset={reset}
            EwayBillLoginShow={EwayBillLoginShow}
            refetch={refetch}
          />
        );
      case "3":
        return (
          <ExtendValidity
            ewayId={EwayId}
            setUpdateType={setUpdateType}
            ewbValidity={eWayData?.eWayBill?.ewbValidity}
            refetchEwayBillById={refetchEwayBillById}
            reset={reset}
            EwayBillLoginShow={EwayBillLoginShow}
            refetch={refetch}
            partBs={eWayData?.eWayBill?.partBs || []}
          />
        );
      case "4":
        return (
          <CancelEwayBill
            ewayId={EwayId}
            setUpdateType={setUpdateType}
            refetchEwayBillById={refetchEwayBillById}
            reset={reset}
            EwayBillLoginShow={EwayBillLoginShow}
            refetch={refetch}
          />
        );
      case "5":
        return (
          <RejectEwayBill
            ewayId={EwayId}
            setUpdateType={setUpdateType}
            refetchEwayBillById={refetchEwayBillById}
            reset={reset}
            EwayBillLoginShow={EwayBillLoginShow}
            refetch={refetch}
          />
        );
      default:
        return (
          <UpdateOptions
            control={control}
            updateTypeWatch={updateTypeWatch}
            setUpdateType={setUpdateType}
            allowedActions={eWayData?.eWayBill?._pm}
          />
        );
    }
  };

  useEffect(() => {
    if (isSyncError) {
      if (syncError?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(isRefetchingSync);
      } else {
        enqueueSnackbar(syncError?.response?.data?.message, {
          variant: "error"
        });
      }
    }
    // eslint-disable-next-line
  }, [syncError?.response?.data?.message, isSyncError, EwayBillLoginShow]);

  useEffect(() => {
    if (isSyncSuccess) {
      reset();
      enqueueSnackbar("Eway Bill Synced Successfully", { variant: "success" });
      refetchEwayBillById();
      setIsSynced(false);
    }
  }, [isSyncSuccess, refetchEwayBillById, reset]);

  useEffect(() => {
    if (EwayId && eWayData) {
      const {
        supplyType,
        subSupplyType,
        docType,
        docNo,
        docDate,
        fromTrdName,
        fromGstin,
        fromAddr1,
        fromAddr2,
        fromPlace,
        fromPincode,
        fromState,
        actFromState,
        toTrdName,
        toGstin,
        toAddr1,
        toAddr2,
        toPlace,
        toPincode,
        toState,
        actToState,
        transMode,
        transDistance,
        transporterId,
        transDocDate,
        vehicleNo,
        vehicleType,
        transactionType,
        items
      } = eWayData?.eWayBill;

      setValue(EwayBillFormKeys.supplyType, supplyType.name);
      setValue(EwayBillFormKeys.subType, subSupplyType.name);
      setValue(EwayBillFormKeys.docType, docType.name);
      setValue(EwayBillFormKeys.docNo, docNo);
      setValue(EwayBillFormKeys.docDate, moment(docDate).toDate());
      setValue(EwayBillFormKeys.supplierName, fromTrdName);
      setValue(EwayBillFormKeys.supplierGstin, fromGstin);
      setValue(EwayBillFormKeys.shipFrom1, fromAddr1);
      setValue(EwayBillFormKeys.shipFrom2, fromAddr2);
      setValue(EwayBillFormKeys.supplierPlace, fromPlace);
      setValue(EwayBillFormKeys.supplierPIN, fromPincode);
      setValue(EwayBillFormKeys.supplierState1, fromState.name);
      setValue(EwayBillFormKeys.supplierState2, actFromState.name);
      setValue(EwayBillFormKeys.recipientName, toTrdName);
      setValue(EwayBillFormKeys.recipientGstin, toGstin);
      setValue(EwayBillFormKeys.shipTo1, toAddr1);
      setValue(EwayBillFormKeys.shipTo2, toAddr2);
      setValue(EwayBillFormKeys.recipientPlace, toPlace);
      setValue(EwayBillFormKeys.recipientPIN, toPincode);
      setValue(EwayBillFormKeys.recipientState1, toState.name);
      setValue(EwayBillFormKeys.recipientState2, actToState.name);
      setValue(EwayBillFormKeys.mode, transMode?.name);
      setValue(EwayBillFormKeys.distance, transDistance);
      setValue(EwayBillFormKeys.transporterId, transporterId);
      setValue(
        EwayBillFormKeys.transportDocDate,
        moment(transDocDate).toDate()
      );
      setValue(EwayBillFormKeys.vehicleNo, vehicleNo);
      setValue(EwayBillFormKeys.vehicleType, vehicleType.name);
      setValue(EwayBillFormKeys.TransactionType, transactionType.name);
      replace(
        items.map((item) => ({
          _id: item.sku,
          rate: item.rate,
          quantity: item.quantity,
          advol: item.cessRate,
          Nonadval: item.cessNonAdvolValue,
          taxableAmount: item.taxableAmount,
          cgstRate: item.cgstRate,
          sgstRate: item.sgstRate,
          igstRate: item.igstRate,
          hsn: item.skuData.hsn,
          unit: item.skuData.unit,
          skuCode: item.skuData.skuCode,
          productName: item.skuData.productName,
          brandName: item.skuData.brandName,
          image: item.skuData.image
        }))
      );
    }
  }, [EwayId, eWayData, setValue, replace]);

  useEffect(() => {
    if (dcInvId && invEwayData) {
      const {
        supplyType,
        subSupplyType,
        docType,
        docNo,
        docDate,
        fromTrdName,
        fromGstin,
        fromAddr1,
        fromAddr2,
        fromPlace,
        fromPincode,
        fromState,
        actFromState,
        toTrdName,
        toGstin,
        toAddr1,
        toAddr2,
        toPlace,
        toPincode,
        toState,
        actToState,
        transactionType,
        items
      } = invEwayData?.ewb;

      setValue(EwayBillFormKeys.supplyType, supplyType.name);
      setValue(EwayBillFormKeys.subType, subSupplyType.name);
      setValue(EwayBillFormKeys.docType, docType.name);
      setValue(EwayBillFormKeys.docNo, docNo);
      setValue(EwayBillFormKeys.docDate, moment(docDate).toDate());
      setValue(EwayBillFormKeys.supplierName, fromTrdName);
      setValue(EwayBillFormKeys.supplierGstin, fromGstin);
      setValue(EwayBillFormKeys.shipFrom1, fromAddr1);
      setValue(EwayBillFormKeys.shipFrom2, fromAddr2);
      setValue(EwayBillFormKeys.supplierPlace, fromPlace);
      setValue(EwayBillFormKeys.supplierPIN, fromPincode);
      setValue(EwayBillFormKeys.supplierState1, fromState.name);
      setValue(EwayBillFormKeys.supplierState2, actFromState.name);
      setValue(EwayBillFormKeys.recipientName, toTrdName);
      setValue(EwayBillFormKeys.recipientGstin, toGstin);
      setValue(EwayBillFormKeys.shipTo1, toAddr1);
      setValue(EwayBillFormKeys.shipTo2, toAddr2);
      setValue(EwayBillFormKeys.recipientPlace, toPlace);
      setValue(EwayBillFormKeys.recipientPIN, toPincode);
      setValue(EwayBillFormKeys.recipientState1, toState.name);
      setValue(EwayBillFormKeys.recipientState2, actToState.name);
      setValue(EwayBillFormKeys.TransactionType, transactionType.name);
      replace(
        items.map((item) => ({
          _id: item.sku,
          rate: item.rate,
          quantity: item.quantity,
          advol: item.cessRate,
          Nonadval: item.cessNonAdvolValue,
          taxableAmount: item.taxableAmount,
          cgstRate: item.cgstRate,
          sgstRate: item.sgstRate,
          igstRate: item.igstRate,
          hsn: item.skuData.hsn,
          unit: item.skuData.unit,
          skuCode: item.skuData.skuCode,
          productName: item.skuData.productName,
          brandName: item.skuData.brandName,
          image: item.skuData.image
        }))
      );
    }
  }, [dcInvId, invEwayData, setValue, replace]);

  useEffect(() => {
    if (distanceWatch === undefined) {
      setValue(EwayBillFormKeys.distance, 0);
    }
  }, [distanceWatch, setValue]);

  const sections = [
    {
      title: "Transaction Details",
      component: (
        <TransactionDetails
          ewayId={EwayId || dcInvId}
          control={control}
          watch={watch}
          isFetching={isFetching}
          supplyType={data?.supplyTypes || []}
          transactionType={data?.transactionTypes || []}
        />
      ),
      hideDivider: true
    },
    {
      title: "Supplier",
      component: (
        <SupplierForm
          ewayId={EwayId || dcInvId}
          control={control}
          states={data?.states}
          isFetching={isFetching}
        />
      )
    },
    {
      title: "Recipient",
      component: (
        <RecipientForm
          ewayId={EwayId || dcInvId}
          control={control}
          states={data?.states}
          isFetching={isFetching}
        />
      )
    },
    {
      title: "Item Details",
      component: (
        <ItemDetailsForm
          ewayId={EwayId || dcInvId}
          control={control}
          fields={fields}
          remove={remove}
          append={append}
          options={data?.skus || []}
          watch={watch}
          EwayId={EwayId || dcInvId}
          setValue={setValue}
          replace={replace}
          handleSubmit={handleSubmit}
          calculationValues={{
            taxableAmount: EwayId
              ? eWayData?.eWayBill?.totalValue
              : dcInvId
              ? invEwayData?.ewb?.totalValue
              : "",
            cgst: EwayId
              ? eWayData?.eWayBill?.sgstValue
              : dcInvId
              ? invEwayData?.ewb?.sgstValue
              : "",
            sgst: EwayId
              ? eWayData?.eWayBill?.cgstValue
              : dcInvId
              ? invEwayData?.ewb?.cgstValue
              : "",
            igst: EwayId
              ? eWayData?.eWayBill?.igstValue
              : dcInvId
              ? invEwayData?.ewb?.igstValue
              : "",
            cessAdv: EwayId
              ? eWayData?.eWayBill?.cessValue
              : dcInvId
              ? invEwayData?.ewb?.cessValue
              : "",
            cessNonAdv: EwayId
              ? eWayData?.eWayBill?.cessNonAdvolValue
              : dcInvId
              ? invEwayData?.ewb?.cessNonAdvolValue
              : "",
            totalInv: EwayId
              ? eWayData?.eWayBill?.totInvValue
              : dcInvId
              ? invEwayData?.ewb?.totInvValue
              : ""
          }}
        />
      )
    },
    {
      title: "Transportation Details",
      component: <TransportationDetails control={control} ewayId={EwayId} />
    }
  ];

  const EditEwayBill = (EwayId) => {
    showDrawer({
      component: (
        <EwayBillForm
          hideDrawer={hideDrawer}
          EwayBillLoginShow={EwayBillLoginShow}
          EwayId={EwayId || null}
        />
      ),
      closeIcon: false
    });
  };

  const showInPartB = ["1", "3"];

  const onInvEwaySubmit = (data) => {
    const cleanObj = (obj) =>
      Object.fromEntries(
        Object.entries(obj).filter(
          ([, value]) => value !== undefined && value !== null && value !== ""
        )
      );

    const formatObj = cleanObj({
      transMode: data[EwayBillFormKeys.mode],
      transDistance: data[EwayBillFormKeys.distance],
      transporterName: data[EwayBillFormKeys.transporterName],
      transporterId: data[EwayBillFormKeys.transporterId],
      transDocNo: data[EwayBillFormKeys.transportDocNo],
      ...{
        ...(Boolean(data[EwayBillFormKeys.transportDocDate])
          ? {
              transDocDate: moment(
                data[EwayBillFormKeys.transportDocDate]
              ).format("YYYY-MM-DD")
            }
          : {})
      },
      vehicleNo: data[EwayBillFormKeys.vehicleNo],
      vehicleType: data[EwayBillFormKeys.vehicleType]
    });

    createInvEwayBill(
      {
        data: formatObj
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar("E-Waybill Created!", { variant: "success" });
          hideDrawer();
          EditEwayBill(res.data.data.eWayBill._id);
        }
      }
    );
  };

  const onSubmit = (data) => {
    const cleanObj = (obj) =>
      Object.fromEntries(
        Object.entries(obj).filter(
          ([, value]) => value !== undefined && value !== null && value !== ""
        )
      );

    const formatObj = cleanObj({
      supplyType: data[EwayBillFormKeys.supplyType],
      subSupplyType: data[EwayBillFormKeys.subType],
      subSupplyDesc: data["subSupplyDesc"],
      docType: data[EwayBillFormKeys.docType],
      docNo: data[EwayBillFormKeys.docNo],
      ...{
        ...(Boolean(data[EwayBillFormKeys.docDate])
          ? {
              docDate: moment(data[EwayBillFormKeys.docDate]).format(
                "YYYY-MM-DD"
              )
            }
          : {})
      },
      fromGstin: data[EwayBillFormKeys.supplierGstin],
      fromTrdName: data[EwayBillFormKeys.supplierName],
      fromAddr1: data[EwayBillFormKeys.shipFrom1],
      fromAddr2: data[EwayBillFormKeys.shipFrom2],
      fromPlace: data[EwayBillFormKeys.supplierPlace],
      fromPincode: data[EwayBillFormKeys.supplierPIN],
      fromState: data[EwayBillFormKeys.supplierState1],
      actFromState: data[EwayBillFormKeys.supplierState2],
      toGstin: data[EwayBillFormKeys.recipientGstin],
      toTrdName: data[EwayBillFormKeys.recipientName],
      toAddr1: data[EwayBillFormKeys.shipTo1],
      toAddr2: data[EwayBillFormKeys.shipTo2],
      toPlace: data[EwayBillFormKeys.recipientPlace],
      toPincode: data[EwayBillFormKeys.recipientPIN],
      toState: data[EwayBillFormKeys.recipientState1],
      actToState: data[EwayBillFormKeys.recipientState2],
      dispatchFromGSTIN: data[EwayBillFormKeys.supplierGstin],
      dispatchFromTradeName: data[EwayBillFormKeys.supplierName],
      shipToGSTIN: data[EwayBillFormKeys.recipientGstin],
      shipToTradeName: data[EwayBillFormKeys.recipientName],
      otherValue: data["others"],
      transactionType: data[EwayBillFormKeys.TransactionType],
      transMode: data[EwayBillFormKeys.mode],
      transDistance: data[EwayBillFormKeys.distance],
      transporterName: data[EwayBillFormKeys.transporterName],
      transporterId: data[EwayBillFormKeys.transporterId],
      transDocNo: data[EwayBillFormKeys.transportDocNo],
      ...{
        ...(Boolean(data[EwayBillFormKeys.transportDocDate])
          ? {
              transDocDate: moment(
                data[EwayBillFormKeys.transportDocDate]
              ).format("YYYY-MM-DD")
            }
          : {})
      },
      vehicleNo: data[EwayBillFormKeys.vehicleNo],
      vehicleType: data[EwayBillFormKeys.vehicleType],
      items: data["itemDetails"]?.map(cleanObj).map((item) =>
        cleanObj({
          sku: item._id,
          rate: +item.rate,
          quantity: +item.quantity,
          cessRate: +item.advol,
          cessNonAdvolValue: +item.Nonadval
        })
      )
    });

    createEwayBill(
      {
        data: formatObj
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar("E-Waybill Created!", { variant: "success" });
          refetch();
          hideDrawer();
          EditEwayBill(res.data.data.eWayBill._id);
        }
      }
    );
  };

  const onNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      setManuallyExpandedIndex(null);
      if (activeStep < sections.length) {
        setActiveStep((prev) => prev + 1);
      } else {
        handleSubmit(onSubmit)();
      }
    }
  };

  const onBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (isCreateError) {
      if (createError?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(handleSubmit(onSubmit));
      } else {
        enqueueSnackbar(createError?.response?.data?.message, {
          variant: "error"
        });
      }
    }
    if (isCreateInvError) {
      if (createInvError?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(handleSubmit(onInvEwaySubmit));
      } else {
        enqueueSnackbar(createInvError?.response?.data?.message, {
          variant: "error"
        });
      }
    } // eslint-disable-next-line
  }, [
    createError?.response?.data?.message,
    isCreateError,
    EwayBillLoginShow,
    createError?.response?.data?.ewbErrorCode,
    createInvError?.response?.data?.message,
    isCreateInvError,
    createInvError?.response?.data?.ewbErrorCode
  ]);

  return isFetchingEway || isSyncingEway || isFetchingInvEway ? (
    <Grid
      item
      xs={12}
      height={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Box component={"form"} onSubmit={handleSubmit(onNext)}>
      <Grid
        container
        sx={{
          padding: { xs: "20px 20px 0px 20px", md: "25px 25px 0px 25px" }
        }}
        gap={2}
      >
        <Grid container display={"flex"} alignItems={"center"}>
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { xs: 16, md: 20 },
                lineHeight: { xs: "18px", md: "24.2px" },
                color: "#027AFF"
              }}
            >
              {"E-Waybill"}
            </Typography>
          </Grid>
          <Grid item xs={4.5} md={6} display={"flex"} justifyContent={"center"}>
            {EwayId && (
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: 14, md: 20 },
                  lineHeight: { xs: "16px", md: "24.2px" },
                  color: "#027AFF"
                }}
              >
                {eWayData?.eWayBill?.ewbNo}
              </Typography>
            )}
          </Grid>
          <Grid item xs={1.5} md={1} display={"flex"} justifyContent={"center"}>
            {EwayId && eWayData?.eWayBill?._pm?.sync && (
              <CustomTooltip title={"Sync Data"} placement="top">
                <IconButton onClick={() => setIsSynced(true)}>
                  <Sync />
                </IconButton>
              </CustomTooltip>
            )}
          </Grid>
          <Grid item xs={1.5} md={1} display={"flex"} justifyContent={"center"}>
            {EwayId && (
              <CustomTooltip title={"Print"} placement="top">
                <IconButton onClick={handlePrint}>
                  <Print />
                </IconButton>
              </CustomTooltip>
            )}
          </Grid>
          <Grid
            item
            xs={1.5}
            md={1}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <IconButton onClick={hideDrawer}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Accordion
              elevation={0}
              sx={{
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "45px"
                }
              }}
              defaultExpanded={!EwayId || dcInvId}
              expanded={
                Boolean(updateTypeWatch)
                  ? manuallyExpandedIndex === null
                  : undefined
              }
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMore
                    sx={{
                      height: "25px",
                      width: "25px",
                      color: "#2773FF"
                    }}
                  />
                }
                sx={{
                  padding: 0,
                  border: "none",
                  position: "unset",
                  minHeight: 0,
                  display: "flex",
                  justifyContent: "left",
                  "& .MuiAccordionSummary-content": {
                    margin: 0,
                    flexGrow: 1
                  },
                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: 0,
                    flexGrow: 1
                  }
                }}
              >
                <Typography
                  fontWeight={500}
                  fontSize={20}
                  lineHeight={"24.2px"}
                  color={"#2773FF"}
                >
                  {"Part A"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  border: "none",
                  p: `0px`,
                  m: 0
                }}
              >
                <Grid container rowSpacing={2}>
                  {sections.map((section, index) => (
                    <Grid item xs={12} key={index}>
                      <AccordianInfo
                        title={section?.title}
                        key={index}
                        defaultExpanded={
                          Boolean(dcInvId) && index === 4 ? true : undefined
                        }
                        expanded={
                          !EwayId
                            ? Boolean(dcInvId)
                              ? undefined
                              : activeStep === index ||
                                manuallyExpandedIndex === index
                            : undefined
                        }
                        setManuallyExpandedIndex={() =>
                          Boolean(updateTypeWatch)
                            ? setManuallyExpandedIndex(null)
                            : setManuallyExpandedIndex(index)
                        }
                        hideDivider={section?.hideDivider || false}
                      >
                        {section?.component}
                      </AccordianInfo>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ border: "0.4px solid #B7BEC7" }} />
          </Grid>
          {!showInPartB.includes(updateType) && (
            <>
              <Grid item xs={12}>
                <Accordion
                  elevation={0}
                  defaultExpanded={
                    EwayId
                      ? showInPartB.includes(updateType)
                      : Boolean(dcInvId) || false
                  }
                  expanded={
                    !EwayId
                      ? Boolean(dcInvId)
                        ? undefined
                        : activeStep === sections.length ||
                          manuallyExpandedIndex === sections.length
                      : undefined
                  }
                  onChange={() => setManuallyExpandedIndex(sections.length)}
                  sx={{
                    "& .MuiAccordionSummary-root.Mui-expanded": {
                      minHeight: "45px"
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMore
                        sx={{
                          height: "25px",
                          width: "25px",
                          color: "#2773FF"
                        }}
                      />
                    }
                    sx={{
                      padding: 0,
                      border: "none",
                      position: "unset",
                      minHeight: 0,
                      display: "flex",
                      justifyContent: "left",
                      "& .MuiAccordionSummary-content": {
                        margin: 0,
                        flexGrow: 1
                      },
                      "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: 0,
                        flexGrow: 1
                      }
                    }}
                  >
                    <Grid item xs={4} display={"flex"} alignItems={"center"}>
                      <Typography
                        fontWeight={500}
                        fontSize={20}
                        lineHeight={"24.2px"}
                        color={"#2773FF"}
                      >
                        {"Part B"}
                      </Typography>
                    </Grid>
                    {EwayId && (
                      <Grid item xs={8} display={"flex"} alignItems={"center"}>
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", md: "14px" },
                            fontWeight: 600,
                            lineHeight: { xs: "14px", md: "16.94px" },
                            color: "#2773FF",
                            letterSpacing: "0.002em"
                          }}
                        >
                          {`EWB valid till : ${moment(
                            eWayData?.eWayBill?.ewbValidity
                          ).format("DD.MM.YYYY")} ${moment(
                            eWayData?.eWayBill?.ewbValidity
                          ).format("HH:mm")}`}
                        </Typography>
                      </Grid>
                    )}
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      border: "none",
                      p: `0px`,
                      m: 0
                    }}
                  >
                    {EwayId ? (
                      <Grid
                        container
                        sx={{
                          padding: "40px 20px 10px 20px"
                        }}
                        spacing={3}
                      >
                        {eWayData?.eWayBill?.partBs?.map((item, index) => (
                          <Grid item xs={12} key={index} mb={1}>
                            <VehicleHistory {...item} />
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <PartB
                        control={control}
                        isFetching={isFetching}
                        transportationMode={data?.transportationModes}
                        vehicleType={data?.vehicleTypes}
                        ewayId={EwayId}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  pb: {
                    xs: !EwayId ? "120px" : 0,
                    md: !EwayId ? "100px" : 0
                  }
                }}
              >
                <Divider sx={{ border: "0.4px solid #B7BEC7" }} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {EwayId ? (
        <>
          {getUpdateType(updateType)}
          <div style={{ display: "none" }}>
            <div ref={componentRef}>
              <EwayPrintPage data={eWayData?.eWayBill} />
            </div>
          </div>
        </>
      ) : (
        <Box
          display={"flex"}
          sx={{
            padding: {
              xs: "10px 16px 10px 16px",
              md: 0
            },
            gap: { xs: 1, md: 0 },
            width: { xs: "100%", md: 520 },
            background: { xs: "white", md: "transparent" },
            position: "fixed",
            borderTop: { xs: "1px solid #B7BEC7", md: "none" },
            bottom: 0
          }}
        >
          {dcInvId ? (
            <Button
              type="button"
              variant="contained"
              component={LoadingButton}
              loading={isCreatingInvEwayBill}
              fullWidth
              sx={{
                fontWeight: 500,
                fontSize: { xs: 15, md: 24 },
                height: {
                  xs: 48,
                  md: 81
                },
                borderRadius: { xs: "8px", md: 0 }
              }}
              onClick={handleSubmit(onInvEwaySubmit)}
            >
              {"Submit"}
            </Button>
          ) : (
            <>
              {activeStep > 0 && (
                <Button
                  type="button"
                  variant="soft"
                  fullWidth
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: 15, md: 24 },
                    height: {
                      xs: 48,
                      md: 81
                    },
                    borderRadius: { xs: "8px", md: 0 },
                    backgroundColor: "#EFF2F7",
                    "&:hover": {
                      backgroundColor: "#EFF2F7",
                      color: "#2773FF"
                    }
                  }}
                  onClick={onBack}
                >
                  {"Go back"}
                </Button>
              )}
              <Button
                type="button"
                variant="contained"
                component={LoadingButton}
                loading={isCreatingEwayBill}
                fullWidth
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: 15, md: 24 },
                  height: {
                    xs: 48,
                    md: 81
                  },
                  borderRadius: { xs: "8px", md: 0 }
                }}
                onClick={() => {
                  activeStep < 3 || activeStep >= sections.length
                    ? onNext()
                    : setActiveStep((prev) => prev + 1);
                }}
              >
                {activeStep < sections.length
                  ? `Next(${activeStep + 1}/${sections.length + 1})`
                  : "Generate E-Waybill"}
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default EwayBillForm;
