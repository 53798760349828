import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid
} from "@mui/material";
import { Done } from "@mui/icons-material";

const PricingCard = ({
  tag = "SINGLE USER",
  tagBgColor = "#607088",
  usageText = "Free Usage",
  usageTextColor = "#027AFF",
  cardBgColor = "#B7BEC7",
  features = [
    "Single Warehouse",
    "Inventory Management",
    "Purchases, Returns",
    "Sales, Invoices",
    "Dues, Receivables"
  ]
}) => {
  return (
    <Box position={"relative"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: 15,
          left: { xs: -40, md: -60 },
          backgroundColor: tagBgColor,
          padding: "4px 12px",
          zIndex: 1,
          width: { xs: "210px", md: "270px" },
          height: { xs: "51px", md: "78px" },
          borderRadius: "20px"
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "26px", md: "32px" },
            fontWeight: 700,
            lineHeight: { xs: "31.47px", md: "38.73px" },
            color: "#FFFFFF"
          }}
        >
          {tag}
        </Typography>
      </Box>
      <Card
        sx={{
          borderRadius: "20px",
          backgroundColor: cardBgColor,
          padding: { xs: "10px", md: "20px" },
          position: "relative",
          height: { xs: "353px", md: "450px" },
          width: { xs: "277px", md: "355px" }
        }}
      >
        <CardContent>
          <Grid item xs={12} sx={{ mt: { xs: 9, md: 11 } }}>
            <Typography
              sx={{
                fontSize: { xs: "24px", md: "32px" },
                lineHeight: { xs: "29.05px", md: "38.72px" },
                color: usageTextColor,
                fontWeight: 700,
                ml: 5
              }}
            >
              {usageText}
            </Typography>
          </Grid>
          <List>
            {features.map((feature, index) => (
              <ListItem key={index} disableGutters>
                <ListItemIcon>
                  <Done sx={{ color: "#FFFFFF" }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px ", md: "24px " },
                      fontWeight: 700,
                      lineHeight: {
                        xs: "21.78px ",
                        md: "29.05px "
                      },
                      ml: -2,
                      color: "#FFFFFF"
                    }}
                  >
                    {feature}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PricingCard;
