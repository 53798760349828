import React, { useCallback, useState } from "react";
import DeliveryChallanTable from "./DeliveryChallanTable";
import GatePassTable from "./GatePassTable";
import { CustomTabs } from "../../../shared/customTab/CustomTabs";
import CreateInvoiceTable from "./CreateInvoiceTable";
import { useFetchGPDocuments } from "../../../query-hooks/ClientOrderGatePass/useFetchGPDocuments";
import { Backdrop, Box, Button, Card, CircularProgress } from "@mui/material";
import { statusKeys } from "../../../constants";
import CreateDebitNote from "./CreateDebitNote";
import { isMobileDevice } from "../../../App";
import EwayBillForm from "../EwayBills/EwayBillForm";
import EwayBillLogin from "../EwayBills/EwayBillLogin";
import { useCustomDialog } from "../../../shared/customDialog";
import { useDrawer } from "../../../shared/customSideDrawer";
import { isMobile } from "react-device-detect";

const getDraftItems = (gatePassItems, formDataItems) => {
  const skuSet = new Set(gatePassItems?.map((item) => item?.sku));
  const filterFormDataItems = formDataItems?.filter(
    (item) => !skuSet.has(item.sku)
  );
  filterFormDataItems?.forEach((obj) => {
    obj.quantity = 0;
  });
  return [...gatePassItems, ...filterFormDataItems];
};

export const getIdsByDocType = (doc = [], docType) => {
  return (
    doc?.find((obj) => obj.docType && obj.docType.displayName === docType) ||
    null
  );
};

const GatePassTabs = ({
  orderId,
  GatePassId,
  expanded,
  GatePassData,
  isTaxApplicable,
  GatePassVehicleOptions,
  orderInfo,
  CreateGPFormData,
  refetchGPs,
  refetchFormData,
  DcCreateAllowed,
  InvCreateAllowed
}) => {
  const { showDrawer, hideDrawer } = useDrawer();
  const { showDialog, hideDialog } = useCustomDialog();
  const [gatePassTab, setGatePassTab] = useState(0);

  const handleTabChange = (index) => {
    setGatePassTab(index);
  };

  const {
    data: Documents,
    isFetching: isFetchingDocs,
    refetch: refectDocs
  } = useFetchGPDocuments(
    orderId,
    GatePassId,
    expanded,
    GatePassData?.status?.name === "Placed"
  );

  const InvoiceData =
    Documents?.order?.orderType?.name === "SaleOrder"
      ? getIdsByDocType(Documents?.documents, "Invoice")
      : getIdsByDocType(Documents?.documents, "Debit Note");

  const EwayBillLoginShow = useCallback(
    (autoSubmit) => {
      showDialog({
        component: (
          <EwayBillLogin hideDialog={hideDialog} autoSubmit={autoSubmit} />
        ),
        size: "xs",
        closeIcon: true
      });
    },
    [showDialog, hideDialog]
  );

  const CreateEwayBill = useCallback(
    (EwayId) => {
      showDrawer({
        component: (
          <EwayBillForm
            hideDrawer={hideDrawer}
            EwayBillLoginShow={EwayBillLoginShow}
            EwayId={EwayId || null}
            refetch={refectDocs}
            dcInvId={EwayId ? null : InvoiceData?._id || null}
          />
        ),
        closeIcon: false
      });
    },
    [hideDrawer, showDrawer, EwayBillLoginShow, refectDocs, InvoiceData?._id]
  );

  const EwayNoButton = () => {
    return DcData?.ewbNo ? (
      <Button
        variant="link"
        disableRipple
        onClick={() => DcCreateAllowed && CreateEwayBill(DcData?.ewb)}
        sx={{ fontSize: 15, fontWeight: 600 }}
      >
        {`${isMobile ? "Ewb:" : "EwayBill No.:"} ${DcData?.ewbNo}`}
      </Button>
    ) : (
      ""
    );
  };

  const draftItems = getDraftItems(
    GatePassData?.items ?? [],
    CreateGPFormData?.order?.items ?? []
  );

  const createGatePass = (
    <GatePassTable
      orderId={orderId}
      truckRegNoOptions={GatePassVehicleOptions}
      GatePassStatus={GatePassData?.status?.name}
      tableData={draftItems}
      orderInfo={orderInfo}
      GatePassInfo={GatePassData}
      isTaxApplicable={isTaxApplicable}
      refetchGPs={refetchGPs}
      refetchFormData={refetchFormData}
      ewayButton={EwayNoButton}
      DcCreateAllowed={DcCreateAllowed}
    />
  );

  const DcData = getIdsByDocType(Documents?.documents, "Delivery Challan");

  const isDcPlaced = DcData
    ? Boolean(DcData?.status?.name !== statusKeys.Draft)
    : false;

  const deliveryChallan = (
    <DeliveryChallanTable
      gatePassId={GatePassData?._id ?? ""}
      dcId={DcData?._id}
      DcData={DcData}
      orderInfo={orderInfo}
      DcCreateAllowed={DcCreateAllowed}
      refectDocs={refectDocs}
      ewayButton={EwayNoButton}
    />
  );

  const isInvoicePlaced = InvoiceData
    ? Boolean(InvoiceData?.status?.name !== statusKeys.Draft)
    : false;

  const invoice =
    Documents?.order?.orderType?.name === "SaleOrder" ? (
      <CreateInvoiceTable
        orderId={orderId}
        dcId={DcData?._id}
        invId={InvoiceData?._id}
        deliveredDate={DcData?.deliveredDate || null}
        orderType={Documents?.order?.orderType?.name}
        orderInfo={Documents?.order}
        InvoiceData={InvoiceData}
        DcCreateAllowed={DcCreateAllowed}
        InvCreateAllowed={InvCreateAllowed}
        refectDocs={refectDocs}
        ewayButton={EwayNoButton}
        CreateEwayBill={CreateEwayBill}
        ewbId={DcData?.ewb}
      />
    ) : (
      <CreateDebitNote
        orderId={orderId}
        dcId={DcData?._id}
        invId={InvoiceData?._id}
        orderType={Documents?.order?.orderType?.name}
        orderInfo={Documents?.order}
        InvoiceData={InvoiceData}
        InvCreateAllowed={InvCreateAllowed}
        refectDocs={refectDocs}
      />
    );

  const tabs = [
    {
      label: GatePassData?.code ?? "Draft",
      Component: createGatePass
    },
    ...((DcCreateAllowed && GatePassData?.status?.name === "Placed") ||
    isDcPlaced
      ? [{ label: `Delivery Challan`, Component: deliveryChallan }]
      : []),
    ...(isInvoicePlaced ||
    (isDcPlaced &&
      InvCreateAllowed &&
      Documents?.order?.orderType?.name !== "StockTransfer")
      ? [
          {
            label:
              Documents?.order?.orderType?.name === "SaleOrder"
                ? `Invoice`
                : `Debit Note`,
            Component: invoice
          }
        ]
      : [])
  ];

  return isFetchingDocs ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetchingDocs}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (DcCreateAllowed && GatePassData?.status?.name === "Placed") ||
    isDcPlaced ? (
    <Card
      sx={{
        p: { xs: 0, md: 1 }
      }}
    >
      <CustomTabs
        tabs={tabs}
        activeTab={gatePassTab}
        onChange={handleTabChange}
        fixTab={isMobileDevice}
      />
    </Card>
  ) : (
    <Box display={"block"} sx={{ mt: { xs: 0, md: 5 } }}>
      {createGatePass}
    </Box>
  );
};

export default GatePassTabs;
