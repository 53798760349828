import { getOtherEwayByDateSync } from "../../../inventory/services/index";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useSyncOtherDataByDate = (isSyncByOther, params) => {
  const queryOtherEwayByDateSync = useQuery(
    [queryKeys.querySyncOtherEwayBillByDate, isSyncByOther, params],
    () => getOtherEwayByDateSync(params),
    {
      enabled: isSyncByOther && Boolean(params?.date),
      refetchOnWindowFocus: false
    }
  );

  return queryOtherEwayByDateSync;
};
