import * as yup from "yup";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../shared/context/auth-context";
import { useFetchGPs } from "../../query-hooks/ClientOrderGatePass/useFetchGPs";
import { useFetchGPFormData } from "../../query-hooks/ClientOrderGatePass/useFetchGPFormData";
import MiniDialogBoxForms from "../../shared/UiElements/MiniDialogBoxForms";
import { enqueueSnackbar } from "notistack";
import { useRecordDelivered } from "../../query-hooks/ClientOrderDeliveryChallan/useRecordDelivered";
import { useCreateDCInvoicePayment } from "../../query-hooks/ClientOrderInvoicePayments/useCreateDCInvoicePayment";
import { useCustomDialog } from "../../shared/customDialog";
import { useFetchDeliveredFormData } from "../../query-hooks/ClientOrderDeliveryChallan/useFetchDeliveredFormData";
import { useFetchDCInvoicePaymentFormData } from "../../query-hooks/ClientOrderInvoicePayments/useFetchDCInvoicePaymentFormData";
import { error_msg, statusKeys } from "../../constants";
import DateInput from "../../shared/formElements/DateInput";
import TextInput from "../../shared/formElements/TextInput";
import DropDown from "../../shared/formElements/DropDown";
import { CalendarTodayOutlined } from "@mui/icons-material";
import moment from "moment";
import { dropdownOptions } from "../../shared/utils";
import GatePassTable from "../components/GatePass/GatePassTable";
import { LoadingButton } from "@mui/lab";
import { CustomTabs } from "../../shared/customTab/CustomTabs";
import GatePassTabs from "../components/GatePass/GatePassTabs";

const RecordDeliveryDateForm = (control, options) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      gap={1}
    >
      <Box width={"183px"}>
        <DropDown
          control={control}
          name={"dcId"}
          label={"Select Delivery Challan"}
          options={options}
        />
      </Box>
      <Box width={"183px"}>
        <DateInput
          control={control}
          name={"date"}
          minDate={new Date("1900-01-01")}
          endIcon={CalendarTodayOutlined}
          placeholder={"Pick Date and time"}
        />
      </Box>
    </Box>
  );
};

const ReceivableForm = (control, options) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      gap={1}
    >
      <Box width={"183px"}>
        <DropDown
          control={control}
          name={"invId"}
          label={"Select Invoice"}
          options={options}
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <DateInput
          control={control}
          name={"date"}
          minDate={new Date("1900-01-01")}
          endIcon={CalendarTodayOutlined}
          placeholder={"Pick Date"}
          popperPlacement={"right-end"}
        />
        <TextInput control={control} name={"utrNo"} placeholder={"UTR No."} />
        <TextInput
          control={control}
          name={"invoiceAmount"}
          placeholder={"Inv Amt"}
        />
      </Box>
    </Box>
  );
};

const verifyDeliverySchema = yup.object({
  dcId: yup.string().required(error_msg.required),
  date: yup.string().required(error_msg.required)
});

const recordReceivableSchema = yup.object({
  invId: yup.string().required(error_msg.required),
  date: yup.string().required(error_msg.required),
  utrNo: yup.string().required(error_msg.required),
  invoiceAmount: yup.string().required(error_msg.required)
});

const GatePass = () => {
  const { profileData } = useContext(AppContext);
  const { orderId, Document, Page } = useParams();
  const { showDialog, hideDialog } = useCustomDialog();
  const { role, allowedScModules } = profileData?.user;
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const DcCreateAllowed =
    !Boolean(Document) &&
    (Boolean(role === "CustomerAdmin") ||
      allowedScModules?.some(({ name }) => name === "Warehouse"));

  const InvCreateAllowed =
    Boolean(Document) &&
    Boolean(Page === "Invoice") &&
    (Boolean(role === "CustomerAdmin") ||
      allowedScModules?.some(({ name }) => name === "Finance"));

  const RecordCreateAllowed =
    Boolean(Document) &&
    Boolean(Page === "Sale") &&
    (Boolean(role === "CustomerAdmin") ||
      allowedScModules?.some(({ name }) => name === "Sale"));

  const {
    data: GatePassesData,
    isFetching: isGatePassesFetching,
    refetch: refetchGPs
  } = useFetchGPs(orderId);

  const AllowCreate =
    DcCreateAllowed &&
    !isGatePassesFetching &&
    Boolean(
      GatePassesData?.order?.itemDetails?.quantity >
        GatePassesData?.order?.itemDetails?.outQuantity
    );

  const {
    data: CreateGPFormData,
    isFetching: isFetchingFormData,
    refetch: refetchFormData
  } = useFetchGPFormData(orderId, AllowCreate);

  const {
    data: DCDropDown,
    isFetching: isFetchingDCDropDown,
    refetch: refetchDCDropDown
  } = useFetchDeliveredFormData(orderId, RecordCreateAllowed);

  const {
    data: InvoicesDropDown,
    isFetching: isFetchingInvoicesDropDown,
    refetch: refetchInvDropDown
  } = useFetchDCInvoicePaymentFormData(orderId, RecordCreateAllowed);

  const {
    mutate: recordDelivery,
    isError: isRecordDeliveryError,
    error: recordDeliveryError
  } = useRecordDelivered();

  const {
    mutate: createDCInvoice,
    isError: isCreateInvoiceError,
    error: createInvoiceError
  } = useCreateDCInvoicePayment();

  const onRecordDeliveryDate = (data) => {
    const formData = {
      deliveredDate: moment(data["date"]).format("YYYY-MM-DD")
    };
    recordDelivery(
      {
        data: formData,
        dcId: data["dcId"]
      },
      {
        onSuccess: (res) => {
          hideDialog();
          enqueueSnackbar("Marked delivered!", {
            variant: "success"
          });
          refetchDCDropDown();
          refetchGPs();
        }
      }
    );
  };

  const onCreateInvoice = (data) => {
    const formData = {
      utrNo: data["utrNo"],
      paymentDate: moment(data["date"]).format("YYYY-MM-DD"),
      amount: +data["invoiceAmount"]
    };
    createDCInvoice(
      {
        data: formData,
        invoiceId: data["invId"]
      },
      {
        onSuccess: (res) => {
          hideDialog();
          enqueueSnackbar("DC Invoice Saved!", {
            variant: "success"
          });
          refetchInvDropDown();
        }
      }
    );
  };

  const onRecordDelivery = () => {
    showDialog({
      component: (
        <MiniDialogBoxForms
          text={
            "I have verified that the stock is delivered at customer's Shipto location on"
          }
          btnText={"Submit"}
          btnCallback={onRecordDeliveryDate}
          childComponent={RecordDeliveryDateForm}
          schema={verifyDeliverySchema}
          options={
            dropdownOptions(DCDropDown?.deliveryChallans, "_id", "code") ?? []
          }
        />
      )
    });
  };

  const mergeDropDownArrays = (arr1, arr2) => {
    const merged = [...arr1, ...arr2];
    const result = {};

    merged.forEach((item) => {
      if (item.displayName) {
        result[item.name] = { ...result[item.name], ...item };
      }
    });

    return Object.values(result);
  };

  const onRecordReceivable = () => {
    showDialog({
      component: (
        <MiniDialogBoxForms
          text={""}
          btnText={"Submit"}
          btnCallback={onCreateInvoice}
          childComponent={ReceivableForm}
          schema={recordReceivableSchema}
          options={mergeDropDownArrays(
            dropdownOptions(InvoicesDropDown?.invoices, "_id", "code") ?? [],
            dropdownOptions(InvoicesDropDown?.invoices, "_id", "customCode") ??
              []
          )}
        />
      )
    });
  };

  const viewOnlyGPs =
    !AllowCreate && GatePassesData?.gatePasses?.length
      ? GatePassesData?.gatePasses?.filter(
          (item) => item.status.name !== statusKeys.Draft
        )
      : GatePassesData?.gatePasses;

  const NewGP = (
    <GatePassTable
      orderId={orderId}
      truckRegNoOptions={dropdownOptions(
        CreateGPFormData?.vehicles,
        "regNo",
        "regNo"
      )}
      tableData={CreateGPFormData?.order?.items}
      orderInfo={CreateGPFormData?.order}
      isTaxApplicable={CreateGPFormData?.taxRule?.isTaxApplicable}
      refetchGPs={refetchGPs}
      refetchFormData={refetchFormData}
    />
  );

  useEffect(() => {
    if (isRecordDeliveryError) {
      enqueueSnackbar(recordDeliveryError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isCreateInvoiceError) {
      enqueueSnackbar(createInvoiceError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    recordDeliveryError?.response?.data?.message,
    isRecordDeliveryError,
    createInvoiceError?.response?.data?.message,
    isCreateInvoiceError
  ]);

  const tabs = [].concat(
    viewOnlyGPs?.map((item, index) => ({
      label: `${item?.code ?? "Draft"}`,
      Component: (
        <GatePassTabs
          orderId={orderId}
          GatePassId={item?._id}
          expanded={activeTab === index}
          GatePassData={item}
          orderInfo={GatePassesData?.order}
          GatePassVehicleOptions={
            CreateGPFormData?.vehicles
              ? dropdownOptions(CreateGPFormData?.vehicles, "regNo", "regNo")
              : []
          }
          CreateGPFormData={CreateGPFormData}
          refetchGPs={refetchGPs}
          refetchFormData={refetchFormData}
          DcCreateAllowed={DcCreateAllowed}
          InvCreateAllowed={InvCreateAllowed}
          isTaxApplicable={GatePassesData?.taxRule?.isTaxApplicable}
        />
      )
    })),
    ...(AllowCreate &&
    GatePassesData?.gatePasses?.length &&
    !Boolean(
      GatePassesData?.gatePasses?.some((obj) => obj.status.name === "Draft")
    ) &&
    !isFetchingFormData
      ? [
          {
            label: `New GP`,
            Component: NewGP
          }
        ]
      : [])
  );

  return isGatePassesFetching || isFetchingFormData ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isGatePassesFetching || isFetchingFormData}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box
      display={"flex"}
      flexDirection={"column"}
      paddingBottom={"20px"}
      gap={4}
    >
      {RecordCreateAllowed &&
        GatePassesData?.order?.orderType?.name === "SaleOrder" && (
          <>
            <Box
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              columnGap={4}
              sx={{
                mt: { xs: -4.5, md: 0 },
                ml: { xs: 5, md: 0 },
                pr: { xs: 2, md: 0 }
              }}
              zIndex={1}
            >
              <Button
                variant="link"
                component={LoadingButton}
                loading={isFetchingDCDropDown}
                disableRipple
                onClick={onRecordDelivery}
                disabled={
                  isFetchingDCDropDown
                    ? true
                    : !Boolean(DCDropDown?.deliveryChallans?.length)
                }
              >
                {"Record Delivery date"}
              </Button>
              <Button
                variant="link"
                component={LoadingButton}
                loading={isFetchingInvoicesDropDown}
                disableRipple
                onClick={onRecordReceivable}
                disabled={
                  isFetchingInvoicesDropDown
                    ? true
                    : !Boolean(InvoicesDropDown?.invoices?.length)
                }
              >
                {"Record Receivable"}
              </Button>
            </Box>
            <Divider sx={{ display: { xs: "none", md: "block" } }} />
          </>
        )}
      <Box>
        {AllowCreate &&
        GatePassesData?.gatePasses?.length === 0 &&
        !Boolean(
          GatePassesData?.gatePasses?.some((obj) => obj.status.name === "Draft")
        ) &&
        !isFetchingFormData ? (
          NewGP
        ) : (
          <CustomTabs
            tabs={tabs}
            activeTab={activeTab}
            onChange={handleTabChange}
            backgroundColor={"#F0F2F5"}
          />
        )}
      </Box>
    </Box>
  );
};

export default GatePass;
