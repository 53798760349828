import React from "react";
import RadioButtonGroup from "../../../shared/formElements/RadioButtonGroup";
import { Box, Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { EwayBillFormKeys } from "../../../constants/formKeys";

const updateTypes = [
  {
    key: "updatePartB",
    label: "Update Part B",
    value: "1",
    color: "#1B51B3",
    xsSize: 5.5,
    mdSize: 5
  },
  {
    key: "updateTransporter",
    label: "Update Transporter",
    value: "2",
    color: "#6097FF",
    xsSize: 6.5,
    mdSize: 5.7
  },
  {
    key: "updateValidity",
    label: "Extend Validity",
    value: "3",
    color: "#F9A90E",
    xsSize: 5.7,
    mdSize: 5.2
  },
  {
    key: "cancel",
    label: "Cancel",
    value: "4",
    color: "#F05454",
    xsSize: 3.3,
    mdSize: 3
  },
  {
    key: "reject",
    label: "Reject",
    value: "5",
    color: "#F05454",
    xsSize: 3,
    mdSize: 3
  }
];

const UpdateOptions = ({
  control,
  updateTypeWatch,
  setUpdateType,
  allowedActions
}) => {
  const isProceedEnabled = Object.values(allowedActions).some(
    (action) => action
  );

  const onProceedClick = () => {
    setUpdateType(updateTypeWatch);
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "20px 20px 120px 20px", md: "25px 25px 100px 25px" }
        }}
        gap={2}
      >
        <Grid item xs={12}>
          <RadioButtonGroup
            defaultValue={
              allowedActions?.updatePartB
                ? isProceedEnabled && "1"
                : isProceedEnabled && "5"
            }
            control={control}
            name={EwayBillFormKeys.updateType}
            options={updateTypes.map((item) => ({
              label: item.label,
              value: item.value,
              color: item.color,
              xsSize: item.xsSize,
              mdSize: item.mdSize,
              disabled: !allowedActions[item.key]
            }))}
            sx={{
              fontWeight: 600,
              display: "flex",
              justifyContent: "center"
            }}
          />
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        sx={{
          padding: {
            xs: "10px 16px 10px 16px",
            md: 0
          },
          gap: { xs: 1, md: 0 },
          width: { xs: "100%", md: 520 },
          background: { xs: "white", md: "transparent" },
          position: "fixed",
          borderTop: { xs: "1px solid #B7BEC7", md: "none" },
          bottom: 0
        }}
      >
        <Button
          type="button"
          variant="contained"
          component={LoadingButton}
          loading={false}
          fullWidth
          disabled={!isProceedEnabled}
          sx={{
            fontWeight: 500,
            fontSize: { xs: 15, md: 24 },
            height: {
              xs: 48,
              md: 81
            },
            borderRadius: { xs: "8px", md: 0 }
          }}
          onClick={onProceedClick}
        >
          {"Proceed"}
        </Button>
      </Box>
    </>
  );
};

export default UpdateOptions;
