import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import React from "react";
import { AddressSection } from "../pages/MaterialReceivedReports";

export const DocInfo = ({ label, value }) => {
  return (
    <Grid
      item
      xs={12}
      container
      columnSpacing={2}
      display={"flex"}
      alignItems={"center"}
    >
      <Grid item xs={6} display={"flex"} justifyContent={"right"}>
        <Typography fontSize={14} fontWeight={400} lineHeight={"16.94px"}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography fontSize={14} fontWeight={400} lineHeight={"16.94px"}>
          {value || "--"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const AccordianInfo = ({
  title,
  children,
  defaultExpanded = false,
  hideDivider = false
}) => {
  return (
    <Grid item xs={12}>
      {!hideDivider && (
        <Divider sx={{ border: "0.4px solid #B7BEC7", mx: "15px" }} />
      )}
      <Accordion elevation={0} defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={
            <ExpandMore
              sx={{
                height: "25px",
                width: "25px",
                color: "#2773FF"
              }}
            />
          }
        >
          <Typography
            fontWeight={600}
            fontSize={14}
            lineHeight={"16.94px"}
            color={"#1B51B3"}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const MobileOrdersPage = ({
  title,
  subTitle = "",
  docInfo,
  accordianInfo,
  showOtherDetails = false,
  showAttachments = false,
  hideTotal = false,
  otherInfo,
  mobileTable,
  Total,
  Remarks,
  preparedBy,
  ButtonGroup,
  Attachments
}) => {
  return (
    <Box
      sx={{
        border: "0.4px solid #B7BEC7",
        boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
        borderRadius: "8px",
        paddingTop: "40px",
        position: "relative"
      }}
    >
      <Grid container rowSpacing={2}>
        <Grid item xs={12} container rowSpacing={0.5}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Typography fontSize={18} fontWeight={600} lineHeight={"21.78px"}>
              {title}
            </Typography>
          </Grid>
          {Boolean(subTitle) && (
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <Typography fontSize={16} fontWeight={500} lineHeight={"20px"}>
                {subTitle}
              </Typography>
            </Grid>
          )}
        </Grid>
        {docInfo?.map((item, index) => (
          <DocInfo key={index} label={item.label} value={item.value} />
        ))}
        <Grid item xs={12} container rowSpacing={1}>
          {accordianInfo?.map((item, index) => (
            <AccordianInfo key={index} title={item?.title}>
              <AddressSection
                name={item?.name}
                addressLine1={item?.addressLine1}
                addressLine2={item?.addressLine2}
                addressLine3={item?.addressLine3 ?? ""}
              />
            </AccordianInfo>
          ))}
          {showOtherDetails && (
            <AccordianInfo title={"Other details"} defaultExpanded>
              {otherInfo}
            </AccordianInfo>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ border: "0.4px solid #B7BEC7", mx: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          {mobileTable}
        </Grid>
        {!hideTotal && (
          <Grid item xs={12} container rowSpacing={1}>
            <AccordianInfo title={"Totals"} defaultExpanded>
              <Grid container rowSpacing={2}>
                {Total?.map((item, index) => (
                  <DocInfo key={index} label={item.label} value={item.value} />
                ))}
              </Grid>
            </AccordianInfo>
          </Grid>
        )}
        <Grid item xs={12} container rowSpacing={1}>
          <AccordianInfo title={"Remarks"} defaultExpanded>
            {Remarks}
          </AccordianInfo>
        </Grid>
        {showAttachments && (
          <Grid item xs={12} container rowSpacing={1}>
            <AccordianInfo title={"Attachments"} defaultExpanded>
              {Attachments}
            </AccordianInfo>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider sx={{ border: "0.4px solid #B7BEC7", mx: "15px" }} />
        </Grid>
        <Grid item xs={12} pl={"15px"} mb={2}>
          <Typography fontSize={14} fontWeight={400} lineHeight={"16.94px"}>
            {`Prepared By : ${preparedBy}`}
          </Typography>
        </Grid>
        {ButtonGroup}
      </Grid>
    </Box>
  );
};

export default MobileOrdersPage;
