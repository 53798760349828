import * as yup from "yup";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid
} from "@mui/material";
import TextInput from "../../../shared/formElements/TextInput";
import { useForm } from "react-hook-form";
import { add_customer_fiterKeys } from "../../../constants/formKeys";
import { add_customer_labels, error_msg } from "../../../constants";
import CheckBoxGroup from "../../../shared/formElements/CheckBoxGroup";
import AddStoreForm from "./AddStoreForm";
import DropDown from "../../../shared/formElements/DropDown";
import { AddOutlined } from "@mui/icons-material";
import AddStore from "../../../assets/icons/AddStores.svg";
import { capitalizeFirstCharacter, dropdownOptions } from "../../../shared/utils";
import { useFetchBillingFormData } from "../../../query-hooks/ClientsCustomer/CustomerBillingAddress/useFetchBillingFormData";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useCreateBillingAddress } from "../../../query-hooks/ClientsCustomer/CustomerBillingAddress/useCreateBillingAddress";
import { useUpdateBillingAddress } from "../../../query-hooks/ClientsCustomer/CustomerBillingAddress/useUpdateBillingAddress";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { useFetchBillingById } from "../../../query-hooks/ClientsCustomer/CustomerBillingAddress/useFetchBillingById";
import { useFetchShipToAddress } from "../../../query-hooks/ClientsCustomer/CustomerShipToAddress/useFetchShipToAddress";
import { TitleText } from "../../../warehouse/pages/Assets";
import WarehouseCard from "../../../warehouse/components/WarehouseAssets/WarehouseCard";
import {
  BlockedIcon,
  StyledBox,
  StyledCheckBoxContainer
} from "../../../purchase/pages/AddVendor";
import { AccordianInfo } from "../../../warehouse/components/MobileOrdersPage";
import { DrawerButton } from "../../../inventory/components/SKU/SkuDetailsForm";
import MobileWarehouseCard from "../../../warehouse/components/WarehouseAssets/MobileWarehouseCard";
import { useDrawer } from "../../../shared/customSideDrawer";
import { useFetchGst } from "../../../query-hooks/ClientEwayBill/useFetchGst";
import EwayBillLogin from "../../../warehouse/components/EwayBills/EwayBillLogin";
import { useCustomDialog } from "../../../shared/customDialog";
import { VerifiedPhone } from "../../../customer/pages/Profile";
import ErrorPopup from "../../../shared/UiElements/ErrorPopup";

const createBillingSchema = (includeGSTIN) => {
  const baseSchema = {
    [add_customer_fiterKeys.address]: yup.string().required(error_msg.required),
    [add_customer_fiterKeys.area]: yup.string().required(error_msg.required),
    [add_customer_fiterKeys.city]: yup.string().required(error_msg.required),
    [add_customer_fiterKeys.state]: yup.string().required(error_msg.required),
    [add_customer_fiterKeys.zipCode]: yup.string().required(error_msg.required),
    [add_customer_fiterKeys.mapstore]: yup
      .string()
      .required(error_msg.required),
    [add_customer_fiterKeys.shipToAddress]: yup
      .string()
      .required(error_msg.required),
    [add_customer_fiterKeys.shipToArea]: yup
      .string()
      .required(error_msg.required),
    [add_customer_fiterKeys.shipToCity]: yup
      .string()
      .required(error_msg.required),
    [add_customer_fiterKeys.shipToState]: yup
      .string()
      .required(error_msg.required),
    [add_customer_fiterKeys.shipToZipCode]: yup
      .string()
      .required(error_msg.required),
    [add_customer_fiterKeys.pointofContact]: yup
      .string()
      .required(error_msg.required),
    [add_customer_fiterKeys.mobileNo]: yup
      .string()
      .required(error_msg.required),
    [add_customer_fiterKeys.codeArea]: yup.string().required(error_msg.required)
  };

  if (includeGSTIN) {
    baseSchema[add_customer_fiterKeys.gstin] = yup
      .string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Please Enter Correct GSTIN"
      )
      .required(error_msg.required);
  }

  return yup.object(baseSchema);
};

const shipToSchema = yup.object({
  [add_customer_fiterKeys.mapstore]: yup.string().required(error_msg.required),
  [add_customer_fiterKeys.shipToAddress]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.shipToArea]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.shipToCity]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.shipToState]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.shipToZipCode]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.pointofContact]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.mobileNo]: yup.string().required(error_msg.required),
  [add_customer_fiterKeys.codeArea]: yup.string().required(error_msg.required)
});

const updateBillingSchema = (includeGSTIN) => {
  const baseSchema = {
    [add_customer_fiterKeys.address]: yup.string().required(error_msg.required),
    [add_customer_fiterKeys.area]: yup.string().required(error_msg.required),
    [add_customer_fiterKeys.city]: yup.string().required(error_msg.required),
    [add_customer_fiterKeys.state]: yup.string().required(error_msg.required),
    [add_customer_fiterKeys.zipCode]: yup.string().required(error_msg.required)
  };

  if (includeGSTIN) {
    baseSchema[add_customer_fiterKeys.gstin] = yup
      .string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Please Enter Correct GSTIN"
      )
      .required(error_msg.required);
  }

  return yup.object(baseSchema);
};

const BillingDetails = ({
  hideLabel = false,
  control,
  setValue,
  isFormDataFetching,
  formData,
  billingId,
  billingData,
  isEdit,
  unRegistered,
  isFetchingGst,
  allowCheck,
  handleCheckGst,
  isGstChecked
}) => {
  return (
    <>
      <Grid item xs={12}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.gstin}
          label={add_customer_labels.gstin}
          placeholder={add_customer_labels.gstinPlaceHolder}
          disabled={unRegistered === "yes" || isEdit}
          endIcon={isGstChecked && VerifiedPhone}
          tooltipTitle={"Enter 15 Characters to Verify GSTIN"}
          endButton={() =>
            !isGstChecked && (
              <Button
                variant="link"
                component={LoadingButton}
                loading={isFetchingGst}
                disabled={unRegistered === "yes" || isEdit || !allowCheck}
                onClick={handleCheckGst}
              >
                {"Check"}
              </Button>
            )
          }
        />
      </Grid>
      <Grid item xs={12} ml={0.5}>
        <StyledCheckBoxContainer>
          <CheckBoxGroup
            control={control}
            name={add_customer_fiterKeys.unRegister}
            options={[
              {
                name: "yes",
                displayName: add_customer_labels.unRegister
              }
            ]}
            setValue={setValue}
            disabled={isEdit}
            defaultValues={
              billingId ? (billingData?.billing?.gstNo ? [] : ["yes"]) : []
            }
          />
        </StyledCheckBoxContainer>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.address}
          label={!hideLabel && add_customer_labels.address}
          placeholder={"Address"}
          disabled={isEdit}
          mandatoryField
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.area}
          placeholder={add_customer_labels.area}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.city}
          placeholder={add_customer_labels.city}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <DropDown
          control={control}
          name={add_customer_fiterKeys.state}
          placeholder={add_customer_labels.state}
          isLoading={isFormDataFetching}
          disabled={isEdit}
          options={dropdownOptions(formData?.states, "displayName", "displayName")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.zipCode}
          placeholder={add_customer_labels.code}
          disabled={isEdit}
        />
      </Grid>
    </>
  );
};

const BillingAddress = ({ customerId, billingId }) => {
  const [isEdit, setIsEdit] = useState(Boolean(billingId));
  const [includeGSTIN, setIncludeGSTIN] = useState(true);
  const [storeId, setStoreId] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [showShipToForm, setShowShipToForm] = useState(!Boolean(billingId));
  const [checkGst, setCheckGst] = useState(false);
  const [isGstChecked, setIsGstChecked] = useState("none");
  const { control, setValue, watch, handleSubmit } = useForm({
    resolver: yupResolver(
      Boolean(isEdit)
        ? shipToSchema
        : Boolean(billingId)
        ? updateBillingSchema(includeGSTIN)
        : createBillingSchema(includeGSTIN)
    )
  });
  const handleCheckGst = () => {
    setCheckGst(true);
  };

  const { showDrawer, hideDrawer } = useDrawer();
  const { showDialog, hideDialog } = useCustomDialog();

  const handleStoreEdit = (id = null) => {
    showDrawer({
      title: Boolean(id) ? "Edit Shipto" : "Add Shipto",
      height: "fit-content",
      component: (
        <AddStoreForm
          control={control}
          setValue={setValue}
          watch={watch}
          handleSubmit={handleSubmit}
          customerId={customerId}
          billingId={billingId}
          storeId={id}
          isFormDataFetching={isFormDataFetching}
          options={dropdownOptions(formData?.states, "displayName", "displayName")}
          setShowShipToForm={setShowShipToForm}
          handleCardClick={handleCardClick}
          refetchStores={refetchStores}
          hideDrawer={hideDrawer}
          hideLabel
        />
      )
    });
  };

  const handleEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const handleShipToForm = () => {
    setShowShipToForm(true);
    setSelectedCardIndex(null);
    setStoreId(null);
  };

  const handleCardClick = (index) => {
    setSelectedCardIndex(index);
  };

  const { data: formData, isFetching: isFormDataFetching } =
    useFetchBillingFormData(customerId);

  const {
    data: billingData,
    isFetching: isBillingFetching,
    refetch
  } = useFetchBillingById(customerId, billingId);

  const {
    data: Stores,
    isFetching: isFetchingStores,
    refetch: refetchStores
  } = useFetchShipToAddress(customerId, billingId);

  const {
    mutate: createBillingAddress,
    isLoading: isCreating,
    isError: isCreateError,
    error: createError
  } = useCreateBillingAddress();

  const {
    mutate: updateBillingAddress,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError
  } = useUpdateBillingAddress();

  const unRegistered = watch(add_customer_fiterKeys.unRegister)?.[0];

  useEffect(() => {
    if (unRegistered === "yes") {
      setIncludeGSTIN(false);
      setValue(add_customer_fiterKeys.gstin, "");
    }
  }, [unRegistered, setValue]);

  useEffect(() => {
    if (!isBillingFetching) {
      const billing = billingData?.billing?.address;
      setValue(add_customer_fiterKeys.address, billing?.address);
      setValue(add_customer_fiterKeys.area, billing?.area);
      setValue(add_customer_fiterKeys.city, billing?.city);
      setValue(add_customer_fiterKeys.state, billing?.state);
      setValue(add_customer_fiterKeys.zipCode, billing?.pincode);
      billingData?.billing?.gstNo &&
        setValue(add_customer_fiterKeys.gstin, billingData?.billing?.gstNo);
    }
    // eslint-disable-next-line
  }, [isBillingFetching, billingId, setValue]);

  const onCreateBilling = (data) => {
    createBillingAddress(
      {
        data: {
          address: {
            address: data[add_customer_fiterKeys.address],
            area: data[add_customer_fiterKeys.area],
            city: data[add_customer_fiterKeys.city],
            state: data[add_customer_fiterKeys.state],
            pincode: +data[add_customer_fiterKeys.zipCode]
          },
          ...{
            ...(data[add_customer_fiterKeys.gstin] && {
              gstNo: data[add_customer_fiterKeys.gstin]
            })
          },
          store: {
            address: {
              address: data[add_customer_fiterKeys.shipToAddress],
              area: data[add_customer_fiterKeys.shipToArea],
              city: data[add_customer_fiterKeys.shipToCity],
              state: data[add_customer_fiterKeys.shipToState],
              pincode: +data[add_customer_fiterKeys.shipToZipCode],
              location: {
                coordinates: [
                  +data[add_customer_fiterKeys.lat],
                  +data[add_customer_fiterKeys.lng]
                ]
              }
            },
            code: data[add_customer_fiterKeys.codeArea],
            contact: {
              name: data[add_customer_fiterKeys.pointofContact],
              mobile: data[add_customer_fiterKeys.mobileNo]
            }
          }
        },
        customerId: customerId
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar("Billing Address Saved and New Store Created!", {
            variant: "success"
          });
          window.location.reload();
        }
      }
    );
  };

  const onUpdateBilling = (data) => {
    updateBillingAddress(
      {
        data: {
          address: {
            address: data[add_customer_fiterKeys.address],
            area: data[add_customer_fiterKeys.area],
            city: data[add_customer_fiterKeys.city],
            state: data[add_customer_fiterKeys.state],
            pincode: +data[add_customer_fiterKeys.zipCode]
          },
          ...{
            ...(data[add_customer_fiterKeys.gstin] && {
              gstNo: data[add_customer_fiterKeys.gstin]
            })
          }
        },
        customerId: customerId,
        billingId: billingId
      },
      {
        onSuccess: () => {
          handleEdit();
          enqueueSnackbar("Billing Address Changes Saved", {
            variant: "success"
          });
          refetch();
        }
      }
    );
  };

  useEffect(() => {
    if (isCreateError)
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    if (isUpdateError)
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
  }, [
    updateError?.response?.data?.message,
    createError?.response?.data?.message,
    isUpdateError,
    isCreateError
  ]);

  const gstWatch = watch(add_customer_fiterKeys.gstin);

  const allowCheck = gstWatch?.length === 15;

  const {
    data: gstData,
    isFetching: isFetchingGst,
    refetch: refetchGst,
    isSuccess: isGstSuccess,
    isError: isGstError,
    error: gstError
  } = useFetchGst(checkGst, gstWatch);

  const EwayBillLoginShow = useCallback(
    (autoSubmit) => {
      showDialog({
        component: (
          <EwayBillLogin hideDialog={hideDialog} autoSubmit={autoSubmit} />
        ),
        size: "xs",
        closeIcon: true
      });
    },
    [showDialog, hideDialog]
  );

  useEffect(() => {
    if (!allowCheck) {
      setIsGstChecked("none");
      setCheckGst(false);
      setValue(add_customer_fiterKeys.address, "");
      setValue(add_customer_fiterKeys.area, "");
      setValue(add_customer_fiterKeys.city, "");
      setValue(add_customer_fiterKeys.state, "");
      setValue(add_customer_fiterKeys.zipCode, "");
    }
  }, [allowCheck, setValue]);

  const getEndIcon = (type) => {
    switch (type) {
      case "none":
        return false;
      case "1":
        return VerifiedPhone;
      case "2":
        return BlockedIcon;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (isGstSuccess) {
      if (gstData?.gst?.blockStatus?.name === "U") {
        setIsGstChecked("1");
        setCheckGst(false);
        setValue(add_customer_fiterKeys.address, gstData?.gst?.address1);
        setValue(add_customer_fiterKeys.area, gstData?.gst?.address2);
        setValue(add_customer_fiterKeys.city, gstData?.gst?.address3 || "");
        setValue(
          add_customer_fiterKeys.state,
          capitalizeFirstCharacter(gstData?.gst?.state?.displayName)
        );
        setValue(add_customer_fiterKeys.zipCode, gstData?.gst?.pincode);
      } else {
        setIsGstChecked("2");
        setCheckGst(false);
        showDialog({
          component: (
            <ErrorPopup
              text={`This GSTIN is Blocked`}
              subText={`Legal Name : ${gstData?.gst?.legalName || "--"}`}
              btn2Text={"Try Another GSTIN"}
              btn2Callback={() => {
                hideDialog();
                setIsGstChecked("none");
              }}
            />
          ),
          backdropOff: true
        });
      }
    } // eslint-disable-next-line
  }, [
    isGstSuccess,
    setIsGstChecked,
    gstData?.gst?.blockStatus?.name,
    setValue
  ]);

  useEffect(() => {
    if (isGstError) {
      if (gstError?.response?.data?.ewbErrorCode === "238") {
        EwayBillLoginShow(refetchGst);
      } else {
        enqueueSnackbar(gstError?.response?.data?.message, {
          variant: "error"
        });
      }
      setCheckGst(false);
    }
    // eslint-disable-next-line
  }, [gstError?.response?.data?.message, isGstError]);

  return isFetchingStores ? (
    <Grid
      item
      xs={12}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetchingStores}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  ) : (
    <>
      <StyledBox
        sx={{ display: { xs: "none", md: "flex" } }}
        flexDirection={"column"}
        gap={2}
      >
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StyledBox
                display={"flex"}
                flexDirection={"column"}
                width={"100%"}
              >
                <Grid item xs={12} container spacing={2}>
                  <BillingDetails
                    billingData={billingData}
                    billingId={billingId}
                    control={control}
                    formData={formData}
                    isEdit={isEdit}
                    isFormDataFetching={isFormDataFetching}
                    setValue={setValue}
                    unRegistered={unRegistered}
                    allowCheck={allowCheck}
                    handleCheckGst={handleCheckGst}
                    isFetchingGst={isFetchingGst}
                    endIcon={getEndIcon(isGstChecked)}
                  />
                </Grid>
                <Grid item xs={12}>
                  {billingId && (
                    <Box display={"flex"} justifyContent={"right"}>
                      {isEdit ? (
                        <Button variant="text" onClick={handleEdit}>
                          {"Edit"}
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          component={LoadingButton}
                          loading={isUpdating}
                          onClick={handleSubmit(onUpdateBilling)}
                        >
                          {"Save"}
                        </Button>
                      )}
                    </Box>
                  )}
                </Grid>
              </StyledBox>
            </Grid>
            {showShipToForm && (
              <Grid item xs={6}>
                <StyledBox
                  display={"flex"}
                  flexDirection={"column"}
                  width={"100%"}
                >
                  <Grid item xs={12} container spacing={2}>
                    <AddStoreForm
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      handleSubmit={handleSubmit}
                      customerId={customerId}
                      billingId={billingId}
                      storeId={storeId}
                      isFormDataFetching={isFormDataFetching}
                      options={dropdownOptions(formData?.states, "displayName", "displayName")}
                      setShowShipToForm={setShowShipToForm}
                      handleCardClick={handleCardClick}
                      refetchStores={refetchStores}
                      hideDrawer={hideDrawer}
                    />
                  </Grid>
                </StyledBox>
              </Grid>
            )}
          </Grid>
          {billingId ? (
            <>
              <Box
                display={"flex"}
                gap={2}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Box
                  display={"flex"}
                  gap={2}
                  justifyContent={"left"}
                  alignItems={"flex-end"}
                >
                  <img src={AddStore} alt="Store" />
                  <TitleText>{add_customer_labels.addStore}</TitleText>
                </Box>
                <Button
                  variant="text"
                  whiteSpace="nowrap"
                  disableRipple
                  onClick={handleShipToForm}
                >
                  <AddOutlined /> {add_customer_labels.addStoreButton}
                </Button>
              </Box>
              {Stores?.stores.length > 0 && (
                <Box
                  display={"flex"}
                  border={"0.6px solid #027AFF"}
                  borderRadius={"8px"}
                  padding={"15px 25px"}
                  gap={2}
                  sx={{ overflowX: "auto" }}
                >
                  {Stores?.stores?.map((item, index) => (
                    <WarehouseCard
                      key={index}
                      index={index}
                      code={`${item?.code}`}
                      formId={item?._id}
                      address={`${item?.address?.city}, ${item?.address?.state}`}
                      selectedCardIndex={selectedCardIndex}
                      handleCardClick={handleCardClick}
                      setFormId={setStoreId}
                      setShowForm={setShowShipToForm}
                      sx={{
                        background:
                          selectedCardIndex === index ? "#6097FF" : "#607088 ",
                        position:
                          selectedCardIndex === index ? "relative" : "static",
                        top: selectedCardIndex === index ? -8 : 0
                      }}
                    />
                  ))}
                </Box>
              )}
            </>
          ) : (
            <Box display={"flex"} justifyContent={"end"}>
              <Button
                variant="contained"
                component={LoadingButton}
                loading={isCreating}
                onClick={handleSubmit(onCreateBilling)}
              >
                {"Save"}
              </Button>
            </Box>
          )}
        </Box>
      </StyledBox>
      <Grid
        container
        sx={{ display: { xs: "block", md: "none" } }}
        mb={!billingId && 15}
      >
        <Grid item xs={12}>
          <AccordianInfo
            title={"Billing Address"}
            defaultExpanded={!billingId}
            hideDivider
          >
            <Grid container rowSpacing={2} columnSpacing={4}>
              <BillingDetails
                billingData={billingData}
                billingId={billingId}
                control={control}
                formData={formData}
                isEdit={isEdit}
                isFormDataFetching={isFormDataFetching}
                setValue={setValue}
                unRegistered={unRegistered}
                hideLabel
                allowCheck={allowCheck}
                handleCheckGst={handleCheckGst}
                isFetchingGst={isFetchingGst}
                endIcon={getEndIcon(isGstChecked)}
              />
            </Grid>
            {!Boolean(billingId) && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} container spacing={2} mt={1}>
                  <AddStoreForm
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    handleSubmit={handleSubmit}
                    customerId={customerId}
                    billingId={billingId}
                    storeId={storeId}
                    isFormDataFetching={isFormDataFetching}
                    options={dropdownOptions(formData?.states, "displayName", "displayName")}
                    setShowShipToForm={setShowShipToForm}
                    handleCardClick={handleCardClick}
                    refetchStores={refetchStores}
                    hideDrawer={hideDrawer}
                  />
                </Grid>
              </>
            )}
          </AccordianInfo>
        </Grid>
        {!Boolean(billingId) ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              width: "100%",
              position: "fixed",
              bottom: "70px",
              zIndex: 1
            }}
          >
            <DrawerButton
              variant="contained"
              component={LoadingButton}
              loading={isCreating}
              onClick={handleSubmit(onCreateBilling)}
            >
              {"Save "}
            </DrawerButton>
          </Grid>
        ) : (
          <Grid item xs={12} container spacing={4} px={"25px"}>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"right"}
              alignItems={"center"}
            >
              <Button
                variant="link"
                disableRipple
                onClick={() => handleStoreEdit(null)}
              >
                {"+ Add Shipto"}
              </Button>
            </Grid>
            {Stores?.stores.length > 0 && (
              <Grid item xs={12} container spacing={4}>
                {Stores?.stores?.map((item, index) => (
                  <Grid item xs={6}>
                    <MobileWarehouseCard
                      index={index}
                      code={item?.code}
                      id={item?._id}
                      onEditClick={() => handleStoreEdit(item?._id)}
                      sx={{
                        background: "#6097FF"
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default BillingAddress;
