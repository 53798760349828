import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  styled
} from "@mui/material";
import { otherControlsHover, otherControlsResting } from "../theme/globalTheme";
import { Controller } from "react-hook-form";

const CustomIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 24,
  height: 24,
  backgroundColor: otherControlsResting,
  "input:hover ~ &": {
    backgroundColor: otherControlsHover
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "#EEF1F4"
  }
}));

const CustomIconChecked = styled(CustomIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "&:before": {
    display: "block",
    width: 24,
    height: 24,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""'
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.primary.dark
  }
}));

const RadioButtonGroup = ({
  label,
  helperText,
  name,
  control,
  options,
  disabled = false,
  display = "flex",
  flexDirection = "row",
  sx = {},
  RadioGroupSx = { width: "100%" },
  defaultValue = ""
}) => {
  const generateRadioOptions = (field) => {
    return options.map((option, index) => {
      return (
        <Grid
          item
          key={index}
          xs={option?.xsSize || 12}
          md={option?.mdSize || 12}
          sx={sx}
        >
          <FormControlLabel
            key={option.value}
            value={option.value}
            label={option.label}
            disabled={option?.disabled}
            control={
              <Radio
                checkedIcon={<CustomIconChecked />}
                icon={<CustomIcon />} 
                disabled={option?.disabled}
                sx={{
                  color: `${option.color}`,
                  "&.Mui-checked + span": {
                    color: `${option.color} !important`
                  }
                }}
                checked={field.value === option.value}
              />
            }
            sx={{ "& .MuiTypography-root": { color: option?.color, ...sx } }}
          />
        </Grid>
      );
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue} // Use defaultValue prop
      render={({ field, fieldState: { error } }) => (
        <FormControl
          sx={{
            display: display,
            flexDirection: "row",
            alignItems: "center",
            "& > div": {
              gap: flexDirection === "row" ? 0 : "14px",
              flexDirection: flexDirection,
              "& > label": {
                marginLeft: 0
              }
            }
          }}
          disabled={disabled}
        >
          {label && (
            <FormLabel
              id={name}
              sx={{
                // border: "2px solid red",
                marginRight: { xs: "18px", md: "28px" },
                fontWeight: field.value && 500,
                marginBottom: "initial"
              }}
            >
              {label}
            </FormLabel>
          )}
          <RadioGroup
            {...field}
            aria-labelledby={name}
            name={name}
            row
            onChange={(e) => field.onChange(e.target.value)}
            sx={RadioGroupSx}
          >
            <Grid container rowSpacing={3}>
              {generateRadioOptions(field)}
            </Grid>
          </RadioGroup>
          {helperText && (
            <FormHelperText sx={{ letterSpacing: "0.1em" }}>
              {helperText}
            </FormHelperText>
          )}
          {error && (
            <FormHelperText
              error
              sx={{
                marginLeft: 0
              }}
            >
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default RadioButtonGroup;
